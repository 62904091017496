import React, { PropsWithChildren, useEffect } from 'react'
import { UserRole } from '@frontend/shared/types'
import { useBoolean } from '@frontend/shared/hooks'
import { LoaderFullscreen } from '@frontend/shared/ui'
import { useRouter } from 'next/router'

import { useAuthentication } from '../context'

interface RightsProps {
  fallbackUrl: string
  rolesPage: UserRole[]
}

export const Rights = ({ children, fallbackUrl, rolesPage }: PropsWithChildren<RightsProps>) => {
  const { customer } = useAuthentication()
  const { value: pageAllowed, setTrue: setPageAllowedTrue, setFalse: setPageAllowedFalse } = useBoolean(false)
  const router = useRouter()

  useEffect(() => {
    if (rolesPage.some((role) => customer?.roles?.includes(role))) {
      setPageAllowedTrue()
    } else {
      router.replace(fallbackUrl)
    }
  }, [rolesPage, fallbackUrl, router, setPageAllowedFalse, setPageAllowedTrue, customer?.roles])

  if (pageAllowed) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return <LoaderFullscreen />
}
