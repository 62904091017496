import { getEnvironmentVariable } from '@frontend/shared/environment'

/**
 * For working example in storybook.
 * All content in storybook is wrapped into iframe tag.
 */
export const getWindow = (): Window => (getEnvironmentVariable('STORYBOOK') ? window.parent : window)

export const getHashName = () => getWindow().location.hash.substr(1)

export const scrollToTop = () => window.scrollTo(0, 0)

export const getCookieDomain = () => window.location.hostname
