import { Text } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import React, { PropsWithChildren } from 'react'

export const CardSuffix = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useTheme()

  return (
    <Text color={theme.colors.gray['500']} fontSize={[theme.fontSizes.text.lg, theme.fontSizes.text.xl]} size='xl'>
      {children}
    </Text>
  )
}
