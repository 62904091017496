import { css, styled, StyledProps } from '@frontend/shared/theme'
import { space } from 'styled-system'
import { FC, ForwardedRef, InputHTMLAttributes, MutableRefObject, ReactNode } from 'react'

import { Flex } from '../../../layout/flex/flex'
import { getBorderColor } from '../base/input.styles'

export interface InputAffix {
  component: ReactNode
  type: 'Icon' | 'LeadingText'
}

export interface InputStyledProps extends InputHTMLAttributes<HTMLInputElement> {
  customPrefix?: InputAffix
  error?: boolean
  ref?: MutableRefObject<HTMLInputElement | undefined> | ForwardedRef<HTMLInputElement>
  touched?: boolean
}

interface ContentProps {
  focused: boolean
  error?: boolean
  hovered?: boolean
  touched?: boolean
  value?: string | ReadonlyArray<string> | number
}

const getBackground = ({ disabled, theme }: StyledProps<InputStyledProps>) => {
  if (disabled) {
    return theme.colors.gray['50']
  }

  return theme.components.input.backgroundColor
}

const getColor = ({ disabled, theme }: StyledProps<InputStyledProps>) => {
  if (disabled) {
    return theme.colors.gray['500']
  }

  return theme.components.input.textColor
}

const getSvgColor = ({ theme, focused, hovered, touched, error }: StyledProps<ContentProps>) => {
  if (touched && error) {
    return theme.colors.error['500']
  }

  if (focused || hovered) {
    return theme.colors.primary.blue.mainHover
  }

  return undefined
}

const getBorderRadius = ({ customPrefix }: StyledProps<InputStyledProps>) => {
  if (customPrefix?.type === 'LeadingText') {
    return css`
      border-radius: 0.5rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `
  }

  return css`
    border-radius: 0.5rem;
  `
}

const getPaddingLeft = ({ customPrefix }: StyledProps<InputStyledProps>) => {
  return customPrefix?.type === 'Icon' ? '2.625rem' : '0.875rem'
}

export const InputStyled: FC<InputStyledProps> = styled.input.attrs<InputStyledProps>(({ error, ...props }) => ({
  pl: getPaddingLeft(props),
  pr: error ? '2.5rem' : '0.875rem',
  py: '0.625rem',
}))`
  ${space};

  font-size: 1rem;
  color: ${getColor};
  font-weight: 400;
  width: 100%;
  height: 2.75rem;
  background: ${getBackground};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${getBorderColor};
  ${getBorderRadius};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.xs};

  ::placeholder {
    color: ${({ theme }) => theme.components.input[':placeholder'].textColor};
    font-weight: 400;
    font-size: 1rem;
  }
`

export const Content = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex: 1;

  svg {
    transition: all ${({ theme }) => theme.transition};
    color: ${getSvgColor};
  }
`

export const ErrorWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.875rem;
  margin: auto;
`
