import React from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Flex } from '../../../layout/flex/flex'
import { Text } from '../../../text/typography'
import { Grid } from '../../../layout/grid/grid'

import { Step, StepSize } from './progress-steps.styles'

interface ProgressStepsProps {
  activeStep: number
  steps: number
  size?: StepSize
}

export const ProgressSteps = ({ steps, activeStep, size = 'md' }: ProgressStepsProps) => {
  const theme = useTheme()

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Flex alignItems='center' flex='0 auto' mr={2}>
        <Text color={theme.colors.gray['700']} size='sm'>
          Step {activeStep} of {steps}
        </Text>
      </Flex>
      <Flex flex={0}>
        <Grid gap='sm' gridTemplateColumns={`repeat(${steps}, auto)`}>
          {new Array(steps).fill(0).map((_, index) => {
            const active = index + 1 === activeStep
            const passed = index + 1 < activeStep

            return <Step active={active} key={`step-${index}`} passed={passed} size={size} />
          })}
        </Grid>
      </Flex>
    </Flex>
  )
}
