import React from 'react'

import { Section } from '../../../layout/section/section'
import { Skeleton } from '../root/skeleton'
import { Form } from '../../../form'
import { Flex } from '../../../layout/flex'

export const SkeletonForm = () => (
  <Section>
    <Form.Group>
      <Skeleton height='3rem' width='100%' />
      <Skeleton height='3rem' width='100%' />
    </Form.Group>
    <Form.Group>
      <Skeleton height='3rem' width='100%' />
      <Skeleton height='3rem' width='100%' />
    </Form.Group>
    <Form.Group>
      <Skeleton height='3rem' width='100%' />
      <Skeleton height='3rem' width='100%' />
    </Form.Group>
    <Flex justifyContent='flex-end'>
      <Flex height='3rem' width='10rem'>
        <Skeleton height='100%' width='100%' />
      </Flex>
    </Flex>
  </Section>
)
