import { styled, StyledProps, Theme } from '@frontend/shared/theme'
import { StylesConfig } from 'react-select'

import { Flex } from '../../layout/flex/flex'

export interface TabProps {
  disabled?: boolean
  selected?: boolean
}

const getTabColor = ({ selected, theme }: StyledProps<TabProps>) =>
  selected ? theme.colors.primary.blue.mainHover : theme.colors.gray['500']

const getBackgroundColor = ({ selected, theme }: StyledProps<TabProps>) =>
  selected ? 'linear-gradient(270deg, #06AAB7 0%, #68C07D 0.01%, #02A8B4 106.52%)' : theme.colors.gray['200']

export const TabsWrapper = styled(Flex).attrs(() => ({
  as: 'ul',
}))`
  padding-left: 0;
  position: relative;
  width: 100%;
  height: 2.375rem;
  overflow-x: auto;

  &:before {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.gray['200']};
  }
`

export const Tab = styled.div.attrs(() => ({
  as: 'li',
}))<TabProps>`
  list-style-type: none;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${getTabColor};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  font-size: 1rem;
  font-weight: 500;
  transition: all ${({ theme }) => theme.transition};
  padding: 0 0.5rem 0.5rem 0.5rem;

  &:after {
    content: '';
    position: absolute;
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
    bottom: 0;
    left: 0;
    width: ${({ selected }) => (selected ? '100%' : '0%')};
    height: ${({ selected }) => (selected ? '0.125rem' : '0.0625rem')};
    background: ${getBackgroundColor};
    transition: all ${({ theme }) => theme.transition};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.blue.main};
    transition: all ${({ theme }) => theme.transition};

    &:after {
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(270deg, #06aab7 0%, #68c07d 0.01%, #02a8b4 106.52%);
      transition: all ${({ theme }) => theme.transition};
    }
  }

  &:focus {
    color: ${({ theme }) => theme.colors.gray['500']};
    transition: all ${({ theme }) => theme.transition};
    outline: transparent;
  }
`

export const getTabsSelectStyles = <OptionType, IsMulti extends boolean>(
  theme: Theme,
): Partial<StylesConfig<OptionType, IsMulti>> => ({
  control: (base, { isFocused }) => ({
    ...base,
    width: '100%',
    height: '3rem',
    boxShadow: undefined,
    background: 'transparent',
    border: 'none',
    borderRadius: 'none',
    borderBottomWidth: '0.125rem',
    borderBottomStyle: 'solid',
    borderBottomColor: isFocused ? theme.colors.primary.blue.main : theme.colors.gray['300'],
    outline: 'transparent',
    fontSize: theme.fontSizes.text.md,
    transition: theme.transition,
    '&:hover': {
      borderBottomColor: theme.colors.primary.blue.main,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0.5rem 0.75rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
    fontWeight: 600,
    minWidth: 'auto',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  input: (base) => ({
    ...base,
    fontSize: theme.fontSizes.text.md,
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.colors.gray['900'],
    fontWeight: 400,
    maxWidth: 'calc(100% - 1rem)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  option: (base, { isDisabled, isSelected }) => ({
    ...base,
    color: theme.colors.gray['900'],
    fontSize: theme.fontSizes.text.md,
    background: isSelected ? theme.colors.gray['100'] : 'transparent',
    cursor: isDisabled ? 'default' : 'pointer',
    padding: '0.75rem 1rem',
    transition: theme.transition,
    '&:hover': {
      background: theme.colors.gray['100'],
    },
    '&:first-of-type': {
      borderRadius: '0.25rem 0.25rem 0 0',
    },
    '&:last-of-type': {
      borderRadius: '0 0 0.25rem 0.25rem',
    },
  }),
  menu: (base) => ({
    ...base,
    marginTop: 4,
    boxShadow: theme.shadows.lg,
    borderWidth: 1,
    borderColor: theme.colors.gray['300'],
    borderStyle: 'solid',
    padding: 0,
    zIndex: 10,
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    zIndex: 10,
  }),
})
