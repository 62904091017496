import { FC } from 'react'
import { styled } from '@frontend/shared/theme'

import { Flex, FlexProps } from '../../../../layout'

interface WrapperProps extends FlexProps {
  rangePicker?: boolean
}

export const Wrapper: FC<WrapperProps> = styled(Flex).attrs(() => ({
  p: 2,
}))<WrapperProps>`
  border-radius: 0.5rem;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.primary.contrast};
  box-shadow: ${({ theme }) => theme.shadows.md};
  cursor: default;

  .datepicker-style-0-years .react-datepicker__month-container:nth-child(1) .react-datepicker__month,
  .datepicker-style-0-years .react-datepicker__month-container:nth-child(1) .react-datepicker__day-names,
  .datepicker-style-0-months .react-datepicker__month-container:nth-child(1) .react-datepicker__month,
  .datepicker-style-0-months .react-datepicker__month-container:nth-child(1) .react-datepicker__day-names {
    display: none;
  }

  .datepicker-style-1-years .react-datepicker__month-container:nth-child(2) .react-datepicker__month,
  .datepicker-style-1-years .react-datepicker__month-container:nth-child(2) .react-datepicker__day-names,
  .datepicker-style-1-months .react-datepicker__month-container:nth-child(2) .react-datepicker__month,
  .datepicker-style-1-months .react-datepicker__month-container:nth-child(2) .react-datepicker__day-names {
    display: none;
  }

  .react-datepicker-wrapper {
    display: none;
  }

  *:focus {
    outline: transparent;
  }

  .react-datepicker-popper {
    position: relative !important;
    transform: unset !important;
    top: unset !important;
    left: unset !important;
    margin: 0 !important;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__header {
    border-bottom-width: 0.0625rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    background: transparent;
    text-align: left;
    padding-top: 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__month {
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  .react-datepicker__day-names {
    margin-top: 1rem;

    > * {
      color: ${({ theme }) => theme.colors.gray['700']};
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
  }

  .react-datepicker__day-name {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .react-datepicker__day {
    border-radius: 50%;
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    color: ${({ theme }) => theme.colors.gray['500']};
    margin: 0.0625rem 0;
    transition: all ${({ theme }) => theme.transition};
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background: ${({ theme }) => theme.colors.gray['100']};
  }

  .react-datepicker__day--disabled {
    opacity: 0.6;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: ${({ theme }) => theme.colors.primary.blue.mainHover};
    color: ${({ theme }) => theme.colors.primary.contrast};
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: ${({ theme }) => theme.fontSizes.text.md};
    font-weight: normal;
    line-height: 2;
  }

  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.colors.primary.blue.mainHover};
    color: ${({ theme }) => theme.colors.primary.contrast};
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background: ${({ theme }) => theme.colors.primary.blue.mainHover};
    color: ${({ theme }) => theme.colors.primary.contrast};
    border-radius: 0;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    background: ${({ theme }) => theme.colors.primary.blue.mainHover};
    color: ${({ theme }) => theme.colors.primary.contrast};
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    background: ${({ theme }) => theme.colors.primary.blue.mainHover};
    color: ${({ theme }) => theme.colors.primary.contrast};
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.gray['200']};
    background: ${({ theme }) => theme.colors.primary.contrast};
  }

  .react-datepicker__current-month {
  }
`
