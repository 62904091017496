import { STORAGE_REFRESH_TOKEN_EXPIRES_AT, STORAGE_TOKEN_EXPIRES_AT } from '@frontend/shared/constants'
import { getCookieDomain, getLocalStorage } from '@frontend/shared/utils'
import { Logger } from '@frontend/shared/logger'

import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

interface RefreshTokenResponse {
  expiresAt: string
  refreshExpiresAt: string
}

export const useServiceRefreshToken = () => {
  const { post, error, loading, response } = useAuthFetch<RefreshTokenResponse>()
  const { set: setTokenExpiresAt } = getLocalStorage(STORAGE_TOKEN_EXPIRES_AT)
  const { set: setRefreshTokenExpiresAt } = getLocalStorage(STORAGE_REFRESH_TOKEN_EXPIRES_AT)

  const refreshToken = async (): Promise<void> => {
    const result = await post(ENDPOINT_URL.refreshToken, { cookieDomain: getCookieDomain() })

    if (response.ok) {
      setTokenExpiresAt(result.expiresAt)
      setRefreshTokenExpiresAt(result.refreshExpiresAt)
    } else {
      Logger.error('Refresh token failed', response)
    }
  }

  return { refreshToken, loading, error }
}
