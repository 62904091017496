import React from 'react'

import { Grid } from '../../../../../layout/grid/grid'
import { InputTabRadio, InputTabRadioProps } from '../root/input-tab-radio'

type Option = Pick<InputTabRadioProps, 'title' | 'description' | 'icon' | 'value' | 'disabled'>

export type Options = Option[]

export interface InputTabRadioGroupProps extends Pick<InputTabRadioProps, 'name' | 'checked' | 'onChange'> {
  options: Options
  gridTemplateColumns?: string | string[]
}

export const InputTabRadioGroup = ({
  options,
  name,
  onChange,
  checked,
  gridTemplateColumns,
}: InputTabRadioGroupProps) => (
  <Grid gap='sm' gridTemplateColumns={gridTemplateColumns ?? `repeat(${options.length}, max-content)`}>
    {options.map(({ value, description, title, icon, disabled }) => (
      <InputTabRadio
        checked={checked}
        description={description}
        disabled={disabled}
        icon={icon}
        key={value}
        name={name}
        title={title}
        value={value}
        onChange={onChange}
      />
    ))}
  </Grid>
)
