import React, { useCallback } from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { useTheme } from '@frontend/shared/theme'

import { Toast, progressColor } from './toast'

interface UseToastOptions extends ToastOptions {
  message?: string
}

export type UseToast = (title: string, overrideOptions?: UseToastOptions) => void

export const useToast = (toastOptions?: ToastOptions) => {
  const theme = useTheme()

  const showToast = useCallback(
    (title: string, message: string, overrideOptions?: UseToastOptions) => {
      const { type = 'success' } = overrideOptions || {}
      const options: UseToastOptions = { ...toastOptions, ...overrideOptions }

      toast(<Toast message={message || overrideOptions?.message} title={title} type={type} />, {
        ...options,
        position: options.position ?? toast.POSITION.TOP_RIGHT,
        autoClose: options.autoClose ?? 5000,
        progressStyle: {
          backgroundColor: progressColor(type, theme),
        },
      })
    },
    [theme, toastOptions],
  )

  const showToastDefault = useCallback(
    (title: string, message: string, overrideOptions?: UseToastOptions) => {
      showToast(title, message, { ...overrideOptions, type: 'default' })
    },
    [showToast],
  )

  const showToastInfo = useCallback(
    (title: string, message: string, overrideOptions?: UseToastOptions) => {
      showToast(title, message, { ...overrideOptions, type: 'info' })
    },
    [showToast],
  )

  const showToastError = useCallback(
    (title: string, message: string, overrideOptions?: UseToastOptions) => {
      showToast(title, message, { ...overrideOptions, type: 'error' })
    },
    [showToast],
  )

  const showToastSuccess = useCallback(
    (title: string, message: string, overrideOptions?: UseToastOptions) => {
      showToast(title, message, { ...overrideOptions, type: 'success' })
    },
    [showToast],
  )

  const showToastWarning = useCallback(
    (title: string, message: string, overrideOptions?: UseToastOptions) => {
      showToast(title, message, { ...overrideOptions, type: 'warning' })
    },
    [showToast],
  )

  const showToastGenericError = useCallback(() => {
    showToastError('Application error', 'Please retry in few minutes. The problem was reported.')
  }, [showToastError])

  const showToastErrorRecaptcha = useCallback(() => {
    showToastError('Recaptcha', 'We could not initialize recaptcha. Please, refresh the page and try it again')
  }, [showToastError])

  return {
    showToast,
    showToastDefault,
    showToastInfo,
    showToastError,
    showToastSuccess,
    showToastWarning,
    showToastGenericError,
    showToastErrorRecaptcha,
  }
}
