import getConfig from 'next/config'

/**
 * Reads environment setting from the next publicRuntimeConfig with a fallback
 * via process.env
 *
 * @param {string} key Environment property name
 * @param {string} defaultValue Optional default value in case environment variable has not been set
 * @returns {string} value of environment property
 */
export const getEnvironmentVariable = (key: string, defaultValue = ''): string =>
  getConfig()?.publicRuntimeConfig?.[key] || process.env[key] || defaultValue

export const IS_DEV = getEnvironmentVariable('NEXT_PUBLIC_DEPLOYMENT_TARGET') === 'development'
export const IS_TEST = getEnvironmentVariable('NEXT_PUBLIC_DEPLOYMENT_TARGET') === 'test'
