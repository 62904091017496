import React, { PropsWithChildren } from 'react'
import { AuthenticationProvider, LoginProvider } from '@frontend/domains/shared/authentication'
import { isAppSection, routes } from '@frontend/domains/plus/routes'

const initialAppRoute = () => routes.dashboard.getUrl()

export const AppPlus = ({ children }: PropsWithChildren<unknown>) => (
  <AuthenticationProvider isAppSection={isAppSection}>
    <LoginProvider initialAppRoute={initialAppRoute}>{children}</LoginProvider>
  </AuthenticationProvider>
)
