import React from 'react'
import { Button, Icon } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import { useAuthentication } from '@frontend/domains/shared/authentication'

import { UserProfileWrapper, TextWrapper } from './user-profile.styles'

export const UserProfile = (): JSX.Element => {
  const theme = useTheme()
  const { signOut } = useAuthentication()

  return (
    <UserProfileWrapper>
      <TextWrapper mr={4}>
        <Button name='sign-out' type='icon' onClick={signOut}>
          <Icon color={theme.colors.primary.contrast} fontSize='1.5rem' kind='FiLogOut' />
        </Button>
      </TextWrapper>
      {/*<UserAvatar name='A.S.' size='sm' />*/}
    </UserProfileWrapper>
  )
}
