import React, { useEffect, useRef } from 'react'
import { Button, Display, FieldOtp, Flex, Form, Grid, Text } from '@frontend/shared/ui'
import { useRouter } from 'next/router'
import { Logger } from '@frontend/shared/logger'
import { FormikProps } from 'formik'
import { useBoolean } from '@frontend/shared/hooks'
import Image from 'next/image'

import { LoginAction, useServiceSkipRequiredAction } from '../../service'
import { useLogin } from '../../context/login/context-login'
import { routes } from '../../router/routes'

import { initialValues } from './form/initial-values'
import { validationSchema } from './form/validation-schema'
import { FormValues } from './form/types'
import { TwoFactorAuthenticationSkeleton } from './components/skeleton'

export const TwoFactorAuthentication = () => {
  const router = useRouter()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const serviceSkipRequiredAction = useServiceSkipRequiredAction()
  const { action, registerDevice, authenticateOTP, otpQrCodeBase64, loading, email } = useLogin()
  const { value: initializing, setFalse: setInitializingFalse } = useBoolean(true)

  const submitForm = async ({ otp }: FormValues) => {
    switch (action) {
      case LoginAction.RegisterOTP:
        await registerDevice<FormValues>(otp, formikRef)

        break
      case LoginAction.AuthenticateOTP:
        await authenticateOTP<FormValues>(otp, formikRef)

        break
      default:
        Logger.error(`Implement new action for two factor authentication - ${action}`)
        break
    }
  }

  const goToSignIn = async () => router.replace(routes.signIn.getUrl())

  const initialize = async (action: LoginAction) =>
    [LoginAction.AuthenticateOTP, LoginAction.UpdatePassword, LoginAction.RegisterOTP].includes(action)
      ? setInitializingFalse()
      : goToSignIn()

  const skipOTP = async () => {
    const skipped = await serviceSkipRequiredAction.skipRequiredAction(email)

    skipped && (await goToSignIn())
  }

  useEffect(() => {
    initialize(action)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  if (initializing) {
    return <TwoFactorAuthenticationSkeleton />
  }

  return (
    <Grid>
      <Display data-test-id='title' size='xs' textAlign='center' weight={500}>
        Two-factor Authentication
      </Display>

      <Grid gap='sm'>
        {action === LoginAction.AuthenticateOTP && (
          <Text textAlign='center'>Sign in using Google Authenticator on your mobile phone</Text>
        )}

        {action === LoginAction.RegisterOTP && (
          <>
            <Text textAlign='center'>
              Enable two-factor authentication (2FA) <br />
              as an extra layer of protection by using Google Authenticator on your mobile phone
            </Text>

            <Flex justifyContent='center'>
              <Image alt='QR code' data-test-id='qr-code' height={200} src={otpQrCodeBase64} width={200} />
            </Flex>
          </>
        )}

        <Form<FormValues>
          initialValues={initialValues}
          innerRef={formikRef}
          name='two-factor-authentication-otp'
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          <Grid>
            <FieldOtp autoFocus label='Enter the 6-digit OTP code from your app' name='otp' placeholder='-'>
              <FieldOtp.Input data-type='input' />
              <FieldOtp.Input data-type='input' />
              <FieldOtp.Input data-type='input' />
              <FieldOtp.Input data-type='input' />
              <FieldOtp.Input data-type='input' />
              <FieldOtp.Input data-type='input' />
            </FieldOtp>

            <Grid gap='sm' gridTemplateColumns='100%'>
              <Button disabled={loading} htmlType='submit' loading={loading} name='finish'>
                {action === LoginAction.AuthenticateOTP ? 'Confirm OTP code' : 'Enable extra security'}
              </Button>
              {action === LoginAction.RegisterOTP && (
                <Button disabled={loading} htmlType='button' name='skip-otp' type='linkColor' onClick={skipOTP}>
                  Skip 2FA
                </Button>
              )}
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  )
}
