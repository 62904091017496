export const colors = {
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#E4E7EC',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  primary: {
    blue: {
      main: '#00A9B5',
      mainHover: '#149BA5',
      25: 'rgba(0, 135, 145, 0.25)',
      50: 'rgba(0, 135, 145, 0.5)',
      75: 'rgba(0, 135, 145, 0.75)',
    },
    darkBlue: {
      main: '#004F5D',
      mainHover: '#004552',
    },
    mainGradient: {
      main: 'linear-gradient(270deg, #06AAB7 0%, #68C07D 0.01%, #02A8B4 106.52%)',
      mainHover: 'linear-gradient(270deg, #49B05C 0.01%, #149BA5 106.52%)',
    },
    green: {
      main: '#6EC179',
      mainHover: '#49B05C',
      50: '#6EC179',
      75: '#6EC179',
      900: '#42307D',
    },
    contrast: '#fff',
  },
  secondary: {
    darkBlue: {
      main: '#598D95',
    },
    bgGray: {
      main: '#F0F4F5',
      50: 'rgba(240, 244, 245, 0.5)',
      70: 'rgba(240, 244, 245, 0.7)',
    },
    darkGray: {
      main: '#598D95',
      25: 'rgba(89, 141, 149, 0.25)',
      50: 'rgba(89, 141, 149, 0.5)',
      70: 'rgba(89, 141, 149, 0.7)',
    },
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
}
