import { Flex } from '@frontend/shared/ui'
import { css, styled } from '@frontend/shared/theme'

interface MenuItemWrapperProps {
  active: boolean
  subMenu?: boolean
  submenuActive?: boolean
  visible?: boolean
}

export const IconWrapper = styled(Flex).attrs(() => ({
  width: '1.5rem',
  height: '1.5rem',
}))``

export const MenuItemWrapper = styled(Flex).attrs<MenuItemWrapperProps>(({ subMenu, visible }) => ({
  height: '2.5rem',
  px: '0.5rem',
  pl: subMenu ? '3rem' : '0.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: visible ? 'flex' : 'none',
}))<MenuItemWrapperProps>`
  outline: transparent;
  cursor: pointer;
  transition: all ${({ theme }) => theme.transition};
  overflow: hidden;
  border-radius: 0.375rem;

  ${({ active }) =>
    active
    && css`
      background-color: ${({ theme }) => theme.colors.primary.blue.mainHover};
      transition: all ${({ theme }) => theme.transition};
    `}

  &:hover,
  &:focus {
    background-color: ${({ theme, subMenu }) =>
      subMenu ? theme.colors.primary.blue['25'] : theme.colors.primary.blue.main};
    transition: all ${({ theme }) => theme.transition};
  }
`
