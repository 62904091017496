import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'The field is required',
  },
  string: {
    email: 'Invalid email address',
    max: 'Max length is ${max} characters',
  },
})
