import React, { PropsWithChildren, useMemo } from 'react'
import { AppLayout, AppLayoutProps } from '@frontend/domains/shared/components'
import { useBoolean } from '@frontend/shared/hooks'

import { Header } from './components/header/header'
import { Menu } from './components/menu/menu'

type LayoutAppProps = Pick<AppLayoutProps, 'type'>

export const LayoutApp = ({ children, type = 'large' }: PropsWithChildren<LayoutAppProps>) => {
  const { value: mobileMenuOpen, toggle: toggleMobileMenu } = useBoolean(false)

  const menu = useMemo(
    () => <Menu mobileMenuOpen={mobileMenuOpen} onClickMenuToggler={toggleMobileMenu} />,
    [mobileMenuOpen, toggleMobileMenu],
  )
  const header = useMemo(() => <Header onClickMenuToggler={toggleMobileMenu} />, [toggleMobileMenu])

  return (
    <AppLayout header={header} menu={menu} type={type}>
      {children}
    </AppLayout>
  )
}
