import React from 'react'
import { useTheme } from '@frontend/shared/theme'

import { LoaderWrapperProps, LoaderWrapper, loaderSizes } from './loader.styles'
import { LoaderSpinner } from './loader-spinner'

export type LoaderProps = Pick<LoaderWrapperProps, 'size' | 'opacity' | 'color' | 'backgroundColor'>

export const Loader = ({ size = 'md', opacity = 1, color, backgroundColor }: LoaderProps): JSX.Element => {
  const theme = useTheme()
  const sizeInRem = loaderSizes({ size })

  return (
    <LoaderWrapper height={sizeInRem} opacity={opacity} size={size} width={sizeInRem}>
      <LoaderSpinner
        backgroundColor={backgroundColor ?? theme.components.loader.backgroundColor}
        color={color ?? theme.components.loader.textColor}
        size={sizeInRem}
      />
    </LoaderWrapper>
  )
}
