import { SkeletonProps } from 'react-loading-skeleton'
import React from 'react'
import dynamic from 'next/dynamic'

import { SkeletonWrapper } from './skeleton.styles'

const DynamicSkeleton = dynamic(() => import('react-loading-skeleton'), { ssr: false })

export const Skeleton = ({ width, height, circle, borderRadius, style }: SkeletonProps) => (
  <SkeletonWrapper>
    <DynamicSkeleton
      borderRadius={borderRadius}
      circle={circle}
      containerClassName='skeleton-wrapper'
      height={height}
      style={style}
      width={width}
    />
  </SkeletonWrapper>
)
