import { css, styled, StyledProps } from '@frontend/shared/theme'

import { Flex } from '../../../layout/flex/flex'

export type IconFeaturedColor = 'primary' | 'gray' | 'error' | 'warning' | 'success'
export type IconFeaturedSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type IconFeaturedType =
  | 'lightCircle'
  | 'lightCircleOutline'
  | 'darkCircle'
  | 'lightSquare'
  | 'midSquare'
  | 'darkSquare'

export interface IconFeaturedWrapperProps {
  color: IconFeaturedColor
  size: IconFeaturedSize
  type: IconFeaturedType
}

const borderSize = ({ size }: IconFeaturedWrapperProps) =>
  ({
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.625rem',
  }[size])

const lightCircleBackgroundColor = ({ theme, color }: StyledProps<IconFeaturedWrapperProps>) => {
  switch (color) {
    case 'gray':
    case 'error':
    case 'warning':
    case 'success':
      return theme.colors[color]['100']
    case 'primary':
    default:
      return theme.colors.primary.blue['75']
  }
}

const lightCircleColor = ({ theme, color }: StyledProps<IconFeaturedWrapperProps>) =>
  ({
    primary: theme.colors.gray['50'],
    gray: theme.colors.gray['600'],
    error: theme.components.iconFeatured.lightCircleOutline.error.backgroundColor,
    warning: theme.components.iconFeatured.lightCircleOutline.warning.backgroundColor,
    success: theme.components.iconFeatured.lightCircleOutline.success.backgroundColor,
  }[color])

const lightCircleStyles = css`
  background-color: ${lightCircleBackgroundColor};
  border-radius: 50%;
`

const lightCircleOutlineBorderInner = ({ theme, color }: StyledProps<IconFeaturedWrapperProps>) => {
  switch (color) {
    case 'gray':
    case 'error':
    case 'warning':
    case 'success':
      return theme.colors[color]['50']
    case 'primary':
    default:
      return theme.colors.primary.blue['25']
  }
}

const lightCircleOutlineStyles = css`
  position: relative;
  background-color: ${lightCircleBackgroundColor};
  border-color: ${lightCircleOutlineBorderInner};
  border-style: solid;
  border-width: ${borderSize};
  border-radius: 50%;
`

const darkCircleBackgroundColor = ({ theme, color }: StyledProps<IconFeaturedWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['600']
    case 'error':
    case 'warning':
    case 'success':
      return theme.colors[color]['500']
    case 'primary':
    default:
      return theme.colors.primary.blue.main
  }
}

const darkCircleOutlineBorderInner = ({ theme, color }: StyledProps<IconFeaturedWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['700']
    case 'error':
    case 'warning':
    case 'success':
      return theme.colors[color]['600']
    case 'primary':
    default:
      return theme.colors.primary.blue.mainHover
  }
}

const darkCircleStyles = css`
  background-color: ${darkCircleBackgroundColor};
  border-color: ${darkCircleOutlineBorderInner};
  border-style: solid;
  border-width: ${borderSize};
  border-radius: 50%;
`

const lightSquareStyles = css`
  background-color: ${({ theme }) => theme.colors.primary.blue['25']};
  border-radius: 0.375rem;
`

const midSquareStyles = css`
  background-color: ${({ theme }) => theme.colors.primary.blue.main};
  border-radius: 0.375rem;
`

const darkSquareStyles = css`
  background-color: ${({ theme }) => theme.colors.primary.green.mainHover};
  border-radius: 0.375rem;
`

export const iconSize = (size: IconFeaturedSize) =>
  ({
    xs: '0.625rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
  }[size])

export const iconFeaturedTypeColor = ({ theme, type, color, size }: StyledProps<IconFeaturedWrapperProps>) => {
  switch (type) {
    case 'darkCircle':
    case 'midSquare':
    case 'darkSquare':
      return theme.colors.primary.contrast
    case 'lightSquare':
      return theme.colors.primary.blue.mainHover
    case 'lightCircle':
    case 'lightCircleOutline':
    default:
      return lightCircleColor({ theme, type, color, size })
  }
}

export const iconFeaturedType = {
  lightCircle: lightCircleStyles,
  lightCircleOutline: lightCircleOutlineStyles,
  darkCircle: darkCircleStyles,
  lightSquare: lightSquareStyles,
  midSquare: midSquareStyles,
  darkSquare: darkSquareStyles,
}
export const iconFeaturedSize = {
  xs: '1.5rem',
  sm: '2rem',
  md: '2.5rem',
  lg: '3rem',
  xl: '3.5rem',
}

export const IconFeaturedWrapper = styled(Flex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))<IconFeaturedWrapperProps>`
  width: ${({ size }: IconFeaturedWrapperProps) => iconFeaturedSize[size]};
  height: ${({ size }: IconFeaturedWrapperProps) => iconFeaturedSize[size]};

  ${({ type }) => iconFeaturedType[type]};
`
