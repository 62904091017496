import { styled } from '@frontend/shared/theme'
import { flexbox, space } from 'styled-system'

import { FlexProps } from '../../../../layout/flex/flex'
import { Grid } from '../../../../layout/grid'

export const CustomHeaderButton = styled.button`
  ${space};
  flex: 1;
  background: transparent;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary.blue.mainHover};
  font-weight: 500;
  border: 0;
  transition: all ${({ theme }) => theme.transition};
  cursor: pointer;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.blue.mainHover};
    transition: all ${({ theme }) => theme.transition};
    text-decoration: none;
  }
`

export const CustomHeaderArrow = styled.button<FlexProps>`
  ${flexbox};
  display: flex;
  background: transparent;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray['900']};
  flex: 0;
`

export const Months = styled(Grid).attrs(() => ({
  gridTemplateColumns: '1fr 1fr 1fr',
  alignItems: 'center',
  gridGap: 2,
  gridRowGap: 2,
}))`
  text-align: center;
`

export const Years = styled(Grid).attrs(() => ({
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  alignItems: 'center',
  gridGap: 2,
  gridRowGap: 2,
}))`
  overflow-y: scroll;
  height: 15rem;
  text-align: center;
`

export interface CustomItemProps {
  selected?: boolean
}

export const CustomItem = styled.div<CustomItemProps>`
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin: 0;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.primary.blue.mainHover : theme.colors.primary.contrast};
  color: ${({ theme, selected }) => (selected ? theme.colors.primary.contrast : theme.colors.gray['900'])};
  transition: all ${({ theme }) => theme.transition};

  &:hover {
    background: ${({ theme, selected }) => (selected ? theme.colors.primary.blue.mainHover : theme.colors.gray['100'])};
    color: ${({ theme, selected }) => (selected ? theme.colors.primary.contrast : theme.colors.gray['900'])};
    transition: all ${({ theme }) => theme.transition};
  }
`
