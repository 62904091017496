import { useCallback, useEffect, useState } from 'react'
import { Breakpoint as BreakpointsAlias } from '@frontend/shared/theme'
import { IS_BROWSER } from '@frontend/shared/constants'

export interface Breakpoints {
  isLg: boolean
  isMd: boolean
  isSm: boolean
  isXl: boolean
  isXs: boolean
}

interface UseBreakpointsInput {
  breakpoints: BreakpointsAlias
  onBreakpointChange?: (input: Breakpoints) => void
}

export const useBreakpoints = ({ breakpoints, onBreakpointChange }: UseBreakpointsInput): Breakpoints => {
  const [windowSize, setWindowSize] = useState<number>(IS_BROWSER ? window.innerWidth / 16 : 0)

  const smBreakpointValue = Number(breakpoints[0].split('rem')[0])
  const mdBreakpointValue = Number(breakpoints[1].split('rem')[0])
  const lgBreakpointValue = Number(breakpoints[2].split('rem')[0])
  const xlBreakpointValue = Number(breakpoints[3].split('rem')[0])

  const isXs = windowSize ? windowSize < smBreakpointValue : false
  const isSm = windowSize ? smBreakpointValue <= windowSize && windowSize < mdBreakpointValue : false
  const isMd = windowSize ? mdBreakpointValue <= windowSize && windowSize < lgBreakpointValue : false
  const isLg = windowSize ? lgBreakpointValue <= windowSize && windowSize < xlBreakpointValue : false
  const isXl = windowSize ? windowSize >= xlBreakpointValue : false

  const windowResizeHandler = useCallback(() => setWindowSize(window.innerWidth / 16), [])

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler)
    windowResizeHandler()

    return () => window.removeEventListener('resize', windowResizeHandler)
  }, [windowResizeHandler])

  useEffect(() => {
    onBreakpointChange?.({ isXs, isSm, isMd, isLg, isXl })
  }, [onBreakpointChange, isXs, isSm, isMd, isLg, isXl])

  return { isXs, isSm, isMd, isLg, isXl }
}
