import { ForwardedRef, MutableRefObject, useEffect, useRef } from 'react'

/**
 * Combines multiple refs together
 * eg. ForwardedRef and MutableRefObject so that one ref is passed into ref prop
 */
export const useCombinedRefs = <T>(
  ...refs: Array<ForwardedRef<T> | MutableRefObject<T>>
): MutableRefObject<T | undefined> => {
  const targetRef = useRef<T>()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return
      }

      if (typeof ref === 'function') {
        ref(targetRef.current || null)
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current || null
      }
    })
  }, [refs])

  return targetRef
}
