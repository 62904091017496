import { useTheme } from '@frontend/shared/theme'
import React, { useState } from 'react'

import { Flex } from '../../layout/flex/flex'
import { Text, Display } from '../../text/typography'
import { Button } from '../button'
import { Icon } from '../../text'
import { WRAPPER_MAX_WIDTH } from '../../constants'

import { FaqContent, FaqText, FaqWrapper } from './faq.styles'

interface Question {
  answer: string
  question: string
}

export interface FaqProps {
  questions: Question[]
  testID: string
  subTitle?: string
  title?: string
}

export const Faq = ({ title, subTitle, questions, testID }: FaqProps) => {
  const [opened, setOpened] = useState<number | null>(0)
  const theme = useTheme()

  const toggleFaq = (index: number) => setOpened(opened === index ? null : index)

  return (
    <Flex data-test-id={`faq-${testID}`} justifyContent='flex-start'>
      <Flex flexDirection='column' maxWidth={WRAPPER_MAX_WIDTH} width='100%'>
        {(title || subTitle) && (
          <Flex flexDirection='column' justifyContent='center' mb='4rem'>
            {title && <Display textAlign='center'>{title}</Display>}

            {subTitle && (
              <Flex justifyContent='center' mt='1.25rem'>
                <Text color={theme.colors.gray['500']}>{subTitle}</Text>
              </Flex>
            )}
          </Flex>
        )}

        {questions.map(({ question, answer }, index) => {
          const questionOpen = index === opened

          return (
            <FaqWrapper key={question} opened={questionOpen} onClick={() => toggleFaq(index)}>
              <Flex>
                <Button name={`question-${index}`} type='icon' onClick={() => toggleFaq(index)}>
                  <Icon
                    color={theme.colors.gray['400']}
                    fontSize='1.25rem'
                    kind={questionOpen ? 'FiMinusCircle' : 'FiPlusCircle'}
                  />
                </Button>
              </Flex>
              <Flex flexDirection='column' pl={['1rem', '1.625rem']}>
                <Flex pb='0.5rem'>
                  <Text data-test-id={`question-${index}`} size='lg'>
                    {question}
                  </Text>
                </Flex>

                <FaqContent opened={questionOpen}>
                  <FaqText
                    color={theme.colors.gray['500']}
                    dangerouslySetInnerHTML={{ __html: answer }}
                    data-test-id={`answer-${index}`}
                    size='md'
                    weight={400}
                  />
                </FaqContent>
              </Flex>
            </FaqWrapper>
          )
        })}
      </Flex>
    </Flex>
  )
}
