import {
  alignContent,
  alignItems,
  display,
  grid,
  gridRow as gridRowAlias,
  gridRowGap as gridRowGapAlias,
  justifyContent,
  justifyItems,
  margin,
  padding,
  position,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  order,
  GridProps as GridPropsAlias,
  AlignItemsProps,
  AlignContentProps,
  DisplayProps,
  JustifyContentProps,
  JustifyItemsProps,
  PaddingProps,
  PositionProps,
  MarginProps,
  WidthProps,
  MaxWidthProps,
  HeightProps,
  MinHeightProps,
  OrderProps,
  BackgroundProps,
  background,
  MinWidthProps,
  flex,
  FlexProps,
  maxHeight,
  MaxHeightProps,
} from 'styled-system'
import { GridGap, styled, StyledProps } from '@frontend/shared/theme'
import { ForwardedRef, HTMLAttributes } from 'react'

type GridCustomProps = GridPropsAlias &
  AlignItemsProps &
  AlignContentProps &
  BackgroundProps &
  DisplayProps &
  JustifyContentProps &
  JustifyItemsProps &
  MarginProps &
  PaddingProps &
  PositionProps &
  WidthProps &
  MinWidthProps &
  MaxWidthProps &
  HeightProps &
  MinHeightProps &
  MaxHeightProps &
  OrderProps &
  FlexProps &
  Pick<HTMLAttributes<HTMLDivElement>, 'className'>

export interface GridProps extends GridCustomProps {
  gap?: GridGap
  ref?: ForwardedRef<HTMLDivElement>
}

const getGridGap = ({ theme, gap, gridGap, gridRowGap }: StyledProps<GridProps>) => {
  if (gap && !gridGap && !gridRowGap) {
    return {
      gridGap: theme.gridGap[gap as GridGap],
      gridRowGap: theme.gridGap[gap as GridGap],
    }
  }

  return {
    gridGap: gridGap ?? theme.gridGap.lg,
    gridRowGap: gridRowGap ?? theme.gridGap.lg,
  }
}

export const Grid = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['width', 'height', 'overflow', 'display', 'color', 'order'].includes(prop.toString())
      && defaultValidatorFn(prop),
  })
  .attrs<GridProps>(({ width, ...props }) => ({ ...getGridGap(props), width: width ?? '100%' }))<GridProps>`
  display: grid;
  grid-template-columns: 100%;
  ${grid}
  ${gridRowAlias}
  ${gridRowGapAlias}
  ${background}
  ${alignContent}
  ${alignItems}
  ${display}
  ${justifyContent}
  ${justifyItems}
  ${margin}
  ${position}
  ${padding}
  ${width}
  ${minWidth}
  ${maxWidth}
  ${height}
  ${minHeight}
  ${maxHeight}
  ${order}
  ${flex}
`
