import React from 'react'

import { Section } from '../../../layout/section/section'
import { Skeleton } from '../root/skeleton'

export const SkeletonTable = () => (
  <Section>
    <Skeleton height='3rem' width='100%' />
    <Skeleton height='2rem' width='100%' />
    <Skeleton height='3rem' width='100%' />
  </Section>
)
