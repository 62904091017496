import {
  AuthenticateOTPError,
  AuthenticateResponse,
  LoginAction,
  OtpQrCodeResponse,
  SuccessfullyAuthenticatedResponse,
} from './types'

interface Result {
  action?: LoginAction
  errorId?: string
}

interface ExtendedResult extends Result {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const isActionAuthenticateOTP = (data: ExtendedResult): data is AuthenticateResponse =>
  data?.action === LoginAction.AuthenticateOTP

export const isActionAuthenticateOTPError = (data: ExtendedResult): data is AuthenticateOTPError => !!data?.errorId

export const isActionChangePassword = (data: ExtendedResult): data is AuthenticateResponse =>
  data?.action === LoginAction.UpdatePassword

export const isActionIssueToken = (data: ExtendedResult): data is SuccessfullyAuthenticatedResponse =>
  data?.action === LoginAction.IssueToken

export const isActionRegisterOTP = (data: ExtendedResult): data is OtpQrCodeResponse =>
  data?.action === LoginAction.RegisterOTP
