import { CachePolicies, Res } from 'use-http'
import { ResponseError } from '@frontend/shared/types'
import { RefObject } from 'react'
import { FormikProps } from 'formik'
import { useToast } from '@frontend/shared/ui'
import { useApiFetch } from '@frontend/domains/shared/components'

import { ENDPOINT_URL } from '../constants'

export const useServiceFriendlyReferralCode = () => {
  const { showToastError } = useToast()

  const { loading, error, data, post, response } = useApiFetch<ResponseError>({
    cachePolicy: CachePolicies.NETWORK_ONLY,
  })

  const changeFriendlyReferralCode = async <FormValues>(
    code: string,
    formikRef: RefObject<FormikProps<FormValues>>,
  ) => {
    const onError = (response: Res<ResponseError>) => {
      response.data?.message?.includes('code already exists')
        ? formikRef?.current?.setFieldError('code', `This referral code email already exist. Please use another one.`)
        : showToastError('Friendly name', 'Referral code could not be changed. Please try it again later')
    }

    const body = { code }
    await post(ENDPOINT_URL.friendlyReferralCode, body, onError)

    return response.ok
  }

  return { loading, error, data, changeFriendlyReferralCode }
}
