import React from 'react'
import { IS_BROWSER } from '@frontend/shared/constants'
import { useTheme } from '@frontend/shared/theme'

import { Grid } from '../../layout/grid/grid'
import { Flex } from '../../layout/flex'
import { useToast } from '../toast'
import { Button } from '../button'
import { Icon, Text } from '../../text'
import { Divider } from '../../layout/divider'

import { DetailWrapper, DetailWrapperProps } from './detail.styles'

interface Row extends DetailWrapperProps {
  label: string
  buttons?: JSX.Element[]
  notFilled?: boolean
  textToCopy?: string
  value?: string | null | JSX.Element
  visible?: boolean
}

export interface DetailProps {
  rows: Row[]
}

const formatStringValue = (value?: string | null) =>
  value === undefined || value === '' || value === null ? '---' : value

const getValue = (type: DetailWrapperProps['type'], value: Row['value'], notFilled?: boolean) => {
  if (notFilled !== undefined && notFilled) {
    return <Text>---</Text>
  }

  if (
    type === 'item'
    && typeof value !== 'object'
    && (typeof value === 'string' || value === undefined || value === null)
  ) {
    return <Text>{formatStringValue(value)}</Text>
  }

  return value
}

let headingKey = ''

export const Detail = ({ rows }: DetailProps) => {
  const theme = useTheme()
  const { showToastInfo } = useToast()

  const copyValue = (label: string, value?: Row['value'], textToCopy?: string) => () => {
    let textCopied = false

    if (IS_BROWSER && typeof value === 'string') {
      navigator.clipboard.writeText(formatStringValue(value))
      textCopied = true
    } else if (IS_BROWSER && typeof textToCopy === 'string') {
      navigator.clipboard.writeText(formatStringValue(textToCopy))
      textCopied = true
    }

    textCopied && showToastInfo('Clipboard', `${label} was copied.`, { autoClose: 2000 })
  }

  return (
    <Grid gap='sm'>
      {rows
        .filter(({ visible = true }) => visible)
        .map(({ label, value, type = 'item', buttons = [], notFilled, textToCopy }) => {
          headingKey = type === 'heading' ? label : headingKey

          return (
            <DetailWrapper gap='sm' key={`${headingKey}-${label}-${value}`} type={type}>
              <Flex alignItems='center' flex={1} justifyContent='space-between'>
                <Flex flexDirection={type === 'item' ? 'column' : 'row'} pr={3}>
                  <>
                    <Text color={theme.colors.gray['500']} size='sm' weight={type === 'heading' ? 600 : 500}>
                      {label}
                    </Text>

                    {getValue(type, value, notFilled)}
                  </>
                </Flex>
                {type === 'item' && (
                  <Flex>
                    <Grid gridTemplateColumns={`repeat(${buttons.length + 1}, auto)`}>
                      {buttons.map((button) => button)}
                      <Button
                        name={`copy-${label}`}
                        title='Copy'
                        type='icon'
                        onClick={copyValue(label, value, textToCopy)}
                      >
                        <Icon kind='FiCopy' />
                      </Button>
                    </Grid>
                  </Flex>
                )}
              </Flex>
              {type === 'heading' && <Divider />}
            </DetailWrapper>
          )
        })}
    </Grid>
  )
}
