import { styled } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex/flex'

export const PrefixWrapper = styled(Flex).attrs(() => ({
  px: '0.875rem',
  py: '0.625rem',
  justifyContent: 'center',
  alignItems: 'center',
}))`
  height: 2.75rem;
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({ theme }) => theme.colors.gray['300']};
  border-right: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray['900']};
`
