import { styled } from '@frontend/shared/theme'
import { Flex } from '@frontend/shared/ui'
import { display } from 'styled-system'

export interface MenuWrapperProps {
  mobileMenuOpen: boolean
  width?: string
}

export const MenuWrapper = styled(Flex).attrs<MenuWrapperProps>(({ mobileMenuOpen, width = '17rem' }) => ({
  flexDirection: 'column',
  height: '100%',
  position: ['absolute', null, null, 'relative'],
  width: [mobileMenuOpen ? width : '0rem', null, null, width],
  right: ['0rem', null, null, 'initial'],
  flex: '1 0 auto',
}))<MenuWrapperProps>`
  ${display};

  transition: width ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.colors.primary.darkBlue.main};
  z-index: 20;
  overflow-y: auto;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.18);
`

export const MenuInnerWrapper = styled(Flex).attrs(() => ({
  p: '1rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))``

export const LogoWrapper = styled(Flex)`
  height: 1.25rem;
  justify-items: center;
`
