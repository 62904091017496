import React, { AriaAttributes, SVGAttributes } from 'react'

import featherIcons from './components/feather/feather'
import materialDesignIcons from './components/material-design/material-design'

const mergedIcons = { ...featherIcons, ...materialDesignIcons }

export type IconKind = keyof typeof mergedIcons

type SpecificIconProps = Pick<SVGAttributes<SVGElement>, 'color' | 'fontSize' | 'strokeWidth'>

export interface IconProps extends SpecificIconProps, Pick<AriaAttributes, 'aria-hidden'> {
  kind: IconKind
}

export const Icon = ({
  kind,
  color,
  fontSize = '1rem',
  strokeWidth = 2,
  'aria-hidden': ariaHidden,
}: IconProps): JSX.Element => {
  const Component = mergedIcons[kind]

  return <Component aria-hidden={ariaHidden} color={color} fontSize={fontSize} strokeWidth={strokeWidth} />
}
