import { styled } from '@frontend/shared/theme'

import { Text, TextProps } from '../../../../text/typography/typography'

export interface Label {
  disabled?: boolean
  touched?: boolean
}

export const Label = styled(Text).attrs<TextProps>(({ size, weight, theme }) => ({
  size,
  weight,
  color: theme.components.fieldWrapper.label.textColor,
}))`
  line-height: 1rem;
  align-items: center;
`
