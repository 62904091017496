import React from 'react'

import { Text } from '../../../../text/typography'
import { Flex } from '../../../../layout/flex'
import { CommonDatePickerProps } from '../types'

import { Years, CustomItem } from './custom-header.styles'
import { createRange } from './helpers'

type CalendarYearsProps = Pick<CommonDatePickerProps, 'translations'> & {
  actualYear: number
  onChange(month: number): void
  rangeYears: number[]
}

export const CalendarYears = ({ actualYear, rangeYears, onChange, translations }: CalendarYearsProps) => {
  const [yearFrom, yearTo] = rangeYears
  const years = createRange(yearFrom, yearTo).reverse()

  return (
    <>
      <Flex justifyContent='center' mb={2}>
        <Text>{translations.selectYear}</Text>
      </Flex>
      <Years>
        {years.map((year) => (
          <CustomItem key={year} selected={actualYear === year} onClick={() => onChange(year)}>
            {year}
          </CustomItem>
        ))}
      </Years>
    </>
  )
}
