import { Carousel, Flex, Grid, Lightbox, Markdown, RequestResultError, Section } from '@frontend/shared/ui'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { VerificationPeriod, Customer, Project, SellingUnit, SubscriptionType } from '@frontend/shared/types'
import { useBoolean } from '@frontend/shared/hooks'

import { useServiceProjects } from '../hooks'
import { getFile, parseQuantityValue } from '../utils'
import { Header } from '../ui'
import { Routes } from '../types'
import { byVerificationPeriodRangeDesc } from '../filter'

import { DetailSkeleton } from './detail-skeleton'
import { FormValues } from './components/components/section-form/types'
import { SidebarTonne } from './components/sidebar-tonne'
import { SidebarTree } from './components/sidebar-tree'

interface DetailProps {
  routes: Routes
  customer?: Customer
  error?: Error
  loading?: boolean
}

export const Detail = ({ customer, error, loading, routes }: DetailProps) => {
  const router = useRouter()
  const serviceProjects = useServiceProjects()

  const { value: lightboxOpen, setTrue: setLightboxOpenTrue, setFalse: setLightboxOpenFalse } = useBoolean(false)
  const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0)
  const [project, setProject] = useState<Project>()
  const [verificationPeriod, setVerificationPeriod] = useState<VerificationPeriod>()

  const ticker = router.query.ticker?.toString()

  useEffect(() => {
    if (ticker && serviceProjects.data && serviceProjects.data.length > 0) {
      const projectDetail = serviceProjects.data.find((project) => project.ticker === ticker)

      if (projectDetail) {
        if (projectDetail.sellingUnit === SellingUnit.CarbonTonne) {
          const sortedVerificationPeriods = projectDetail?.verificationPeriods?.sort(byVerificationPeriodRangeDesc)

          sortedVerificationPeriods?.length && setVerificationPeriod(sortedVerificationPeriods[0])
        }

        setProject(projectDetail)
      }
    }
  }, [serviceProjects.data, ticker])

  if (!project || !ticker || serviceProjects.loading || loading) {
    return <DetailSkeleton />
  }

  if (serviceProjects.error || error) {
    return (
      <Section>
        <RequestResultError />
      </Section>
    )
  }

  const { description, country, location, name, sellingUnit } = project

  const place = location ? `${country}, ${location}` : country
  const carouseImages = project.images.map(({ id, hash }) => ({ source: getFile(id, 'LARGE'), hash }))
  const lightboxImages = project.images.map(({ id }) => getFile(id, 'LARGE'))

  const submitForm = async ({ quantityOriginal, vintage }: FormValues) =>
    router.push(
      routes.carbonOffsetProjectCheckout.getUrl(
        {
          ticker,
          subscription: SubscriptionType.Onetime,
          gift: false,
          quantity: parseQuantityValue(quantityOriginal.toString()),
          vintage,
        },
        router.query,
      ),
    )

  const openLightbox = (index: number) => {
    setLightboxPhotoIndex(index)
    setLightboxOpenTrue()
  }

  return (
    <>
      <Lightbox
        images={lightboxImages}
        open={lightboxOpen}
        photoIndex={lightboxPhotoIndex}
        onCloseRequest={setLightboxOpenFalse}
      />

      <Grid>
        <Header
          sideLinkHref={routes.carbonOffsetProjectList.getUrl(router.query)}
          sideLinkTitle='Back to projects'
          title={name}
        />

        <Grid gridTemplateColumns={['1fr', null, null, '3fr 24rem']}>
          <Section gridAutoRows='max-content' noPadding>
            <Flex height={['10rem', '14rem', '16rem', '20.75rem']}>
              <Carousel images={carouseImages} onClickImage={openLightbox} />
            </Flex>

            <Flex pb={5} px={5}>
              <Markdown text={description} />
            </Flex>
          </Section>

          {sellingUnit === SellingUnit.CarbonTonne && verificationPeriod && (
            <SidebarTonne
              customer={customer}
              place={place}
              project={project}
              routes={routes}
              verificationPeriod={verificationPeriod}
              onSubmitForm={submitForm}
            />
          )}

          {sellingUnit === SellingUnit.Tree && (
            <SidebarTree
              customer={customer}
              place={place}
              project={project}
              routes={routes}
              onSubmitForm={submitForm}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
