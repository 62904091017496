import { object, string } from 'yup'

export const validationSchema = object({
  otp: string()
    .test('is-valid', 'Enter 6-digit OTP code', (value) => {
      if (!value) {
        return true
      }

      return /^\d{6}$/gm.test(value)
    })
    .required(),
})
