import { keyframes, styled } from '@frontend/shared/theme'

export const ARROW_SIZE = 0.25

const openAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const Arrow = styled.span`
  width: ${ARROW_SIZE}rem;
  height: ${ARROW_SIZE}rem;
  transform: rotate(45deg) translateX(-50%);
  background: black;
`

export const AnimatedWrapper = styled.div`
  animation: 0.25s ${openAnimation} ease-in-out;
`
