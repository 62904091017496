import { Theme, theme } from '@frontend/shared/theme'
import { mergeDeepRight } from 'ramda'

import { components } from './components'

const componentsIframe = mergeDeepRight(theme.components, components)

export interface ThemeIframe extends Theme {
  components: typeof componentsIframe
}

export const themeIframe: ThemeIframe = {
  ...theme,
  components: componentsIframe,
}
