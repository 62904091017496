import Logger from 'js-logger'

import { ILogLevel } from './types'

export const getLogLevel = (value?: string): ILogLevel => {
  switch (value) {
    case Logger.TRACE.name:
      return Logger.TRACE
    case Logger.DEBUG.name:
      return Logger.DEBUG
    case Logger.INFO.name:
      return Logger.INFO
    case Logger.WARN.name:
      return Logger.WARN
    case Logger.ERROR.name:
      return Logger.ERROR
    default:
      return Logger.ERROR
  }
}
