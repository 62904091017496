import useFetch, { IncomingOptions, Res } from 'use-http'
import { SERVICE_URL } from '@frontend/shared/constants'
import { useCallback } from 'react'
import { useToast } from '@frontend/shared/ui'
import { Logger } from '@frontend/shared/logger'

type CustomFetchData<TData> = (
  routeOrBody?: string | BodyInit | object,
  body?: BodyInit | object,
  onError?: (response: Res<TData>) => void,
) => Promise<TData>

export const useApiFetch = <TData>(options?: IncomingOptions) => {
  const { showToastError } = useToast()
  const fetchApi = useFetch<TData>(SERVICE_URL.beUrl, { ...options })

  const globalValidationError = useCallback(
    (response: Res<TData>) => {
      if (400 <= response.status && response.status < 500) {
        Logger.warn('Validation error - ', response.statusText)
        showToastError('Request failed', 'Please check whether filled values are correct and retry.')
      }
    },
    [showToastError],
  )

  const post: CustomFetchData<TData> = useCallback<CustomFetchData<TData>>(
    async (routeOrBody, body, onError) => {
      const result = await fetchApi.post(routeOrBody, body)

      if (!fetchApi?.response?.ok) {
        if (onError) {
          onError?.(fetchApi.response)
        } else {
          globalValidationError(fetchApi.response)
        }
      }

      return result
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchApi.response],
  )

  return { ...fetchApi, post }
}
