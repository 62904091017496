import { useRouter } from 'next/router'
import { STORAGE_REFRESH_TOKEN_EXPIRES_AT, STORAGE_TOKEN_EXPIRES_AT } from '@frontend/shared/constants'
import { getCookieDomain, getLocalStorage } from '@frontend/shared/utils'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'

import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'
import { routes } from '../../router/routes'

export const clearAuthTokens = () => {
  const { remove: removeTokenExpiresAt } = getLocalStorage(STORAGE_TOKEN_EXPIRES_AT)
  const { remove: removeRefreshTokenExpiresAt } = getLocalStorage(STORAGE_REFRESH_TOKEN_EXPIRES_AT)

  removeTokenExpiresAt()
  removeRefreshTokenExpiresAt()
}

export const useServiceSignOut = () => {
  const router = useRouter()
  const { showToastGenericError } = useToast()
  const { post, error, loading, response } = useAuthFetch()

  const signOut = async () => {
    const body = { cookieDomain: getCookieDomain() }
    await post(ENDPOINT_URL.signOut, body)

    if (response.ok) {
      clearAuthTokens()

      await router.push(routes.signIn.getUrl())
    } else {
      Logger.error('Sign out failed', response)
      showToastGenericError()
    }
  }

  return { signOut, error, loading }
}
