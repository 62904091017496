import { keyframes, styled, css } from '@frontend/shared/theme'
import { animated } from 'react-spring'

export interface ProgressBarInnerProps {
  progress: number
  loop?: boolean
}

const keyframeBar = keyframes`
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }

`
export const ProgressBarWrapper = styled(animated.div)`
  position: relative;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.primary.blue['25']};
  width: 100%;
  height: 0.5rem;
  transition: all ${({ theme }) => theme.transition};
`

export const ProgressBarInner = styled(animated.div)<ProgressBarInnerProps>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
  width: ${({ progress }) => progress}%;
  max-width: 100%;
  height: 0.5rem;
  background: ${({ theme }) => theme.colors.primary.blue.mainHover};
  transition: all ${({ theme }) => theme.transition};
  ${({ loop }) =>
    loop
    && css`
      animation: ${keyframeBar} 2s linear infinite;
    `}
`
