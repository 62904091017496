import '@react-google-maps/api'
import BigNumber from 'bignumber.js'

export enum Fence {
  NONE = 'No Fencing Needed',
  PARTIAL = 'Partial Fencing (Natural boundaries)',
  ENTIRE = 'Fence Entire Area',
}

export enum PlantingMethod {
  Hand = 'HAND',
  Tractor = 'TRACTOR',
  Excavator = 'EXCAVATOR',
}

export enum AreaStatus {
  Draft = 'DRAFT',
  Designing = 'DESIGNING',
  Planting = 'PLANTING',
  Validating = 'VALIDATING',
  PreparingField = 'PREPARING_FIELD',
}

interface TreePercentageRatio {
  code: string
  count: number
  percentage: number
}

export interface LatLng {
  latitude: number
  longitude: number
}

export interface TreeRatioSubmission {
  submittedAt: string
  submittedBy: string
  treeRatios: TreePercentageRatio[]
}

export type TreeRatioSubmissions = TreeRatioSubmission[]

export interface AreaExclusion {
  coordinates: google.maps.LatLng[]
  id: string
}

interface TreePhotoMetadata {
  coordinates: LatLng
  cameraBrand?: string
  cameraModel?: string
  date?: string
  fNumber?: string
  fileName?: string
  fileSizeBytes?: number
  flash?: string
  focalLength?: string
  height?: number
  resolution?: number
  shutterSpeed?: string
  width?: number
}

export interface TreePhotoResponse {
  id: string
  metadata: TreePhotoMetadata
}

export interface TreePhoto {
  id: string
  metadata: Omit<TreePhotoMetadata, 'coordinates'> & {
    coordinates: google.maps.LatLng
  }
}

export interface Area {
  coordinates: google.maps.LatLng[]
  farmId: string
  id: string
  name: string
  exclusions?: AreaExclusion[]
  fence?: string | null
  fireLine?: string
  note?: string
  plantingMethod?: string | null
  plantingYear?: number | null
  preparatoryWork?: string
  presetCode?: string | null
  soilClassCode?: string
  soilClassName?: string
  status?: AreaStatus
  treePhotoIds?: string[]
  treeRatioSubmission?: TreeRatioSubmission
  typeCode?: string
  typeName?: string
}

export type Areas = Area[]

export interface AreaExclusionResponse {
  coordinates: LatLng[]
  id: string
}

export interface AreaResponse extends Omit<Area, 'coordinates' | 'exclusions'> {
  coordinates: LatLng[]
  name: string
  exclusions?: AreaExclusionResponse[]
}

type AreasResponse = AreaResponse[]

export interface TreeCountResponse {
  areaCoveredHa: number
  code: string
  count: number
  earn: number
}

export interface TreeCount {
  code: string
  name: string
  ratio: BigNumber
  treeCountPerHa: number
  nameLatin?: string
  nameLocal?: string
}

export interface ProjectKml {
  fileName: string
  s3Path: string
}

export interface ProjectLandTitle {
  contentType: string
  fileName: string
  s3Path: string
}

export interface Farm {
  areas: Omit<AreaResponse, 'treeRatioSubmission'>[]
  farmerCountry: string
  farmerEmail: string
  farmerFirstName: string
  farmerLastName: string
  farmerPhoneNumber: string
  farmerTown: string
  fmnr: string
  id: string
  involvedPeople: number
  note: string
  ownOrLease: string
  portfolioSizeHa: number
  reforestationAgroforestry: string
  registeredAt: string
  treeCalculator: boolean
  alternativeLivelihoods?: string
  animals?: string
  animalsExclusion?: string
  annualRainfallMm?: number
  bankAccount?: string
  challenges?: string
  cooking?: string[]
  coordinatorEmail?: string
  coordinatorFullName?: string
  coordinatorOrganisation?: string
  coordinatorPhoneNumber?: string
  currentSpecies?: string
  currentTreeCount?: string
  district?: string
  dumbPhoneBanking?: string
  farmerZipCode?: string
  firewoodPrice?: number
  futureSpecies?: string
  futureTreesPerHa?: number
  kmlCountry?: string
  kmlProjectSizeHa?: number
  membership?: string
  plantingAreas?: AreasResponse
  productionFoodPurpose?: string
  productionWoodPurpose?: string
  projectKml?: ProjectKml
  projectLandTitles?: ProjectLandTitle[]
  projectSizeHa?: number
  projectTicker?: string
  resourceManagementGroups?: string
  training?: string
  treeRatioSubmissions?: TreeRatioSubmissions
}

export interface FarmListResponse {
  arrPercentage: number
  averageSizeOfAreaHa: number
  averageWorkersOnLand: number
  fmnrPercentage: number
  ownersPercentage: number
  portfolioAreaHa: number
  totalAreaHa: number
}

export type FarmListData = Pick<
  Farm,
  | 'id'
  | 'projectTicker'
  | 'farmerFirstName'
  | 'farmerLastName'
  | 'kmlProjectSizeHa'
  | 'farmerTown'
  | 'projectKml'
  | 'projectLandTitles'
  | 'registeredAt'
  | 'treeCalculator'
  | 'district'
>

export interface TreeSpecies {
  code: string
  defaultRatioPercentage: number
  name: string
  treeCountPerHa: number
  earnPerHa?: number
  nameAbbreviation?: string
  nameLatin?: string
  nameLocal?: string
}

export type Ratio = Record<string, number>

export interface Preset {
  code: string
  name: string
  ratio: Ratio
  color?: string
  districts?: string[]
  nameAbbreviation?: string
  retired?: boolean
}

export interface FarmProject {
  country: string
  location: string
  name: string
  ticker: string
  url: string
  boundaryCoordinateEast?: number
  boundaryCoordinateNorth?: number
  boundaryCoordinateSouth?: number
  boundaryCoordinateWest?: number
  distributionRulePresets?: Preset[]
  regions?: LatLng[][]
  treeSpecies?: TreeSpecies[]
}
