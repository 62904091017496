export enum Routes {
  Dashboard = 'dashboard',
  Error = 'error',
  LoginRedeem = 'loginRedeem',
  LoginChangePassword = 'loginChangePassword',
  LoginTwoFactorAuthentication = 'loginTwoFactorAuthentication',
  PasswordResetFinalize = 'passwordResetFinalize',
  PasswordResetInitialize = 'passwordResetInitialize',
  Portfolio = 'portfolio',
  SignIn = 'signIn',
  SignUp = 'signUp',
}

export const routes = {
  [Routes.Dashboard]: {
    getUrl: () => '/',
  },
  [Routes.Error]: {
    getUrl: () => '/error',
  },
  [Routes.LoginRedeem]: {
    getUrl: () => '/redeem',
  },
  [Routes.LoginChangePassword]: {
    getUrl: () => '/login/change-password',
  },
  [Routes.LoginTwoFactorAuthentication]: {
    getUrl: () => '/login/two-factor-authentication',
  },
  [Routes.PasswordResetFinalize]: {
    getUrl: () => '/login/password-reset/finalize',
  },
  [Routes.PasswordResetInitialize]: {
    getUrl: () => '/login/password-reset/initialize',
  },
  [Routes.SignIn]: {
    getUrl: () => '/login/sign-in',
  },
  [Routes.SignUp]: {
    getUrl: () => '/login/sign-up',
  },
} as const
