/* eslint-disable react/jsx-props-no-spreading */
import { useDropzone, DropzoneProps, FileRejection } from 'react-dropzone'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex/flex'
import { IconFeatured } from '../../../../text/icon/featured/icon-featured'
import { Text } from '../../../../text/typography'
import { Grid } from '../../../../layout/grid/grid'
import { FileUploadWrapper } from '../base/input-file-upload.styles'

import { MultipleFiles } from './components/multiple-files'
import { SingleFile } from './components/single-file'

type CustomDropzoneProps = Pick<DropzoneProps, 'disabled' | 'accept' | 'multiple' | 'maxFiles' | 'maxSize'>

export interface InputFileUploadProps extends CustomDropzoneProps {
  name: string
  onFilesChange: (files: File[]) => void
  value: File[]
  defaultValue?: File[]
  description?: string
  error?: string
  touched?: boolean
}

export const InputFileUpload = ({
  name,
  disabled,
  accept,
  multiple = false,
  maxFiles,
  maxSize,
  description,
  onFilesChange,
  error,
  touched,
  defaultValue = [],
  value,
}: InputFileUploadProps) => {
  const theme = useTheme()
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>(defaultValue)
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])

  useEffect(() => {
    if (value.length === 0) {
      setAcceptedFiles([])
      setRejectedFiles([])
    }
  }, [value.length])

  const onDropAccepted = (files: File[]) => {
    let acceptedFilesCount = acceptedFiles.length

    files.forEach((file) => {
      if (maxFiles && maxFiles <= acceptedFilesCount) {
        return
      }

      setAcceptedFiles((prevFiles) => {
        const files = [...prevFiles, file]
        onFilesChange(files)

        return files
      })
      acceptedFilesCount += 1
    })
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) =>
    setRejectedFiles((prevFiles) => [...prevFiles, ...rejectedFiles])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    disabled,
    accept,
    maxSize,
    multiple,
  })

  const removeAcceptedFile = (name: string) => {
    const files = acceptedFiles.filter((acceptedFile) => acceptedFile.name !== name)

    setAcceptedFiles(files)
    onFilesChange(files)
  }

  const removeRejectedFile = (name: string) =>
    setRejectedFiles(rejectedFiles.filter((rejectedFile) => rejectedFile.file.name !== name))

  if (!multiple && (acceptedFiles.length === 1 || rejectedFiles.length === 1)) {
    return (
      <SingleFile
        acceptedFiles={acceptedFiles}
        rejectedFiles={rejectedFiles}
        onRemoveAcceptedFile={removeAcceptedFile}
        onRemoveRejectedFile={removeRejectedFile}
      />
    )
  }

  return (
    <Grid gap='sm'>
      <FileUploadWrapper
        {...getRootProps()}
        data-test-id='dropzone'
        disabled={disabled}
        dragActive={isDragActive}
        error={!!error}
        touched={touched}
      >
        <IconFeatured color='gray' kind='FiUpload' />
        {isDragActive ? (
          <Flex mt={2}>
            <Text color={theme.colors.primary.blue.main} size='sm'>
              Drop files here
            </Text>
          </Flex>
        ) : (
          <Flex mt={2}>
            <Text color={theme.colors.primary.blue.main} size='sm'>
              Click to upload&nbsp;
            </Text>
            <Text color={theme.colors.gray['500']} size='sm' weight={400}>
              or drag and drop
            </Text>
          </Flex>
        )}
        {description && (
          <Flex mt={2}>
            <Text color={theme.colors.gray['500']} data-test-id='description' size='sm' textAlign='center' weight={400}>
              {description}
            </Text>
          </Flex>
        )}
        <input {...getInputProps()} id={name} />
      </FileUploadWrapper>

      {multiple && (
        <MultipleFiles
          acceptedFiles={acceptedFiles}
          rejectedFiles={rejectedFiles}
          onRemoveAcceptedFile={removeAcceptedFile}
          onRemoveRejectedFile={removeRejectedFile}
        />
      )}
    </Grid>
  )
}
