import { generateRandomString, getCookieDomain } from '@frontend/shared/utils'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'

import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

/**
 * OAuth PKCE (Proof Key for Code Exchange) code challenge.
 * It is SHA256 hash of randomly generated string encoded to Base64.
 */
const generateCodeChallenge = async (codeVerifier: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const digest = await window.crypto.subtle.digest('SHA-256', data)
  const { encode: base64encode } = await import('base64-arraybuffer')
  const base64Digest = base64encode(digest)

  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

interface InitializeResponse {
  execution: string
  sessionCode: string
  tabId: string
}

/**
 * Initializes authentication session.
 */
export const useServiceInitialize = () => {
  const { showToastGenericError } = useToast()
  const { post, error, loading, response } = useAuthFetch<InitializeResponse>()

  const initialize = async (recaptchaToken: string) => {
    try {
      const codeVerifier = generateRandomString(128)
      const codeChallenge = await generateCodeChallenge(codeVerifier)
      const body = {
        recaptchaToken,
        cookieDomain: getCookieDomain(),
        codeChallenge,
        nonce: generateRandomString(21),
        state: generateRandomString(31),
      }

      const result = await post(ENDPOINT_URL.initialize, body)

      if (response.ok) {
        return { ...result, codeVerifier }
      }
    } catch (error) {
      showToastGenericError()
      Logger.error(error)
    }

    return undefined
  }

  return { initialize, error, loading }
}
