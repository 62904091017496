import dynamic from 'next/dynamic'

const Collapse = dynamic(() => import('./collapse/collapse').then((module) => module.Collapse))

export * from './divider'
export * from './flex'
export * from './grid'
export * from './popper'
export * from './responsive'
export * from './section'
export { Collapse }
