import React from 'react'
import { useRouter } from 'next/router'
import { routes } from '@frontend/domains/plus/routes'
import { AppMenu, AppMenuProps } from '@frontend/domains/shared/components'

type MenuProps = Pick<AppMenuProps, 'mobileMenuOpen' | 'onClickMenuToggler'>

export const Menu = ({ mobileMenuOpen, onClickMenuToggler }: MenuProps) => {
  const router = useRouter()

  const menuItems: AppMenuProps['menuItems'] = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'FiHome',
      onSelect: () => router.push(routes.dashboard.getUrl()),
      active: router.pathname === routes.dashboard.getUrl(),
      visible: true,
    },
    {
      id: 'portfolio',
      label: 'Portfolio',
      icon: 'FiFile',
      onSelect: () => router.push(routes.portfolio.getUrl()),
      active: router.pathname.startsWith(routes.portfolio.getUrl()),
      visible: true,
    },
    {
      id: 'market',
      label: 'Market',
      icon: 'FiTrendingUp',
      onSelect: () => router.push(routes.carbonOffsetProjectList.getUrl()),
      active: router.pathname.startsWith(routes.carbonOffsetProjectList.getUrl()),
      visible: true,
    },
    {
      id: 'certificate-redeem',
      label: 'Redeem',
      icon: 'FiGift',
      onSelect: () => router.push(routes.certificateRedeem.getUrl()),
      active: router.pathname === routes.certificateRedeem.getUrl(),
      visible: true,
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'FiSettings',
      active: router.pathname === routes.settingsTwoFactorAuthentication.getUrl(),
      visible: true,
      subMenuItems: [
        {
          id: 'two-factor-authentication',
          label: '2FA',
          onSelect: () => router.push(routes.settingsTwoFactorAuthentication.getUrl()),
          active: router.pathname === routes.settingsTwoFactorAuthentication.getUrl(),
          visible: true,
        },
        {
          id: 'change-password',
          label: 'Change password',
          onSelect: () => router.push(routes.settingsChangePassword.getUrl()),
          active: router.pathname === routes.settingsChangePassword.getUrl(),
          visible: true,
        },
      ],
    },
  ]

  return <AppMenu menuItems={menuItems} mobileMenuOpen={mobileMenuOpen} onClickMenuToggler={onClickMenuToggler} />
}
