import { LoginAction } from '../../service/login/types'

import { ContextLoginProps } from './context-login.types'

export const initialState: ContextLoginProps = {
  action: LoginAction.None,
  otpQrCodeBase64: '',
  authenticate: () =>
    new Promise((resolve) => {
      resolve(false)
    }),
  authenticateOTP: () =>
    new Promise((resolve) => {
      resolve(false)
    }),
  email: '',
  loading: false,
  registerDevice: () =>
    new Promise((resolve) => {
      resolve(false)
    }),
  updatePassword: () =>
    new Promise((resolve) => {
      resolve(false)
    }),
}
