import { getCookieDomain } from '@frontend/shared/utils'

import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

import { SuccessfullyAuthenticatedResponse } from './types'

interface RequiredAction {
  execution: string
  sessionCode: string
  tabId: string
}

interface RequiredActionRegisterOTP extends RequiredAction {
  otp: string
  otpSecret: string
}

interface RequiredActionUpdatePassword extends RequiredAction {
  passwordNew: string
  username: string
}

export const useServiceRequiredAction = () => {
  const { post, error, loading } = useAuthFetch<SuccessfullyAuthenticatedResponse>()

  /**
   * Do a required action of OTP registration.
   */
  const registerOTP = async (params: RequiredActionRegisterOTP): Promise<SuccessfullyAuthenticatedResponse> => {
    const body = { cookieDomain: getCookieDomain(), ...params }

    return post(ENDPOINT_URL.requiredActionRegisterOTP, body)
  }

  /**
   * Do a required action to update password.
   */
  const updatePassword = async (params: RequiredActionUpdatePassword): Promise<SuccessfullyAuthenticatedResponse> => {
    const body = { cookieDomain: getCookieDomain(), ...params }

    return post(ENDPOINT_URL.requiredActionUpdatePassword, body)
  }

  return { registerOTP, updatePassword, error, loading }
}
