import React from 'react'

import { RequestResult } from '../root/request-result'

export const RequestResultError = () => (
  <RequestResult
    description='Sorry, we could not load the requested data, please try again later. The problem was reported.'
    title='We could not retrieve data'
    type='error'
  />
)
