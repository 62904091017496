import { styled } from '@frontend/shared/theme'
import { Flex, Screen } from '@frontend/shared/ui'
import { Logger } from '@frontend/shared/logger'

export interface MainLayoutWrapperProps {
  type?: 'small' | 'medium' | 'large'
}

interface LayoutScreenProps {
  menuOpenMobile?: boolean
}

const getMainLayoutWrapperMaxWidth = (type: MainLayoutWrapperProps['type']) => {
  switch (type) {
    case 'small':
      return ['auto', '48rem']
    case 'medium':
      return ['auto', '75rem']
    case 'large':
      return 'auto'
    default: {
      Logger.error(`Implement new main layout type - ${type} for app layout`)

      return 'auto'
    }
  }
}

export const AppScreen = styled(Screen).attrs<LayoutScreenProps>(({ menuOpenMobile }) => ({
  height: menuOpenMobile ? '100vh' : 'min-content',
  overflow: menuOpenMobile ? 'hidden' : 'visible',
}))<LayoutScreenProps>``

export const MainLayoutWrapper = styled(Flex).attrs<MainLayoutWrapperProps>(({ type }) => ({
  width: '100%',
  maxWidth: getMainLayoutWrapperMaxWidth(type),
  p: [4, 6],
  pb: ['16rem', '11rem', null, '8rem'],
  flex: 1,
  flexDirection: 'column',
}))<MainLayoutWrapperProps>``

export const ScrollWrapper = styled(Flex).attrs(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  flex: 1,
  flexDirection: 'column',
}))``

export const MainLayout = styled(Flex).attrs(() => ({
  flex: 1,
  flexDirection: 'column',
}))``

export const FooterContainer = styled(Flex).attrs(() => ({
  bottom: '0rem',
  position: 'absolute',
  width: '100%',
  justifyContent: 'center',
  px: [4, 6],
}))`
  background-color: ${({ theme }) => theme.colors.secondary.bgGray.main};
`
