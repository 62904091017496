import { Router } from 'next/router'

import { ActionType, ActionStatus } from '../enums'

export interface ActionResult {
  status: ActionStatus
  type: ActionType
}

function isActionResultRouterQuery(x: unknown): x is ActionResult {
  if (typeof x !== 'object') {
    return false
  }

  return (x as ActionResult).type !== undefined && (x as ActionResult).status !== undefined
}

export const useResolveActionResultRouterQuery = (query: Router['query']): ActionResult | null => {
  if (isActionResultRouterQuery(query)) {
    return {
      status: query.status,
      type: query.type,
    }
  }

  return null
}
