import React, { useEffect, useState } from 'react'
import { useTheme } from '@frontend/shared/theme'
import { KEY } from '@frontend/shared/constants'
import dynamic from 'next/dynamic'

import { Icon } from '../../../text'
import { useBreakpoints } from '../../../hooks/use-breakpoints/use-breakpoints'

import { CalendarContainer } from './components/calendar-container'
import { Wrapper } from './components/date.styles'
import { DatePickerProps, DatepickerStyle, DateValue, RangeDatePickerProps, SingleDatePickerProps } from './types'
import { CustomHeader } from './components/custom-header'

const isRangeValue = (value: DateValue): value is RangeDatePickerProps['value'] => Array.isArray(value)

const getDate = (value: RangeDatePickerProps['value'] | SingleDatePickerProps['value']): Date =>
  isRangeValue(value) ? value[0] : value

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const DynamicDatepicker = dynamic(() => import('react-datepicker'), { ssr: false })

export const DatePicker = ({
  value,
  onChange,
  onClose,
  dateFormat,
  rangeYears,
  minDate,
  maxDate,
  excludeDates,
  selectsRange,
  name,
  translations,
  isOpen,
}: DatePickerProps): JSX.Element => {
  const id = `datepicker-${name}`

  const theme = useTheme()

  const { isXs, isSm } = useBreakpoints({ breakpoints: theme.breakpoints })

  const getMonthsCount = (): number => {
    if (isXs || isSm) {
      return 1
    }

    return selectsRange ? 2 : 1
  }

  const [datepickerStyle, setDatepickerStyle] = useState<DatepickerStyle[]>(Array(getMonthsCount()).fill('days'))

  useEffect(() => {
    const handleClick = () => onClose?.(value as Date & [Date, Date])
    const handleKeyDown = (event: KeyboardEvent) => event.key === KEY.escape && onClose?.(value as Date & [Date, Date])

    document.addEventListener('click', handleClick)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose, value])

  const startDate = isRangeValue(value) ? value[0] : undefined
  const endDate = isRangeValue(value) ? value[1] : undefined

  const previousMonthButtonLabel = <Icon kind='FiArrowLeft' />
  const nextMonthButtonLabel = <Icon kind='FiArrowRight' />

  return (
    <Wrapper data-testid='datepicker' id={id} rangePicker={selectsRange} onClick={(event) => event.stopPropagation()}>
      <DynamicDatepicker
        calendarContainer={CalendarContainer({
          datepickerStyle,
          selectsRange,
          startDate,
          endDate,
          onChange: onChange as RangeDatePickerProps['onChange'],
          translations,
        })}
        dateFormat={dateFormat}
        endDate={endDate}
        excludeDates={excludeDates}
        inline
        maxDate={maxDate}
        minDate={minDate}
        monthsShown={getMonthsCount()}
        nextMonthButtonLabel={nextMonthButtonLabel}
        open={isOpen}
        previousMonthButtonLabel={previousMonthButtonLabel}
        renderCustomHeader={CustomHeader({
          datepickerStyle,
          setDatepickerStyle,
          translations,
          selectsRange,
          rangeYears,
        })}
        selected={getDate(value)}
        selectsRange={selectsRange}
        startDate={startDate}
        onChange={onChange}
      />
    </Wrapper>
  )
}
