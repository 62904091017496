import { css } from 'styled-components'

const sm = '40rem' // 640px
const md = '48rem' // 768px
const lg = '64rem' // 1024px
const xl = '80rem' // 1280px
const xxl = '92.5rem' // 1480px

export const breakpoints = [sm, md, lg, xl, xxl] as const

export const gridGap = {
  sm: 2,
  md: 4,
  lg: 6,
  xl: 8,
  xxl: 10,
}

export const transition = '0.25s ease-in-out'

export const shadows = {
  xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
  '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.2)',
} as const

export const blurs = {
  sm: {
    light: css`
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(0.5rem);
    `,
    dark: css`
      background: rgba(52, 64, 84, 0.6);
      backdrop-filter: blur(0.5rem);
    `,
  },
  md: {
    light: css`
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(1rem);
    `,
    dark: css`
      background: rgba(52, 64, 84, 0.6);
      backdrop-filter: blur(1rem);
    `,
  },
  lg: {
    light: css`
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(1.5rem);
    `,
    dark: css`
      background: rgba(52, 64, 84, 0.6);
      backdrop-filter: blur(1.5rem);
    `,
  },
  xl: {
    light: css`
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(2.5rem);
    `,
    dark: css`
      background: rgba(52, 64, 84, 0.6);
      backdrop-filter: blur(2.5rem);
    `,
  },
} as const

export const space = [
  '0.25rem', // 4px
  '0.5rem', // 8px
  '0.75rem', // 12px
  '1rem', // 16px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '2rem', // 32px
  '2.5rem', // 40px
  '3rem', // 48px
  '4rem', // 64px
  '5rem', // 80px
  '6rem', // 96px
  '8rem', // 128px
  '10rem', // 160px
  '12rem', // 192px
  '14rem', // 224px
  '16rem', // 256px
] as const

export const fontFamily = 'Montserrat, sans-serif'

export const fontSizes = {
  display: {
    xs: '1.5rem',
    sm: '1.875rem',
    md: '2.25rem',
    lg: '3rem',
    xl: '3.75rem',
    '2xl': '4.5rem',
  },
  text: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
} as const

export const fontWeights = { normal: 400, medium: 500, bolder: 600, bold: 700 } as const
