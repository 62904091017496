import { Button, FieldAmount, Flex, Form, Grid, Icon, Section, Text } from '@frontend/shared/ui'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormikProps } from 'formik'
import { formatNumber, formatPrice, getCookie, roundWithPrecision } from '@frontend/shared/utils'
import { useTheme } from '@frontend/shared/theme'
import { Customer, SellingUnit, Vintage } from '@frontend/shared/types'

import { calcPrice, getSellingUnit } from '../../../../utils'
import { Summary } from '../../../../ui/layout/summary/summary'
import { STRIPE_MAX_AMOUNT_LIMIT } from '../../../../constants'

import { FormValues } from './types'
import { validationSchema } from './validation-schema'
import { PreselectedButtons } from './section-form.styles'

export interface SectionFormProps {
  productPrice: number
  sellingUnit: SellingUnit
  submitForm: (values: FormValues) => void
  ticker: string
  customer?: Customer
  vintage?: Vintage
}

const COOKIE_QUANTITY_KEY = 'quantity'
const COOKIE_QUANTITY_ORIGINAL_KEY = 'quantityOriginal'

const getPredefinedValues = (sellingUnit: SellingUnit) =>
  sellingUnit === SellingUnit.CarbonTonne
    ? {
        1: '1 tonne',
        2: '2 tonnes',
        5: '5 tonnes',
      }
    : {
        5: '5 trees',
        20: '20 trees',
        50: '50 trees',
      }

const { getMixedValue: getQuantity, setMixedValue: setQuantity } =
  getCookie<Record<string, number>>(COOKIE_QUANTITY_KEY)

const { getMixedValue: getQuantityOriginal, setMixedValue: setQuantityOriginal } =
  getCookie<Record<string, number>>(COOKIE_QUANTITY_ORIGINAL_KEY)

export const SectionForm = ({ productPrice, submitForm, ticker, customer, sellingUnit, vintage }: SectionFormProps) => {
  const predefinedValues = getPredefinedValues(sellingUnit)

  const theme = useTheme()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const [predefinedValue, setPredefinedValue] = useState(() => {
    const firstPredefinedValue = Object.keys(predefinedValues)[0]

    return parseInt(firstPredefinedValue, 10)
  })

  const initialCookieQuantity = getQuantity()?.[ticker]
  const initialCookieQuantityOriginal = getQuantityOriginal()?.[ticker]

  const initialValues = {
    quantity: initialCookieQuantity ? initialCookieQuantity.toString() : roundWithPrecision(predefinedValue).toString(),
    quantityOriginal: initialCookieQuantityOriginal ?? predefinedValue,
    priceTotal: initialCookieQuantity
      ? calcPrice(initialCookieQuantity, productPrice)
      : calcPrice(predefinedValue, productPrice),
    vintage: vintage?.vintage,
  }

  const changeCookieQuantity = (quantity: number, quantityOriginal: number) => {
    const cookieQuantity = getQuantity()
    const cookieQuantityOriginal = getQuantityOriginal()

    setQuantity({ ...cookieQuantity, [ticker]: quantity })
    setQuantityOriginal({ ...cookieQuantityOriginal, [ticker]: quantityOriginal })
  }

  const changePredefinedValue = (predefinedQuantity: number) => {
    const quantity = roundWithPrecision(predefinedQuantity)
    const priceTotal = calcPrice(predefinedQuantity, productPrice)

    formikRef.current?.resetForm()
    formikRef.current?.setFieldValue('quantity', quantity)
    formikRef.current?.setFieldValue('quantityOriginal', predefinedQuantity, false)
    formikRef.current?.setFieldValue('priceTotal', priceTotal, false)
    setPredefinedValue(predefinedQuantity)
    changeCookieQuantity(quantity, predefinedQuantity)
  }

  const changeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const valueNumber = parseFloat(value)

    setPredefinedValue(0)

    formikRef.current?.setFieldValue('priceTotal', calcPrice(valueNumber, productPrice), false)
    formikRef.current?.setFieldValue('quantityOriginal', value, false)

    const cookieValue = sellingUnit === SellingUnit.Tree ? roundWithPrecision(valueNumber, 0) : valueNumber

    changeCookieQuantity(cookieValue, cookieValue)
  }

  const changeVintage = ({ priceEur, vintage }: Vintage) => {
    if (formikRef?.current) {
      const { quantity } = formikRef.current.values ?? {}

      formikRef.current.setFieldValue('vintage', vintage, false)
      formikRef.current.setFieldValue('priceTotal', calcPrice(parseFloat(quantity), priceEur))
    }
  }

  const minQuantity = 1
  const maxQuantity = roundWithPrecision(STRIPE_MAX_AMOUNT_LIMIT / productPrice, 0)

  useEffect(() => {
    vintage && changeVintage(vintage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vintage])

  return (
    <Section>
      <Form<FormValues>
        initialValues={initialValues}
        innerRef={formikRef}
        name='detail'
        validationSchema={validationSchema(sellingUnit, minQuantity, maxQuantity)}
        onSubmit={submitForm}
      >
        {({ values }) => (
          <Grid gap='sm'>
            <Text data-test-id='select-custom-amount-title'>
              {sellingUnit === SellingUnit.Tree ? 'Select custom amount of trees' : 'Select custom amount'}
            </Text>

            <PreselectedButtons
              data-test-id='preselected-buttons'
              gap='sm'
              gridTemplateColumns={`repeat(${Object.values(predefinedValues).length}, max-content)`}
            >
              {Object.entries(predefinedValues).map(([value, label]) => {
                const valueNumber = parseInt(value, 10)
                const type = parseFloat(values.quantity) === valueNumber ? 'primary' : 'tertiaryGray'

                return (
                  <Button
                    key={value.toString()}
                    name={`predefined-${value}`}
                    type={type}
                    onClick={() => changePredefinedValue(valueNumber)}
                  >
                    {label}
                  </Button>
                )
              })}
            </PreselectedButtons>

            <Grid gap='sm' gridTemplateColumns='1fr auto'>
              <FieldAmount
                label={getSellingUnit(sellingUnit, true)}
                name='quantity'
                precision={0}
                onChange={changeQuantity}
              />
              <Flex pt={6}>
                <Text data-test-id='amount' size='lg'>
                  = {formatPrice(values.priceTotal)}
                </Text>
              </Flex>
            </Grid>

            {customer?.purchaseSums?.[ticker] ? (
              <Summary gridTemplateColumns='auto max-content'>
                <Flex flexDirection='column'>
                  <Text color={theme.colors.gray['700']}>You bought</Text>
                  <Text color={theme.colors.gray['600']}>
                    {formatPrice(customer?.purchaseSums[ticker].eur)} /{' '}
                    {formatNumber(customer?.purchaseSums[ticker].quantity)} {getSellingUnit(sellingUnit, true)}
                  </Text>
                </Flex>
                <Button htmlType='submit' name='add-to-cart'>
                  Buy more
                </Button>
              </Summary>
            ) : (
              <Grid gridTemplateColumns='max-content'>
                <Button htmlType='submit' name='add-to-cart'>
                  Buy
                  <Flex ml={1}>
                    <Icon kind='FiArrowRight' />
                  </Flex>
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Form>
    </Section>
  )
}
