import {
  Button,
  Field,
  FieldAmount,
  FieldSelect,
  Flex,
  Form,
  Grid,
  Icon,
  Section,
  Text,
  useToast,
} from '@frontend/shared/ui'
import { VerificationPeriod, Project, SellingUnit } from '@frontend/shared/types'
import { formatNumber, formatPrice } from '@frontend/shared/utils'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { Summary } from '../../../ui/layout/summary/summary'
import { Routes } from '../../../types'
import { useTheme } from '../../../ui/theme/theme-iframe/styled-components'
import { useQueryParserProjectCheckout } from '../../../hooks/use-query-parser-project-checkout'
import { byVintageDateDesc } from '../../../filter'

import { getInitialValues } from './form/initial-values'
import { useServiceReservation } from './services/use-service-reservation'
import { validationSchema } from './form/validation-schema'
import { FormValues } from './form/types'

interface FormReservationProps {
  price: number
  project: Project
  routes: Routes
  emailDefault?: string
  verificationPeriod?: VerificationPeriod
}

export const FormReservation = ({ routes, project, price, emailDefault, verificationPeriod }: FormReservationProps) => {
  const theme = useTheme()
  const router = useRouter()
  const { loading, reserveOffset } = useServiceReservation()
  const { showToastSuccess } = useToast()
  const queryParams = useQueryParserProjectCheckout(router.query)

  useEffect(() => {
    !queryParams?.vintage && router.back()
  }, [queryParams?.vintage, router])

  if (!queryParams?.vintage) {
    return null
  }

  const vintageOptions =
    verificationPeriod?.vintages?.sort(byVintageDateDesc).map(({ vintage, priceEur }) => ({
      label: `${vintage} - ${formatPrice(priceEur)}`,
      value: vintage,
    })) ?? []

  const initialValues = getInitialValues({
    emailDefault,
    vintage: queryParams?.vintage,
    expectedPrice: project.prices.eur,
    name: queryParams.name,
    email: queryParams.email,
  })

  const submitForm = async ({ name, email, expectedPrice, vintage }: FormValues) => {
    const { quantity } = queryParams
    const { ticker: projectTicker, sellingUnit } = project
    const reserved = await reserveOffset({
      email: email.trim(),
      currency: 'eur',
      name,
      quantity,
      projectTicker,
      sellingUnit,
      expectedPrice: parseFloat(expectedPrice),
      vintage,
    })

    if (reserved) {
      showToastSuccess(
        'Reservation successful',
        'Thank you for your reservation. See your email for more details about your order.',
      )

      await router.replace(routes.carbonOffsetProjectList.getUrl(router.query))
    }
  }

  return (
    <Form initialValues={initialValues} name='checkout' validationSchema={validationSchema} onSubmit={submitForm}>
      <Grid gridTemplateColumns={['100%', null, null, null, '3fr 24rem']}>
        <Section>
          <Text size='xl'>Payment details</Text>

          <Form.Group>
            <Field label='Name' name='name' />
            <Field disabled={!!emailDefault} label='Email' name='email' placeholder='carbon.neutral@earthbanc.io' />
          </Form.Group>

          <Form.Group>
            <FieldSelect label='Vintage' name='vintage' options={vintageOptions} />
            <FieldAmount
              label='Expected price per tonne'
              name='expectedPrice'
              precision={2}
              prefix={{ type: 'LeadingText', component: 'EUR' }}
            />
          </Form.Group>
        </Section>

        <Section gridAutoRows='max-content'>
          <Text data-test-id='order-summary-title' size='xl'>
            Order Summary
          </Text>

          <Summary gap='md'>
            <Text
              color={theme.components.checkout.orderSummary.name.textColor}
              data-test-id='order-summary-name'
              size='md'
              weight={600}
            >
              {project?.name}
            </Text>

            <Flex justifyContent='space-between'>
              <Text
                color={theme.components.checkout.orderSummary.totalAmount.textColor}
                data-test-id='order-summary-amount-co2-title'
                size='md'
              >
                Total amount of {project.sellingUnit === SellingUnit.Tree ? 'trees' : 'CO2'}
              </Text>
              <Text
                color={theme.components.checkout.orderSummary.totalAmount.textColor}
                data-test-id='order-summary-amount-co2-value'
                size='md'
              >
                {formatNumber(queryParams?.quantity)} {project.sellingUnit === SellingUnit.Tree ? '' : 't'}
              </Text>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text
                color={theme.components.checkout.orderSummary.totalPrice.textColor}
                data-test-id='order-summary-total-title'
                size='md'
                weight={600}
              >
                Total
              </Text>
              <Text
                color={theme.components.checkout.orderSummary.totalPrice.textColor}
                data-test-id='order-summary-total-value'
                size='md'
                weight={600}
              >
                {formatPrice(price)}
              </Text>
            </Flex>
          </Summary>

          <Grid>
            <Button disabled={loading} htmlType='submit' loading={loading} name='pay'>
              <Flex mr={1}>
                <Icon kind='FiLock' />
              </Flex>{' '}
              Reserve
            </Button>
          </Grid>
        </Section>
      </Grid>
    </Form>
  )
}
