import { DATE_FORMAT, LANGUAGE_CODE } from '@frontend/shared/constants'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'

interface NumberFormatOptions {
  currency?: string | undefined
  currencySign?: string | undefined
  languageCode?: string
  localeMatcher?: string | undefined
  maximumFractionDigits?: number | undefined
  maximumSignificantDigits?: number | undefined
  minimumFractionDigits?: number | undefined
  minimumIntegerDigits?: number | undefined
  minimumSignificantDigits?: number | undefined
  style?: string | undefined
  useGrouping?: boolean | undefined
}

export const formatPrice = (price: number, options?: NumberFormatOptions) =>
  new Intl.NumberFormat(options?.languageCode ?? LANGUAGE_CODE, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'EUR',
    ...options,
  }).format(price || 0)

export const formatDay = (day: number) => (day === 1 ? `${day} day` : `${day} days`)

export const formatDate = (date: string, format = DATE_FORMAT.fullDate) => {
  const parseDate = new Date(date)

  return dayjs(parseDate).isValid() ? dayjs(parseDate).format(format) : '-'
}

export const formatDateLocale = (date: string) => (dayjs(date).isValid() ? dayjs(date).format('LLL') : '-')

export const formatNumber = (number: number, options?: NumberFormatOptions) =>
  new Intl.NumberFormat(LANGUAGE_CODE, { maximumSignificantDigits: 3, ...options }).format(number)

export const formatNumberLocale = (number: number, options?: NumberFormatOptions) =>
  new Intl.NumberFormat(undefined, { maximumSignificantDigits: 3, ...options }).format(number)

export const bigNumberToPercent = (number: BigNumber, options?: NumberFormatOptions) =>
  new Intl.NumberFormat(LANGUAGE_CODE, { style: 'percent', maximumSignificantDigits: 3, ...options }).format(
    number.toNumber(),
  )

export const OPTIONS_NUMBER: Record<'zeroPrecise' | 'singlePrecise' | 'doublePrecise', NumberFormatOptions> = {
  zeroPrecise: { maximumFractionDigits: 0, maximumSignificantDigits: undefined },
  singlePrecise: { maximumFractionDigits: 1, maximumSignificantDigits: undefined },
  doublePrecise: { maximumFractionDigits: 2, maximumSignificantDigits: undefined },
}

export const OPTIONS_PRICE: Record<'USD' | 'INR', NumberFormatOptions> = {
  USD: { languageCode: 'en-US', currency: 'USD' },
  INR: { languageCode: 'hi-IN', currency: 'INR' },
}
