import { Grid, RequestResult, Section } from '@frontend/shared/ui'
import React from 'react'
import { LayoutApp } from '@frontend/domains/plus/app'
import { H1 } from '@frontend/domains/shared/components'

export const Error = () => (
  <LayoutApp>
    <Grid>
      <H1>Application error</H1>

      <Section>
        <RequestResult
          description='The problem was already reported to our dev team. They will do the best to get things right.'
          title='We are sorry, but something does not work correctly in our application right now.'
          type='error'
        />
      </Section>
    </Grid>
  </LayoutApp>
)
