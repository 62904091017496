import { StyledProps } from '@frontend/shared/theme'

interface GetBorderColor {
  disabled?: boolean
  error?: boolean | string
  focused?: boolean
  touched?: boolean
}

export const hasError = ({ error, touched }: GetBorderColor) => !!(touched && error)

export const getBorderColor = ({ theme, ...props }: StyledProps<GetBorderColor>) => {
  if (hasError(props)) {
    return theme.components.input[':error'].borderColor
  }

  if (props.focused) {
    return theme.components.input[':focused'].borderColor
  }

  return theme.components.input.borderColor
}
