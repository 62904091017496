import { FileRejection } from 'react-dropzone'
import React from 'react'

import { File } from '../../../../../screen/file'

interface FilesPreviewProps {
  onRemoveAcceptedFile: (name: string) => void
  onRemoveRejectedFile: (name: string) => void
  acceptedFiles?: File[]
  rejectedFiles?: FileRejection[]
}

export const SingleFile = ({
  rejectedFiles = [],
  acceptedFiles = [],
  onRemoveAcceptedFile,
  onRemoveRejectedFile,
}: FilesPreviewProps) => {
  const acceptedFile = acceptedFiles?.[0]
  const rejectedFile = rejectedFiles?.[0]

  if (acceptedFiles?.length === 1) {
    return <File name={acceptedFile.name} type='upload' onClose={() => onRemoveAcceptedFile(acceptedFile.name)} />
  }

  if (rejectedFiles?.length === 1) {
    return (
      <File
        description={rejectedFile.errors?.[0].message}
        name={rejectedFile.file.name}
        type='error'
        onClose={() => onRemoveRejectedFile(rejectedFile.file.name)}
      />
    )
  }

  return null
}
