import { Flex, Grid } from '@frontend/shared/ui'
import React, { createRef, PropsWithChildren, useEffect, useState } from 'react'
import { GridTemplateColumnsProps } from 'styled-system'

interface StickyPanelProps {
  gridTemplateColumns?: GridTemplateColumnsProps['gridTemplateColumns']
  testID?: string
}

export const StickyPanel = ({
  children,
  gridTemplateColumns = ['1fr', null, '1fr 1fr', '100%'],
  testID = '',
}: PropsWithChildren<StickyPanelProps>) => {
  const [panelTopOffset, setPanelTopOffset] = useState(0)
  const stickyPanel = createRef<HTMLDivElement>()

  useEffect(() => {
    stickyPanel?.current && setPanelTopOffset(stickyPanel?.current.getBoundingClientRect().top)

    const scrollHandler = () => {
      if (stickyPanel?.current) {
        const { top } = stickyPanel.current.getBoundingClientRect()

        if (top <= 0 && window.scrollY >= panelTopOffset) {
          stickyPanel.current.style.position = 'sticky'
          stickyPanel.current.style.top = '0'
        } else {
          stickyPanel.current.style.position = 'static'
        }
      }
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [stickyPanel, panelTopOffset])

  return (
    <Flex data-test-id={testID} flexDirection='column'>
      <Grid gridAutoRows='max-content' gridTemplateColumns={gridTemplateColumns} ref={stickyPanel} width='100%'>
        {children}
      </Grid>
    </Flex>
  )
}
