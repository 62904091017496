import React, { PropsWithChildren } from 'react'
import dayjs from 'dayjs'

import { getRange, RangeType } from './helpers'
import { SelectableButton } from './selectable-button'

export interface DateRangeButtonProps {
  onChange: (date: [Date, Date]) => void
  rangeType: RangeType
  endDate?: Date | null
  startDate?: Date | null
  testID?: string
}

export const DateRangeButton = ({
  rangeType,
  children,
  onChange,
  startDate,
  endDate,
  testID,
}: PropsWithChildren<DateRangeButtonProps>) => {
  const range = getRange(rangeType)

  const buttonClickHandler = () => onChange(range)

  return (
    <SelectableButton
      data-testid={testID}
      selected={
        startDate && endDate && dayjs(startDate).isSame(range[0], 'day') && dayjs(endDate).isSame(range[1], 'day')
      }
      onClick={buttonClickHandler}
    >
      {children}
    </SelectableButton>
  )
}
