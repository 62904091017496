import { Display } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import React, { PropsWithChildren } from 'react'

export const CardNumber = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useTheme()

  return (
    <Display
      color={theme.colors.gray['700']}
      fontSize={[theme.fontSizes.display.md, theme.fontSizes.display.lg]}
      lineHeight={['2.5rem', '3rem']}
      size='lg'
    >
      {children}
    </Display>
  )
}
