import { useState } from 'react'
import { useBoolean } from '@frontend/shared/hooks'

import { ModalAlertType } from './modal-alert'

export type OpenAlertModal = (title: string, type: ModalAlertType, description?: string, onConfirm?: () => void) => void

export const useModalAlert = () => {
  const [alertModalType, setAlertModalType] = useState<ModalAlertType>('success')
  const [alertModalTitle, setAlertModalTitle] = useState('')
  const [alertModalDescription, setAlertModalDescription] = useState('')
  const { value: alertModalOpen, setTrue: openModal, setFalse: closeModal, toggle: toggleModal } = useBoolean(false)
  const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(() => () => toggleModal())

  const openAlertModal: OpenAlertModal = (title, type, description, onConfirm) => {
    const confirmHandler = () => {
      closeModal()
      onConfirm?.()
    }

    setAlertModalTitle(title)
    setAlertModalType(type)
    description && setAlertModalDescription(description)
    setAlertModalOnConfirm(() => confirmHandler)
    openModal()
  }

  return {
    alertModalOpen,
    openModal,
    openAlertModal,
    alertModalTitle,
    alertModalDescription,
    alertModalOnConfirm,
    alertModalType,
  }
}
