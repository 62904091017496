import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

/**
 * Disables two-factor authentication with OTP for the currently
 * authenticated user and removes all registered OTP devices.
 */
export const useServiceDisableOtpAuthentication = () => {
  const { post, error, loading, response } = useAuthFetch<unknown>()

  const disableOTPAuthentication = async (password: string, otp: string): Promise<boolean> => {
    const body = { password, otp }
    await post(ENDPOINT_URL.disableOTPAuthentication, body)

    return response.ok
  }

  return { disableOTPAuthentication, error, loading }
}
