import dynamic from 'next/dynamic'

import { ProgressBarProps } from './bar/progress-bar'
import { ProgressCircleProps } from './circle/progress-circle'

const ProgressBar = dynamic<ProgressBarProps>(() => import('./bar/progress-bar').then((module) => module.ProgressBar))
const ProgressCircle = dynamic<ProgressCircleProps>(() =>
  import('./circle/progress-circle').then((module) => module.ProgressCircle),
)

export { ProgressBar, ProgressCircle }
export * from './steps'
