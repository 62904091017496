import { ParsedUrlQuery } from 'querystring'

import { CarbonOffsetProjectCheckoutQueries } from './types'

export enum Routes {
  CarbonOffsetProjectCheckout = 'carbonOffsetProjectCheckout',
  CarbonOffsetProjectFaq = 'carbonOffsetProjectFaq',
  CarbonOffsetProjectList = 'carbonOffsetProjectList',
  CarbonOffsetProjectDetail = 'carbonOffsetProjectDetail',
}

const getIframeQueries = (queries: ParsedUrlQuery, operator: '?' | '&' = '?') => {
  const params = new URLSearchParams()

  typeof queries?.sellingUnit === 'string' && params.set('sellingUnit', queries?.sellingUnit)
  Array.isArray(queries?.tags) && queries.tags.map((tag) => params.append('tags', tag))

  const queryResult = params.toString()

  return queryResult !== '' ? `${operator}${queryResult}` : ''
}

export const routes = {
  [Routes.CarbonOffsetProjectCheckout]: {
    getUrl: (
      { ticker, gift, quantity, subscription, vintage }: CarbonOffsetProjectCheckoutQueries,
      queries: ParsedUrlQuery,
    ) => {
      const queryParams = {
        ticker,
        gift: gift.toString(),
        quantity: quantity.toString(10),
        subscription: subscription.toString(),
      }

      vintage && Object.assign(queryParams, { vintage })

      return `/checkout?${new URLSearchParams(queryParams).toString()}${getIframeQueries(queries, '&')}`
    },
  },
  [Routes.CarbonOffsetProjectFaq]: {
    getUrl: (queries: ParsedUrlQuery) => `/faq${getIframeQueries(queries)}`,
  },
  [Routes.CarbonOffsetProjectList]: {
    getUrl: (queries: ParsedUrlQuery) => `/${getIframeQueries(queries)}`,
  },
  [Routes.CarbonOffsetProjectDetail]: {
    getUrl: (ticker: string, queries: ParsedUrlQuery) => `/detail?ticker=${ticker}${getIframeQueries(queries, '&')}`,
  },
} as const
