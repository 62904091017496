import { styled, css } from '@frontend/shared/theme'

interface ListProps {
  spacing?: 'sm' | 'md'
}

export const Li = styled.li`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

const listStyles = css<ListProps>`
  margin: 0;
  padding-left: 1.2rem;

  ${Li}:not(:last-of-type) {
    margin-bottom: ${({ spacing = 'md' }) => (spacing === 'sm' ? '0.25rem' : '0.75rem')};
  }
`

export const Ol = styled.ol<ListProps>`
  ${listStyles};
`

export const Ul = styled.ul<ListProps>`
  ${listStyles};
`
