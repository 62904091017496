import { DisplayFontSize, FontWeight, styled, TextFontSize, Theme } from '@frontend/shared/theme'
import {
  color,
  ColorProps,
  fontSize,
  fontWeight,
  lineHeight,
  LineHeightProps,
  FontSizeProps,
  textAlign,
  TextAlignProps,
} from 'styled-system'
import { StyledComponent } from 'styled-components'
import { PropsWithChildren } from 'react'

type TypographyCustomProps = TextAlignProps & ColorProps & LineHeightProps & FontSizeProps

type ResponsiveDisplayFontSize = DisplayFontSize | null
type ResponsiveTextFontSize = TextFontSize | null

export interface DisplayProps extends TypographyCustomProps {
  size?: DisplayFontSize | ResponsiveDisplayFontSize[]
  weight?: FontWeight
}

export interface TextProps extends TypographyCustomProps {
  size?: TextFontSize | ResponsiveTextFontSize[]
  weight?: FontWeight
}

const getDisplayFontSize = (size: DisplayFontSize | ResponsiveDisplayFontSize[], theme: Theme) => {
  if (typeof size === 'string') {
    return theme.fontSizes.display[size]
  }

  return size.map((unit) => (unit ? theme.fontSizes.display[unit] : null))
}

const getTextFontSize = (size: TextFontSize | ResponsiveTextFontSize[], theme: Theme) => {
  if (typeof size === 'string') {
    return theme.fontSizes.text[size]
  }

  return size.map((unit) => (unit ? theme.fontSizes.text[unit] : null))
}

export const Display: PropsWithChildren<StyledComponent<'span', Theme, DisplayProps>> = styled.span
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['color', 'fontSize', 'fontWeight', 'textAlign', 'lineHeight'].includes(prop.toString())
      && defaultValidatorFn(prop),
  })
  .attrs<DisplayProps>(({ size = 'md', weight = 600, theme, color, fontSize }) => ({
    fontSize: fontSize ?? getDisplayFontSize(size, theme),
    fontWeight: weight,
    color: color || theme.components.body.textColor,
  }))`
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${color};
  ${lineHeight};
`

export const Text: PropsWithChildren<StyledComponent<'span', Theme, TextProps>> = styled.span
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['color', 'fontSize', 'fontWeight', 'textAlign', 'lineHeight'].includes(prop.toString())
      && defaultValidatorFn(prop),
  })
  .attrs<TextProps>(({ size = 'md', weight = 500, theme, color, fontSize }) => ({
    fontSize: fontSize ?? getTextFontSize(size, theme),
    fontWeight: weight,
    color: color || theme.components.body.textColor,
  }))`
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${color};
  ${lineHeight};
`

export const TextBreakable = styled(Text)`
  word-break: break-word;
`

export const TextNonBreakable = styled(Text)`
  white-space: nowrap;
`
