import { useStripe } from '@stripe/react-stripe-js'
import { PaymentIntent, StripeIbanElement } from '@stripe/stripe-js'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'

interface ConfirmSepaPayment {
  clientSecret: string
  email: string
  iban: StripeIbanElement
  name: string
}

export const useServiceConfirmSepaPayment = () => {
  const { showToastWarning, showToastGenericError } = useToast()
  const stripe = useStripe()

  const confirmSepaPayment = async ({
    email,
    iban,
    name,
    clientSecret,
  }: ConfirmSepaPayment): Promise<PaymentIntent | undefined> => {
    if (!stripe) {
      return undefined
    }

    try {
      const result = await stripe.confirmSepaDebitPayment(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name,
            email,
          },
        },
      })

      if (result?.error) {
        showToastWarning('There was a problem with your payment', result?.error?.message ?? '')

        return undefined
      }

      return result.paymentIntent
    } catch (error) {
      Logger.error(error)
      showToastGenericError()

      return undefined
    }
  }

  return { confirmSepaPayment }
}
