import { getEnvironmentVariable } from '@frontend/shared/environment'
import { RATINGS_PRIORITY, SERVICE_URL } from '@frontend/shared/constants'
import { ImageFormat, Project, Rating, SellingUnit, Vintage } from '@frontend/shared/types'
import { Logger } from '@frontend/shared/logger'
import { formatPrice } from '@frontend/shared/utils'

import { ENDPOINT_URL } from './constants'

export const calcPrice = (quantity: number, price: number) => quantity * price

export const getFile = (id: number, format?: ImageFormat) =>
  getEnvironmentVariable('NEXT_PUBLIC_RESOURCE_URL') + SERVICE_URL.beUrl + ENDPOINT_URL.cmsFile(id, format)

export const getPrice = (price: number, sellingUnit: SellingUnit) =>
  `${formatPrice(price)} / ${getSellingUnit(sellingUnit)}`

export const getRating = (ratings: Project['ratings'] = []): Rating | undefined => {
  const sortedRatings: Project['ratings'] = []

  RATINGS_PRIORITY.forEach((priorityType) => {
    const priorityFound = ratings.find(({ type }) => type === priorityType)

    priorityFound && sortedRatings.push(priorityFound)
  })

  return sortedRatings?.[0]
}

export const getSellingUnit = (sellingUnit: SellingUnit, plural = false) => {
  switch (sellingUnit) {
    case SellingUnit.CarbonTonne:
      return plural ? 'tonnes CO2' : 't'
    case SellingUnit.Tree:
      return plural ? 'trees' : 'tree'
    default:
      Logger.error(`Implement new type of selling unit - ${sellingUnit} for offset projects.`)

      return plural ? 'tonnes CO2' : 't'
  }
}

export const parseQuantityValue = (value: string) => parseFloat(value.replace(',', '.'))

export const isVintageValid = (validatingVintage: string, vintages: Vintage[]) =>
  !!vintages.find(({ vintage }) => vintage === validatingVintage)

export const getPlace = (country: string, location?: string) => (location ? `${country}, ${location}` : country)
