import React from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Icon, Text } from '../../../text'
import { Flex } from '../../../layout/flex'

import {
  BadgeDot,
  badgeFontSize,
  BadgePrefix,
  BadgeWrapper,
  BadgeWrapperProps,
  getBadgePrefixColor,
  textColor,
} from './badge.styles'

export interface BadgeProps extends BadgeWrapperProps {
  label: string
  prefix?: BadgePrefix
  testID?: string
}

export const Badge = ({ label, size = 'sm', color, testID, prefix }: BadgeProps): JSX.Element => {
  const theme = useTheme()

  return (
    <BadgeWrapper color={color} size={size}>
      {prefix && (
        <>
          {prefix.type === 'dot' && <BadgeDot color={color} />}
          {prefix.type === 'icon' && prefix.value && (
            <Flex mr={1}>
              <Icon color={getBadgePrefixColor({ theme, color })} fontSize='0.8rem' kind={prefix.value} />
            </Flex>
          )}
        </>
      )}
      <Text color={textColor({ theme, color })} data-test-id={testID ?? 'badge'} size={badgeFontSize[size]}>
        {label}
      </Text>
    </BadgeWrapper>
  )
}
