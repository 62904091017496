import React, { memo, ReactNode } from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Text } from '../../text'
import { Flex, Grid } from '../../layout'

import { CardHeaderImage, CardHeaderWrapper } from './card-header.styles'

export interface CardHeaderProps {
  testID: string
  title: string
  badge?: ReactNode
  buttons?: ReactNode
  imageSource?: string
  subTitle?: string
}

export const CardHeader = memo(({ subTitle, title, imageSource, badge, buttons, testID }: CardHeaderProps) => {
  const theme = useTheme()

  return (
    <CardHeaderWrapper alignItems='center' data-test-id={testID} justifyContent='space-between'>
      <Flex flex={1}>
        {imageSource && (
          <Flex mr='1rem'>
            <CardHeaderImage alt='' height={56} src={imageSource} width={56} />
          </Flex>
        )}
        <Grid gap='sm'>
          <Flex alignItems='center'>
            <Text data-test-id={`${testID}-title`} size='lg'>
              {title}
            </Text>
            {badge && <Flex ml='1rem'>{badge}</Flex>}
          </Flex>

          {subTitle && (
            <Flex>
              <Text color={theme.colors.gray['500']} data-test-id={`${testID}-sub-title`} size='sm'>
                {subTitle}
              </Text>
            </Flex>
          )}
        </Grid>
      </Flex>

      {buttons && <Flex flex='0 auto'>{buttons}</Flex>}
    </CardHeaderWrapper>
  )
})
