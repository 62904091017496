import { getBlurHasDataUrl } from '@frontend/shared/utils'
import React from 'react'
import { useTheme } from '@frontend/shared/theme'
import { Rating } from '@frontend/shared/types'

import { Badge } from '../../../badge/root'
import { Grid } from '../../../../layout/grid/grid'
import { Icon } from '../../../../text/icon/root/icon'
import { Flex } from '../../../../layout/flex/flex'
import { Text, TextNonBreakable } from '../../../../text/typography'
import { useBreakpoints } from '../../../../hooks'
import { CardImage, CardImageWrapper, RatingWrapper } from '../base/card.styles'

export interface CardInformationProps {
  description: string
  imageHash: string
  imageSource: string
  place: string
  ticker: string
  title: string
  price?: string
  rating?: Rating
  tags?: string[]
}

export const CardInformation = ({
  imageSource,
  imageHash,
  place,
  rating,
  tags,
  description,
  title,
  price,
  ticker,
}: CardInformationProps) => {
  const theme = useTheme()
  const { isSm } = useBreakpoints({ breakpoints: theme.breakpoints })

  const locationFontSize = isSm ? '0.5rem' : '0.75rem'

  return (
    <Flex flexDirection='column'>
      <CardImageWrapper height='10rem' justifyContent='center' position='relative'>
        <CardImage
          alt=''
          blurDataURL={getBlurHasDataUrl(imageHash, 320, 160)}
          fill
          placeholder='blur'
          priority
          quality={100}
          sizes='100vw'
          src={imageSource}
        />
        {rating && (
          <RatingWrapper
            data-test-id='rating'
            gap='sm'
            gridTemplateColumns='repeat(2, max-content)'
            justifyContent='flex-end'
          >
            <Badge color='gray' label={rating.type.toUpperCase()} size='lg' />
          </RatingWrapper>
        )}
      </CardImageWrapper>
      <Grid gap='md' p='1.25rem'>
        <Grid gap='sm'>
          <Flex alignItems='center' flex={0}>
            <Icon color={theme.components.card.place.textColor} fontSize={locationFontSize} kind='FiMapPin' />
            <Flex pl='0.25rem'>
              <Text color={theme.components.card.place.textColor} data-test-id='location' size='xs'>
                {place}
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent='space-between'>
            <div>
              <Text color={theme.components.card.title.textColor} data-test-id='project-title' weight={600}>
                {title}
              </Text>
              <Text color={theme.components.card.ticker.textColor} data-test-id='project-abbreviation'>
                &nbsp;{ticker}
              </Text>
            </div>
            {price && (
              <Flex>
                <TextNonBreakable color={theme.components.card.price.textColor} data-test-id='price'>
                  {price}
                </TextNonBreakable>
              </Flex>
            )}
          </Flex>
        </Grid>
        <Flex>
          <Text color={theme.components.card.description.textColor} size='sm'>
            {description}
          </Text>
        </Flex>
        {tags && tags.length > 0 && (
          <Flex data-test-id='tags' flexWrap='wrap' m='-0.5rem'>
            {tags.map((tag) => (
              <Flex flex='0 auto' key={tag} m='0.5rem'>
                <Badge color='gray' key={tag} label={tag} size='lg' />
              </Flex>
            ))}
          </Flex>
        )}
      </Grid>
    </Flex>
  )
}
