import React from 'react'
import { components, OptionProps as ReactSelectOptionsProps } from 'react-select'
import { useTheme } from '@frontend/shared/theme'

import { Flex } from '../../../layout'
import { Icon } from '../../../text/icon/root/icon'

export const Option = <OptionType, IsMulti extends boolean = false>() => {
  const theme = useTheme()

  return (props: ReactSelectOptionsProps<OptionType, IsMulti>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSelected, label, value, selectProps } = props
    const { name } = selectProps
    const parsedValue = JSON.parse(value)

    return (
      components.Option && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.Option {...props} isDisabled={parsedValue.disabled}>
          <Flex
            alignItems='center'
            data-test-id={`${name}-${parsedValue.value}`}
            justifyContent={isSelected ? 'space-between' : 'flex-start'}
            position='relative'
          >
            {label}
            {isSelected && (
              <Icon color={theme.components.select.option.mark.backgroundColor} fontSize='1.3rem' kind='FiCheck' />
            )}
          </Flex>
        </components.Option>
      )
    )
  }
}
