export const ENDPOINT_URL = {
  authenticate: '/auth/authenticate',
  authenticateOTP: '/auth/authenticate-otp',
  customer: '/customers/me',
  certificateRedeem: '/customers/offset/certificates/redeem',
  disableOTPAuthentication: '/auth/disable-otp-authentication',
  enableOTPAuthentication: '/auth/enable-otp-authentication',
  changePassword: '/auth/password-change',
  friendlyReferralCode: '/customers/friendly-referral-code',
  initialize: '/auth/initialize',
  issueToken: '/auth/token',
  requiredActionRegisterOTP: '/auth/required-action-register-otp',
  requiredActionUpdatePassword: '/auth/required-action-update-password',
  passwordResetFinalize: '/auth/password-reset/finalize',
  passwordResetInitialize: '/auth/password-reset/initialize',
  refreshToken: '/auth/refresh-token',
  signOut: '/auth/sign-out',
  signUp: '/auth/sign-up',
  skipRequiredAction: '/auth/skip-required-action',
  treeTokens: '/customers/tree-tokens',
}

export const COOKIE_REDEEM_CODE_KEY = 'redeemCode'
