import { Skeleton, Flex, Grid, Section } from '@frontend/shared/ui'
import React from 'react'

export const CheckoutSkeleton = () => (
  <Flex flex={1} justifyContent='center'>
    <Grid maxWidth='67.5rem'>
      <Grid gridTemplateColumns='3fr 24rem'>
        <Section>
          <Skeleton height='3rem' />

          <Grid gridTemplateColumns='1fr 1fr'>
            <Skeleton height='3rem' />
            <Skeleton height='3rem' />
          </Grid>

          <Skeleton height='3rem' />
          <Skeleton height='3rem' />

          <Grid gridTemplateColumns='1fr 1fr'>
            <Skeleton height='3rem' />
            <Skeleton height='3rem' />
          </Grid>

          <Skeleton height='3rem' />
        </Section>

        <Section gridAutoRows='max-content'>
          <Skeleton height='3rem' />

          <Skeleton height='10rem' />

          <Skeleton height='3rem' />
        </Section>
      </Grid>
    </Grid>
  </Flex>
)
