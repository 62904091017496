import { getEnvironmentVariable } from '@frontend/shared/environment'
import { CONTENT_SECURITY_POLICY_NONCE } from '@frontend/shared/constants'
import { dynamicImport } from '@frontend/shared/utils'

const googleAnalyticsKey = getEnvironmentVariable('NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY')

if (googleAnalyticsKey) {
  dynamicImport(() =>
    import('react-ga4').then((module) => {
      const ReactGA = module.default

      ReactGA.initialize(googleAnalyticsKey, { nonce: CONTENT_SECURITY_POLICY_NONCE })
      ReactGA.ga('send', { hintType: 'pageview' })

      return null
    }),
  )
}
