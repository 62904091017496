import { styled } from '@frontend/shared/theme'

import { Flex } from '../../layout/flex/flex'
import { WRAPPER_MAX_WIDTH } from '../../constants'

const AVATAR_HEIGHT = '3rem'
const SEPARATOR_PADDING = '0.25rem'

export const ActivityFeedWrapper = styled(Flex).attrs(() => ({
  pb: 3,
  maxWidth: WRAPPER_MAX_WIDTH,
  width: '100%',
}))`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: calc(${AVATAR_HEIGHT} + ${SEPARATOR_PADDING});
    left: 1.5rem;
    height: calc(100% - (${AVATAR_HEIGHT} + (${SEPARATOR_PADDING} * 2)));
    width: 0.125rem;
    background-color: ${({ theme }) => theme.colors.gray['200']};
    border-radius: 0.125rem;
    z-index: 10;
  }

  &:last-child {
    &:before {
      display: none;
    }
  }
`
