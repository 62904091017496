import { styled } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex/flex'
import { getBorderColor } from '../../base/input.styles'

interface FileUploadWrapperProps {
  disabled: boolean
  dragActive: boolean
  error: boolean
  touched: boolean
}

export const FileUploadWrapper = styled(Flex).attrs<FileUploadWrapperProps>(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))<FileUploadWrapperProps>`
  background: ${({ theme }) => theme.colors.primary.contrast};
  border-width: 0.0625rem;
  border-style: solid;
  border-color: ${getBorderColor};
  border-radius: 0.5rem;
  padding: 1rem;
  transition: all ${({ theme }) => theme.transition};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.gray['200']};
  }
`
