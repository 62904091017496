import React from 'react'
import { FiUser } from 'react-icons/fi'

import { Avatar } from '../avatar'
import { AvatarProps } from '../avatar/avatar'

import { AvatarWrapper } from './user-avatar.styles'

export interface UserAvatarProps extends Pick<AvatarProps, 'size'> {
  name: string
  avatar?: string
}

export const UserAvatar = ({ name, size, avatar }: UserAvatarProps): JSX.Element => (
  <AvatarWrapper>
    <Avatar alt={name} size={size} src={avatar}>
      {!avatar ? <FiUser /> : null}
    </Avatar>
  </AvatarWrapper>
)
