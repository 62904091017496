import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react'

import { ButtonBase } from '../base/button-base'
import { ButtonTypes, StyledButton, StyledCustomButtonBaseProps } from '../base/button-base.styles'

interface CustomButtonProps
  extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'disabled' | 'autoFocus' | 'title'> {
  name: string
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  type?: ButtonTypes
}

export type ButtonProps = PropsWithChildren<CustomButtonProps & Partial<StyledCustomButtonBaseProps>>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      name,
      autoFocus,
      htmlType = 'button',
      children,
      type = 'primary',
      icon = 'none',
      loading = false,
      destructive = false,
      size = 'md',
      onClick,
      disabled,
      title,
    },
    ref,
  ) => {
    const clickable = !!onClick || htmlType === 'submit'

    return (
      <StyledButton
        autoFocus={autoFocus}
        clickable={clickable}
        data-test-id={`button-${name}`}
        destructive={destructive}
        disabled={disabled}
        hierarchy={type}
        icon={icon}
        loading={loading}
        name={name}
        ref={ref}
        size={size}
        title={title}
        type={htmlType}
        onClick={onClick}
      >
        <ButtonBase disabled={disabled} loading={loading} type={type}>
          {children}
        </ButtonBase>
      </StyledButton>
    )
  },
)
