import { styled } from '@frontend/shared/theme'
import { ForwardedRef } from 'react'

import { Grid, GridProps } from '../../../layout/grid/grid'
import { Flex } from '../../../layout/flex/flex'

interface RefProps {
  ref?: ForwardedRef<HTMLDivElement>
}

export const ModalBackground = styled(Flex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  p: '1.25rem',
}))<RefProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999;
  ${({ theme }) => theme.blurs.md.dark};
  overflow: auto;
`

export const ModalContent = styled(Grid).attrs<GridProps>(({ maxWidth, padding }) => ({
  p: padding ?? '1.5rem',
  gridGap: 5,
  gridRowGap: 5,
  maxWidth: maxWidth ?? '25rem',
  maxHeight: '90%',
  tabIndex: 0,
  id: 'alert_dialog',
  role: 'alertdialog',
  'aria-modal': 'true',
  'aria-labelledby': 'dialog_label',
  'aria-describedby': 'dialog_desc',
}))<RefProps>`
  position: relative;
  outline: transparent;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.contrast};
  border-radius: 0.75rem;
  overflow-y: auto;
`
