import React from 'react'
import { useTheme } from '@frontend/shared/theme'

import { IconFeatured, IconKind, Text } from '../../../text'
import { Grid } from '../../../layout'
import { Button } from '../../button'
import { Modal, ModalProps } from '../root/modal'

export type ModalAlertType = 'success' | 'error' | 'warning'

export interface ModalAlertProps extends Pick<ModalProps, 'title' | 'open' | 'loading'> {
  description: string
  onConfirm: () => void
  title: string
  type: ModalAlertType
  confirm?: string
}

const iconKind = (type: ModalAlertType): IconKind => {
  switch (type) {
    case 'warning':
      return 'FiAlertTriangle'
    case 'error':
      return 'FiAlertCircle'
    case 'success':
    default:
      return 'FiCheckCircle'
  }
}

export const ModalAlert = ({
  title,
  description,
  confirm = 'Confirm',
  open,
  onConfirm,
  loading,
  type,
}: ModalAlertProps) => {
  const theme = useTheme()

  const icon = <IconFeatured color={type} kind={iconKind(type)} size='lg' type='lightCircleOutline' />

  return (
    <Modal icon={icon} loading={loading} open={open} title={title}>
      <Text color={theme.colors.gray['500']} id='dialog_desc' size='sm' textAlign='center'>
        {description}
      </Text>
      <Grid>
        <Button autoFocus name='confirm-alert' onClick={onConfirm}>
          {confirm}
        </Button>
      </Grid>
    </Modal>
  )
}
