import React, { PropsWithChildren } from 'react'

import { Grid, Flex } from '../../../../layout'
import { Tooltip } from '../../../../screen/tooltip/tooltip'
import { Icon } from '../../../../text/icon'

import { Label } from './label'
import { Message } from './message'

export interface FieldWrapperProps {
  name: string
  disabled?: boolean
  error?: string
  hint?: string
  label?: string | JSX.Element
  labelRight?: string | JSX.Element
  tooltip?: string
  touched?: boolean
}

export const FieldWrapper = ({
  children,
  disabled,
  name,
  label,
  labelRight,
  error,
  hint,
  touched,
  tooltip,
}: PropsWithChildren<FieldWrapperProps>) => {
  const getJustifyContent = () => {
    if (label && labelRight) {
      return 'space-between'
    }

    return label ? 'flex-start' : 'flex-end'
  }

  return (
    <Flex data-test-id={`field-${name}`} flexDirection='column' id={`field-${name}`} width='100%'>
      {(label || labelRight) && (
        <Flex alignItems='center' flex={1} justifyContent={getJustifyContent()} mb={label ? '0.375rem' : 0}>
          {label && (
            <Label as='label' data-test-id='label' disabled={disabled} htmlFor={name} size='sm' touched={touched}>
              {label}
            </Label>
          )}
          {typeof labelRight === 'string' ? <Label size='sm'>{labelRight}</Label> : labelRight}
          {tooltip && (
            <>
              <Flex
                alignItems='center'
                data-tooltip-content={tooltip}
                height='0.75rem'
                id={`tooltip-${name}`}
                justifyContent='center'
                ml={1}
              >
                <Icon fontSize={12} kind='FiInfo' />
              </Flex>
              <Tooltip anchorId={`tooltip-${name}`} variant='light' />
            </>
          )}
        </Flex>
      )}
      {children}

      {(hint || (touched && error)) && (
        <Grid gap='sm' mt='0.375rem'>
          {hint && (
            <Flex alignItems='center'>
              <Message data-test-id='hint'>{hint}</Message>
            </Flex>
          )}
          {touched && error && (
            <Message data-test-id='validation-error' type='error'>
              {error}
            </Message>
          )}
        </Grid>
      )}
    </Flex>
  )
}
