/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Props as ReactSelectProps } from 'react-select'
import { useTheme } from '@frontend/shared/theme'
import dynamic from 'next/dynamic'

import { Option } from './components/option-component'
import { getStyles } from './components/select.styles'
import { InputComponent } from './components/input-component'
import { SingleValueComponent } from './components/single-value-component'

const DynamicSelect = dynamic(() => import('react-select'), { ssr: false })

export interface OptionType<Value> {
  label: string
  value: Value
  disabled?: boolean
  options?: OptionType<Value>[]
}

export function isOptionType<Value>(x: unknown): x is OptionType<Value> {
  if (typeof x !== 'object' || x === null) {
    return false
  }

  return (x as OptionType<Value>).value !== undefined && (x as OptionType<Value>).label !== undefined
}

export interface SelectProps<Value = string, IsMulti extends boolean = false>
  extends ReactSelectProps<OptionType<Value>, IsMulti> {
  displayValue?(valueOption: Pick<OptionType<Value>, 'label' | 'value'>): string
  error?: string
  touched?: boolean
}

export const Select = <Value,>(props: SelectProps<Value>): JSX.Element => {
  const theme = useTheme()
  const { value = null, placeholder = null, name, options = [], styles } = props

  return (
    <>
      {/* @ts-ignore */}
      <DynamicSelect<OptionType<Value>>
        components={{
          Option: Option(),
          Input: InputComponent,
          SingleValue: SingleValueComponent,
        }}
        getOptionValue={JSON.stringify}
        hideSelectedOptions={false}
        inputId={name}
        isClearable={false}
        menuPortalTarget={document.body}
        name={name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        options={options}
        placeholder={placeholder}
        styles={styles ?? getStyles(theme)}
        value={value}
      />
    </>
  )
}
