import baseStyled, {
  useTheme as baseUseTheme,
  css as baseCss,
  ThemeProvider as baseThemeProvider,
  keyframes,
  createGlobalStyle as baseCreateGlobalStyle,
  ThemeProviderComponent,
  ThemedStyledInterface,
  ThemedCssFunction,
  CSSObject,
  ThemedStyledProps,
  GlobalStyleComponent,
  Interpolation,
} from 'styled-components'

import { ThemeIframe as Theme } from './theme'

type CreateGlobalStyleFnc<P = Record<string, unknown>> = (
  first: TemplateStringsArray | CSSObject | Interpolation<ThemedStyledProps<P, Theme>>,
  ...interpolations: Interpolation<ThemedStyledProps<P, Theme>>[]
) => // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
GlobalStyleComponent<P, Theme>

type UseThemeFnc = () => Theme

export type StyledProps<Props> = {
  theme: Theme
} & Props
export type { Theme }
export { keyframes }

export const styled = baseStyled as ThemedStyledInterface<Theme>
export const useTheme = baseUseTheme as UseThemeFnc
export const css = baseCss as ThemedCssFunction<Theme>
export const ThemeProvider = baseThemeProvider as ThemeProviderComponent<Theme>
export const createGlobalStyle = baseCreateGlobalStyle as CreateGlobalStyleFnc
