/* eslint-disable dot-notation */
import React, { CSSProperties, MutableRefObject, PropsWithChildren, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper, PopperProps as ReactPopperProps } from 'react-popper'

import { Arrow, ARROW_SIZE, AnimatedWrapper } from './components/popper.styles'

const HIGHEST_Z_INDEX = 2147483647
const HALF_OF_ARROW_SIZE = (ARROW_SIZE * 16) / 2

const getArrowStyles = (
  styles: { [p: string]: CSSProperties },
  attributes: { [p: string]: { [p: string]: string } | undefined },
) => {
  const placement = attributes?.['popper']?.['data-popper-placement']
  const arrowStyles = { ...styles['arrow'], transform: `${styles['arrow']?.['transform']} rotate(45deg)` }

  if (placement?.includes?.('bottom')) {
    return { ...arrowStyles, top: -HALF_OF_ARROW_SIZE }
  }

  if (placement?.includes?.('right')) {
    return { ...arrowStyles, left: -HALF_OF_ARROW_SIZE }
  }

  if (placement?.includes?.('left')) {
    return { ...arrowStyles, right: -HALF_OF_ARROW_SIZE }
  }

  if (placement?.includes?.('top')) {
    return { ...arrowStyles, bottom: -HALF_OF_ARROW_SIZE }
  }

  return arrowStyles
}

export interface PopperProps<T> {
  isOpen: boolean
  targetElementRef: MutableRefObject<T | null>
  placement?: ReactPopperProps<T>['placement']
  showArrow?: boolean
}

export const Popper = <T extends HTMLElement>({
  children,
  isOpen,
  placement,
  showArrow,
  targetElementRef,
}: PropsWithChildren<PopperProps<T>>) => {
  const arrowElement = useRef(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(targetElementRef.current, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement.current, padding: HALF_OF_ARROW_SIZE } },
      {
        name: 'offset',
        options: {
          offset: [0, HALF_OF_ARROW_SIZE],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
        },
      },
    ],
    strategy: 'fixed',
    placement,
  })

  const popperStyle = isOpen
    ? { ...styles['popper'], zIndex: HIGHEST_Z_INDEX }
    : {
        display: 'none',
      }

  const arrowStyles = getArrowStyles(styles, attributes)

  return isOpen
    ? ReactDOM.createPortal(
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div ref={setPopperElement} style={popperStyle} {...attributes['popper']}>
          <AnimatedWrapper>
            {children}
            {showArrow && <Arrow ref={arrowElement} style={arrowStyles} />}
          </AnimatedWrapper>
        </div>,
        document.body,
      )
    : null
}
