import React, { ReactNode } from 'react'

import { Flex } from '../../../../layout/flex'
import { Grid } from '../../../../layout/grid'
import { Divider } from '../../../../layout/divider'
import { Responsive } from '../../../../layout/responsive'
import { DatepickerStyle, CommonDatePickerProps } from '../types'

import { DateRangeButton, DateRangeButtonProps } from './date-range-button'
import { RangeType } from './helpers'

interface CalendarContainerProps extends Omit<DateRangeButtonProps, 'rangeType' | 'testID'> {
  datepickerStyle: DatepickerStyle[]
  translations: CommonDatePickerProps['translations']
  selectsRange?: boolean
}

interface CalendarContainerInnerProps {
  children: ReactNode[]
}

export const CalendarContainer =
  ({ datepickerStyle, selectsRange, startDate, endDate, onChange, translations }: CalendarContainerProps) =>
  ({ children }: CalendarContainerInnerProps) =>
    (
      <Grid
        className={datepickerStyle.map((value, index) => `datepicker-style-${index}-${value}`).join(' ')}
        gridGap='0rem'
        gridRowGap='0rem'
        gridTemplateColumns={['1fr', selectsRange ? 'max-content 1fr' : '1fr']}
        height='100%'
        position='relative'
      >
        {selectsRange && (
          <Flex flexDirection={['column', 'row']} mr={2}>
            <Flex flexDirection='column' mr={4}>
              <DateRangeButton
                endDate={endDate}
                rangeType={RangeType.Today}
                startDate={startDate}
                testID='today'
                onChange={onChange}
              >
                {translations.rangeToday}
              </DateRangeButton>
              <DateRangeButton
                endDate={endDate}
                rangeType={RangeType.Last30Days}
                startDate={startDate}
                testID='last30Days'
                onChange={onChange}
              >
                {translations.rangeLast30days}
              </DateRangeButton>
              <DateRangeButton
                endDate={endDate}
                rangeType={RangeType.Last12Month}
                startDate={startDate}
                testID='last12Month'
                onChange={onChange}
              >
                {translations.rangeLast12Months}
              </DateRangeButton>
            </Flex>
            <Responsive hide='sm'>
              <Flex flex={1} height='100%' minWidth='auto'>
                <Divider margin='0 !important' orientation='vertical' />
              </Flex>
            </Responsive>
            <Responsive show='sm'>
              <Flex flex={1}>
                <Divider />
              </Flex>
            </Responsive>
          </Flex>
        )}
        <Grid gridGap={[9, 10]} gridRowGap={[9, 10]}>
          <Grid gridTemplateColumns={['1fr', selectsRange ? '1fr 1fr' : '1fr']}>{children}</Grid>
        </Grid>
      </Grid>
    )
