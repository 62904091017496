import {
  FacebookRegular,
  Flex,
  Grid,
  InstagramRegular,
  LinkedinRegular,
  Text,
  TwitterRegular,
} from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import React from 'react'
import { LINKS, SOCIAL_MEDIA } from '@frontend/shared/constants'

import { Link } from '../../screen'

import { FooterWrapper } from './footer.styles'

export const Footer = () => {
  const theme = useTheme()

  return (
    <FooterWrapper flex={1}>
      <Flex
        alignItems='center'
        flex={1}
        flexDirection={['column', null, null, 'row']}
        justifyContent='space-between'
        mb={['0rem', 5]}
        mt={[5, 8]}
      >
        <Flex alignItems='center' flex={1} flexDirection={['column', 'row']} mb={[4, null, null, '0rem']}>
          <Text color={theme.colors.primary.darkBlue.main} size='sm'>
            Follow us on social media
          </Text>

          <Grid gap='sm' gridTemplateColumns='repeat(4, max-content)' ml={3} mt={[3, '0rem']} width='auto'>
            <Link href={SOCIAL_MEDIA.facebook} target='_blank' type='icon'>
              <FacebookRegular />
            </Link>
            <Link href={SOCIAL_MEDIA.twitter} target='_blank' type='icon'>
              <TwitterRegular />
            </Link>
            <Link href={SOCIAL_MEDIA.instagram} target='_blank' type='icon'>
              <InstagramRegular />
            </Link>
            <Link href={SOCIAL_MEDIA.linkedin} target='_blank' type='icon'>
              <LinkedinRegular />
            </Link>
          </Grid>
        </Flex>
        <Flex flex={1}>
          <Grid
            gridGap={[0, 6]}
            gridRowGap={[0, 6]}
            gridTemplateColumns={['100%', 'repeat(3, max-content)']}
            justifyContent='flex-end'
          >
            <Flex justifyContent={['center', 'initial']}>
              <Link href={LINKS.privacyPolicy} target='_blank'>
                Privacy Policy
              </Link>
            </Flex>
            <Flex justifyContent={['center', 'initial']}>
              <Link href={LINKS.termsConditions} target='_blank'>
                Terms & Conditions
              </Link>
            </Flex>
            <Flex justifyContent={['center', 'initial']}>
              <Link href={LINKS.contact} target='_blank'>
                Give us Feedback
              </Link>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
    </FooterWrapper>
  )
}
