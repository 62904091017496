import React, { PropsWithChildren, ReactNode } from 'react'
import { Flex, Grid } from '@frontend/shared/ui'

import { Footer } from '../footer/footer'

import {
  AppScreen,
  FooterContainer,
  MainLayout,
  MainLayoutWrapper,
  MainLayoutWrapperProps,
  ScrollWrapper,
} from './app-layout.styles'

export interface AppLayoutProps extends MainLayoutWrapperProps {
  header: ReactNode
  menu: ReactNode
}

export const AppLayout = ({ children, type = 'large', header, menu }: PropsWithChildren<AppLayoutProps>) => (
  <AppScreen>
    <Flex flex={1}>
      {menu}
      <Flex alignItems='center' flex='1 100%' flexDirection='column' position='relative'>
        {header}

        <MainLayoutWrapper type={type}>
          <ScrollWrapper>
            <MainLayout>
              <Grid gridAutoRows='max-content'>{children}</Grid>
            </MainLayout>
          </ScrollWrapper>
        </MainLayoutWrapper>

        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Flex>
    </Flex>
  </AppScreen>
)
