import React from 'react'
import { Flex, Section, Text } from '@frontend/shared/ui'
import { Project, SellingUnit } from '@frontend/shared/types'
import { Link } from '@frontend/domains/shared/components'
import { useRouter } from 'next/router'

import { Routes } from '../../../types'

interface SectionFaqProps extends Pick<Project, 'sellingUnit'> {
  routes: Routes
}

export const SectionFaq = ({ sellingUnit, routes }: SectionFaqProps) => {
  const router = useRouter()

  return (
    <Section>
      <Flex flexDirection='column'>
        <Text data-test-id='buy-a-gift-title' weight={700}>
          You can buy the {sellingUnit === SellingUnit.Tree ? 'trees' : 'offsets'} as a gift!
        </Text>
        <Text data-test-id='buy-a-gift-description'>
          Just select the order as a gift on the checkout page. If you have questions, see the&nbsp;
          <Flex display='inline-block'>
            <Link href={routes.carbonOffsetProjectFaq.getUrl(router.query)} testID='buy-a-gift-faq'>
              F.A.Q. page.
            </Link>
          </Flex>
        </Text>
      </Flex>
    </Section>
  )
}
