import Link from 'next/link'
import React, { PropsWithChildren, RefObject, useEffect } from 'react'
import { Transition, config } from 'react-spring'

import { CardWrapper, AnimatedWrapper, Placeholder } from './card.styles'
import { CardInformation } from './components/information/card-information'
import { CardInformationButton } from './components/information/card-information-button'
import { CardInvestment } from './components/investment'

export interface CardProps {
  ticker: string
  href?: string
  onClick?(): void
  restorationRef?: RefObject<HTMLDivElement> | null
  selected?: boolean
}

const CardComponent = ({
  href,
  ticker,
  children,
  restorationRef,
  onClick,
  selected = false,
}: PropsWithChildren<CardProps>) => {
  useEffect(() => {
    if (!restorationRef?.current) {
      return
    }

    restorationRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
  }, [restorationRef])

  return (
    <Transition
      config={{ ...config.slow, duration: 1000 }}
      enter={{ opacity: 1 }}
      from={{ opacity: 0 }}
      items
      leave={{ opacity: 0 }}
    >
      {(styles) => (
        <AnimatedWrapper ref={restorationRef} style={styles} onClick={onClick}>
          {href ? (
            <Link data-test-id={ticker} href={href} passHref style={{ textDecoration: 'none', display: 'grid' }}>
              <CardWrapper selected={selected}>{children}</CardWrapper>
            </Link>
          ) : (
            <Placeholder data-test-id={ticker}>
              <CardWrapper selected={selected}>{children}</CardWrapper>
            </Placeholder>
          )}
        </AnimatedWrapper>
      )}
    </Transition>
  )
}

export const Card = Object.assign(CardComponent, { CardInformation, CardInvestment, CardInformationButton })
