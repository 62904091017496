import { css, styled } from '@frontend/shared/theme'

import { Flex } from '../../../layout/flex/flex'
import ticker from '../../../assets/svg/icons/tick.svg'

export type StepSize = 'sm' | 'md' | 'lg'

interface StepProps {
  active: boolean
  passed: boolean
  size: StepSize
}

const stepSize = ({ size }: StepProps) =>
  ({
    sm: '1.5rem',
    md: '2rem',
    lg: '2.5rem',
  }[size])

const getCircleSize = (size: StepSize) =>
  ({
    sm: '0.5rem',
    md: '0.625rem',
    lg: '0.75rem',
  }[size])

export const Step = styled(Flex)<StepProps>`
  position: relative;
  width: ${stepSize};
  height: ${stepSize};
  background: ${({ active, passed, theme }) =>
    active || passed ? theme.colors.primary.blue.mainHover : theme.colors.gray['200']};
  border-radius: 50%;
  box-shadow: ${({ active }) => (active ? '0px 0px 0px 4px rgba(20, 155, 165, 0.25)' : 'none')};

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;

    ${({ active, passed, size }) =>
      (active || !passed)
      && css`
        background-color: ${({ theme }) => theme.colors.primary.contrast};
        width: ${getCircleSize(size)};
        height: ${getCircleSize(size)};
        border-radius: 50%;
      `}

    ${({ passed }) =>
      passed
      && css`
        background: url(${ticker}) 50% 50% no-repeat;
        background-size: 50%;
      `}
  }
`
