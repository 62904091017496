import React, { PropsWithChildren } from 'react'
import { Anchor, AnchorProps, ButtonProps } from '@frontend/shared/ui'

interface LinkButtonProps
  extends Partial<Pick<ButtonProps, 'size' | 'type' | 'name'>>,
    Partial<Pick<AnchorProps, 'target' | 'replace' | 'testID' | 'download'>>,
    Pick<AnchorProps, 'href'> {}

export const Link = ({
  children,
  href,
  replace,
  size = 'md',
  type = 'linkColor',
  target,
  testID,
  download,
}: PropsWithChildren<LinkButtonProps>) => (
  <Anchor download={download} href={href} replace={replace} size={size} target={target} testID={testID} type={type}>
    {children}
  </Anchor>
)
