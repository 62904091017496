import { CarbonOffsetProjectCheckoutQueries } from '@frontend/domains/shared/carbon-offset'
import { routes as routesAuthentication, Routes as RoutesAuthentication } from '@frontend/domains/shared/authentication'

export enum Routes {
  CarbonOffsetProjectDetail = 'carbonOffsetProjectDetail',
  CarbonOffsetProjectFaq = 'carbonOffsetProjectFaq',
  CarbonOffsetProjectCheckout = 'carbonOffsetProjectCheckout',
  CarbonOffsetProjectList = 'carbonOffsetProjectList',
  CertificateRedeem = 'certificateRedeem',
  Dashboard = 'dashboard',
  Error = 'error',
  LoginRedeem = 'loginRedeem',
  Portfolio = 'portfolio',
  SettingsChangePassword = 'settingsChangePassword',
  SettingsTwoFactorAuthentication = 'settingsTwoFactorAuthentication',
}

export const routes = {
  [Routes.CarbonOffsetProjectDetail]: {
    getUrl: (ticker: string) => `/carbon-offsets/detail?ticker=${ticker}`,
  },
  [Routes.CarbonOffsetProjectFaq]: {
    getUrl: () => '/carbon-offsets/faq',
  },
  [Routes.CarbonOffsetProjectCheckout]: {
    getUrl: ({ ticker, gift, quantity, subscription, vintage }: CarbonOffsetProjectCheckoutQueries) => {
      const queryParams = {
        ticker,
        gift: gift.toString(),
        quantity: quantity.toString(10),
        subscription: subscription.toString(),
      }

      vintage && Object.assign(queryParams, { vintage })

      return `/carbon-offsets/checkout?${new URLSearchParams(queryParams).toString()}`
    },
  },
  [Routes.CarbonOffsetProjectList]: {
    getUrl: () => '/carbon-offsets',
  },
  [Routes.CertificateRedeem]: {
    getUrl: () => '/certificate-redeem',
  },
  [Routes.Dashboard]: {
    getUrl: () => '/',
  },
  [Routes.Error]: {
    getUrl: () => '/error',
  },
  [Routes.LoginRedeem]: {
    getUrl: () => '/redeem',
  },
  [RoutesAuthentication.LoginChangePassword]: {
    getUrl: () => routesAuthentication.loginChangePassword.getUrl(),
  },
  [RoutesAuthentication.LoginTwoFactorAuthentication]: {
    getUrl: () => routesAuthentication.loginTwoFactorAuthentication.getUrl(),
  },
  [RoutesAuthentication.PasswordResetFinalize]: {
    getUrl: () => routesAuthentication.passwordResetFinalize.getUrl(),
  },
  [RoutesAuthentication.PasswordResetInitialize]: {
    getUrl: () => routesAuthentication.passwordResetInitialize.getUrl(),
  },
  [RoutesAuthentication.SignIn]: {
    getUrl: () => routesAuthentication.signIn.getUrl(),
  },
  [RoutesAuthentication.SignUp]: {
    getUrl: () => routesAuthentication.signUp.getUrl(),
  },
  [Routes.Portfolio]: {
    getUrl: (polling = false) => `/portfolio${polling ? '?polling=true' : ''}`,
  },
  [Routes.SettingsChangePassword]: {
    getUrl: () => '/settings/change-password',
  },
  [Routes.SettingsTwoFactorAuthentication]: {
    getUrl: () => '/settings/two-factor-authentication',
  },
} as const

const matchPath = (section: string) => (path: string) => path === section
const includeSection = (section: string) => (path: string) => path.includes(section)

const publicSections = [
  includeSection(routesAuthentication.passwordResetFinalize.getUrl()),
  matchPath(routes.carbonOffsetProjectFaq.getUrl()),
  matchPath(routes.error.getUrl()),
  matchPath(routesAuthentication.loginChangePassword.getUrl()),
  matchPath(routes.loginRedeem.getUrl()),
  matchPath(routesAuthentication.loginTwoFactorAuthentication.getUrl()),
  matchPath(routesAuthentication.passwordResetInitialize.getUrl()),
  matchPath(routesAuthentication.signUp.getUrl()),
  matchPath(routesAuthentication.signIn.getUrl()),
]

export const isPublicSection = (path: string) => publicSections.map((comparator) => comparator(path)).some(Boolean)
export const isAppSection = (path: string) => !isPublicSection(path)
