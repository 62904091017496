import { Faq as FaqComponent, FaqProps, Flex } from '@frontend/shared/ui'
import React from 'react'

const questions: FaqProps['questions'] = [
  {
    question: 'What is a Carbon offset gift certificate?',
    answer:
      'You can give a gift that is good for the Planet. After purchase, we will send you a pdf certificate'
      + ' with the unique code. You then share the certificate with your dear one and they can register and redeem'
      + ' the code at our website. The bought offsets will be then added to their portfolio.',
  },
  {
    question: 'How can I buy a Carbon offset certificate?',
    answer:
      'During a checkout, select the option “Buy as a gift”. This will ensure that the gift certificate'
      + ' with unique code will be sent to your email address. You can then share the certificate with your loved one.',
  },
  {
    question: 'Can I buy a Carbon Offset certificate as a subscription?',
    answer: 'Sure. You can select a payment as a subscription and give the gift that lasts all year long.',
  },
  {
    question: 'Can I send one certificate to more people?',
    answer: 'Nope. The code can be displayed only once, after the redemption, the code is invalidated.',
  },
  {
    question: 'I cannot find the offsets I bought as a gift in my portfolio. Where is it?',
    answer:
      'Once your friend claims their offsets, it will be displayed in their portfolio. We are working on a '
      + 'way to indicate gifts you have given also on your dashboard.',
  },
]

export const Faq = () => (
  <Flex justifyContent='center'>
    <FaqComponent questions={questions} testID='carbon-offsets' />
  </Flex>
)
