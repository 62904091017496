import React, { CSSProperties } from 'react'

interface LoaderSpinnerProps {
  backgroundColor: CSSProperties['backgroundColor']
  color: CSSProperties['color']
  size: CSSProperties['width']
}

export const LoaderSpinner = ({ size, backgroundColor, color }: LoaderSpinnerProps): JSX.Element => (
  <svg className='prefix__lds-rolling' height={size} preserveAspectRatio='xMidYMid' viewBox='0 0 100 100' width={size}>
    <circle cx={50} cy={50} fill='none' r={40} stroke={backgroundColor} strokeWidth={12} />
    <circle
      cx={50}
      cy={50}
      fill='none'
      r={40}
      stroke={color}
      strokeDasharray='60 360'
      strokeLinecap='round'
      strokeWidth={12}
    >
      <animateTransform
        attributeName='transform'
        begin='0s'
        calcMode='linear'
        dur='1.5s'
        keyTimes='0;1'
        repeatCount='indefinite'
        type='rotate'
        values='0 50 50;360 50 50'
      />
    </circle>
  </svg>
)
