import 'core-js/actual/array/at'
import AppNext, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '@frontend/shared/theme'
import React, { ReactNode } from 'react'
import '@frontend/shared/google-analytics'
import '@frontend/shared/validations-yup'
import '@frontend/shared/logger'
import { ToastContainer } from '@frontend/shared/ui'
import { Slide } from 'react-toastify'
import { Interceptor, AppPlus } from '@frontend/domains/plus/app'
import { Error } from '@frontend/domains/plus/error'
import { themeIframe } from '@frontend/domains/shared/carbon-offset'
import { ErrorBoundary } from '@sentry/nextjs'

import 'react-tooltip/dist/react-tooltip.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-number-input/style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-image-lightbox/style.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'

type CustomAppProps = AppProps & {
  Component: { getLayout: (page: ReactNode) => ReactNode }
}

const CustomApp = ({ Component }: CustomAppProps) => {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <Head>
        <title>Earthbanc</title>
      </Head>

      <ThemeProvider theme={themeIframe}>
        <GlobalStyle />
        <ErrorBoundary fallback={Error} showDialog>
          <ToastContainer autoClose={false} closeButton={false} draggable={false} transition={Slide} />
          <Interceptor>
            <AppPlus>{getLayout(<Component />)}</AppPlus>
          </Interceptor>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  )
}

CustomApp.getInitialProps = (props: AppContext) => {
  return AppNext.getInitialProps(props)
}

export default CustomApp
