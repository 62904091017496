import { styled } from '@frontend/shared/theme'
import { Flex } from '@frontend/shared/ui'

export const TextWrapper = styled(Flex).attrs(() => ({
  alignItems: 'flex-end',
  pe: 8,
  width: 'auto',
}))`
  font-weight: 700;
`

export const UserProfileWrapper = styled(Flex).attrs(() => ({ alignItems: 'center' }))``
