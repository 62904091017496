import React from 'react'
import { Button, Flex, Grid, Icon, logoWhite, useBreakpoints } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'

import { MenuItem, MenuItemType } from './components/menu-item/menu-item'
import { LogoWrapper, MenuInnerWrapper, MenuWrapper, MenuWrapperProps } from './app-menu.styles'

export interface AppMenuProps extends Pick<MenuWrapperProps, 'width'> {
  menuItems: MenuItemType[]
  mobileMenuOpen: boolean
  onClickMenuToggler: () => void
}

export const AppMenu = ({ mobileMenuOpen, onClickMenuToggler, menuItems, width }: AppMenuProps) => {
  const theme = useTheme()
  const { isXs, isSm, isMd } = useBreakpoints({ breakpoints: theme.breakpoints })
  const isMobile = isXs || isSm || isMd

  const menuItemSelectHandler = (onClick?: () => void) => () => {
    onClick?.()
    isMobile && onClickMenuToggler()
  }

  return (
    <MenuWrapper data-menu-open={mobileMenuOpen} data-test-id='menu' mobileMenuOpen={mobileMenuOpen} width={width}>
      <MenuInnerWrapper>
        <Grid>
          <LogoWrapper justifyContent={['flex-end', null, null, 'center']}>
            {!isMobile && <img alt='Earthbanc - logo' height={22} src={logoWhite} width={136} />}

            {isMobile && (
              <Flex pr={1} pt={1}>
                <Button name='close-menu' type='icon' onClick={onClickMenuToggler}>
                  <Icon color={theme.colors.primary.contrast} fontSize='1.5rem' kind='FiX' />
                </Button>
              </Flex>
            )}
          </LogoWrapper>

          <Grid gridGap={0} gridRowGap={0} pt={0}>
            {menuItems.map(({ id, active, icon, label, onSelect, subMenuItems, visible }) => (
              <MenuItem
                active={active}
                icon={icon}
                id={id}
                key={id}
                label={label}
                subMenuItems={subMenuItems}
                visible={visible}
                onSelectItem={menuItemSelectHandler(onSelect)}
                onSelectSubItem={menuItemSelectHandler}
              />
            ))}
          </Grid>
        </Grid>
      </MenuInnerWrapper>
    </MenuWrapper>
  )
}
