import { Button, Display, Field, FieldPassword, Flex, Form, Grid, Text, useToast } from '@frontend/shared/ui'
import { Link, useGoogleRecaptcha } from '@frontend/domains/shared/components'
import React, { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { FormikProps } from 'formik'
import { useTheme } from '@frontend/shared/theme'

import { useLogin } from '../../context/login/context-login'
import { routes } from '../../router/routes'
import { useResolveActionResultRouterQuery } from '../../router/query-parser'
import { ActionStatus, ActionType } from '../../router/enums'

import { validationSchema } from './form/validation-schema'
import { initialValues } from './form/initial-values'
import { FormValues } from './form/types'

export const SignIn = () => {
  const theme = useTheme()
  const router = useRouter()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { showToastError, showToastSuccess } = useToast()
  const { getRecaptchaToken, initializeScript } = useGoogleRecaptcha({ onDemand: true })
  const { type, status } = useResolveActionResultRouterQuery(router.query) || {}
  const { loading, authenticate } = useLogin()

  useEffect(() => {
    if (type === ActionType.VerifyEmail) {
      if (status === ActionStatus.Success) {
        showToastSuccess('Verification successful', 'Email was successfully verified. Please sign in.')
      } else {
        showToastError('Verification failed', 'Please, contact our support for manual verification.')
      }
    }
  }, [showToastError, showToastSuccess, status, type])

  const submitForm = async ({ email, password }: FormValues) => {
    const recaptchaToken = await getRecaptchaToken('signIn')

    if (recaptchaToken) {
      const onSuccessRegister = async () => {
        await router.replace(routes.loginTwoFactorAuthentication.getUrl())
      }

      await authenticate({ email: email.trim(), password, recaptchaToken, onSuccessRegister, formikRef })
    }
  }

  return (
    <Form<FormValues>
      initialValues={initialValues}
      innerRef={formikRef}
      name='sign-in'
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      <Grid>
        <Display data-test-id='title' size='xs' textAlign='center' weight={500}>
          Sign in
        </Display>

        <Field label='Email' name='email' type='text' onChange={() => initializeScript()} />

        <FieldPassword label='Password' name='password' />

        <Button disabled={loading} htmlType='submit' loading={loading} name='sign-in' size='xl'>
          Sign in
        </Button>

        <Link href={routes.passwordResetInitialize.getUrl()}>Forgot password?</Link>

        <Flex alignItems='center' justifyContent='center'>
          <Text color={theme.colors.secondary.darkBlue.main} size='md' weight={400}>
            New to Earthbanc?
          </Text>
          &nbsp;
          <Link href={routes.signUp.getUrl()} size='sm' testID='sign-up' type='linkColor'>
            Join now
          </Link>
        </Flex>
      </Grid>
    </Form>
  )
}
