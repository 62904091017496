import { css, styled } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex/flex'

const RADIO_SIZE = '1rem'

export const RadioWrapper = styled(Flex)`
  width: auto;
  max-width: 100%;
  min-width: ${RADIO_SIZE};
`

export type RadioType = 'circle' | 'check'

interface InputProps {
  disabled: boolean
  hasError: boolean
  pure: boolean
  hover?: boolean
  markType?: RadioType
}

export const Input = styled.input<InputProps>`
  opacity: 0;
  position: absolute;
  margin: auto 0;

  + label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding-left: calc(${RADIO_SIZE} + ${({ theme, pure }) => (pure ? 0 : theme.space[2])});

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0.125rem;
      left: 0;
    }

    &:before {
      width: ${RADIO_SIZE};
      height: ${RADIO_SIZE};
      background: ${({ theme }) => theme.components.inputRadio.backgroundColor};
      border-width: 0.0625rem;
      border-style: solid;
      border-color: ${({ theme, hasError }) =>
        hasError ? theme.colors.error['500'] : theme.components.inputRadio.borderColor};
      box-sizing: border-box;
      border-radius: 50%;
    }
  }

  &[disabled] + label {
    cursor: default;

    &:before {
      background-color: ${({ theme }) => theme.colors.gray['100']};
      border-color: ${({ theme }) => theme.colors.gray['200']};
    }
  }

  &:checked + label {
    &:before {
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.gray['200'] : theme.components.inputRadio[':checked'].backgroundColor};
      border-color: ${({ theme, hasError, disabled }) =>
        hasError && !disabled ? theme.colors.error['500'] : theme.components.inputRadio[':checked'].borderColor};
    }

    ${({ markType }) =>
      markType === 'circle'
        ? css`
            &:after {
              width: 0.5rem;
              height: 0.5rem;
              left: 0.25rem;
              top: 0.38rem;
              background: ${({ theme }) => theme.colors.primary.contrast};
              border-radius: 50%;
            }
          `
        : css`
            &:after {
              display: block;
              border-style: solid;
              background: ${({ theme }) => theme.colors.primary.contrast};
              transform: rotate(45deg);
              width: 0.3125rem;
              height: 0.5625rem;
              left: 0.3125rem;
              top: 0.25rem;
              border-width: 0 0.125rem 0.125rem 0;
            }
          `}
  }

  &[disabled]&:checked + label {
    &:before {
      background-color: ${({ theme }) => theme.colors.gray['200']};
      border-color: ${({ theme }) => theme.colors.gray['200']};
    }

    &:after {
      width: 0.5rem;
      height: 0.5rem;
      left: 0.25rem;
      top: 0.35rem;
      border-radius: 50%;
    }
  }

  ${({ hover }) =>
    hover
    && css`
      color: ${({ theme }) => theme.components.inputRadio[':hover'].boxShadowColor};
      box-shadow: 0 0 0 0.25rem;
      transition: all ${({ theme }) => theme.transition};
      outline: transparent;
    `}

  &:hover:not([disabled]) {
    + label::before {
      color: ${({ theme }) => theme.components.inputRadio[':hover'].boxShadowColor};
      box-shadow: 0 0 0 0.25rem;
      transition: all ${({ theme }) => theme.transition};
      outline: transparent;
    }
  }

  &:focus:not([disabled]) {
    + label::before {
      color: ${({ theme }) => theme.components.inputRadio[':hover'].boxShadowColor};
      box-shadow: 0 0 0 0.25rem;
      transition: all ${({ theme }) => theme.transition};
      outline: transparent;
    }
  }
`
