import { styled, StyledProps } from '@frontend/shared/theme'
import { space } from 'styled-system'

export interface SelectableButtonProps {
  selected?: boolean | null
}

export const SelectableButton = styled.button.attrs(() => ({
  mb: 2,
  p: 1,
}))<StyledProps<SelectableButtonProps>>`
  ${space};
  display: flex;
  background: transparent;
  color: ${({ theme, selected }) => (selected ? theme.colors.primary.blue.main : theme.colors.gray['700'])};
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: 500;
  width: 100%;
  border: 0;
  justify-content: flex-start;
  transition: all ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primary.blue.main};
    transition: color ${({ theme }) => theme.transition};
  }
`
