import { theme } from '@frontend/shared/theme'

export const components = {
  detail: {
    place: {
      textColor: theme.colors.primary.blue.main,
    },
  },
  header: {
    linkBack: {
      textColor: theme.colors.gray['500'],
    },
  },
  checkout: {
    orderSummary: {
      name: {
        textColor: theme.colors.gray['700'],
      },
      totalAmount: {
        textColor: theme.colors.gray['700'],
      },
      totalPrice: {
        textColor: theme.colors.primary.blue.main,
      },
      wrapper: {
        backgroundColor: theme.colors.secondary.bgGray['70'],
      },
    },
    paymentSepa: {
      textColor: theme.colors.gray['700'],
    },
  },
  logo: 'dark',
}
