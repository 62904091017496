import React from 'react'

import { Grid } from '../../../layout/grid/grid'
import { Icon, IconKind } from '../../../text/icon/root/icon'

import { ButtonGroupWrapper, ButtonItem } from './button-group.styles'

interface ButtonItemProps {
  onClick(): void
  text: string
  active?: boolean
  disabled?: boolean
  iconKind?: IconKind
  onlyIcon?: boolean
}

export interface ButtonGroupProps {
  items: ButtonItemProps[]
}

export const ButtonGroup = ({ items }: ButtonGroupProps) => (
  <ButtonGroupWrapper gridGap='0rem' gridRowGap='0rem' gridTemplateColumns={`repeat(${items.length}, auto)`}>
    {items.map(({ text, onClick, onlyIcon = false, iconKind, disabled, active }) => {
      const icon = iconKind && <Icon kind={iconKind} />

      return (
        <ButtonItem active={active} clickable disabled={disabled} key={text} onClick={onClick}>
          {onlyIcon ? (
            icon
          ) : (
            <Grid alignItems='center' gap='sm' gridTemplateColumns={icon ? 'auto auto' : '100%'}>
              {icon} {text}
            </Grid>
          )}
        </ButtonItem>
      )
    })}
  </ButtonGroupWrapper>
)
