import { styled } from '@frontend/shared/theme'
import { Tooltip } from 'react-tooltip'

export type { ITooltip } from 'react-tooltip'

export const TooltipWrapper = styled(Tooltip)`
  border-radius: 0.5rem !important;
  font-size: 0.75rem !important;
  font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
`
