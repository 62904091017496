import dayjs from 'dayjs'
import { Logger } from '@frontend/shared/logger'

export enum RangeType {
  Today,
  Last30Days,
  Last12Month,
}

export const getRange = (rangeType: RangeType): [Date, Date] => {
  const startOfDay = dayjs().startOf('day').toDate()
  const endOfDay = dayjs().endOf('day').toDate()

  switch (rangeType) {
    case RangeType.Today:
      return [startOfDay, endOfDay]
    case RangeType.Last30Days:
      return [dayjs(startOfDay).subtract(30, 'day').toDate(), startOfDay]
    case RangeType.Last12Month: {
      return [dayjs(startOfDay).subtract(12, 'month').toDate(), startOfDay]
    }
    default:
      Logger.error(`Implement new range type for date picker - ${rangeType}`)

      return [startOfDay, endOfDay]
  }
}

export const createRange = (from: number, to: number) => {
  const range = []

  for (let i = from; i <= to; i += 1) {
    range.push(i)
  }

  return range
}
