import { useEffect, useState } from 'react'

const DEFAULT_PAGE_SIZE = 9

interface PaginationOptions {
  defaultPageSize?: number
}

export const usePagination = ({ defaultPageSize = DEFAULT_PAGE_SIZE }: PaginationOptions = {}) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  useEffect(() => setPageCount(Math.ceil(totalItems / pageSize)), [totalItems, pageSize])

  return { pageIndex, totalItems, pageCount, pageSize, setPageIndex, setTotalItems, setPageSize }
}
