import React, { ReactNode } from 'react'
import { Flex, Grid, logoWhite, useBreakpoints } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'

import { Link } from '../../screen/link/link'

import { HeaderWrapper } from './app-header.styles'

interface AppHeaderProps {
  right: ReactNode
}

export const AppHeader = ({ right }: AppHeaderProps) => {
  const theme = useTheme()
  const { isXs, isSm, isMd } = useBreakpoints({ breakpoints: theme.breakpoints })
  const isMobile = isXs || isSm || isMd

  if (isMobile) {
    return (
      <HeaderWrapper flex='0 auto' justifyContent='space-between'>
        <Flex flex={3} pl={6}>
          <Link href='/'>
            <img alt='Earthbanc - logo' height={22} src={logoWhite} width={136} />
          </Link>
        </Flex>
        <Flex flex={1} justifyContent='flex-end'>
          {right}
        </Flex>
      </HeaderWrapper>
    )
  }

  return (
    <HeaderWrapper flex='0 auto' justifyContent='space-between'>
      <Grid gridTemplateColumns='1fr auto' px={7}>
        {/* there can be some Title component*/}
        <Flex />

        {right}
      </Grid>
    </HeaderWrapper>
  )
}
