import { styled } from '@frontend/shared/theme'

import { Grid } from '../../../layout/grid/grid'

interface ButtonItemProps {
  active?: boolean
  clickable?: boolean
  disabled?: boolean
}

const getCursor = ({ disabled, clickable }: ButtonItemProps) => {
  switch (true) {
    case disabled:
      return 'not-allowed'
    case clickable:
      return 'pointer'
    default:
      return 'default'
  }
}

export const ButtonItem = styled.button<ButtonItemProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  height: max-content;
  max-width: 100%;
  text-decoration: none;
  padding: 0.625rem 1rem;
  min-height: 2.5rem;
  cursor: ${getCursor};
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  transition: all ${({ theme }) => theme.transition};
  color: ${({ theme, active }) => (active ? theme.colors.gray['800'] : theme.colors.gray['700'])};
  background-color: ${({ theme, active }) => (active ? theme.colors.gray['50'] : theme.colors.primary.contrast)};
  border-color: ${({ theme }) => theme.colors.gray['300']};
  border-width: 0.0625rem;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: none;

  &:disabled,
  [disabled] {
    opacity: 0.4;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray['50']};
    color: ${({ theme }) => theme.colors.gray['800']};
  }
`

export const ButtonGroupWrapper = styled(Grid)`
  overflow-x: auto;

  button:first-of-type {
    border-left-style: solid;
    border-left-width: 0.0625rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  button:last-of-type {
    border-right-style: solid;
    border-right-width: 0.0625rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`
