import React from 'react'

import { File } from '../base/file'

export interface FileDownloadProps {
  href: string
  name: string
  description?: string
  testID?: string
}

export const FileDownload = ({ href, description, name, testID }: FileDownloadProps) => (
  <File description={description} href={href} name={name} testID={testID} type='download' />
)
