import { styled } from '@frontend/shared/theme'

import { Grid, GridProps } from '../../../layout/grid/grid'
import { AlertColors, descriptionColor } from '../text/alert.styles'

interface ButtonsWrapperProps extends GridProps {
  color: AlertColors
}

export const ButtonsWrapper = styled(Grid)<ButtonsWrapperProps>`
  color: ${descriptionColor};
`
