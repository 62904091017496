import { object, string, InferType } from 'yup'

export const themePublicValidationSchema = object({
  body: object({
    backgroundColor: string().colorValidation(),
    textColor: string().colorValidation(),
  }),
  components: object({
    anchor: object({
      textColor: string().colorValidation(),
    }),
    badge: object({
      backgroundColor: string().colorValidation(),
      textColor: string().colorValidation(),
    }),
    button: object({
      primary: object({
        ':hover': object({
          backgroundColor: string().colorValidation(),
        }),
        backgroundColor: string().colorValidation(),
        textColor: string().colorValidation(),
      }),
      secondary: object({
        ':hover': object({
          backgroundColor: string().colorValidation(),
        }),
        backgroundColor: string().colorValidation(),
        textColor: string().colorValidation(),
      }),
    }),
    card: object({
      description: object({
        textColor: string().colorValidation(),
      }),
      place: object({
        textColor: string().colorValidation(),
      }),
      price: object({
        textColor: string().colorValidation(),
      }),
      ticker: object({
        textColor: string().colorValidation(),
      }),
      title: object({
        textColor: string().colorValidation(),
      }),
      wrapper: object({
        backgroundColor: string().colorValidation(),
        borderColor: string().colorValidation(),
      }),
    }),
    carousel: object({
      arrow: object({
        backgroundColor: string().colorValidation(),
        textColor: string().colorValidation(),
      }),
      dot: object({
        wrapper: object({
          backgroundColor: string().colorValidation(),
        }),
        ':before': object({
          backgroundColor: string().colorValidation(),
          ':selected': object({
            backgroundColor: string().colorValidation(),
          }),
        }),
      }),
      wrapper: object({
        backgroundColor: string().colorValidation(),
      }),
    }),
    fieldWrapper: object({
      label: object({
        textColor: string().colorValidation(),
      }),
      message: object({
        error: object({
          textColor: string().colorValidation(),
        }),
        hint: object({
          textColor: string().colorValidation(),
        }),
      }),
    }),
    h1: object({
      textColor: string().colorValidation(),
    }),
    header: object({
      linkBack: object({
        textColor: string().colorValidation(),
      }),
    }),
    checkbox: object({
      backgroundColor: string().colorValidation(),
      borderColor: string().colorValidation(),
      textColor: string().colorValidation(),
      ':checked': object({
        backgroundColor: string().colorValidation(),
        borderColor: string().colorValidation(),
      }),
    }),
    iconFeatured: object({
      lightCircleOutline: object({
        error: object({
          backgroundColor: string().colorValidation(),
        }),
        warning: object({
          backgroundColor: string().colorValidation(),
        }),
        success: object({
          backgroundColor: string().colorValidation(),
        }),
      }),
    }),
    input: object({
      ':error': object({
        borderColor: string().colorValidation(),
      }),
      ':focused': object({
        borderColor: string().colorValidation(),
      }),
      ':placeholder': object({
        textColor: string().colorValidation(),
      }),
      backgroundColor: string().colorValidation(),
      borderColor: string().colorValidation(),
      textColor: string().colorValidation(),
    }),
    inputRadio: object({
      ':checked': object({
        backgroundColor: string().colorValidation(),
        borderColor: string().colorValidation(),
      }),
      ':hover': object({
        boxShadowColor: string().colorValidation(),
      }),
      backgroundColor: string().colorValidation(),
      borderColor: string().colorValidation(),
    }),
    inputTabRadio: object({
      wrapper: object({
        ':checked': object({
          borderColor: string().colorValidation(),
        }),
        backgroundColor: string().colorValidation(),
        borderColor: string().colorValidation(),
      }),
    }),
    markdown: object({
      textColor: string().colorValidation(),
    }),
    section: object({
      backgroundColor: string().colorValidation(),
      borderColor: string().colorValidation(),
    }),
    select: object({
      dropdownIndicator: object({
        textColor: string().colorValidation(),
      }),
      option: object({
        ':selected': object({
          backgroundColor: string().colorValidation(),
          textColor: string().colorValidation(),
        }),
        backgroundColor: string().colorValidation(),
        textColor: string().colorValidation(),
      }),
    }),
    toast: object({
      progress: object({
        error: object({
          backgroundColor: string().colorValidation(),
        }),
        warning: object({
          backgroundColor: string().colorValidation(),
        }),
        success: object({
          backgroundColor: string().colorValidation(),
        }),
      }),
      wrapper: object({
        backgroundColor: string().colorValidation(),
        textColor: string().colorValidation(),
      }),
    }),
  }),
  page: object({
    checkout: object({
      orderSummary: object({
        name: object({
          textColor: string().colorValidation(),
        }),
        totalAmount: object({
          textColor: string().colorValidation(),
        }),
        totalPrice: object({
          textColor: string().colorValidation(),
        }),
        wrapper: object({
          backgroundColor: string().colorValidation(),
        }),
      }),
      paymentSepa: object({
        textColor: string().colorValidation(),
      }),
    }),
    detail: object({
      place: object({
        textColor: string().colorValidation(),
      }),
    }),
    logo: string().oneOf(['light', 'dark']),
  }),
})

export type ThemePublic = InferType<typeof themePublicValidationSchema>
