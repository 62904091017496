import { useFetch, CachePolicies } from 'use-http'
import { SERVICE_URL } from '@frontend/shared/constants'
import { Customer } from '@frontend/shared/types'

import { ENDPOINT_URL } from '../constants'

export const useServiceCustomerQuery = () => {
  const { loading, error, data } = useFetch<Customer>(
    SERVICE_URL.beUrl + ENDPOINT_URL.customer,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  )

  return { loading, error, data }
}

export const useServiceCustomerLazy = () => {
  const { loading, error, get, data } = useFetch<Customer>(SERVICE_URL.beUrl + ENDPOINT_URL.customer, {
    cachePolicy: CachePolicies.NO_CACHE,
  })

  return { loading, error, getCustomer: get, data }
}
