import { styled } from '@frontend/shared/theme'
import { CSSProperties, FC } from 'react'

import { Flex, FlexProps } from '../../layout'

type ScreenProps = Pick<CSSProperties, 'position' | 'background'>

export const Screen: FC<FlexProps & ScreenProps> = styled(Flex).attrs<ScreenProps>(
  ({ position = 'absolute', background }) => ({
    position,
    height: 'min-content',
    minHeight: '100vh',
    background,
  }),
)<ScreenProps>`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
`
