import { useTheme } from '@frontend/shared/theme'
import React, { ChangeEvent, useEffect, useRef } from 'react'

import { Flex } from '../../../../layout/flex/flex'
import { Text } from '../../../../text'
import { Message } from '../../../field/base/components/message'
import { hasError } from '../../base/input.styles'

import { CheckboxWrapper, InputCheckboxProps } from './input-checkbox.styles'

export const InputCheckbox = ({
  label,
  disabled,
  name,
  checked,
  onChange,
  onBlur,
  indeterminate,
  error,
  touched,
  size = 'md',
  hint,
  value,
  htmlFor,
}: InputCheckboxProps): JSX.Element => {
  const theme = useTheme()
  const ref = useRef<HTMLInputElement>(null)

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }

    onChange?.(e)
  }

  useEffect(() => {
    if (indeterminate && ref.current) {
      ref.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <CheckboxWrapper
      aria-checked={checked}
      checked={checked}
      data-test-id={`field-${name}`}
      disabled={disabled}
      hasError={hasError({ touched, error })}
      indeterminate={indeterminate}
      pure={!label}
      size={size}
    >
      <input
        checked={checked}
        disabled={disabled}
        id={htmlFor ?? name}
        name={name}
        ref={ref}
        type='checkbox'
        value={value}
        onBlur={onBlur}
        onChange={changeHandler}
      />
      <label data-test-id='label' htmlFor={htmlFor ?? name}>
        {label && (
          <>
            <Text color={theme.components.checkbox.textColor} size={size}>
              {label}
            </Text>
            {hasError({ touched, error }) && (
              <Flex mt={0}>
                <Message type='error'>{error}</Message>
              </Flex>
            )}
          </>
        )}
      </label>
      {hint && (
        <Text color={theme.colors.gray['500']} data-test-id='hint' size={size}>
          {hint}
        </Text>
      )}
    </CheckboxWrapper>
  )
}
