import { styled } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex'

export const IconLeftWrapper = styled(Flex).attrs(() => ({
  position: 'absolute',
  left: '0.875rem',
}))``

export const IconRightWrapper = styled(Flex).attrs(() => ({
  position: 'absolute',
  pr: 2,
}))``
