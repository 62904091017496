import { Grid, Skeleton } from '@frontend/shared/ui'
import React from 'react'

export const ChangePasswordSkeleton = () => (
  <Grid>
    <Skeleton height='2.5rem' />
    <Skeleton height='4rem' />
    <Skeleton height='2.5rem' />
  </Grid>
)
