import { styled } from '@frontend/shared/theme'

import { Text } from '../../../../text/typography'

interface MessageProps {
  type?: 'error' | 'hint'
}

export const Message = styled(Text)<MessageProps>`
  align-items: center;
  color: ${({ theme, type = 'hint' }) =>
    type === 'error'
      ? theme.components.fieldWrapper.message.error.textColor
      : theme.components.fieldWrapper.message.hint.textColor};
  font-size: 0.875rem;
  font-weight: 400;
`
