/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/ban-ts-comment */
import React from 'react'
import { components, InputProps } from 'react-select'

// TODO-SPURNY (Ales Spurny, 19.10.2021, @medel pleae check it)
// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
type InputComponentProps<OptionType, IsMulti extends boolean = false> = InputProps & {
  options: readonly OptionType[]
  selectOption: (newValue: OptionType) => void
}

export const InputComponent = <OptionType, IsMulti extends boolean = false>({
  selectProps,
  ...restProps
}: InputComponentProps<OptionType, IsMulti>) => (
  // @ts-ignore
  <components.Input
    {...restProps}
    // @ts-ignore
    autoComplete={selectProps.autoComplete ?? 'off'}
    data-test-id={selectProps.name}
    // @ts-ignore
    data-test-value={selectProps.value?.value}
  />
)
