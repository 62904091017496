import { css, styled } from '@frontend/shared/theme'
import { AnchorHTMLAttributes } from 'react'
import { animated } from 'react-spring'
import { LinkProps } from 'next/dist/client/link'

import { Grid } from '../../layout/grid'
import { Flex } from '../../layout/flex'

interface CardWrapperProps extends Pick<AnchorHTMLAttributes<HTMLLinkElement>, 'href'> {
  selected: boolean
}

interface PlaceholderProps {
  href?: LinkProps['href']
  passHref?: boolean
}

export const CardWrapper = styled(Grid).attrs(() => ({
  gridTemplateRows: '1fr auto auto',
  gridRowGap: '0rem',
  gridGap: '0rem',
}))<CardWrapperProps>`
  position: relative;
  background: ${({ theme }) => theme.components.card.wrapper.backgroundColor};
  border: 0.0125rem solid ${({ theme }) => theme.components.card.wrapper.borderColor};
  border-radius: 0.5rem;
  transition: all ${({ theme }) => theme.transition};
  text-decoration: none;

  &:hover,
  &:focus {
    box-shadow: ${({ theme }) => theme.shadows.lg};
  }

  ${({ selected }) =>
    selected
    && css`
      transform: translateZ(0px) scale3d(0.95, 0.95, 1);
    `};
`

export const Placeholder = styled(Flex).attrs(() => ({
  flex: 1,
}))<PlaceholderProps>``

export const AnimatedWrapper = animated(Grid)
