import React from 'react'

import { Flex } from '../../../layout/flex/flex'
import { Loader } from '../root'

export const LoaderFullscreen = () => (
  <Flex alignItems='center' height='100%' justifyContent='center' position='absolute' width='100%'>
    <Loader size='xl' />
  </Flex>
)
