import BigNumber from 'bignumber.js'

export const convertBigNumberValuesToString = (values: Record<string, BigNumber>): Record<string, string> =>
  Object.entries(values).reduce((previousValue, [key, value]) => ({ ...previousValue, [key]: value.toString() }), {})

export const convertAnyValuesToBigNumber = (values: Record<string, string | number>): Record<string, BigNumber> =>
  Object.entries(values).reduce(
    (previousValue, [key, value]) => ({ ...previousValue, [key]: new BigNumber(value) }),
    {},
  )
