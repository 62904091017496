import React from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Icon } from '../../../text/icon/root/icon'
import { Flex } from '../../../layout/flex/flex'
import { Button } from '../../button/root/button'
import { Text } from '../../../text/typography'

import { alertIcon, AlertWrapper, descriptionColor, titleColor, WrapperProps } from './alert.styles'

export interface AlertTextProps extends WrapperProps {
  title: string
  description?: string
  onClose?: () => void
}

export const AlertText = ({ title, color, description, onClose }: AlertTextProps) => {
  const theme = useTheme()

  return (
    <AlertWrapper color={color}>
      <Flex flex='0 auto' mr={1}>
        <Icon color={descriptionColor({ theme, color })} kind={alertIcon(color)} />
      </Flex>
      <Flex flex={1} flexDirection='column'>
        <Text color={titleColor({ theme, color })} size='sm'>
          {title}
        </Text>
        {description && (
          <Text color={descriptionColor({ theme, color })} size='sm' weight={400}>
            {description}
          </Text>
        )}
      </Flex>
      {onClose && (
        <Flex flex='0 auto'>
          <Button name='alert-close' type='icon' onClick={onClose}>
            <Icon color={titleColor({ theme, color })} fontSize='1.2rem' kind='FiX' />
          </Button>
        </Flex>
      )}
    </AlertWrapper>
  )
}
