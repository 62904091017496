import { getHashName, getWindow } from '@frontend/shared/utils'
import React, { ReactNode, useEffect, useState } from 'react'

import { getTabId } from './helpers'

export interface TabContentOption {
  name: string
  render: () => ReactNode
}

export interface TabsContentProps {
  options: TabContentOption[]
  defaultTabName?: string
}

export const TabsContent = ({ defaultTabName, options }: TabsContentProps) => {
  const clientWindow = getWindow()

  const [activeTabName, setActiveTabName] = useState(() =>
    clientWindow?.location?.hash ? getHashName() : defaultTabName || options[0].name,
  )

  useEffect(() => {
    const onHashChange = async () => {
      const newHashName = getHashName()

      clientWindow?.location?.hash && setActiveTabName(newHashName)
    }

    clientWindow?.addEventListener('hashchange', onHashChange, false)

    return () => clientWindow?.removeEventListener('hashchange', onHashChange, false)
  }, [options, clientWindow])

  const activeTab = options.find(({ name }) => name === activeTabName)

  return (
    <div aria-labelledby={`tab-${activeTabName}`} id={getTabId(activeTabName)} role='tabpanel'>
      {activeTab?.render()}
    </div>
  )
}
