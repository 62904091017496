import { Button, Field, Form, Grid, Text, useToast } from '@frontend/shared/ui'
import React, { useRef } from 'react'
import { useTheme } from '@frontend/shared/theme'
import { useRouter } from 'next/router'
import { FormikProps } from 'formik'
import { useGoogleRecaptcha } from '@frontend/domains/shared/components'

import { useServicePasswordReset } from '../../../service/login/use-service-password-reset'
import { routes } from '../../../router/routes'

import { initialValues } from './form/initial-values'
import { FormValues } from './form/types'
import { validationSchema } from './form/validation-schema'

export const PasswordResetInitialize = () => {
  const theme = useTheme()
  const router = useRouter()
  const { showToastSuccess } = useToast()
  const { getRecaptchaToken } = useGoogleRecaptcha()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { passwordResetInitialize, loading } = useServicePasswordReset()

  const submitForm = async ({ email }: FormValues) => {
    const recaptchaToken = await getRecaptchaToken('passwordResetInitialize')

    if (recaptchaToken) {
      const passwordResetSuccess = await passwordResetInitialize({ email: email.trim(), recaptchaToken })

      if (passwordResetSuccess) {
        await router.push(routes.signIn.getUrl())
        showToastSuccess(
          'Check your email',
          `An email has been sent to ${email} with instructions to reset your password.`,
        )
      }
    }
  }

  return (
    <Form
      initialValues={initialValues}
      innerRef={formikRef}
      name='password-reset-initialize'
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      <Grid gap='sm'>
        <Text data-test-id='title' size='lg'>
          Forgot your password?
        </Text>

        <Text color={theme.colors.gray['700']} size='sm'>
          Enter your email below, you will receive an email with instructions on how to reset your password in a few
          minutes.
        </Text>

        <Field label='Email' name='email' placeholder='example@mail.com' />

        <Grid gap='sm' gridTemplateColumns='100%'>
          <Button disabled={loading} htmlType='submit' loading={loading} name='password-reset'>
            Reset password
          </Button>
          <Button
            disabled={loading}
            name='sign-in'
            type='linkColor'
            onClick={() => router.push(routes.signIn.getUrl())}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}
