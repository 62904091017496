import React from 'react'
import { Button, Flex, Icon, useBreakpoints } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import { AppHeader } from '@frontend/domains/shared/components'
import { useAuthentication } from '@frontend/domains/shared/authentication'

import { UserProfile } from './user-profile/user-profile'

interface HeaderProps {
  onClickMenuToggler: () => void
}

export const Header = ({ onClickMenuToggler }: HeaderProps) => {
  const theme = useTheme()
  const { signOut } = useAuthentication()
  const { isXs, isSm, isMd } = useBreakpoints({ breakpoints: theme.breakpoints })
  const isMobile = isXs || isSm || isMd

  const right = isMobile ? (
    <>
      <Flex pr={4}>
        <Button name='sign-out' type='icon' onClick={signOut}>
          <Icon color={theme.colors.primary.contrast} fontSize='1.5rem' kind='FiLogOut' />
        </Button>
      </Flex>

      <Flex alignItems='center' pr={4}>
        <Button name='toggle-menu' type='icon' onClick={onClickMenuToggler}>
          <Icon color={theme.colors.primary.contrast} fontSize='1.5rem' kind='FiMenu' />
        </Button>
      </Flex>
    </>
  ) : (
    <UserProfile />
  )

  return <AppHeader right={right} />
}
