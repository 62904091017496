import { getCookieDomain, getLocalStorage } from '@frontend/shared/utils'
import { STORAGE_REFRESH_TOKEN_EXPIRES_AT, STORAGE_TOKEN_EXPIRES_AT } from '@frontend/shared/constants'

import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'
import { useServiceCustomerLazy } from '../use-service-customer'
import { useAuthentication } from '../../context/authentication/context-authentication'

interface IssueTokenResponse {
  errorCode: string
  expiresAt: string
  refreshExpiresAt: string
}

/**
 * Token service.
 * Issues a token for successful login in an authentication session.
 */
export const useServiceIssueToken = () => {
  const { set: setTokenExpiresAt } = getLocalStorage(STORAGE_TOKEN_EXPIRES_AT)
  const { set: setRefreshTokenExpiresAt } = getLocalStorage(STORAGE_REFRESH_TOKEN_EXPIRES_AT)
  const { setCustomer } = useAuthentication()
  const serviceCustomer = useServiceCustomerLazy()
  const { post, error, loading, response } = useAuthFetch<IssueTokenResponse>()

  const issueToken = async (code: string, codeVerifier: string) => {
    const body = {
      code,
      cookieDomain: getCookieDomain(),
      redirectUri: window.location.origin,
      codeVerifier,
    }

    const result = await post(ENDPOINT_URL.issueToken, body)

    if (response.ok) {
      setTokenExpiresAt(result.expiresAt)
      setRefreshTokenExpiresAt(result.refreshExpiresAt)

      const customer = await serviceCustomer.getCustomer()
      customer && setCustomer(customer)

      return true
    }

    return false
  }

  return { issueToken, error, loading }
}
