import { H1 } from '@frontend/domains/shared/components'
import { LayoutApp } from '@frontend/domains/plus/app'
import { Grid, RequestResult, Section } from '@frontend/shared/ui'
import React from 'react'

export const Error404 = () => (
  <LayoutApp>
    <Grid>
      <H1>Sorry, we can&apos;t find that page</H1>

      <Section>
        <RequestResult
          description="Don't worry though, everything is still awesome!"
          title='Looks like this page is missing.'
          type='error'
        />
      </Section>
    </Grid>
  </LayoutApp>
)
