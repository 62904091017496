import { SvgProps } from '@frontend/shared/types'
import React from 'react'

export const FacebookSolid = ({ color }: SvgProps) => (
  <svg fill='none' height='45' viewBox='0 0 44 45' width='44' xmlns='http://www.w3.org/2000/svg'>
    <title>Earthbanc - facebook</title>
    <circle cx='21.9424' cy='22.545' fill='#3B5998' r='21.8839' />
    <path
      d={`M26.5775 24.1406L27.2394 19.52H23.1005V16.5216C23.1005 15.2575 23.6787 14.0253 25.5324 14.0253H27.414V10.0915C27.414 10.0915 25.7065 9.7793 24.0739 9.7793C20.6653 9.7793 18.4373 11.9923 18.4373 15.9985V19.52H14.6484V24.1406H18.4373V35.3105H23.1005V24.1406H26.5775Z`}
      fill={color ?? 'white'}
    />
  </svg>
)
