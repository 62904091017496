import { theme } from './theme'
import { breakpoints, gridGap } from './constants'

const fontWeights = Object.values(theme.fontWeights)

export type Breakpoint = typeof breakpoints
export type DisplayFontSize = keyof typeof theme.fontSizes.display
export type TextFontSize = keyof typeof theme.fontSizes.text
export type FontWeight = typeof fontWeights[number]
export type GridGap = keyof typeof gridGap
