import { css, styled, StyledProps } from '@frontend/shared/theme'
import { Logger } from '@frontend/shared/logger'

import { Flex, FlexProps } from '../../../layout/flex/flex'

export interface FileWrapperProps extends FlexProps, Pick<HTMLAnchorElement, 'href'> {
  as: string
  type?: 'upload' | 'download' | 'error'
}

const backgroundColor = ({ type, theme }: StyledProps<FileWrapperProps>) => {
  switch (type) {
    case 'download':
    case 'upload':
      return theme.colors.primary.contrast
    case 'error':
      return theme.colors.error['25']
    default:
      Logger.error(`Implement new background color for file wrapper - ${type}`)

      return theme.colors.primary.contrast
  }
}

const borderColor = ({ type, theme }: StyledProps<FileWrapperProps>) => {
  switch (type) {
    case 'download':
    case 'upload':
      return theme.colors.gray['200']
    case 'error':
      return theme.colors.error['300']
    default:
      Logger.error(`Implement new border color for file wrapper - ${type}`)

      return theme.colors.gray['200']
  }
}

export const FileWrapper = styled(Flex)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['type'].includes(prop.toString()) && defaultValidatorFn(prop),
  })
  .attrs<FileWrapperProps>(({ as }) => ({
    as,
    flexDirection: 'column',
  }))<FileWrapperProps>`
  background: ${backgroundColor};
  border-width: 0.0625rem;
  border-style: solid;
  border-color: ${borderColor};
  border-radius: 0.5rem;
  padding: 1rem;
  text-decoration: none;
  transition: all ${({ theme }) => theme.transition};

  ${({ type }) =>
    (type === 'upload' || type === 'download')
    && css`
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.primary.blue.main};
      }
    `}
`
