import { VerificationPeriod, Project, Vintage } from '@frontend/shared/types'
import { dayjs } from '@frontend/shared/dayjs'

export const activeProject = ({ activeFrom, activeTo }: Project) => {
  const currentDay = dayjs().utc()

  const afterActiveFromOrEqual = currentDay.isSameOrAfter(dayjs(activeFrom, { utc: true }), 'minute')
  const beforeActiveToOrEqual = currentDay.isSameOrBefore(dayjs(activeTo, { utc: true }), 'minute')

  return afterActiveFromOrEqual && beforeActiveToOrEqual
}

export const bySellingUnit = (sellingUnit?: string) => (project: Project) =>
  sellingUnit ? project.sellingUnit === sellingUnit : project

export const byTags = (tags?: string | string[]) => (project: Project) => {
  if (typeof tags === 'string') {
    return tags ? project.tags?.some((tag) => tag === tags) : project
  }

  if (Array.isArray(tags)) {
    return tags?.length ? project.tags?.some((tag) => tags.includes(tag)) : project
  }

  return project
}

export const byVerificationPeriodRangeDesc = (a: VerificationPeriod, b: VerificationPeriod) => {
  const activeFromCurrent = dayjs(a.range.from)
  const activeFromNext = dayjs(b.range.from)
  const activeToCurrent = dayjs(a.range.to)
  const activeToNext = dayjs(b.range.to)

  if (activeFromCurrent.isSame(activeFromNext) && activeToCurrent.isSame(activeToNext)) {
    return 0
  }

  return activeFromCurrent.isBefore(activeFromNext) && activeToCurrent.isBefore(activeToNext) ? 1 : -1
}

export const byVintagePriceAsc = (a: Vintage, b: Vintage) => {
  const vintageCurrent = a.priceEur
  const vintageNext = b.priceEur

  if (vintageCurrent === vintageNext) {
    return 0
  }

  return vintageCurrent < vintageNext ? -1 : 1
}

export const byVintageDateDesc = (a: Vintage, b: Vintage) => {
  const vintageCurrent = dayjs(a.vintage)
  const vintageNext = dayjs(b.vintage)

  if (vintageCurrent.isSame(vintageNext)) {
    return 0
  }

  return vintageCurrent.isBefore(vintageNext) ? 1 : -1
}
