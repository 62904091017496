import {
  Card,
  Display,
  Grid,
  RequestResult,
  RequestResultError,
  Section,
  Skeleton,
  useBreakpoints,
} from '@frontend/shared/ui'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getLocalStorage } from '@frontend/shared/utils'
import { Project, SellingUnit } from '@frontend/shared/types'

import { useServiceProjects } from '../hooks/use-service-projects'
import { STORAGE_PARTNER_ID_KEY } from '../constants'
import { getFile, getPlace, getPrice, getRating } from '../utils'
import { Routes } from '../types'
import { useTheme } from '../ui/theme/theme-iframe/styled-components'
import { activeProject, bySellingUnit, byTags, byVerificationPeriodRangeDesc, byVintagePriceAsc } from '../filter'

export interface ListProps {
  application: 'plus' | 'carbonOffsets'
  routes: Routes
}

const { set: setPartnerId } = getLocalStorage<string>(STORAGE_PARTNER_ID_KEY)

export const List = ({ application, routes }: ListProps) => {
  const theme = useTheme()
  const router = useRouter()
  const { error, loading, data = [] } = useServiceProjects<Project>()
  const { isSm } = useBreakpoints({ breakpoints: theme.breakpoints })
  const sellingUnit = typeof router.query?.sellingUnit === 'string' ? router.query.sellingUnit : undefined
  const tags =
    typeof router.query?.tags === 'string' || Array.isArray(router.query?.tags) ? router.query.tags : undefined

  const title = isSm ? 'Tap to select a project' : 'Select a project'

  useEffect(() => {
    if (typeof router.query?.partner === 'string') {
      setPartnerId(router.query.partner)
    }
  }, [router])

  if (loading) {
    return (
      <Grid
        gridTemplateColumns={
          application === 'plus'
            ? ['1fr', '1fr 1fr', null, null, '1fr 1fr 1fr']
            : ['1fr', '1fr 1fr', null, '1fr 1fr 1fr']
        }
      >
        <Skeleton height='20rem' />
        <Skeleton height='20rem' />
      </Grid>
    )
  }

  if (error) {
    return (
      <Section>
        <RequestResultError />
      </Section>
    )
  }

  const carbonOffsetProjects = data.filter(activeProject).filter(bySellingUnit(sellingUnit)).filter(byTags(tags))

  if (!carbonOffsetProjects || carbonOffsetProjects.length === 0) {
    return (
      <Section>
        <RequestResult description='There are no projects yet. Try it again later.' title='No projects found' />
      </Section>
    )
  }

  return (
    <>
      <Display color={theme.components.h1.textColor} size='xs' weight={500}>
        {title}
      </Display>

      <Grid
        gridTemplateColumns={
          application === 'plus'
            ? ['1fr', '1fr 1fr', null, null, '1fr 1fr 1fr']
            : ['1fr', '1fr 1fr', null, '1fr 1fr 1fr']
        }
      >
        {carbonOffsetProjects.map((project) => {
          const {
            ticker,
            perex,
            country,
            location,
            tags,
            name,
            prices,
            images,
            ratings,
            sellingUnit,
            verificationPeriods,
          } = project
          const place = getPlace(country, location)
          const { id: imageId, hash: imageHash } = images[0]
          let price = getPrice(prices.eur, sellingUnit)

          if (sellingUnit === SellingUnit.CarbonTonne && verificationPeriods) {
            const sortedVerificationPeriods = verificationPeriods?.sort(byVerificationPeriodRangeDesc)
            const verificationPeriod = sortedVerificationPeriods[0]
            const sortedVintagesDesc = verificationPeriod.vintages.sort(byVintagePriceAsc)

            price = `from ${getPrice(sortedVintagesDesc[0].priceEur, sellingUnit)}`
          }

          const goToDetail = () => router.push(routes.carbonOffsetProjectDetail.getUrl(ticker, router.query))

          return (
            <Card href={routes.carbonOffsetProjectDetail.getUrl(ticker, router.query)} key={ticker} ticker={ticker}>
              <Card.CardInformation
                description={perex}
                imageHash={imageHash}
                imageSource={getFile(imageId)}
                key={ticker}
                place={place}
                price={price}
                rating={getRating(ratings)}
                tags={tags}
                ticker={ticker}
                title={name}
              />
              <Card.CardInformationButton onClick={goToDetail} />
            </Card>
          )
        })}
      </Grid>
    </>
  )
}
