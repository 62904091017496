import { RefObject } from 'react'
import { FormikProps } from 'formik'
import { ResponseError } from '@frontend/shared/types'
import { CachePolicies, Res } from 'use-http'
import { useApiFetch } from '@frontend/domains/shared/components'

import { FormValues } from '../form/types'
import { ENDPOINT_URL } from '../../../constants'

interface SignUp {
  email: string
  fullName: string
  password: string
  recaptchaToken?: string
}

export const useServiceSignUp = (formikRef: RefObject<FormikProps<FormValues>>) => {
  const { post, loading, response } = useApiFetch<ResponseError>({
    cachePolicy: CachePolicies.NO_CACHE,
  })

  const signUp = async ({ fullName, email, password, recaptchaToken }: SignUp) => {
    const onError = (response: Res<ResponseError>) => {
      response.status === 409 && formikRef?.current?.setFieldError('email', 'The user with this email already exist.')
    }
    const body = { fullName, email, password, recaptchaToken }
    await post(ENDPOINT_URL.signUp, body, onError)

    return response.ok
  }

  return { signUp, loading }
}
