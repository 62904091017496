import { css, styled, Theme } from '@frontend/shared/theme'

export type sizes = 'sm' | 'md' | 'lg' | 'xl'

export interface ResponsiveProps {
  hide?: sizes | sizes[]
  show?: sizes | sizes[]
}

const getLowerValue = (theme: Theme, breakpoint: number): string => {
  const [valueRem] = theme.breakpoints[breakpoint].split('rem')
  const valuePx = Number(valueRem) * 16

  return `${valuePx - 1}px`
}

const hideStyles = (item: ResponsiveProps['hide']) => {
  switch (item) {
    case 'sm':
      return css`
        @media only screen and (max-width: ${({ theme }) => getLowerValue(theme, 0)}) {
          display: none;
        }
      `
    case 'md':
      return css`
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) and (max-width: ${({ theme }) =>
            getLowerValue(theme, 1)}) {
          display: none;
        }
      `
    case 'lg':
      return css`
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
          display: none;
        }
      `
    case 'xl':
      return css`
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
          display: none;
        }
      `
    default:
      return ''
  }
}

const showStyles = (item: ResponsiveProps['show']) => {
  switch (item) {
    case 'sm':
      return css`
        display: none;
        @media only screen and (max-width: ${({ theme }) => getLowerValue(theme, 0)}) {
          display: contents;
        }
      `
    case 'md':
      return css`
        display: none;
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) and (max-width: ${({ theme }) =>
            getLowerValue(theme, 1)}) {
          display: contents;
        }
      `
    case 'lg':
      return css`
        display: none;
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
          display: contents;
        }
      `
    case 'xl':
      return css`
        display: none;
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
          display: contents;
        }
      `
    default:
      return ''
  }
}

const getHideStyle = (hide: ResponsiveProps['hide']) => {
  if (Array.isArray(hide)) {
    return hide.map((item) => hideStyles(item))
  }

  return hideStyles(hide)
}

const getShowStyle = (show: ResponsiveProps['show']) => {
  if (Array.isArray(show)) {
    return show.map((item) => showStyles(item))
  }

  return showStyles(show)
}

export const ResponsiveWrapper = styled.span<ResponsiveProps>`
  ${({ hide }) => hide && getHideStyle(hide)};
  ${({ show }) => show && getShowStyle(show)};
`
