import { useTheme } from '@frontend/shared/theme'
import React, { ReactNode } from 'react'

import { Flex } from '../../../layout/flex/flex'
import { Grid } from '../../../layout/grid/grid'
import { IconFeatured } from '../../../text/icon/featured/icon-featured'
import { Text } from '../../../text'

export interface NoDataProps {
  description: string
  title: string
  button?: ReactNode
  type?: 'noData' | 'error'
}

export const RequestResult = ({ description, title, button, type = 'noData' }: NoDataProps) => {
  const theme = useTheme()

  return (
    <Flex alignItems='center' flexDirection='column' justifyContent='center'>
      <Grid gap='md' gridTemplateColumns='auto' justifyContent='center' maxWidth='21rem'>
        <Flex justifyContent='center'>
          <IconFeatured
            color={type === 'noData' ? 'gray' : 'warning'}
            kind='FiAlertTriangle'
            size='lg'
            type='lightCircleOutline'
          />
        </Flex>

        <Grid gap='sm'>
          <Text data-test-id='request-result-title' size='md' textAlign='center'>
            {title}
          </Text>

          {description && (
            <Flex justifyContent='center'>
              <Text
                color={theme.colors.gray['500']}
                data-test-id='request-result-description'
                size='sm'
                textAlign='center'
              >
                {description}
              </Text>
            </Flex>
          )}
        </Grid>

        {button}
      </Grid>
    </Flex>
  )
}
