import { addMethod, string, StringSchema } from 'yup'
import { EmailValidator } from 'commons-validator-js'

const emailValidator = new EmailValidator()

addMethod<StringSchema>(string, 'emailValidation', function emailValidation() {
  return this.test('validate-email', 'Invalid email address', (value) => {
    if (!value) {
      return true
    }

    return emailValidator.isValid(value)
  })
})
