import React, { PropsWithChildren } from 'react'

import { Grid } from '../../../layout/grid/grid'

interface FormGroupProps {
  columns?: number
}

export const FormGroup = ({ children, columns = 2 }: PropsWithChildren<FormGroupProps>) => {
  const gridTemplateColumns = ['100%', null, `repeat(2, 1fr)`, `repeat(${columns}, 1fr)`]

  return (
    <Grid alignItems='flex-start' gridTemplateColumns={gridTemplateColumns}>
      {children}
    </Grid>
  )
}
