import { FileRejection } from 'react-dropzone'
import React from 'react'

import { Grid } from '../../../../../layout/grid/grid'
import { File } from '../../../../../screen/file'

interface FilesPreviewProps {
  onRemoveAcceptedFile: (name: string) => void
  onRemoveRejectedFile: (name: string) => void
  acceptedFiles?: File[]
  rejectedFiles?: FileRejection[]
}

export const MultipleFiles = ({
  rejectedFiles = [],
  acceptedFiles = [],
  onRemoveAcceptedFile,
  onRemoveRejectedFile,
}: FilesPreviewProps) => {
  if (rejectedFiles?.length === 0 && acceptedFiles?.length === 0) {
    return null
  }

  return (
    <Grid gap='sm'>
      {rejectedFiles.map((rejectedFile) => (
        <File
          description={rejectedFile.errors?.[0].message}
          key={rejectedFile.file.name}
          name={rejectedFile.file.name}
          type='error'
          onClose={() => onRemoveRejectedFile(rejectedFile.file.name)}
        />
      ))}
      {acceptedFiles.map((acceptedFile) => (
        <File
          key={acceptedFile.name}
          name={acceptedFile.name}
          type='upload'
          onClose={() => onRemoveAcceptedFile(acceptedFile.name)}
        />
      ))}
    </Grid>
  )
}
