import React from 'react'
import { formatPrice, formatDate, formatDay } from '@frontend/shared/utils'
import { useTheme } from '@frontend/shared/theme'
import dayjs from 'dayjs'

import { Grid } from '../../../../layout/grid/grid'
import { Flex } from '../../../../layout/flex/flex'
import { Text, TextNonBreakable } from '../../../../text/typography/typography'
import { Divider } from '../../../../layout/divider'
import { ProgressBar } from '../../../progress/bar/progress-bar'

export interface CardInvestmentProps {
  alreadyRaised: number
  expectedYield: string
  investorCount: number
  targetEur: number
  ticker: string
  activeTo?: string
  usePadding?: boolean
}

export const CardInvestment = ({
  expectedYield,
  activeTo,
  alreadyRaised,
  investorCount,
  targetEur,
  ticker,
  usePadding,
}: CardInvestmentProps) => {
  const theme = useTheme()
  const funded = Math.round((alreadyRaised / targetEur) * 100)

  const lessThanMonth = activeTo && new Date() >= dayjs(activeTo).subtract(1, 'month').toDate()
  const daysTillEnd = activeTo ? dayjs(activeTo).diff(dayjs(), 'day') : 0

  return (
    <Flex pb={usePadding ? '1.25rem' : '0'} px={usePadding ? '1.25rem' : '0'}>
      <Grid data-test-id={`investment-${ticker}`} gap='sm'>
        <Flex justifyContent='space-between'>
          <Flex flex={1} pr={3}>
            <Text size='sm'>{expectedYield}</Text>
          </Flex>
          {activeTo && (
            <Flex justifyContent='flex-end'>
              <TextNonBreakable color={theme.colors.gray['700']} data-test-id='ending-title' size='sm' weight={600}>
                ENDING {lessThanMonth ? 'IN' : 'ON'}: &nbsp;
              </TextNonBreakable>
              <TextNonBreakable data-test-id='ending-value' size='sm'>
                {lessThanMonth ? formatDay(daysTillEnd) : formatDate(activeTo)}
              </TextNonBreakable>
            </Flex>
          )}
        </Flex>
        <Flex>
          <Flex flex={1} flexDirection='column'>
            <Text color={theme.colors.gray['700']} data-test-id='raised-title' size='sm' weight={600}>
              RAISED
            </Text>
            <Text color={theme.colors.gray['600']} data-test-id='raised-value' size='xs'>
              {formatPrice(alreadyRaised)}
            </Text>
          </Flex>
          <Flex flex={0}>
            <Divider orientation='vertical' />
          </Flex>
          <Flex flex={1} flexDirection='column'>
            <Text
              color={theme.colors.gray['700']}
              data-test-id='investors-title'
              size='sm'
              textAlign='center'
              weight={600}
            >
              INVESTORS
            </Text>
            <Text color={theme.colors.gray['600']} data-test-id='investors-value' size='xs' textAlign='center'>
              {investorCount}
            </Text>
          </Flex>
          <Flex>
            <Divider orientation='vertical' />
          </Flex>
          <Flex flex={1} flexDirection='column'>
            <Text color={theme.colors.gray['700']} data-test-id='goal-title' size='sm' textAlign='right' weight={600}>
              GOAL
            </Text>
            <Text color={theme.colors.gray['600']} data-test-id='goal-value' size='xs' textAlign='right'>
              {formatPrice(targetEur)}
            </Text>
          </Flex>
        </Flex>
        <Grid gap='sm'>
          <Text data-test-id='funded' size='sm'>
            Open for investment - {funded}% funded
          </Text>
          <ProgressBar name={`progress-bar-${ticker}`} progress={funded} />
        </Grid>
      </Grid>
    </Flex>
  )
}
