import { ToastContainer as ToastifyContainer } from 'react-toastify'
import { styled } from '@frontend/shared/theme'

/**
 * Styled ToastContainer
 * see: https://fkhadra.github.io/react-toastify/how-to-style/
 */
export const ToastContainer = styled(ToastifyContainer)`
  max-width: 25rem;

  &.Toastify__toast-container {
    width: 100%;
  }

  &.Toastify__toast-container--top-right {
    top: 5rem;
  }

  .Toastify__toast {
    margin-bottom: 2rem;
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: ${({ theme }) => theme.shadows.lg};
    min-height: 0;
    padding: 0.75rem;
    font-family: Montserrat, sans-serif;
    background-color: ${({ theme }) => theme.components.toast.wrapper.backgroundColor};
  }

  .Toastify__toast--dark,
  .Toastify__toast--default,
  .Toastify__toast--success,
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.components.toast.wrapper.backgroundColor};
  }

  .Toastify__toast-icon {
    display: none;
  }
`
