export const generateRandomString = (stringLength: number) => {
  let randomString = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < stringLength; i += 1) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return randomString
}
