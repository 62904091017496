import { Flex, Grid } from '@frontend/shared/ui'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Project, SellingUnit, VerificationPeriod, Vintage } from '@frontend/shared/types'

import { STRIPE_MAX_AMOUNT_LIMIT } from '../constants'
import { Header } from '../ui/layout/header'
import { useQueryParserProjectCheckout } from '../hooks'
import { useServiceProjects } from '../hooks/use-service-projects'
import { calcPrice } from '../utils'
import { Routes } from '../types'
import { byVerificationPeriodRangeDesc } from '../filter'

import { CheckoutSkeleton } from './checkout-skeleton'
import { FormPayment } from './components/payment/form-payment'
import { FormReservation } from './components/reservation/form-reservation'

interface OnPaymentSuccess {
  gift?: boolean
  paymentCard?: boolean
}

interface CheckoutProps {
  routes: Routes
  emailDefault?: string
  onPaymentSuccess?: (params?: OnPaymentSuccess) => void
}

export const Checkout = ({ emailDefault, onPaymentSuccess, routes }: CheckoutProps) => {
  const router = useRouter()
  const serviceProjects = useServiceProjects()

  const [project, setProject] = useState<Project>()
  const [verificationPeriod, setVerificationPeriod] = useState<VerificationPeriod>()
  const [vintage, setVintage] = useState<Vintage>()
  const [price, setPrice] = useState(1)

  const queryParams = useQueryParserProjectCheckout(router.query)

  useEffect(() => {
    if (queryParams.ticker && serviceProjects.data && serviceProjects.data.length > 0 && queryParams?.quantity) {
      const projectDetail = serviceProjects.data.find((project) => project.ticker === queryParams.ticker)

      if (projectDetail) {
        let totalPrice = calcPrice(queryParams.quantity, projectDetail.prices.eur)

        if (projectDetail.sellingUnit === SellingUnit.CarbonTonne) {
          const sortedVerificationPeriods = projectDetail?.verificationPeriods?.sort(byVerificationPeriodRangeDesc)

          if (sortedVerificationPeriods?.length) {
            const vintage = sortedVerificationPeriods[0]?.vintages.find(
              ({ vintage }) => vintage === queryParams.vintage,
            )
            setVerificationPeriod(sortedVerificationPeriods[0])

            if (vintage) {
              setVintage(vintage)
              totalPrice = calcPrice(queryParams.quantity, vintage.priceEur)
            }
          }
        }

        setPrice(totalPrice)
        setProject(projectDetail)
      }
    }
  }, [serviceProjects.data, queryParams.ticker, queryParams.quantity, queryParams.vintage])

  if (!project || !queryParams.ticker || serviceProjects.loading) {
    return <CheckoutSkeleton />
  }

  const priceExceeded =
    project.sellingUnit === SellingUnit.CarbonTonne
    && vintage
    && queryParams.quantity * vintage.priceEur > STRIPE_MAX_AMOUNT_LIMIT

  const reservationRequired =
    (project?.quantityReservationThreshold !== undefined
      && project?.quantityReservationThreshold >= 0
      && queryParams.quantity >= project?.quantityReservationThreshold)
    || priceExceeded

  return (
    <Flex flex={1} justifyContent='center'>
      <Grid maxWidth={['100%', null, null, '67.5rem']}>
        <Header
          sideLinkTitle='Back to project'
          title='Checkout'
          useButton
          onClick={() => router.push(routes.carbonOffsetProjectDetail.getUrl(queryParams.ticker, router.query))}
        />

        {reservationRequired ? (
          <FormReservation
            emailDefault={emailDefault}
            price={price}
            project={project}
            routes={routes}
            verificationPeriod={verificationPeriod}
          />
        ) : (
          <FormPayment
            emailDefault={emailDefault}
            price={price}
            project={project}
            routes={routes}
            verificationPeriod={verificationPeriod}
            onPaymentSuccess={onPaymentSuccess}
          />
        )}
      </Grid>
    </Flex>
  )
}
