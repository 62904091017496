import { Display } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import React, { PropsWithChildren } from 'react'

interface H1Props {
  testID?: string
}

export const H1 = ({ children, testID = 'title' }: PropsWithChildren<H1Props>) => {
  const theme = useTheme()

  return (
    <Display color={theme.components.h1.textColor} data-test-id={testID} size='sm' weight={500}>
      {children}
    </Display>
  )
}
