const defaultOptions = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 10,
  },
]

interface PasswordStrengthReturn {
  contains: string[]
  length: number
  rank: number
  value: string
}

export const passwordStrength = (
  password: string,
  options = defaultOptions,
  allowedSymbols = '!"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~',
): PasswordStrengthReturn => {
  const passwordCopy = password || ''

  const rules = [
    {
      regex: '[a-z]',
      message: 'lowercase',
    },
    {
      regex: '[A-Z]',
      message: 'uppercase',
    },
    {
      regex: '[0-9]',
      message: 'number',
    },
  ]

  allowedSymbols && rules.push({ regex: `[${allowedSymbols}]`, message: 'symbol' })

  const strength = {
    contains: rules.filter((rule) => new RegExp(`${rule.regex}`).test(passwordCopy)).map((rule) => rule.message),
    length: passwordCopy.length,
  }

  const fulfilledOptions = options
    .filter((option) => strength.contains.length >= option.minDiversity)
    .filter((option) => strength.length >= option.minLength)
    .sort((o1, o2) => o2.id - o1.id)
    .map((option) => ({ rank: option.id, value: option.value }))

  return { ...strength, ...fulfilledOptions[0] }
}
