import { styled } from '@frontend/shared/theme'

import { Flex } from '../../layout/flex/flex'
import { Text } from '../../text/typography/typography'

interface FaqProps {
  opened: boolean
}

export const FaqWrapper = styled(Flex).attrs<FaqProps>(({ theme, opened }) => ({
  p: ['1rem', '2rem'],
  background: opened ? theme.colors.gray['50'] : 'transparent',
}))<FaqProps>`
  border-radius: 1rem;
  transition: all ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.gray['50']};
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }
`

export const FaqContent = styled(Flex)<FaqProps>`
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  height: ${({ opened }) => (opened ? 'auto' : '0px')};
  transition: all ${({ theme }) => theme.transition};
  overflow: ${({ opened }) => (opened ? 'auto' : 'hidden')};
`

export const FaqText = styled(Text)`
  a {
    color: ${({ theme }) => theme.colors.gray['900']};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`
