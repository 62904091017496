import React from 'react'

import { Button } from '../../../button/root/button'
import { Flex } from '../../../../layout/flex/flex'

export interface CardInformationButtonsProps {
  onClick(): void
}

export const CardInformationButton = ({ onClick }: CardInformationButtonsProps) => (
  <Flex pb='1.25rem' px='1.25rem'>
    <Button name='detail' size='sm' onClick={onClick}>
      Details
    </Button>
  </Flex>
)
