import { styled } from '@frontend/shared/theme'
import Image from 'next/image'

import { Flex } from '../../layout/flex/flex'

export const CardHeaderWrapper = styled(Flex).attrs(() => ({
  p: '1.25rem 1.5rem',
}))``

export const CardHeaderImage = styled(Image)`
  border-radius: 50%;
`
