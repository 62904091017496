import dynamic from 'next/dynamic'
import { TableData } from 'react-table'

import { TableProps } from './table/basic/table'
import { CarouselProps } from './carousel/carousel'
import { LightboxProps } from './lightbox/lightbox'
import { MarkdownProps } from './markdown/markdown'
import { TooltipProps } from './tooltip/tooltip'
import { NextPageSensorProps } from './next-page-sensor/next-page-sensor'
import { TablePaginationProps } from './table/pagination/table-pagination'

const Table = dynamic(() => import('./table/basic/table').then((module) => module.Table)) as <Data extends TableData>(
  params: TableProps<Data>,
) => JSX.Element

const TablePagination = dynamic(() =>
  import('./table/pagination/table-pagination').then((module) => module.TablePagination),
) as <Data extends TableData>(params: TablePaginationProps<Data>) => JSX.Element

const Carousel = dynamic<CarouselProps>(() => import('./carousel/carousel').then((module) => module.Carousel))
const Lightbox = dynamic<LightboxProps>(() => import('./lightbox/lightbox').then((module) => module.Lightbox))
const Markdown = dynamic<MarkdownProps>(() => import('./markdown/markdown').then((module) => module.Markdown))
const Tooltip = dynamic<TooltipProps>(() => import('./tooltip/tooltip').then((module) => module.Tooltip))
const NextPageSensor = dynamic<NextPageSensorProps>(() =>
  import('./next-page-sensor/next-page-sensor').then((module) => module.NextPageSensor),
)

export * from './activity-feed'
export * from './alert'
export * from './avatar'
export * from './badge'
export * from './button'
export * from './card'
export * from './card-header'
export * from './detail'
export * from './faq'
export * from './file'
export * from './loader'
export * from './modal'
export * from './progress'
export * from './request-result'
export * from './root'
export * from './skeleton'
export * from './table'
export * from './tabs'
export * from './toast'
export * from './user-avatar'
export { Table, Carousel, Lightbox, Markdown, Tooltip, NextPageSensor, TablePagination }
