import React from 'react'

import { Text } from '../../../../text'
import { Flex } from '../../../../layout/flex'
import { CommonDatePickerProps } from '../types'

import { Months, CustomItem } from './custom-header.styles'

type CalendarMonthsProps = Pick<CommonDatePickerProps, 'translations'> & {
  actualMonth: number
  onChange(month: number): void
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const CalendarMonths = ({ actualMonth, onChange, translations }: CalendarMonthsProps) => (
  <>
    <Flex justifyContent='center' mb={2}>
      <Text>{translations.selectMonth}</Text>
    </Flex>
    <Months>
      {monthNames.map((month, index) => (
        <CustomItem key={month} selected={actualMonth === index} onClick={() => onChange(index)}>
          {month}
        </CustomItem>
      ))}
    </Months>
  </>
)
