import { Button, FieldPassword, Form, Grid, Text, useToast } from '@frontend/shared/ui'
import React, { useRef } from 'react'
import { useTheme } from '@frontend/shared/theme'
import { useRouter } from 'next/router'
import { FormikProps } from 'formik'
import { useGoogleRecaptcha } from '@frontend/domains/shared/components'

import { useServicePasswordReset } from '../../../service/login/use-service-password-reset'
import { routes } from '../../../router/routes'

import { initialValues } from './form/initial-values'
import { FormValues } from './form/types'
import { validationSchema } from './form/validation-schema'

export const PasswordResetFinalize = () => {
  const theme = useTheme()
  const router = useRouter()
  const token = typeof router.query.token === 'string' ? router.query.token : undefined
  const { showToastSuccess, showToastWarning } = useToast()
  const { getRecaptchaToken } = useGoogleRecaptcha()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { passwordResetFinalize, loading } = useServicePasswordReset()

  const submitForm = async ({ passwordNew }: FormValues) => {
    if (!token) {
      showToastWarning('Missing token', 'Valid token must be used to reset password.')

      return
    }

    const recaptchaToken = await getRecaptchaToken('passwordResetFinalize')

    if (recaptchaToken) {
      const passwordResetSuccess = await passwordResetFinalize({ passwordNew, recaptchaToken, token })

      if (passwordResetSuccess) {
        await router.replace(routes.signIn.getUrl())
        showToastSuccess('Password changed', 'Your password was changed. Please sign in.')
      }
    }
  }

  return (
    <Form
      initialValues={initialValues}
      innerRef={formikRef}
      name='password-reset-finalize'
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      <Grid gap='sm'>
        <Text data-test-id='title' size='lg'>
          Change your password
        </Text>

        <Text color={theme.colors.gray['700']} size='sm'>
          To ensure that account is well protected, please use 8 or more characters.
        </Text>

        <FieldPassword label='New password' name='passwordNew' strengthIndicator />

        <Grid gap='sm' gridTemplateColumns='100%'>
          <Button disabled={loading} htmlType='submit' loading={loading} name='password-change'>
            Change password
          </Button>
          <Button
            disabled={loading}
            name='sign-in'
            type='linkColor'
            onClick={() => router.push(routes.signIn.getUrl())}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}
