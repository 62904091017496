import { mixed, object, string } from 'yup'

import { PaymentType } from './types'

export const validationSchema = object().shape({
  name: string().required().max(64),
  email: string().required().max(128).trim().emailValidation(),
  iban: mixed()
    .test('is-iban', '', (value, context) => {
      const isSepa = context.parent.paymentType === PaymentType.Sepa

      return isSepa ? value : true
    })
    .when('paymentType', {
      is: PaymentType.Sepa,
      then: string().required(),
      otherwise: string(),
    }),
  cardNumber: mixed()
    .test('is-card-number', '', (value, context) => {
      const isCreditCard = context.parent.paymentType === PaymentType.CreditCard

      return isCreditCard ? value : true
    })
    .when('paymentType', {
      is: PaymentType.CreditCard,
      then: string().required(),
      otherwise: string(),
    }),
})
