import { StylesConfig } from 'react-select'
import { Theme } from '@frontend/shared/theme'

import { getBorderColor } from '../../input/base/input.styles'

export const getStyles = <OptionType, IsMulti extends boolean>(
  theme: Theme,
): Partial<StylesConfig<OptionType, IsMulti>> => ({
  control: (base, { isFocused, isDisabled, selectProps }) => ({
    ...base,
    width: '100%',
    boxShadow: theme.shadows.xs,
    background: theme.components.input.backgroundColor,
    borderWidth: '0.0625rem',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    borderStyle: 'solid',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    borderColor: getBorderColor({ theme, isFocused, touched: selectProps.touched, error: selectProps.error }),
    borderRadius: '0.5rem',
    minHeight: '2.75rem',
    outline: 'transparent',
    padding: '0 0.625rem',
    transition: theme.transition,
    opacity: isDisabled ? '0.6' : '1',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: theme.fontSizes.text.md,
    color: theme.components.input.textColor,
    fontWeight: 400,
    maxWidth: 'calc(100% - 1rem)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  menu: (base) => ({
    ...base,
    boxShadow: theme.shadows.lg,
    borderRadius: '0.5rem',
    border: '0',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 3,
  }),
  option: (base, { isDisabled, isSelected }) => ({
    ...base,
    cursor: isDisabled ? 'default' : 'pointer',
    opacity: isDisabled ? '0.6' : '1',
    backgroundColor: isSelected
      ? theme.components.select.option[':selected'].backgroundColor
      : theme.components.select.option.backgroundColor,
    color: isSelected
      ? theme.components.select.option[':selected'].textColor
      : theme.components.select.option.textColor,
    '&:hover': {
      background: theme.components.select.option[':selected'].backgroundColor,
      color: theme.components.select.option[':selected'].textColor,
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: theme.fontSizes.text.md,
    color: theme.components.input[':placeholder'].textColor,
    fontWeight: 400,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: theme.components.select.dropdownIndicator.textColor,
  }),
})
