import { Grid } from '@frontend/shared/ui'

import { styled } from '../../theme/theme-iframe/styled-components'

export const Summary = styled(Grid).attrs(() => ({
  p: 4,
}))`
  background-color: ${({ theme }) => theme.components.checkout.orderSummary.wrapper.backgroundColor};
  border-radius: 0.5rem;
`
