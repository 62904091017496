import Logger from 'js-logger'
import { captureException } from '@sentry/nextjs'
import { getEnvironmentVariable, IS_DEV } from '@frontend/shared/environment'

import { getLogLevel } from './utils'

const consoleHandler = Logger.createDefaultHandler()

Logger.setLevel(getLogLevel(getEnvironmentVariable('NEXT_PUBLIC_LOG_LEVEL')))

Logger.setHandler((messages, context) => {
  if (IS_DEV) {
    consoleHandler(messages, context)
  } else {
    for (let i = 0; i < messages.length; i += 1) {
      captureException(messages[i])
    }
  }
})

export default Logger
