import { decode } from 'blurhash'
import { IS_BROWSER } from '@frontend/shared/constants'

export const getBlurHasDataUrl = (blurHash: string | undefined, width: number, height: number, punch?: number) => {
  if (!IS_BROWSER || !blurHash) {
    return undefined
  }

  const pixels = decode(blurHash, width, height, punch)
  const canvas = document.createElement('canvas')

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')

  if (ctx) {
    const imageData = ctx?.createImageData(width, height)

    if (imageData) {
      imageData.data.set(pixels)
      ctx.putImageData(imageData, 0, 0)

      return canvas.toDataURL()
    }
  }

  return undefined
}
