import { FieldStripe, Grid, Text, Flex } from '@frontend/shared/ui'
import React from 'react'
import { IbanElement } from '@stripe/react-stripe-js'

import { useTheme } from '../../../../ui/theme/theme-iframe/styled-components'

import { PaymentTypeWrapper } from './payment-type.styles'

interface PaymentTypeSepaProps {
  active: boolean
}

const supportedCountries = ['SEPA']

export const PaymentTypeSepa = ({ active }: PaymentTypeSepaProps) => {
  const theme = useTheme()

  return (
    <Flex position='relative'>
      <PaymentTypeWrapper active={active}>
        <Grid alignItems='flex-start'>
          <FieldStripe
            Element={IbanElement}
            label='IBAN'
            name='iban'
            supportedCountries={supportedCountries}
            type='iban'
          />
        </Grid>

        <Text color={theme.components.checkout.paymentSepa.textColor} lineHeight='1.125rem' size='xs' weight={400}>
          By providing your IBAN and confirming this payment, you are authorizing Earthbanc AB. and Stripe, our payment
          service provider, to send instructions to your bank to debit your account and your bank to debit your account
          in accordance with those instructions. You are entitled to a refund from your bank under the terms and
          conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on
          which your account was debited.
        </Text>
      </PaymentTypeWrapper>
    </Flex>
  )
}
