import React, { PropsWithChildren } from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Loader } from '../../loader/root'

import {
  ButtonTypes,
  getLoaderBackgroundColor,
  getLoaderColor,
  getLoaderOpacity,
  LoaderWrapper,
} from './button-base.styles'

export type ButtonBaseProps = PropsWithChildren<{
  disabled?: boolean
  loading?: boolean
  type?: ButtonTypes
}>

export const ButtonBase = ({ children, type = 'primary', loading = false, disabled }: ButtonBaseProps) => {
  const theme = useTheme()

  return (
    <>
      {children}
      {loading && (type === 'primary' || type === 'secondaryColor' || 'secondaryGray') && (
        <LoaderWrapper disabled={disabled} hierarchy={type} loading={loading}>
          <Loader
            backgroundColor={getLoaderBackgroundColor(theme, type, disabled)}
            color={getLoaderColor(theme, type)}
            opacity={getLoaderOpacity(type)}
            size='sm'
          />
        </LoaderWrapper>
      )}
    </>
  )
}
