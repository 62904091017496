import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

/**
 * Skips required action to register OTP.
 */
export const useServiceSkipRequiredAction = () => {
  const { post, error, loading, response } = useAuthFetch<unknown>()

  const skipRequiredAction = async (email: string): Promise<boolean> => {
    const body = { email }
    await post(ENDPOINT_URL.skipRequiredAction, body)

    return response.ok
  }

  return { skipRequiredAction, error, loading }
}
