import React, { useEffect, useRef } from 'react'
import { Button, Display, FieldPassword, Form, Grid, Text } from '@frontend/shared/ui'
import { useRouter } from 'next/router'
import { FormikProps } from 'formik'
import { useBoolean } from '@frontend/shared/hooks'

import { useLogin } from '../../context'
import { LoginAction } from '../../service'
import { routes } from '../../router'

import { initialValues } from './form/initial-values'
import { validationSchema } from './form/validation-schema'
import { FormValues } from './form/types'
import { ChangePasswordSkeleton } from './components/skeleton'

export const ChangePassword = () => {
  const router = useRouter()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { action, updatePassword, loading, email } = useLogin()
  const { value: initializing, setFalse: setInitializingFalse } = useBoolean(true)

  const submitForm = async ({ passwordNew }: FormValues) => updatePassword(passwordNew, email, formikRef)

  const goToSignIn = async () => router.replace(routes.signIn.getUrl())

  const initialize = async (action: LoginAction) =>
    action === LoginAction.UpdatePassword ? setInitializingFalse() : goToSignIn()

  useEffect(() => {
    initialize(action)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  if (initializing) {
    return <ChangePasswordSkeleton />
  }

  return (
    <Grid>
      <Display data-test-id='title' size='xs' textAlign='center' weight={500}>
        Change password
      </Display>

      <Text textAlign='center'>
        To proceed to the application,
        <br /> please change your password.
      </Text>

      <Form<FormValues>
        initialValues={initialValues}
        innerRef={formikRef}
        name='change-password'
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        <Grid>
          <FieldPassword label='New password' name='passwordNew' strengthIndicator />

          <Grid gap='sm' gridTemplateColumns='100%'>
            <Button disabled={loading} htmlType='submit' loading={loading} name='finish'>
              Change password
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  )
}
