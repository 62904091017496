import { FetchProviderProps, Provider } from 'use-http'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useToast } from '@frontend/shared/ui'
import { Logger } from '@frontend/shared/logger'
import {
  STORAGE_TOKEN_EXPIRES_AT,
  COOKIE_XSRF_TOKEN,
  HEADER_XSRF_TOKEN,
  STORAGE_REFRESH_TOKEN_EXPIRES_AT,
} from '@frontend/shared/constants'
import { routes } from '@frontend/domains/plus/routes'
import { getCookie, getHashName, getLocalStorage, nonEmptyBodyAttributes } from '@frontend/shared/utils'
import { clearAuthTokens, useServiceRefreshToken } from '@frontend/domains/shared/authentication'

const { getStringValue: getXSRFToken } = getCookie<string>(COOKIE_XSRF_TOKEN)

export const Interceptor = ({ children }: PropsWithChildren<unknown>) => {
  const [refreshTokenExpirationDate, setRefreshTokenExpirationDate] = useState<Date>()
  const router = useRouter()
  const { showToastGenericError } = useToast()
  const { get: getTokenExpiresAt } = getLocalStorage<string>(STORAGE_TOKEN_EXPIRES_AT)
  const { get: getRefreshTokenExpiresAt } = getLocalStorage<string>(STORAGE_REFRESH_TOKEN_EXPIRES_AT)
  const { refreshToken } = useServiceRefreshToken()

  useEffect(() => {
    let autoLogoutInterval: NodeJS.Timer

    if (refreshTokenExpirationDate) {
      autoLogoutInterval = setInterval(async () => {
        const hash = getHashName() || router.asPath
        const refreshTokenInvalid = new Date() >= refreshTokenExpirationDate

        if (refreshTokenInvalid) {
          setRefreshTokenExpirationDate(undefined)
          clearInterval(autoLogoutInterval)
          clearAuthTokens()
          await router.replace({ pathname: routes.signIn.getUrl(), hash })
        }
      }, 1000)
    }

    return () => {
      clearInterval(autoLogoutInterval)
    }
  }, [refreshTokenExpirationDate, router])

  const options: FetchProviderProps['options'] = {
    interceptors: {
      request: async (requestParams) => {
        const { options } = requestParams || {}
        const headers = new Headers(options.headers)

        const tokenExpiresAt = getTokenExpiresAt()
        const refreshTokenExpiresAt = getRefreshTokenExpiresAt()

        const authTokenExpirationDate = tokenExpiresAt ? new Date(tokenExpiresAt) : new Date()
        const authTokenMinuteBeforeExpiration = new Date(authTokenExpirationDate.getTime() - 60000)

        const refreshTokenExpirationDate = refreshTokenExpiresAt ? new Date(refreshTokenExpiresAt) : new Date()
        const refreshTokenValid = new Date() < refreshTokenExpirationDate

        refreshTokenExpiresAt && setRefreshTokenExpirationDate(refreshTokenExpirationDate)

        if (refreshTokenValid && authTokenMinuteBeforeExpiration < new Date()) {
          await refreshToken()
        }

        const xsrfToken = getXSRFToken()
        xsrfToken && headers.set(HEADER_XSRF_TOKEN, xsrfToken)

        options.headers = headers

        options?.body
          && typeof options.body === 'string'
          && Object.assign(options, { body: nonEmptyBodyAttributes(options.body) })

        return options
      },
      response: async ({ response }) => {
        if (response.status === 401) {
          const hash = getHashName() || router.asPath

          await router.replace({ pathname: routes.signIn.getUrl(), hash, query: router.query })

          return response
        }

        if (500 <= response.status) {
          Logger.error(response)
          showToastGenericError()
          await router.replace(routes.error.getUrl())
        }

        return response
      },
    },
  }

  return <Provider options={options}>{children}</Provider>
}
