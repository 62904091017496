import React from 'react'
import { useTheme } from '@frontend/shared/theme'
import { Logger } from '@frontend/shared/logger'

import { Flex } from '../../../layout/flex/flex'
import { Text } from '../../../text/typography'
import { Badge } from '../root/badge'

import {
  BadgeGroupColor,
  badgeGroupFontSize,
  BadgeGroupSize,
  BadgeGroupWrapper,
  BadgeGroupWrapperProps,
  badgeSize,
  titleColor,
} from './badge-group.styles'

export interface BadgeGroupProps extends BadgeGroupWrapperProps {
  size: BadgeGroupSize
  title: string
}

const badgeText = (color: BadgeGroupColor) => {
  switch (color) {
    case 'gray':
    case 'primary':
      return 'New feature'
    case 'error':
      return 'Error'
    case 'warning':
      return 'Warning'
    case 'success':
      return 'Success'
    default:
      Logger.error(`Implement new badge text for alert component: ${color}`)

      return 'New feature'
  }
}

export const BadgeGroup = ({ title, color, size }: BadgeGroupProps) => {
  const theme = useTheme()

  return (
    <BadgeGroupWrapper
      alignItems={['flex-start', 'center']}
      color={color}
      flex='0 auto'
      flexDirection={['column', 'row']}
      mr={1}
    >
      <Badge color={color} label={badgeText(color)} size={badgeSize[size]} />
      <Flex alignItems='center' mx={1} my={[1, '0rem']}>
        <Text color={titleColor({ theme, color })} size={badgeGroupFontSize[size]}>
          {title}
        </Text>
      </Flex>
    </BadgeGroupWrapper>
  )
}
