import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DATE_FORMAT } from '@frontend/shared/constants'

export const dateTimeReviver = (key: string, value: unknown) => {
  dayjs.extend(customParseFormat)

  if (typeof value === 'string' && dayjs(value, DATE_FORMAT.dateISO).isValid()) {
    return dayjs(value).toDate()
  }

  return value
}
