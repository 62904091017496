import { useTheme } from '@frontend/shared/theme'
import React, { ChangeEvent, FocusEvent, useRef } from 'react'

import { Flex } from '../../../../layout/flex/flex'
import { Text } from '../../../../text'

import { Input, RadioType, RadioWrapper } from './input-radio.styles'

export interface InputRadioProps {
  checked: string
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
  disabled?: boolean
  error?: string
  hover?: boolean
  label?: string
  markType?: RadioType
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  touched?: boolean
}

export const InputRadio = ({
  label,
  name,
  checked,
  value,
  disabled = false,
  error,
  touched,
  markType = 'circle',
  onChange,
  hover,
  onBlur,
}: InputRadioProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)
  const theme = useTheme()
  const id = `radio-button-${name}-${value}`

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }

    onChange(event)
  }

  return (
    <RadioWrapper>
      <Input
        checked={value === checked}
        disabled={disabled}
        hasError={!!error}
        hover={hover}
        id={id}
        markType={markType}
        name={name}
        pure={!label}
        ref={ref}
        type='radio'
        value={value}
        onBlur={onBlur}
        onChange={changeHandler}
      />
      <label htmlFor={id}>
        <Text>{label}</Text>

        {touched && error && (
          <Flex pt={2}>
            <Text color={theme.colors.error['500']}>{error}</Text>
          </Flex>
        )}
      </label>
    </RadioWrapper>
  )
}
