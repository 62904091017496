import { useBreakpoints, useToast } from '@frontend/shared/ui'
import { Logger } from '@frontend/shared/logger'
import { getEnvironmentVariable } from '@frontend/shared/environment'
import { useTheme } from '@frontend/shared/theme'
import { useEffect, useRef } from 'react'
import { CONTENT_SECURITY_POLICY_NONCE } from '@frontend/shared/constants'
import { useBoolean } from '@frontend/shared/hooks'

declare global {
  interface Window {
    captchaOnLoad: () => void
    grecaptcha: ReCaptchaInstance
  }
}

export interface ReCaptchaInstance {
  execute: (options: ReCaptchaExecuteOptions) => Promise<string>
  ready: (cb: () => void) => void
  render: (id: string, options: ReCaptchaRenderOptions) => void
}

interface GoogleRecaptchaProps {
  onDemand?: boolean
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: 'invisible'
}

const url = 'https://www.google.com/recaptcha/api.js'
const queryString = '?onload=captchaOnLoad&render=explicit'
const gRecaptchaId = 'g-recaptcha'
const sitePublicKey = getEnvironmentVariable('NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY')

const useWidgetBuilder = () => {
  const theme = useTheme()
  const { isXs, isSm } = useBreakpoints({ breakpoints: theme.breakpoints })
  const widgetRef = useRef<HTMLDivElement>()
  const { value: recaptchaLoaded, setTrue: setRecaptchaLoadedTrue } = useBoolean(false)

  const onLoadCallback = () => {
    const noRecaptcha = !document.getElementById(gRecaptchaId)

    if (widgetRef.current && noRecaptcha) {
      widgetRef.current.id = gRecaptchaId
      document.body.appendChild(widgetRef.current)

      window.grecaptcha.render(gRecaptchaId, { sitekey: sitePublicKey, size: 'invisible' })
      window.grecaptcha.ready(setRecaptchaLoadedTrue)
    }
  }

  useEffect(() => {
    widgetRef.current = document.createElement('div')
    widgetRef.current.style.position = 'fixed'
    widgetRef.current.style.zIndex = '100'

    return () => widgetRef.current && widgetRef.current.remove()
  }, [])

  useEffect(() => {
    if (recaptchaLoaded && widgetRef.current?.firstChild) {
      ;(widgetRef.current.firstChild as HTMLDivElement).style.bottom = isXs || isSm ? '2rem' : '13rem'
    }
  }, [isSm, isXs, recaptchaLoaded])

  return { recaptchaLoaded, onLoadCallback }
}

export const useGoogleRecaptcha = ({ onDemand = false }: GoogleRecaptchaProps = {}) => {
  const scriptRef = useRef<HTMLScriptElement>()
  const { showToastWarning, showToastGenericError } = useToast()
  const { recaptchaLoaded, onLoadCallback } = useWidgetBuilder()
  const { value: alreadyLoaded, setTrue: setAlreadyLoadedTrue } = useBoolean(false)

  const recaptchaReady = !sitePublicKey || recaptchaLoaded || alreadyLoaded

  const initializeScript = () => {
    scriptRef.current = document.createElement('script')

    const gRecaptchaElement = document.getElementById(gRecaptchaId)

    if (sitePublicKey && !gRecaptchaElement) {
      window.captchaOnLoad = onLoadCallback
      scriptRef.current.src = url + queryString
      scriptRef.current.async = true
      scriptRef.current.defer = true
      scriptRef.current.nonce = CONTENT_SECURITY_POLICY_NONCE

      document.body.appendChild(scriptRef.current)
    } else {
      setAlreadyLoadedTrue()
    }
  }

  useEffect(() => {
    !onDemand && initializeScript()

    return () => {
      scriptRef.current && scriptRef.current.remove()
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRecaptchaToken = async (action: string): Promise<string | undefined> => {
    if (!sitePublicKey) {
      return 'some'
    }

    if (!(recaptchaLoaded || alreadyLoaded)) {
      showToastWarning('ReCaptcha failed', 'Recaptcha could not be verified. Please, try it again later.')

      return undefined
    }

    try {
      return await window.grecaptcha.execute({ action })
    } catch (error) {
      Logger.error(error)
      showToastGenericError()

      return undefined
    }
  }

  return { recaptchaReady, getRecaptchaToken, initializeScript }
}
