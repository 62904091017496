import { css, styled } from '@frontend/shared/theme'

import { Grid } from '../../layout/grid/grid'

export interface DetailWrapperProps {
  type?: 'item' | 'heading'
}

export const DetailWrapper = styled(Grid).attrs(() => ({
  flexDirection: 'column',
  py: '0.2rem',
  px: '1rem',
}))<DetailWrapperProps>`
  ${({ type }) =>
    type === 'item'
    && css`
      border-radius: 0.5rem;
      transition: all ${({ theme }) => theme.transition};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray['100']};
        box-shadow: ${({ theme }) => theme.shadows.xs};
      }
    `}
`
