import {
  Button,
  Field,
  FieldCheckbox,
  FieldRadioTabGroup,
  FieldSelect,
  Flex,
  Form,
  Grid,
  Icon,
  Section,
  Text,
  useBreakpoints,
  useToast,
} from '@frontend/shared/ui'
import React from 'react'
import { useRouter } from 'next/router'
import { formatNumber, formatPrice, scrollToTop } from '@frontend/shared/utils'
import { VerificationPeriod, Project, SellingUnit, SubscriptionType } from '@frontend/shared/types'
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Logger } from '@frontend/shared/logger'
import { StripeIbanElement } from '@stripe/stripe-js'
import { useBoolean } from '@frontend/shared/hooks'

import { useTheme } from '../../../ui/theme/theme-iframe'
import { useQueryParserProjectCheckout } from '../../../hooks'
import { Summary } from '../../../ui/layout/summary/summary'
import { CURRENCY } from '../../../constants'
import { Routes } from '../../../types'
import { isVintageValid } from '../../../utils'
import stripeBadge from '../../../ui/assets/images/stripe-badge-white.png'

import { PaymentTypeSepa } from './payment-type/payment-type-sepa'
import { PaymentTypeCreditCard } from './payment-type/payment-type-credit-card'
import { FormValues, PaymentType } from './form/types'
import { getInitialValues, paymentTypeOptions } from './form/initial-values'
import { validationSchema } from './form/validation-schema'
import { useServiceCreatePaymentIntent } from './services/use-service-create-payment-intent'
import { useServiceConfirmCardPayment } from './services/use-service-confirm-card-payment'
import { useServiceConfirmSepaPayment } from './services/use-service-confirm-sepa-payment'
import { useServiceCreatePaymentMethod } from './services/use-service-create-payment-method'

interface OnPaymentSuccess {
  gift?: boolean
  paymentCard?: boolean
}

interface FormPaymentProps {
  price: number
  project: Project
  routes: Routes
  emailDefault?: string
  onPaymentSuccess?: (params?: OnPaymentSuccess) => void
  verificationPeriod?: VerificationPeriod
}

export const FormPayment = ({
  emailDefault,
  project,
  price,
  onPaymentSuccess,
  routes,
  verificationPeriod,
}: FormPaymentProps) => {
  const theme = useTheme()
  const router = useRouter()
  const stripe = useStripe()
  const elements = useElements()
  const { isXs, isSm } = useBreakpoints({ breakpoints: theme.breakpoints })
  const { createPaymentIntent } = useServiceCreatePaymentIntent()
  const { confirmCardPayment } = useServiceConfirmCardPayment()
  const { confirmSepaPayment } = useServiceConfirmSepaPayment()
  const { createPaymentMethod } = useServiceCreatePaymentMethod()
  const { showToastSuccess, showToastGenericError, showToastError } = useToast()

  const isMobile = isXs || isSm

  const { value: loading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean(false)

  const queryParams = useQueryParserProjectCheckout(router.query)

  const initialValues = getInitialValues({
    gift: queryParams.gift,
    subscription: queryParams.gift ? SubscriptionType.Onetime : queryParams.subscription,
    emailDefault,
    email: queryParams.email,
    name: queryParams.name,
  })

  const subscriptionOptions = [
    { label: `One off - ${formatPrice(price)}`, value: SubscriptionType.Onetime },
    { label: `Monthly subscription - ${formatPrice(price)} monthly`, value: SubscriptionType.Monthly },
  ]

  const submitForm = async ({ name, email, paymentType, subscription, gift }: FormValues) => {
    if (!stripe || !elements) {
      return
    }

    if (
      project.sellingUnit === SellingUnit.CarbonTonne
      && verificationPeriod?.vintages
      && queryParams?.vintage
      && !isVintageValid(queryParams.vintage, verificationPeriod?.vintages)
    ) {
      showToastError('Missing vintage', 'Vintage you try to submit is invalid')

      return
    }

    setLoadingTrue()

    const paymentCard = paymentType === PaymentType.CreditCard
    const cardElement = paymentCard ? elements.getElement(CardElement) : elements.getElement(IbanElement)

    if (!cardElement) {
      setLoadingFalse()
      Logger.error('Card element was not found')
      scrollToTop()
      showToastGenericError()

      return
    }

    const emailTrimmed = email.trim()
    const paymentMethod = await createPaymentMethod({ email: emailTrimmed, name, paymentType })

    const intentData = await createPaymentIntent({
      quantity: queryParams.quantity,
      currency: CURRENCY,
      email: emailTrimmed,
      gift,
      name,
      paymentMethod: paymentMethod?.type,
      projectTicker: queryParams.ticker,
      sellingUnit: project.sellingUnit,
      subscription: subscription === SubscriptionType.Monthly,
      vintage: queryParams.vintage,
    })

    if (intentData?.clientSecret && paymentMethod?.id) {
      let paymentIntent

      switch (paymentType) {
        case PaymentType.Sepa:
          paymentIntent = await confirmSepaPayment({
            clientSecret: intentData.clientSecret,
            iban: cardElement as StripeIbanElement,
            name,
            email,
          })
          break
        case PaymentType.CreditCard:
          paymentIntent = await confirmCardPayment({
            clientSecret: intentData.clientSecret,
            paymentId: paymentMethod.id,
          })
          break
        default:
          Logger.error(`Someone used invalid payment type ${paymentType}`)
          break
      }

      if (paymentIntent) {
        showToastSuccess(
          'Payment successful',
          'Thank you for your payment, it is being processed. See your email for more details about your order.',
        )

        await (onPaymentSuccess
          ? onPaymentSuccess({ paymentCard, gift })
          : router.replace(routes.carbonOffsetProjectList.getUrl(router.query)))
      }
    }

    scrollToTop()
    setLoadingFalse()
  }

  return (
    <Form initialValues={initialValues} name='checkout' validationSchema={validationSchema} onSubmit={submitForm}>
      {({ values, setFieldValue }) => (
        <Grid gridTemplateColumns={['100%', null, null, null, '3fr 24rem']}>
          <Grid>
            <Section>
              <Text size='xl'>Payment method</Text>

              <FieldRadioTabGroup
                gridTemplateColumns={['100%', '1fr 1fr']}
                name='paymentType'
                options={paymentTypeOptions}
              />

              <Text size='xl'>Payment details</Text>

              <Grid gap='sm'>
                <Form.Group>
                  <Field label={values.paymentType === PaymentType.CreditCard ? 'Name on card' : 'Name'} name='name' />

                  <Field
                    disabled={!!emailDefault}
                    label='Email'
                    name='email'
                    placeholder='carbon.neutral@earthbanc.io'
                  />
                </Form.Group>

                <PaymentTypeSepa active={values.paymentType === PaymentType.Sepa} />
                <PaymentTypeCreditCard active={values.paymentType === PaymentType.CreditCard} />
              </Grid>
            </Section>

            {isMobile ? (
              <Flex justifyContent='center'>
                <img alt='Stripe secure badge' src={stripeBadge} width='100%' />
              </Flex>
            ) : (
              <Section>
                <Flex justifyContent='center'>
                  <img alt='Stripe secure badge' src={stripeBadge} width='70%' />
                </Flex>
              </Section>
            )}
          </Grid>

          <Section gridAutoRows='max-content'>
            <Text data-test-id='order-summary-title' size='xl'>
              Order Summary
            </Text>

            <Summary gap='md'>
              <Text
                color={theme.components.checkout.orderSummary.name.textColor}
                data-test-id='order-summary-name'
                size='md'
                weight={600}
              >
                {project?.name}
              </Text>

              <Grid gap='sm'>
                {project.sellingUnit === SellingUnit.CarbonTonne && (
                  <Flex justifyContent='space-between'>
                    <Text
                      color={theme.components.checkout.orderSummary.totalAmount.textColor}
                      data-test-id='order-summary-vintage-title'
                      size='md'
                    >
                      Vintage
                    </Text>
                    <Text
                      color={theme.components.checkout.orderSummary.totalAmount.textColor}
                      data-test-id='order-summary-vintage-value'
                      size='md'
                    >
                      {queryParams.vintage}
                    </Text>
                  </Flex>
                )}

                <Flex justifyContent='space-between'>
                  <Text
                    color={theme.components.checkout.orderSummary.totalAmount.textColor}
                    data-test-id='order-summary-amount-co2-title'
                    size='md'
                  >
                    Total amount of {project.sellingUnit === SellingUnit.Tree ? 'trees' : 'CO2'}
                  </Text>
                  <Text
                    color={theme.components.checkout.orderSummary.totalAmount.textColor}
                    data-test-id='order-summary-amount-co2-value'
                    size='md'
                  >
                    {formatNumber(queryParams?.quantity)} {project.sellingUnit === SellingUnit.Tree ? '' : 't'}
                  </Text>
                </Flex>

                <Flex justifyContent='space-between'>
                  <Text
                    color={theme.components.checkout.orderSummary.totalPrice.textColor}
                    data-test-id='order-summary-total-title'
                    size='md'
                    weight={600}
                  >
                    Total
                  </Text>
                  <Text
                    color={theme.components.checkout.orderSummary.totalPrice.textColor}
                    data-test-id='order-summary-total-value'
                    size='md'
                    weight={600}
                  >
                    {formatPrice(price)}
                  </Text>
                </Flex>
              </Grid>
            </Summary>

            <Grid alignItems='flex-start' gap='md'>
              <FieldCheckbox
                label='Buy as a gift'
                name='gift'
                onChange={(event) => event.target.checked && setFieldValue('subscription', SubscriptionType.Onetime)}
              />
              <FieldSelect disabled={values.gift} name='subscription' options={subscriptionOptions} />
            </Grid>

            <Grid>
              <Button disabled={loading} htmlType='submit' loading={loading} name='pay'>
                <Flex mr={1}>
                  <Icon kind='FiLock' />
                </Flex>{' '}
                Pay {formatPrice(price)}
              </Button>
            </Grid>
          </Section>
        </Grid>
      )}
    </Form>
  )
}
