import { FieldRadioTabGroupProps } from '@frontend/shared/ui'
import { SubscriptionType } from '@frontend/shared/types'

import { PaymentType } from './types'

export const paymentTypeOptions: FieldRadioTabGroupProps['options'] = [
  {
    value: PaymentType.Sepa,
    title: 'SEPA',
    icon: 'FiDollarSign',
  },
  {
    value: PaymentType.CreditCard,
    title: 'Credit Card',
    icon: 'FiCreditCard',
  },
]

interface InitialValues {
  gift: boolean
  subscription: SubscriptionType
  email?: string
  emailDefault?: string
  name?: string
}

export const getInitialValues = ({ emailDefault, subscription, gift, name, email }: InitialValues) => ({
  paymentType: PaymentType.CreditCard,
  name: name ?? '',
  email: (email || emailDefault) ?? '',
  gift,
  subscription,
  iban: undefined,
  cardNumber: undefined,
})
