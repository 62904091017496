import React, { AnchorHTMLAttributes, forwardRef } from 'react'
import { LinkProps } from 'next/link'

import { ButtonBase, ButtonBaseProps } from '../base/button-base'
import { StyledAnchor, StyledCustomButtonBaseProps } from '../base/button-base.styles'

interface CustomAnchorProps extends LinkProps, Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'download'> {
  testID?: string
}

export type AnchorProps = ButtonBaseProps & Partial<StyledCustomButtonBaseProps> & CustomAnchorProps

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    {
      children,
      type = 'primary',
      icon = 'none',
      loading = false,
      destructive = false,
      size = 'md',
      disabled,
      target,
      onClick,
      href,
      testID,
      download,
    },
    ref,
  ) => (
    <StyledAnchor
      clickable
      data-test-id={testID}
      destructive={destructive}
      download={download}
      hierarchy={type}
      href={href}
      icon={icon}
      loading={loading}
      ref={ref}
      size={size}
      target={target}
      onClick={onClick}
    >
      <ButtonBase disabled={disabled} loading={loading} type={type}>
        {children}
      </ButtonBase>
    </StyledAnchor>
  ),
)
