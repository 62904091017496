import { Flex, Grid, Icon, Section, Tooltip } from '@frontend/shared/ui'
import React, { PropsWithChildren } from 'react'
import { useTheme } from '@frontend/shared/theme'

import { CardTitle, CardTitleProps } from './components/card-title'
import { CardNumber } from './components/card-number'
import { CardSuffix } from './components/card-suffix'

export interface CardProps {
  id: string
  number: number | string
  title: string
  onTitleDoubleClick?: CardTitleProps['onDoubleClick']
  suffix?: string
  tooltip?: string
}

const CardComponent = ({
  title,
  suffix,
  number,
  id,
  tooltip,
  children,
  onTitleDoubleClick,
}: PropsWithChildren<CardProps>) => {
  const theme = useTheme()

  return (
    <Section gridAutoRows='max-content'>
      <Grid gap='sm' gridTemplateColumns='1fr auto'>
        <CardTitle onDoubleClick={onTitleDoubleClick}>{title}</CardTitle>
        {tooltip && (
          <>
            <Flex data-tooltip-content={tooltip} display='inline-block' id={id} justifyContent='center' ml={4}>
              <Icon color={theme.colors.gray['400']} kind='FiHelpCircle' />
            </Flex>
            <Tooltip anchorId={id} variant='dark' />
          </>
        )}
      </Grid>
      <Grid alignItems='flex-end' gap='sm' gridTemplateColumns='max-content max-content'>
        <CardNumber>{number}</CardNumber>
        {suffix && <CardSuffix>{suffix}</CardSuffix>}
      </Grid>
      {children}
    </Section>
  )
}

export const Card = Object.assign(CardComponent, { Number: CardNumber, Suffix: CardSuffix, Title: CardTitle })
