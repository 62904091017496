/* eslint-disable promise/no-nesting */
import { ReactNode } from 'react'
import { Logger } from '@frontend/shared/logger'

type LazyComponent = () => Promise<ReactNode>

export const dynamicImport = (lazyComponent: LazyComponent, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 0) {
            reject(error)

            return
          }

          dynamicImport(lazyComponent, attemptsLeft - 1)
            .then(resolve, reject)
            .catch((error) => Logger.error(error))
        }, 1500)
      })
  })
}
