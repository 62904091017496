import dynamic from 'next/dynamic'

import { InputPhoneProps } from './phone/input-phone'

const InputPhone = dynamic<InputPhoneProps>(() => import('./phone/input-phone').then((module) => module.InputPhone), {
  ssr: false,
})

export * from './checkbox'
export * from './date'
export * from './file-upload'
export * from './mask'
export * from './radio'
export * from './root'
export { InputPhone }
