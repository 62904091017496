import { SvgProps } from '@frontend/shared/types'
import React from 'react'

export const TwitterSolid = ({ color }: SvgProps) => (
  <svg fill='none' height='45' viewBox='0 0 45 45' width='45' xmlns='http://www.w3.org/2000/svg'>
    <title>Earthbanc - twitter</title>
    <circle cx='22.171' cy='22.545' fill='#00ACED' r='21.8839' />
    <path
      d={`M30.8629 18.4261C30.8768 18.6455 30.8768 18.865 30.8768 19.0844C30.8768 25.7763 26.364 33.487 18.1158 33.487C15.5747 33.487 13.2142 32.6563 11.2285 31.2145C11.5896 31.2615 11.9367 31.2772 12.3116 31.2772C14.4083 31.2772 16.3385 30.478 17.8798 29.1145C15.908 29.0674 14.2556 27.61 13.6863 25.6039C13.964 25.6509 14.2417 25.6823 14.5333 25.6823C14.936 25.6823 15.3387 25.6196 15.7136 25.5099C13.6585 25.0397 12.1172 23.0024 12.1172 20.5419V20.4792C12.7142 20.8553 13.4086 21.0904 14.1445 21.1217C12.9364 20.2127 12.1449 18.6612 12.1449 16.9059C12.1449 15.9656 12.3671 15.1036 12.7559 14.3514C14.9637 17.4231 18.2825 19.4291 22.0038 19.6485C21.9344 19.2724 21.8927 18.8806 21.8927 18.4888C21.8927 15.6992 23.8923 13.4268 26.3778 13.4268C27.6692 13.4268 28.8356 14.038 29.6548 15.0253C30.6685 14.8059 31.6405 14.3827 32.5014 13.8029C32.1681 14.9783 31.46 15.9657 30.5296 16.5925C31.4322 16.4828 32.307 16.2007 33.1124 15.8089C32.5015 16.8119 31.7377 17.7052 30.8629 18.4261Z`}
      fill={color ?? 'white'}
    />
  </svg>
)
