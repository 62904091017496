import { useStripe } from '@stripe/react-stripe-js'
import { PaymentIntent } from '@stripe/stripe-js'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'

interface ConfirmCardPayment {
  clientSecret: string
  paymentId: string
}

export const useServiceConfirmCardPayment = () => {
  const { showToastWarning, showToastGenericError } = useToast()
  const stripe = useStripe()

  const confirmCardPayment = async ({
    paymentId,
    clientSecret,
  }: ConfirmCardPayment): Promise<PaymentIntent | undefined> => {
    if (!stripe) {
      return undefined
    }

    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentId,
      })

      if (result?.error) {
        showToastWarning('There was a problem with your payment', result?.error?.message ?? '')

        return undefined
      }

      return result.paymentIntent
    } catch (error) {
      Logger.error(error)
      showToastGenericError()

      return undefined
    }
  }

  return { confirmCardPayment }
}
