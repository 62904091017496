import { styled } from '@frontend/shared/theme'

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 100%;

  form {
    max-width: 100%;
  }
`
