/**
 * Remove all attributes from body which value is empty string
 */
export const nonEmptyBodyAttributes = (body: string) => {
  const parsedBodyEntries = Object.entries(JSON.parse(body))

  return JSON.stringify(
    parsedBodyEntries.reduce((acc, [attribute, value]) => (value !== '' ? { ...acc, [attribute]: value } : acc), {}),
  )
}
