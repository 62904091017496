'use client'

import React, { useState, KeyboardEvent, useEffect, useCallback } from 'react'
import { KEY } from '@frontend/shared/constants'
import { useRouter } from 'next/router'
import { SingleValue } from 'react-select'
import { useTheme } from '@frontend/shared/theme'
import { getHashName, getWindow } from '@frontend/shared/utils'

import { Grid } from '../../layout/grid/grid'
import { Responsive } from '../../layout/responsive/responsive'
import { isOptionType, OptionType, Select } from '../../form/select'

import { getTabId, SelectOption } from './components/helpers'
import { getTabsSelectStyles, Tab, TabsWrapper } from './tabs.styles'
import { TabsContent } from './components/tabs-content'

const mapOptionsForSelect = (options: TabsProps['options']): SelectOption[] =>
  options.map(({ label, disabled, name }) => ({ value: name, label, isDisabled: disabled }))

export interface TabOption {
  label: string
  name: string
  disabled?: boolean
  visible?: boolean
}

export interface TabsProps {
  options: TabOption[]
  defaultTabName?: string
  testID?: string
}

const TabsComponent = ({ options, defaultTabName, testID }: TabsProps) => {
  const theme = useTheme()
  const router = useRouter()
  const clientWindow = getWindow()

  const [activeTabName, setActiveTabName] = useState(
    () => (clientWindow?.location?.hash ? getHashName() : defaultTabName) || options[0].name,
  )

  const optionsForSelect = mapOptionsForSelect(options)

  const changeTab = useCallback(
    (tabName: string) => {
      clientWindow.location.hash = tabName
      setActiveTabName(tabName)
    },
    [clientWindow.location],
  )

  const selectChangeHandler = (value: SingleValue<OptionType<string>>) =>
    isOptionType<OptionType<string>>(value) && changeTab(value.value)

  useEffect(() => {
    router.beforePopState(({ as }) => {
      const hash = as.substring(as.indexOf('#')).replace('#', '')
      const tabExist = options.find(({ name }) => name === hash)

      tabExist ? changeTab(hash) : changeTab(defaultTabName ?? options[0].name)

      return true
    })
  }, [changeTab, defaultTabName, options, router])

  return (
    <Grid>
      <Responsive hide='sm'>
        <TabsWrapper data-test-id={`${testID}-tabs`} role='tablist'>
          {options
            .filter(({ visible = true }) => visible)
            .map(({ label, disabled, name }) => {
              const tabClickHandler = () => !disabled && changeTab(name)
              const labelKeyDownHandler = (e: KeyboardEvent<HTMLDivElement>) =>
                (e.key === KEY.spacebar || e.key === KEY.enter) && !disabled && changeTab(name)

              return (
                <Tab
                  aria-controls={getTabId(name)}
                  aria-disabled={disabled}
                  aria-selected={activeTabName === name}
                  data-test-id={`tab-${name}`}
                  disabled={disabled}
                  id={`tab-${name}`}
                  key={label}
                  role='tab'
                  selected={activeTabName === name}
                  tabIndex={disabled ? -1 : 0}
                  onClick={tabClickHandler}
                  onKeyDown={labelKeyDownHandler}
                >
                  {label}
                </Tab>
              )
            })}
        </TabsWrapper>
      </Responsive>
      <Responsive show='sm'>
        <Select<string>
          aria-controls={getTabId(activeTabName)}
          id={`tab-${activeTabName}`}
          options={optionsForSelect}
          styles={getTabsSelectStyles(theme)}
          value={optionsForSelect.find(({ value }) => value === activeTabName)}
          onChange={selectChangeHandler}
        />
      </Responsive>
    </Grid>
  )
}

/**
 * Tabs and TabsContent are independent between each other
 * Tabs - can be used anywhere you need to show tabs
 * Tabs.TabsContent - can be used anywhere you need to put content
 */
export const Tabs = Object.assign(TabsComponent, { Content: TabsContent })
