import Cookies, { CookieAttributes } from 'js-cookie'

export const getCookie = <StoredValue>(key: string, defaultOptions?: CookieAttributes) => ({
  setStringValue(value: string, options?: CookieAttributes): void {
    const { sameSite = 'strict', ...restOptions } = defaultOptions || {}

    Cookies.set(key, value, options ?? { sameSite, ...restOptions })
  },
  setMixedValue(value: StoredValue, options?: CookieAttributes): void {
    const { sameSite = 'strict', ...restOptions } = defaultOptions || {}

    Cookies.set(key, JSON.stringify(value), options ?? { sameSite, ...restOptions })
  },
  getStringValue(): string | undefined {
    return Cookies.get(key)
  },
  getMixedValue(): StoredValue | undefined {
    const value = Cookies.get(key)

    return value ? JSON.parse(value) : undefined
  },
  remove(): void {
    Cookies.remove(key)
  },
})
