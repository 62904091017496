import { styled } from '@frontend/shared/theme'

import { Flex } from '../../layout/flex/flex'

export const AvatarWrapper = styled(Flex)`
  position: relative;
`

export const KebabMenuWrapper = styled(Flex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))`
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  width: 1rem;
  height: 1rem;
  background-color: ${({ theme }) => theme.colors.primary.darkBlue.main};
  border-radius: 50%;
  transition: color ${({ theme }) => theme.transition};
`
