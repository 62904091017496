import {
  Formik,
  Form as FormikForm,
  FormikHelpers,
  FormikErrors,
  FormikTouched,
  FormikProps,
  FormikConfig,
  FormikValues,
} from 'formik'
import React, { MutableRefObject, ReactNode, Ref } from 'react'

import { FormWrapper } from './form.styles'
import { FormGroup } from './components/form-group'

interface FormProps<Values> {
  initialValues: Values
  name: string
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void
  children?: ((props: FormikProps<Values>) => ReactNode) | ReactNode
  enableReinitialize?: boolean
  initialErrors?: FormikErrors<Values>
  initialTouched?: FormikTouched<Values>
  innerRef?: Ref<FormikProps<Values>> | MutableRefObject<FormikProps<Values>>
  validationSchema?: FormikConfig<Values>['validationSchema']
}

const FormComponent = <Values extends FormikValues>({
  initialValues,
  enableReinitialize = false,
  onSubmit,
  children,
  initialErrors,
  initialTouched,
  validationSchema,
  innerRef,
  name,
}: FormProps<Values>) => (
  <FormWrapper>
    <Formik<Values>
      enableReinitialize={enableReinitialize}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      initialValues={initialValues}
      innerRef={innerRef}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <FormikForm method='post' name={`form-${name}`}>
          {typeof children === 'function' ? children(formikProps) : children}
        </FormikForm>
      )}
    </Formik>
  </FormWrapper>
)
export const Form = Object.assign(FormComponent, { Group: FormGroup })
