import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { LoaderFullscreen } from '@frontend/shared/ui'
import { Customer, UserRole } from '@frontend/shared/types'
import { useBoolean } from '@frontend/shared/hooks'

import { useServiceSignOut } from '../../service/login/use-service-sign-out'
import { useServiceCustomerLazy } from '../../service/use-service-customer'

interface AuthenticationContextValue {
  admin: boolean
  setCustomer(customer: Customer): void
  signOut(): void
  customer?: Customer
}

interface AuthenticationProviderProps {
  isAppSection(path: string): boolean
}

export const ContextAuthentication = createContext<AuthenticationContextValue>({
  customer: undefined,
  setCustomer: () => null,
  signOut: () => null,
  admin: false,
})

export const useAuthentication = () => useContext(ContextAuthentication)

export const AuthenticationProvider = ({ children, isAppSection }: PropsWithChildren<AuthenticationProviderProps>) => {
  const router = useRouter()
  const { value: loading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean(true)
  const [customer, setCustomer] = useState<Customer>()
  const { signOut } = useServiceSignOut()
  const serviceCustomer = useServiceCustomerLazy()
  const admin = !!customer?.roles?.includes(UserRole.ADMIN_LAND_REGISTER)

  const verifySession = useCallback(
    async (pathname?: string) => {
      setLoadingTrue()

      if (isAppSection(pathname ?? router.pathname)) {
        const customer = await serviceCustomer.getCustomer()

        customer && setCustomer(customer)
      }

      setLoadingFalse()
    },
    [setLoadingTrue, isAppSection, router.pathname, setLoadingFalse, serviceCustomer],
  )

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const signedOut = !customer
      const parsedUrl = url.match(/^([a-zA-Z-0-9/-]+)[?|#]/m)
      const pathname = parsedUrl?.[1]

      pathname && signedOut && isAppSection(pathname) && verifySession(pathname)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    verifySession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = useMemo(() => ({ signOut, customer, setCustomer, admin }), [signOut, customer, admin])

  return (
    <ContextAuthentication.Provider value={value}>
      {loading ? <LoaderFullscreen /> : children}
    </ContextAuthentication.Provider>
  )
}
