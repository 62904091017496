import { addMethod, string, StringSchema } from 'yup'

addMethod<StringSchema>(string, 'lowerLimit', function lowerLimit(min: number, message?: string) {
  return this.test('lower-limit', message ?? `Minimum is ${min}`, (value) => {
    if (!value) {
      return true
    }

    return !(parseFloat(value) < min)
  })
})
