import { styled } from '@frontend/shared/theme'
import { color, padding, ColorProps } from 'styled-system'

import { Grid } from '../grid/grid'

export interface SectionProps extends ColorProps {
  noPadding?: boolean
}

export const Section = styled(Grid).attrs<SectionProps>(({ noPadding, ...props }) => ({
  ...props,
  p: noPadding ? '0rem' : '1.5rem',
}))<SectionProps>`
  border: 0.0625rem solid ${({ theme }) => theme.components.section.borderColor};
  background-color: ${({ theme }) => theme.components.section.backgroundColor};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.md};
  ${padding};
  ${color};
`
