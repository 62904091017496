/**
 * ORIGINAL - full
 * LARGE - 1000px
 * MEDIUM - 750px
 * SMALL - 500 px
 * THUMBNAIL - 250px
 */
export type ImageFormat = 'ORIGINAL' | 'LARGE' | 'MEDIUM' | 'SMALL' | 'THUMBNAIL'

export interface Image {
  hash: string
  id: number
}

export interface Rating {
  type: RatingType
  value: string
}

export enum RatingType {
  Additionality = 'additionality',
  EB = 'EB',
  GS = 'GS',
  Leakage = 'leakage',
  Permanence = 'permanence',
  RatingEarthbanc = 'ratingEarthbanc',
  RatingEsg = 'ratingEsg',
  VCS = 'VCS',
}

interface CreditingPeriodRange {
  from: string
  to: string
}

interface VerificationPeriodRange {
  from: string
  to: string
}

export interface Vintage {
  availableQuantity: number
  externalId: string
  priceEur: number
  vintage: string
}

export interface CreditingPeriod {
  range: CreditingPeriodRange
}

export interface VerificationPeriod {
  creditClassVersionFirst: boolean
  methodologyVersionFirst: boolean
  range: VerificationPeriodRange
  vintages: Vintage[]
  creditClassName?: string
  creditClassVersion?: string
  methodologyName?: string
  methodologyVersion?: string
}

export interface PlantingImpact {
  treeOffsetOverLifetimeInTonnes: number
  ecosystemServices?: string
  jobsCreated?: string
}

export interface Project {
  activeFrom: string
  activeTo: string
  availableQuantity: number
  carbonScore: number
  country: string
  description: string
  images: Image[]
  name: string
  perex: string
  prices: {
    eur: number
    usd: number
  }
  sellingUnit: SellingUnit
  ticker: string
  creditingPeriod?: CreditingPeriod
  location?: string
  plantingImpact?: PlantingImpact
  quantityReservationThreshold?: number
  ratings?: Rating[]
  tags?: string[]
  verificationPeriods?: VerificationPeriod[]
}

export enum SellingUnit {
  Tree = 'TREE',
  CarbonTonne = 'TONNE',
}

export interface SvgProps {
  color?: string
  title?: string
}

export enum SubscriptionType {
  Onetime,
  Monthly,
}

export enum UserRole {
  ADMIN_LAND_REGISTER = 'admin-land-register',
  FARM_DESIGNER = 'farm-designer',
  TWO_FACTOR_AUTHENTICATED = 'two-factor-authenticated',
  MRV_LRMA_DRONE_OPERATOR = 'mrv-lrma-drone-operator',
  MRV_LRMA_INVESTOR = 'mrv-lrma-investor',
}

export interface Customer {
  digitalForest: {
    [k: string]: {
      claimedTokens: number
      unclaimedTokens: number
    }
  }
  email: string
  name: string
  purchaseSums: {
    [k: string]: {
      eur: number
      quantity: number
    }
  }
  purchaseTotalEur: number
  referralCode: string
  roles: UserRole[]
  friendlyReferralCode?: string
}

export interface ResponseError {
  errorCode?: string
  errorId?: string
  message?: string
  violations?: Violation[]
}

export interface Violation {
  constraintType: ConstraintType
  message: string
  path: string
  value: string
}

export enum ConstraintType {
  Parameter = 'PARAMETER',
}

export interface PaginationData<Data> {
  data: Data
  totalItems: number
}
