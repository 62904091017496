import React from 'react'
import { useTransition } from 'react-spring'
import { Logger } from '@frontend/shared/logger'

import { Flex } from '../../../layout/flex/flex'
import { Text } from '../../../text/typography'
import { Tooltip } from '../../tooltip/tooltip'

import { ProgressBarWrapper, ProgressBarInnerProps, ProgressBarInner } from './progress-bar.styles'

export interface ProgressBarProps extends ProgressBarInnerProps {
  name: string
  label?: 'none' | 'right' | 'bottom' | 'tooltip'
}

export const ProgressBar = ({ progress, name, label = 'none', loop = false }: ProgressBarProps) => {
  const tooltipId = `tooltip-progress-bar-${name}`

  const transitions = useTransition(progress, {
    from: { width: '0%' },
    enter: { width: `${progress}%` },
    leave: { width: '0%' },
    config: {
      duration: 250,
    },
  })

  const progressBar = transitions((props) => (
    <ProgressBarWrapper>
      <ProgressBarInner
        data-tooltip-content={`${progress}%`}
        id={label === 'tooltip' ? tooltipId : ''}
        loop={loop}
        progress={progress}
        style={props}
      />
    </ProgressBarWrapper>
  ))

  switch (label) {
    case 'none':
      return progressBar
    case 'right':
      return (
        <Flex alignItems='center'>
          {progressBar}
          <Flex ml={1}>
            <Text size='sm'>{progress}%</Text>
          </Flex>
        </Flex>
      )
    case 'bottom':
      return (
        <Flex flexDirection='column'>
          {progressBar}
          <Flex justifyContent='flex-end' mt={1}>
            <Text size='sm'>{progress}%</Text>
          </Flex>
        </Flex>
      )
    case 'tooltip':
      return (
        <>
          {progressBar}
          <Tooltip anchorId={tooltipId} float />
        </>
      )
    default:
      Logger.error(`Implement new label variant for progress bar - ${label}`)

      return progressBar
  }
}
