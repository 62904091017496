import React from 'react'

export const WhatsappSolid = () => (
  <svg fill='none' height='45' viewBox='0 0 44 45' width='44' xmlns='http://www.w3.org/2000/svg'>
    <title>Earthbanc - whatsapp</title>
    <circle cx='22.0577' cy='22.545' fill='#25D366' r='21.8839' />
    <path
      d={`M32.277 12.1956C29.548 9.46008 25.9137 7.95557 22.0515 7.95557C14.0795 7.95557 7.5925 14.4426 7.5925 22.4145C7.5925 24.9611 8.25683 27.4491 9.52036 29.644L7.46875 37.134L15.1346 35.1215C17.2448 36.2743 19.6221 36.88 22.045 36.88H22.0515C30.0169 36.88 36.6472 30.393 36.6472 22.4211C36.6472 18.5588 35.0059 14.931 32.277 12.1956ZM22.0515 34.4441C19.8891 34.4441 17.7724 33.8645 15.9292 32.7703L15.4928 32.5098L10.9467 33.7017L12.1581 29.2663L11.8716 28.8104C10.6667 26.8955 10.0349 24.6876 10.0349 22.4145C10.0349 15.7908 15.4277 10.398 22.058 10.398C25.2689 10.398 28.2845 11.6485 30.551 13.9215C32.8175 16.1946 34.2113 19.2101 34.2048 22.4211C34.2048 29.0513 28.6752 34.4441 22.0515 34.4441ZM28.6427 25.4431C28.2845 25.2607 26.5064 24.388 26.1742 24.2708C25.8421 24.147 25.6011 24.0884 25.3601 24.4531C25.1191 24.8179 24.4287 25.6255 24.2138 25.873C24.0054 26.114 23.7905 26.1465 23.4322 25.9642C21.309 24.9025 19.9152 24.0689 18.5149 21.6655C18.1436 21.0273 18.8861 21.0729 19.5765 19.6921C19.6937 19.4511 19.6351 19.2427 19.5439 19.0603C19.4528 18.878 18.7298 17.0999 18.4302 16.3769C18.1371 15.6735 17.8375 15.7712 17.6161 15.7582C17.4077 15.7452 17.1667 15.7452 16.9257 15.7452C16.6847 15.7452 16.2939 15.8364 15.9618 16.1946C15.6296 16.5593 14.6982 17.4321 14.6982 19.2101C14.6982 20.9882 15.9943 22.7076 16.1702 22.9486C16.3526 23.1896 18.7168 26.8369 22.3446 28.4065C24.6372 29.3965 25.536 29.4812 26.6823 29.3119C27.3791 29.2077 28.8185 28.4391 29.1181 27.5924C29.4177 26.7457 29.4177 26.0228 29.3266 25.873C29.2419 25.7101 29.0009 25.619 28.6427 25.4431Z`}
      fill='white'
    />
  </svg>
)
