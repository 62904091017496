import { CardElement } from '@stripe/react-stripe-js'
import { FieldStripe, Grid, Flex } from '@frontend/shared/ui'
import React from 'react'

import { PaymentTypeWrapper } from './payment-type.styles'

interface PaymentTypeCreditCardProps {
  active: boolean
}

export const PaymentTypeCreditCard = ({ active }: PaymentTypeCreditCardProps) => (
  <Flex position='relative'>
    <PaymentTypeWrapper active={active}>
      <Grid alignItems='flex-start'>
        <FieldStripe Element={CardElement} label='Card Number' name='cardNumber' type='card' />
      </Grid>
    </PaymentTypeWrapper>
  </Flex>
)
