import React, { createContext, PropsWithChildren, RefObject, useCallback, useContext, useMemo, useState } from 'react'
import { getHashName } from '@frontend/shared/utils'
import { useRouter } from 'next/router'
import { FormikProps } from 'formik'
import { UserRole } from '@frontend/shared/types'

import {
  useServiceAuthenticate,
  useServiceAuthenticateOtp,
  useServiceCustomerLazy,
  useServiceInitialize,
  useServiceIssueToken,
  useServiceRequiredAction,
} from '../../service'
import {
  isActionAuthenticateOTP,
  isActionAuthenticateOTPError,
  isActionChangePassword,
  isActionIssueToken,
  isActionRegisterOTP,
} from '../../service/login/actions'
import { routes } from '../../router/routes'

import { Authenticate, ContextLoginProps } from './context-login.types'
import { initialState } from './context-login.initial-state'

export const ContextLogin = createContext<ContextLoginProps>(initialState)

export const useLogin = () => useContext(ContextLogin)

interface LoginProviderProps {
  initialAppRoute(userRoles: UserRole[]): string
}

export const LoginProvider = ({ children, initialAppRoute }: PropsWithChildren<LoginProviderProps>) => {
  const router = useRouter()

  const [loading, setLoading] = useState(initialState.loading)
  const [email, setEmail] = useState(initialState.email)
  const [action, setAction] = useState(initialState.action)
  const [otpQrCodeBase64, setOtpQrCodeBase64] = useState(initialState.otpQrCodeBase64)
  const [otpSecret, setOtpSecret] = useState('')
  const [sessionCode, setSessionCode] = useState('')
  const [execution, setExecution] = useState('')
  const [tabId, setTabId] = useState('')
  const [codeVerifier, setCodeVerifier] = useState('')

  const { getCustomer } = useServiceCustomerLazy()
  const serviceInitialize = useServiceInitialize()
  const serviceAuthenticate = useServiceAuthenticate()
  const serviceIssueToken = useServiceIssueToken()
  const serviceAuthenticateOTP = useServiceAuthenticateOtp()
  const serviceRequiredAction = useServiceRequiredAction()

  const authenticate = useCallback(
    async function authenticate<FormValues>({
      recaptchaToken,
      email,
      password,
      formikRef,
      onSuccessRegister,
    }: Authenticate<FormValues>) {
      setLoading(true)

      const initialized = await serviceInitialize.initialize(recaptchaToken)

      if (initialized) {
        const { tabId, execution, sessionCode } = initialized

        const authenticated = await serviceAuthenticate.authenticate({
          username: email,
          password,
          execution,
          sessionCode,
          tabId,
          formikRef,
        })

        if (authenticated) {
          setEmail(email)
          setExecution(execution)
          setSessionCode(sessionCode)
          setTabId(tabId)
          setAction(authenticated.action)
          setCodeVerifier(initialized.codeVerifier)

          if (isActionIssueToken(authenticated)) {
            const { code } = authenticated

            const tokenIssued = await serviceIssueToken.issueToken(code, initialized.codeVerifier)

            if (tokenIssued) {
              const customer = await getCustomer()
              const hash = getHashName()
              const initialRoute = initialAppRoute(customer.roles)

              setLoading(false)
              await router.push(hash || initialRoute)

              return true
            }
          }

          if (isActionRegisterOTP(authenticated)) {
            const { otpSecret, otpQrCodeBase64, sessionCode } = authenticated

            setOtpQrCodeBase64(otpQrCodeBase64)
            setOtpSecret(otpSecret)
            setSessionCode(sessionCode)

            setLoading(false)
            onSuccessRegister?.()

            return true
          }

          if (isActionAuthenticateOTP(authenticated)) {
            const { sessionCode, tabId, execution } = authenticated

            setSessionCode(sessionCode)
            setTabId(tabId)
            setExecution(execution)
            setLoading(false)

            await router.replace({ pathname: routes.loginTwoFactorAuthentication.getUrl(), hash: getHashName() })

            return true
          }

          if (isActionChangePassword(authenticated)) {
            const { sessionCode, tabId, execution } = authenticated

            setSessionCode(sessionCode)
            setTabId(tabId)
            setExecution(execution)
            setLoading(false)

            await router.replace({ pathname: routes.loginChangePassword.getUrl(), hash: getHashName() })

            return true
          }
        }
      }

      setLoading(false)

      return false
    },
    [getCustomer, initialAppRoute, router, serviceAuthenticate, serviceInitialize, serviceIssueToken],
  )

  const authenticateOTP = useCallback(
    async function authenticateOTP<FormValues>(otp: string, formikRef: RefObject<FormikProps<FormValues>>) {
      setLoading(true)

      const otpAuthenticated = await serviceAuthenticateOTP.authenticateOTP({ otp, execution, sessionCode, tabId })

      if (isActionIssueToken(otpAuthenticated)) {
        const { code } = otpAuthenticated

        const tokenIssued = await serviceIssueToken.issueToken(code, codeVerifier)

        if (tokenIssued) {
          const customer = await getCustomer()
          const hash = getHashName()
          const initialRoute = initialAppRoute(customer.roles)

          setLoading(false)
          await router.replace(hash || initialRoute)

          return true
        }
      }

      if (isActionChangePassword(otpAuthenticated)) {
        setAction(otpAuthenticated.action)
        setSessionCode(otpAuthenticated.sessionCode)
        setTabId(otpAuthenticated.tabId)
        setExecution(otpAuthenticated.execution)
        setLoading(false)

        await router.replace({ pathname: routes.loginChangePassword.getUrl(), hash: getHashName() })

        return true
      }

      if (isActionAuthenticateOTPError(otpAuthenticated)) {
        setExecution(otpAuthenticated.execution)
        setTabId(otpAuthenticated.tabId)
        setSessionCode(otpAuthenticated.sessionCode)
      }

      formikRef?.current?.setFieldError('otp', 'Invalid OTP code, please try it again')

      setLoading(false)

      return false
    },
    [
      codeVerifier,
      execution,
      getCustomer,
      initialAppRoute,
      router,
      serviceAuthenticateOTP,
      serviceIssueToken,
      sessionCode,
      tabId,
    ],
  )

  const registerDevice = useCallback(
    async function registerDevice<FormValues>(otp: string, formikRef: RefObject<FormikProps<FormValues>>) {
      setLoading(true)

      const otpRegistered = await serviceRequiredAction.registerOTP({
        otp,
        execution,
        sessionCode,
        tabId,
        otpSecret,
      })

      if (isActionIssueToken(otpRegistered)) {
        const { code } = otpRegistered

        const tokenIssued = await serviceIssueToken.issueToken(code, codeVerifier)

        if (tokenIssued) {
          const customer = await getCustomer()
          const hash = getHashName()
          const initialRoute = initialAppRoute(customer.roles)

          setLoading(false)
          await router.replace(hash || initialRoute)

          return true
        }
      }

      if (isActionChangePassword(otpRegistered)) {
        setAction(otpRegistered.action)
        setSessionCode(otpRegistered.sessionCode)
        setTabId(otpRegistered.tabId)
        setExecution(otpRegistered.execution)
        setLoading(false)

        await router.replace({ pathname: routes.loginChangePassword.getUrl(), hash: getHashName() })

        return true
      }

      if (isActionAuthenticateOTPError(otpRegistered)) {
        setSessionCode(otpRegistered.sessionCode)
        setTabId(otpRegistered.tabId)
        setExecution(otpRegistered.execution)
      }

      formikRef?.current?.setFieldError('otp', 'Invalid OTP code, please try it again')

      setLoading(false)

      return false
    },
    [
      codeVerifier,
      execution,
      getCustomer,
      initialAppRoute,
      otpSecret,
      router,
      serviceIssueToken,
      serviceRequiredAction,
      sessionCode,
      tabId,
    ],
  )

  const updatePassword = useCallback(
    async function registerDevice<FormValues>(
      passwordNew: string,
      username: string,
      formikRef: RefObject<FormikProps<FormValues>>,
    ) {
      setLoading(true)

      const otpRegistered = await serviceRequiredAction.updatePassword({
        passwordNew,
        username,
        execution,
        sessionCode,
        tabId,
      })

      if (isActionIssueToken(otpRegistered)) {
        const { code } = otpRegistered

        const tokenIssued = await serviceIssueToken.issueToken(code, codeVerifier)

        if (tokenIssued) {
          const customer = await getCustomer()
          const hash = getHashName()
          const initialRoute = initialAppRoute(customer.roles)

          setLoading(false)
          await router.replace(hash || initialRoute)

          return true
        }
      }

      if (isActionAuthenticateOTPError(otpRegistered)) {
        setSessionCode(otpRegistered.sessionCode)
        setTabId(otpRegistered.tabId)
        setExecution(otpRegistered.execution)
      }

      formikRef?.current?.setFieldError('passwordNew', 'Invalid OTP code, please try it again')

      setLoading(false)

      return false
    },
    [
      codeVerifier,
      execution,
      getCustomer,
      initialAppRoute,
      router,
      serviceIssueToken,
      serviceRequiredAction,
      sessionCode,
      tabId,
    ],
  )

  const value = useMemo(
    () => ({
      action,
      authenticate,
      authenticateOTP,
      updatePassword,
      otpQrCodeBase64,
      email,
      loading,
      registerDevice,
    }),
    [action, authenticate, authenticateOTP, email, loading, otpQrCodeBase64, registerDevice, updatePassword],
  )

  return <ContextLogin.Provider value={value}>{children}</ContextLogin.Provider>
}
