import { styled } from '@frontend/shared/theme'
import Image from 'next/image'

import { Grid } from '../../../../layout/grid/grid'
import { Flex } from '../../../../layout/flex/flex'

export const CardImageWrapper = styled(Flex)`
  position: relative;

  span {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    max-height: 100%;
  }
`

export const CardImage = styled(Image)`
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`

export const RatingWrapper = styled(Grid)`
  position: absolute;
  bottom: 0.875rem;
  right: 1.25rem;
`
