import { Grid, Skeleton } from '@frontend/shared/ui'
import React from 'react'

export const TwoFactorAuthenticationSkeleton = () => (
  <Grid>
    <Skeleton height='2.5rem' />

    <Grid gap='sm'>
      <Skeleton height='2rem' />
      <Skeleton height='4rem' />
    </Grid>

    <Skeleton height='2.5rem' />
  </Grid>
)
