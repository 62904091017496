import { Logger } from '@frontend/shared/logger'
import { BaseSchema } from 'yup'
import { AnyObject, ValidateOptions } from 'yup/lib/types'

import { dateTimeReviver } from '../date/date-reviver'

export const jsonSyntaxSafeParse = <Data>(jsonString: string): Data | null => {
  try {
    return JSON.parse(jsonString, dateTimeReviver)
  } catch (e) {
    Logger.error(`Unable to parse JSON string: ${JSON.stringify(e)}`)

    return null
  }
}

export const schemaTypeSafeParse = <Data>(
  schema: BaseSchema,
  jsonStructure: string | Data,
  validationOptions?: ValidateOptions<AnyObject>,
): Data | null => {
  const validJson = typeof jsonStructure === 'string' ? jsonSyntaxSafeParse<Data>(jsonStructure) : jsonStructure

  try {
    return schema.validateSync(validJson, validationOptions)
  } catch (error) {
    Logger.warn(`Invalid schema, runtime validation: ${JSON.stringify(error)}`)

    throw error
  }
}

export const schemaTypeSafeParseAsync = async <Data>(
  schema: BaseSchema,
  jsonStructure: string | Data,
  validationOptions?: ValidateOptions<AnyObject>,
): Promise<Data | null> => {
  const validJson = typeof jsonStructure === 'string' ? jsonSyntaxSafeParse<Data>(jsonStructure) : jsonStructure

  try {
    return schema.validate(validJson, validationOptions)
  } catch (error) {
    Logger.warn(`Invalid schema, runtime validation: ${JSON.stringify(error)}`)

    throw error
  }
}
