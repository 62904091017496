import { addMethod, string, StringSchema } from 'yup'

/**
 * Support validation only for (hex, rgb, rgba, hsl, hsla, named) HTML colors
 */
addMethod<StringSchema>(string, 'colorValidation', function colorValidation() {
  return this.test(
    'validate-color',
    ({ path }) => `Invalid color for path '${path}'`,
    async (value) => {
      if (value === undefined || value === null) {
        return true
      }

      const { TinyColor } = await import('@ctrl/tinycolor')

      const color = new TinyColor(value)

      return color.isValid
    },
  )
})
