import { styled, StyledProps, TextFontSize } from '@frontend/shared/theme'
import { space } from 'styled-system'
import { Logger } from '@frontend/shared/logger'

import { Flex } from '../../../layout/flex/flex'
import { IconKind } from '../../../text'

export type BadgeSize = 'sm' | 'md' | 'lg'
export type BadgeColor = 'blue' | 'blueGray' | 'gray' | 'error' | 'warning' | 'success' | 'primary'
export type BadgePrefixType = 'dot' | 'icon'

export interface BadgePrefix {
  type: BadgePrefixType
  value?: IconKind
}

export interface BadgeWrapperProps {
  color: BadgeColor
  size?: BadgeSize
}

export const badgeFontSize = {
  sm: 'xs' as TextFontSize,
  md: 'sm' as TextFontSize,
  lg: 'sm' as TextFontSize,
}

export const badgePadding = ({ size = 'sm' }: BadgeWrapperProps) =>
  ({
    sm: '0.125rem 0.5rem',
    md: '0.125rem 0.625rem',
    lg: '0.25rem 0.75rem',
  }[size])

export const textColor = ({ theme, color }: StyledProps<BadgeWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.components.badge.gray.label.textColor
    case 'blue':
    case 'primary':
      return theme.colors.primary.contrast
    case 'blueGray':
      return theme.colors.secondary.darkBlue.main
    case 'warning':
      return theme.colors.warning['700']
    case 'error':
      return theme.colors.error['700']
    case 'success':
      return theme.colors.success['700']
    default:
      Logger.error(`Implement new text color for Badge: ${color}`)

      return theme.colors.gray['700']
  }
}

export const getBadgeBackgroundColor = ({ theme, color }: StyledProps<BadgeWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.components.badge.gray.wrapper.backgroundColor
    case 'blue':
      return theme.colors.secondary.darkGray['70']
    case 'blueGray':
      return theme.colors.secondary.bgGray['50']
    case 'primary':
      return theme.colors.primary.blue['50']
    case 'warning':
      return theme.colors.warning['50']
    case 'error':
      return theme.colors.error['50']
    case 'success':
      return theme.colors.success['50']
    default:
      Logger.error(`Implement new background color for Badge: ${color}`)

      return theme.colors.gray['100']
  }
}

export const getBadgePrefixColor = ({ theme, color }: StyledProps<BadgeWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['500']
    case 'blue':
      return theme.colors.primary.contrast
    case 'blueGray':
      return theme.colors.secondary.darkGray.main
    case 'primary':
      return theme.colors.primary.blue.main
    case 'warning':
      return theme.colors.warning['500']
    case 'error':
      return theme.colors.error['500']
    case 'success':
      return theme.colors.success['500']
    default:
      Logger.error(`Implement new background color for BadgeDot: ${color}`)

      return theme.colors.gray['500']
  }
}

export const BadgeWrapper = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: 'max-content',
}))<BadgeWrapperProps>`
  ${space};

  width: max-content;
  border-radius: 1rem;
  text-align: center;
  padding: ${badgePadding};
  background-color: ${getBadgeBackgroundColor};
`

export const BadgeDot = styled(Flex).attrs(() => ({
  mr: 1,
}))`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: ${getBadgePrefixColor};
`
