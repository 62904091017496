/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/ban-ts-comment */
import { components, SingleValueProps } from 'react-select'
import React from 'react'

export const SingleValueComponent = <OptionType, IsMulti extends boolean = false>({
  children,
  ...props
}: SingleValueProps<OptionType, IsMulti>) => (
  <components.SingleValue {...props}>
    {
      // @ts-ignore
      props.selectProps?.displayValue ? props.selectProps.displayValue(props.data) : children
    }
  </components.SingleValue>
)
