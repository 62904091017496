import { Logger } from '@frontend/shared/logger'
import { IS_BROWSER } from '@frontend/shared/constants'

import { dateTimeReviver } from '../../date'

type LocalStorage<StoredValue> = {
  get: (reviver?: boolean) => StoredValue | undefined
  remove: () => void
  set: (value: StoredValue) => void
}

export const getLocalStorage = <StoredValue>(key: string): LocalStorage<StoredValue> => {
  const get = (reviver = true): StoredValue | undefined => {
    try {
      const valueString = IS_BROWSER && window.localStorage.getItem(key)

      return valueString ? JSON.parse(valueString, reviver ? dateTimeReviver : undefined) : undefined
    } catch (error) {
      Logger.error(error)

      return undefined
    }
  }

  const set = (value: StoredValue) => {
    try {
      !!value && IS_BROWSER && window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      Logger.error(error)
    }
  }

  const remove = () => {
    try {
      IS_BROWSER && window.localStorage.removeItem(key)
    } catch (error) {
      Logger.error(error)
    }
  }

  return { get, set, remove }
}
