import React, { PropsWithChildren } from 'react'

import { AvatarWrapper, AvatarWrapperProps, AvatarImg, avatarSize } from './avatar.styles'

export interface AvatarProps extends AvatarWrapperProps {
  alt?: string
  src?: React.ImgHTMLAttributes<HTMLImageElement>['src']
  srcSet?: React.ImgHTMLAttributes<HTMLImageElement>['srcSet']
}

const lettersFallback = (altText: string) =>
  altText
    .split(/\s/)
    .filter((s) => s !== '')
    .map((s) => s.slice(0, 1))
    .map((s) => s.toUpperCase())
    .slice(0, 2)
    .join('')

export const Avatar = ({ alt, children, size = 'md', src, srcSet }: PropsWithChildren<AvatarProps>): JSX.Element => {
  const hasImg = srcSet || src

  let srcImg = `/${src?.replace(/^\//, '')}`

  if (src && (src.startsWith('data:image/jpeg;base64,') || src.startsWith('data:image/png;base64,'))) {
    srcImg = src
  }

  const width = avatarSize[size]
  const height = avatarSize[size]

  return (
    <AvatarWrapper size={size}>
      {hasImg && <AvatarImg alt={alt} as='img' height={height} src={srcImg} srcSet={srcSet} width={width} />}
      {!children && !hasImg && alt && lettersFallback(alt)}
      {children}
    </AvatarWrapper>
  )
}
