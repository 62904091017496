import React from 'react'

import { Grid } from '../../../../layout/grid/grid'
import { InputRadio, InputRadioProps } from '../root/input-radio'
import { Flex } from '../../../../layout/flex/flex'

type Option = Pick<InputRadioProps, 'value' | 'label' | 'disabled'>

export type Options = Option[]

export interface InputRadioGroupProps extends Pick<InputRadioProps, 'name' | 'checked' | 'onChange' | 'onBlur'> {
  options: Options
  wrap?: boolean
}

export const InputRadioGroup = ({ options, name, onChange, checked, onBlur, wrap = false }: InputRadioGroupProps) => {
  const items = options.map(({ value, disabled, label }) => {
    const item = (
      <InputRadio
        checked={checked}
        disabled={disabled}
        key={value}
        label={label}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    )

    return wrap ? (
      <Flex mb={3} mr={3}>
        {item}
      </Flex>
    ) : (
      item
    )
  })

  if (wrap) {
    return <Flex flexWrap='wrap'>{items}</Flex>
  }

  return <Grid gap='sm'>{items}</Grid>
}
