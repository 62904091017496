import { object, string } from 'yup'
import { SellingUnit } from '@frontend/shared/types'
import { roundWithPrecision } from '@frontend/shared/utils'

import { getSellingUnit } from '../../../../utils'

export const validationSchema = (sellingUnit: SellingUnit, minCarbonTonne: number, maxCarbonTonne: number) =>
  object({
    quantity: (sellingUnit === SellingUnit.Tree
      ? string().upperLimit(maxCarbonTonne, `Maximum ${getSellingUnit(sellingUnit, true)} is ${maxCarbonTonne}`)
      : string()
    )
      .required()
      .lowerLimit(
        minCarbonTonne,
        `Minimum ${getSellingUnit(sellingUnit, true)} is ${roundWithPrecision(minCarbonTonne)}`,
      ),
  })
