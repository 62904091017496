export const pollRequest = <Data>(
  caller: () => Promise<Data>,
  validate: (data: Data) => boolean,
  intervalMillis: number,
  maxAttempts?: number,
): Promise<Data | undefined> => {
  let attempts = 0

  const executePoll = async (
    resolve: (value: Data | PromiseLike<Data>) => void,
    reject: (reason?: unknown) => void,
    // eslint-disable-next-line consistent-return
  ): Promise<void> => {
    const result = await caller()
    attempts += 1

    if (validate(result)) {
      return resolve(result)
    }

    if (maxAttempts && attempts === maxAttempts) {
      return reject(undefined)
    }

    setTimeout(executePoll, intervalMillis, resolve, reject)
  }

  return new Promise<Data | undefined>(executePoll)
}
