import { styled } from '@frontend/shared/theme'
import { CSSProperties } from 'react'

import { Flex } from '../../../layout/flex/flex'

export type LoaderSize = 'sm' | 'md' | 'lg' | 'xl'

export interface LoaderWrapperProps extends Pick<CSSProperties, 'color' | 'opacity' | 'backgroundColor'> {
  size?: LoaderSize
}

export const loaderSize = {
  sm: '2rem',
  md: '3rem',
  lg: '3.5rem',
  xl: '4rem',
}

export const loaderSizes = ({ size = 'md' }: LoaderWrapperProps) => loaderSize[size]

export const LoaderWrapper = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))<LoaderWrapperProps>`
  opacity: ${({ opacity }) => opacity};
  transition: all ${({ theme }) => theme.transition};
  width: ${loaderSizes};
  height: ${loaderSizes};
  border-radius: 50%;
  z-index: 10;
`
