import React, { ReactNode, Children } from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Flex } from '../../../layout/flex/flex'
import { Text } from '../../../text/typography'
import { BadgeGroup, BadgeGroupProps } from '../../badge/group/badge-group'
import { AlertWrapper, descriptionColor } from '../text/alert.styles'

import { ButtonsWrapper } from './alert-badge-group.styles'

export interface AlertBadgeGroupProps extends Pick<BadgeGroupProps, 'title' | 'color'> {
  buttons?: ReactNode[]
  description?: string
}

export const AlertBadgeGroup = ({ title, color, description, buttons }: AlertBadgeGroupProps) => {
  const theme = useTheme()

  return (
    <AlertWrapper color={color} flexDirection={['column', null, null, 'row']}>
      <BadgeGroup color={color} size='lg' title={title} />
      {description && (
        <Flex
          flex={1}
          flexDirection='column'
          justifyContent='center'
          mx={['0rem', null, null, 1]}
          my={[1, null, null, '0rem']}
        >
          <Text color={descriptionColor({ theme, color })} size='sm' weight={400}>
            {description}
          </Text>
        </Flex>
      )}
      {buttons && (
        <Flex flex={0}>
          <ButtonsWrapper
            alignItems='center'
            color={color}
            gridTemplateColumns={`repeat(${Children.count(buttons)}, max-content)`}
            justifyContent={['flex-end', null, null, 'flex-start']}
          >
            {buttons}
          </ButtonsWrapper>
        </Flex>
      )}
    </AlertWrapper>
  )
}
