import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

interface PasswordResetInitialize {
  email: string
  recaptchaToken: string
}

interface PasswordResetFinalize {
  passwordNew: string
  recaptchaToken: string
  token: string
}

export const useServicePasswordReset = () => {
  const { post, loading, response } = useAuthFetch()

  const passwordResetInitialize = async ({ email, recaptchaToken }: PasswordResetInitialize) => {
    const body = { email, recaptchaToken }
    await post(ENDPOINT_URL.passwordResetInitialize, body)

    return response.ok
  }

  const passwordResetFinalize = async ({ passwordNew, recaptchaToken, token }: PasswordResetFinalize) => {
    const body = { passwordNew, recaptchaToken, token }
    await post(ENDPOINT_URL.passwordResetFinalize, body)

    return response.ok
  }

  return { loading, passwordResetInitialize, passwordResetFinalize }
}
