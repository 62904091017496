import React from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Flex } from '../../../layout/flex/flex'
import { IconFeatured } from '../../../text/icon/featured/icon-featured'
import { Text } from '../../../text/typography'
import { Icon } from '../../../text/icon/root/icon'
import { Button } from '../../button/root/button'
import { WRAPPER_MAX_WIDTH } from '../../../constants'
import { ProgressBar } from '../../progress/bar/progress-bar'

import { FileWrapper, FileWrapperProps } from './file.styles'

export interface FileProps extends Partial<Pick<FileWrapperProps, 'type' | 'href'>> {
  name: string
  description?: string
  onClose?: () => void
  progressBar?: number
  progressBarLoop?: boolean
  testID?: string
}

export const File = ({
  href = '',
  description,
  name,
  type,
  onClose,
  testID,
  progressBar,
  progressBarLoop,
}: FileProps) => {
  const theme = useTheme()

  return (
    <FileWrapper
      as={type === 'download' ? 'a' : 'div'}
      data-test-id={testID ? `file-${testID}` : 'file'}
      download={type === 'download' ? true : undefined}
      href={href}
      maxWidth={WRAPPER_MAX_WIDTH}
      type={type}
      width='100%'
    >
      <Flex>
        <Flex>
          <IconFeatured color={type === 'error' ? 'error' : 'primary'} kind='FiFile' size='sm' />
        </Flex>
        <Flex flex={1} flexDirection='column' mx={2}>
          <Text
            color={type === 'error' ? theme.colors.error['700'] : theme.colors.gray['700']}
            data-test-id='name'
            size='sm'
          >
            {name}
          </Text>
          {description && (
            <Text
              color={type === 'error' ? theme.colors.error['600'] : theme.colors.gray['500']}
              data-test-id='description'
              size='sm'
              weight={400}
            >
              {description}
            </Text>
          )}
        </Flex>
        <Flex>
          {type === 'download' && <Icon color={theme.colors.gray['500']} kind='FiDownload' />}
          {type !== 'download' && onClose && (
            <Button name='remove' type='icon' onClick={onClose}>
              <Icon color={type === 'error' ? theme.colors.error['700'] : theme.colors.gray['500']} kind='FiX' />
            </Button>
          )}
        </Flex>
      </Flex>
      {progressBar && progressBar >= 0 ? (
        <Flex mt={3}>
          <ProgressBar loop={progressBarLoop} name={name} progress={progressBar} />
        </Flex>
      ) : null}
    </FileWrapper>
  )
}
