import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { KEY } from '@frontend/shared/constants'
import { createPortal } from 'react-dom'

import { Text } from '../../../text'
import { Skeleton } from '../../skeleton/root'
import { Grid } from '../../../layout/grid/grid'
import { Flex } from '../../../layout/flex/flex'

import { ModalBackground, ModalContent } from './modal.styles'

export interface ModalProps {
  open: boolean
  icon?: JSX.Element
  loading?: boolean
  maxWidth?: string
  onClose?: () => void
  padding?: string | number
  title?: string
}

export const Modal = ({
  onClose,
  open,
  loading,
  title,
  children,
  icon,
  maxWidth,
  padding,
}: PropsWithChildren<ModalProps>) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const backgroundRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const backgroundTempRef = backgroundRef.current

    const keyDownHandler = (event: KeyboardEvent) => open && event.key === KEY.escape && onClose?.()

    const clickHandler = (event: MouseEvent) => {
      const isOuterClick = !contentRef.current?.contains(event.target as Node)

      isOuterClick && onClose?.()
    }

    open && backgroundTempRef?.addEventListener('click', clickHandler)
    window.addEventListener('keydown', keyDownHandler)

    return () => {
      backgroundTempRef?.removeEventListener('click', clickHandler)
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [onClose, open])

  return open ? (
    <>
      {createPortal(
        <ModalBackground ref={backgroundRef}>
          <ModalContent maxWidth={maxWidth} padding={padding} ref={contentRef}>
            {loading && (
              <>
                <Text size='lg'>
                  <Skeleton />
                </Text>
                <Skeleton height='8rem' />
                <Grid gridTemplateColumns='1fr 1fr'>
                  <Skeleton height='2rem' />
                  <Skeleton height='2rem' />
                </Grid>
              </>
            )}
            {!loading && (
              <>
                {icon && <Flex justifyContent='center'>{icon}</Flex>}
                {title && (
                  <Text data-test-id='modalLabel' size='lg' textAlign='center'>
                    {title}
                  </Text>
                )}
                {children}
              </>
            )}
          </ModalContent>
        </ModalBackground>,
        document.body,
      )}
    </>
  ) : null
}
