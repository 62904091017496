import { CachePolicies } from 'use-http'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'
import { getLocalStorage } from '@frontend/shared/utils'
import { SellingUnit } from '@frontend/shared/types'
import { useApiFetch } from '@frontend/domains/shared/components'

import { ENDPOINT_URL, STORAGE_PARTNER_ID_KEY } from '../../../../constants'

interface ReservationParams {
  currency: 'eur'
  email: string
  expectedPrice: number
  name: string
  projectTicker: string
  quantity: number
  sellingUnit: SellingUnit
  vintage: string
}

export const useServiceReservation = () => {
  const { showToastGenericError } = useToast()
  const { post, error, loading, response } = useApiFetch({
    cachePolicy: CachePolicies.NETWORK_ONLY,
  })

  const reserveOffset = async (params: ReservationParams): Promise<boolean | undefined> => {
    const { get } = getLocalStorage<string>(STORAGE_PARTNER_ID_KEY)
    const partnerId = get()

    const body = { ...params, partnerId }
    const result = await post(ENDPOINT_URL.offsetReservations, body)

    if (400 <= response.status && response.status < 500) {
      Logger.error('An error occurred when offset reservation.', result)
      showToastGenericError()

      return undefined
    }

    return response.ok
  }

  return { reserveOffset, loading, error }
}
