import { SvgProps } from '@frontend/shared/types'
import React from 'react'

export const TwitterRegular = ({ color }: SvgProps) => (
  <svg fill='none' height='20' viewBox='0 0 24 20' width='24' xmlns='http://www.w3.org/2000/svg'>
    <title>Earthbanc - twitter</title>
    <path
      d={`M7.54752 19.7508C16.6042 19.7508 21.5578 12.2474 21.5578 5.74052C21.5578 5.5274 21.5578 5.31524 21.5434 5.10404C22.507 4.407 23.3389 3.54392 24 2.55524C23.1014 2.95364 22.148 3.2148 21.1718 3.32996C22.1998 2.71465 22.9692 1.74674 23.3366 0.60644C22.3701 1.18005 21.3126 1.58427 20.2099 1.80164C19.4675 1.01222 18.4856 0.489481 17.4162 0.314324C16.3468 0.139168 15.2494 0.321355 14.294 0.832693C13.3385 1.34403 12.5782 2.15601 12.1307 3.14299C11.6833 4.12996 11.5735 5.23691 11.8186 6.29252C9.8609 6.19432 7.94576 5.68555 6.19745 4.79924C4.44915 3.91294 2.90676 2.6689 1.6704 1.14788C1.04073 2.23188 0.847872 3.51511 1.1311 4.7363C1.41433 5.9575 2.15234 7.02483 3.19488 7.721C2.41123 7.69804 1.64465 7.48663 0.96 7.10468V7.16708C0.960311 8.30393 1.35385 9.40568 2.07387 10.2854C2.79389 11.1652 3.79606 11.7689 4.9104 11.994C4.18548 12.1917 3.42487 12.2206 2.68704 12.0784C3.00181 13.0568 3.61443 13.9123 4.43924 14.5254C5.26405 15.1385 6.25983 15.4785 7.28736 15.498C6.26644 16.3004 5.09731 16.8938 3.84687 17.244C2.59643 17.5942 1.28921 17.6944 0 17.5389C2.25183 18.9839 4.87192 19.7504 7.54752 19.7469`}
      fill={color ?? '#1DA1F2'}
    />
  </svg>
)
