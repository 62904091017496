import { Button, Display, Divider, Field, FieldPassword, Flex, Form, Grid, Text, useToast } from '@frontend/shared/ui'
import { Link, useGoogleRecaptcha } from '@frontend/domains/shared/components'
import { useTheme } from '@frontend/shared/theme'
import React, { useRef } from 'react'
import { useRouter } from 'next/router'
import { FormikProps } from 'formik'
import { getHashName } from '@frontend/shared/utils'

import { routes } from '../../router/routes'

import { FormValues } from './form/types'
import { initialValues } from './form/initial-values'
import { validationSchema } from './form/validation-schema'
import { useServiceSignUp } from './service/use-service-sign-up'

export const SignUp = () => {
  const router = useRouter()
  const theme = useTheme()
  const { showToastSuccess } = useToast()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { getRecaptchaToken } = useGoogleRecaptcha()
  const { signUp, loading } = useServiceSignUp(formikRef)

  const submitForm = async ({ fullName, email, password }: FormValues) => {
    const recaptchaToken = await getRecaptchaToken('signUp')

    if (recaptchaToken) {
      const signedUp = await signUp({ fullName, email: email.trim(), password, recaptchaToken })

      if (signedUp) {
        const hash = getHashName()

        await router.push({ pathname: routes.signIn.getUrl(), hash })
        showToastSuccess('Registration successful', 'Please check your email address to verify your account.')
      }
    }
  }

  return (
    <Form<FormValues>
      initialValues={initialValues}
      innerRef={formikRef}
      name='sign-up'
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      <Grid>
        <Display data-test-id='title' size='xs' textAlign='center' weight={500}>
          Sign up
        </Display>

        <Field autoFocus label='Name' name='fullName' placeholder='Your name here' />
        <Field label='Email' name='email' placeholder='example@mail.com' type='email' />
        <FieldPassword label='Password' name='password' strengthIndicator />

        <Grid gridGap='1rem' gridRowGap='1rem'>
          <Button disabled={loading} htmlType='submit' loading={loading} name='sign-up' size='xl'>
            Sign up
          </Button>

          <Flex alignItems='center'>
            <Flex flex={1}>
              <Divider />
            </Flex>
            <Flex flex='0 auto' px={2}>
              <Text color={theme.colors.gray['500']} size='xs' weight={400}>
                or
              </Text>
            </Flex>
            <Flex flex={1}>
              <Divider />
            </Flex>
          </Flex>
          <Link href={routes.signIn.getUrl()} size='xl' testID='sign-in' type='secondaryGray'>
            Sign in
          </Link>
        </Grid>
      </Grid>
    </Form>
  )
}
