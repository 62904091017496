import { styled, StyledProps, TextFontSize } from '@frontend/shared/theme'
import { Logger } from '@frontend/shared/logger'

import { Flex } from '../../../layout/flex/flex'
import { BadgeSize } from '../root/badge.styles'

export type BadgeGroupSize = 'md' | 'lg'
export type BadgeGroupColor = 'gray' | 'error' | 'warning' | 'success' | 'primary'

export interface BadgeGroupWrapperProps {
  color: BadgeGroupColor
}

export const badgeSize = {
  md: 'sm' as BadgeSize,
  lg: 'md' as BadgeSize,
}

export const badgeGroupFontSize = {
  md: 'xs' as TextFontSize,
  lg: 'sm' as TextFontSize,
}

const backgroundColor = ({ color, theme }: StyledProps<BadgeGroupWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['100']
    case 'primary':
      return theme.colors.primary.blue['75']
    case 'success':
      return theme.colors.success['100']
    case 'error':
      return theme.colors.error['100']
    case 'warning':
      return theme.colors.warning['100']
    default:
      Logger.error(`Implement new background color for alert component: ${color}`)

      return theme.colors.gray['100']
  }
}

export const titleColor = ({ color, theme }: StyledProps<BadgeGroupWrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['700']
    case 'primary':
      return theme.colors.primary.contrast
    case 'success':
      return theme.colors.success['700']
    case 'error':
      return theme.colors.error['700']
    case 'warning':
      return theme.colors.warning['700']
    default:
      Logger.error(`Implement new text color for alert component: ${color}`)

      return theme.colors.gray['700']
  }
}

export const BadgeGroupWrapper = styled(Flex)<BadgeGroupWrapperProps>`
  background: ${backgroundColor};
  border-radius: 1rem;
  padding: 0.25rem;
  mix-blend-mode: multiply;
`
