import React, { PropsWithChildren } from 'react'
import { logoPrimary } from '@frontend/shared/ui'

import { Link } from '../../screen/link'
import { Footer } from '../footer'

import { FooterContainer, LayoutWrapper, LogoWrapper, StyledLayout } from './layout-login.styles'

interface LayoutLoginProps {
  logoLink?: string
}

export const LayoutLogin = ({ children, logoLink = '/' }: PropsWithChildren<LayoutLoginProps>) => (
  <LayoutWrapper>
    <LogoWrapper mb={6} mt={[6, '0rem']}>
      <Link href={logoLink}>
        <img alt='Earthbanc - logo' height={28} src={logoPrimary} width={166} />
      </Link>
    </LogoWrapper>
    <StyledLayout>{children}</StyledLayout>
    <FooterContainer>
      <Footer />
    </FooterContainer>
  </LayoutWrapper>
)
