import { css, styled, StyledProps } from '@frontend/shared/theme'

import { Flex } from '../../layout'

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export interface AvatarWrapperProps {
  size?: AvatarSize
}

export const avatarSize = {
  xs: '1.5rem',
  sm: '2rem',
  md: '2.5rem',
  lg: '3rem',
  xl: '3.5rem',
  '2xl': '4rem',
}

const fontSize = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.375rem',
}

const avatarStyles = ({ size = 'md' }: StyledProps<AvatarWrapperProps>) => css`
  width: ${avatarSize[size]};
  height: ${avatarSize[size]};
  font-size: ${fontSize[size]};
`

export const AvatarWrapper = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))<StyledProps<AvatarWrapperProps>>`
  ${avatarStyles};
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary.blue['25']};
  color: ${({ theme }) => theme.colors.primary.blue.mainHover};
  line-height: 1;
  border-radius: 50%;
`

export const AvatarImg = styled('img').attrs(() => ({ slot: 'Img' }))`
  width: 100%;
  height: 100%;
  text-align: center;
  color: transparent;
  object-fit: cover;
`
