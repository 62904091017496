import { CachePolicies, useFetch } from 'use-http'
import { SERVICE_URL } from '@frontend/shared/constants'
import { Project } from '@frontend/shared/types'
import { UseFetch } from 'use-http/dist/cjs/types'

import { ENDPOINT_URL } from '../constants'

export const useServiceProjects = <Data extends Project>(): UseFetch<Data[]> =>
  useFetch<Data[]>(
    SERVICE_URL.beUrl + ENDPOINT_URL.projects,
    {
      cachePolicy: CachePolicies.NETWORK_ONLY,
    },
    [],
  )
