import { FileRejection } from 'react-dropzone'
import React from 'react'

import { Grid } from '../../../../../layout/grid/grid'
import { File } from '../../../../../screen/file'

export interface QueueFile extends FileRejection {
  file: File
  status: 'waiting' | 'uploaded' | 'error'
}

interface FilesPreviewProps {
  onRemoveFile: (name: string) => void
  queue?: QueueFile[]
}

export const MultipleFiles = ({ queue = [], onRemoveFile }: FilesPreviewProps) => {
  if (queue?.length === 0) {
    return null
  }

  return (
    <Grid gap='sm'>
      {queue.map((queueFile) => {
        const error = queueFile.errors.length > 0 || queueFile.status === 'error'

        return (
          <File
            description={queueFile?.errors?.[0]?.message ?? ''}
            key={queueFile.file.name}
            name={queueFile.file.name}
            progressBar={['waiting', 'uploaded'].includes(queueFile.status) ? 100 : 0}
            progressBarLoop={['waiting'].includes(queueFile.status)}
            type={error ? 'error' : 'upload'}
            onClose={error ? () => onRemoveFile(queueFile.file.name) : undefined}
          />
        )
      })}
    </Grid>
  )
}
