import { ChangeEvent, FocusEvent } from 'react'
import { css, styled, StyledProps } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex/flex'

export type CheckboxSizes = 'sm' | 'md'

export interface InputCheckboxProps {
  checked: boolean
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
  disabled?: boolean
  error?: string
  hint?: string
  htmlFor?: string
  indeterminate?: boolean
  label?: string | JSX.Element
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  size?: CheckboxSizes
  touched?: boolean
}

interface CheckboxWrapper extends Pick<InputCheckboxProps, 'disabled' | 'checked' | 'indeterminate' | 'size'> {
  hasError: boolean
  pure: boolean
}

const checkboxSizes = {
  sm: '1rem',
  md: '1.25rem',
} as const

const cursorDisabled = ({ disabled }: StyledProps<CheckboxWrapper>) => (disabled ? 'default' : 'pointer')

const labelColor = ({ disabled, theme }: StyledProps<CheckboxWrapper>) =>
  disabled ? theme.colors.gray['300'] : theme.colors.gray['700']

const checkboxBorder = ({ disabled, checked, hasError, theme }: StyledProps<CheckboxWrapper>) => {
  switch (true) {
    case disabled:
      return theme.colors.gray['200']
    case hasError:
      return theme.colors.error['500']
    case checked:
      return theme.components.checkbox[':checked'].borderColor
    default:
      return theme.components.checkbox.borderColor
  }
}

const checkboxBackground = ({ checked, indeterminate, disabled, hasError, theme }: StyledProps<CheckboxWrapper>) => {
  switch (true) {
    case disabled:
      return theme.colors.gray['100']
    case (checked || indeterminate) && hasError:
      return theme.colors.error['500']
    case checked || indeterminate:
      return theme.components.checkbox[':checked'].backgroundColor
    default:
      return theme.components.checkbox.backgroundColor
  }
}

const checkboxMarker = ({ checked, indeterminate }: StyledProps<CheckboxWrapper>) =>
  checked || indeterminate ? 'block' : 'none'

const hoverStyles = ({ theme, disabled, hasError }: StyledProps<CheckboxWrapper>) => {
  if (disabled) {
    return null
  }

  const color = hasError ? theme.colors.error['500'] : 'rgba(20, 155, 165, 0.25)'

  return css`
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.primary.blue.main};
    transition: all ${theme.transition};
    box-shadow: 0 0 0 0.25rem ${color};
  `
}

const smStyles = {
  input: css<CheckboxWrapper>`
    width: ${checkboxSizes.sm};
    height: ${checkboxSizes.sm};
  `,
  label: css<CheckboxWrapper>`
    padding-left: ${({ pure }) => (pure ? 0 : '0.5rem')};
  `,
  labelBefore: css<CheckboxWrapper>`
    width: ${checkboxSizes.sm};
    height: ${checkboxSizes.sm};
    left: -1.125rem;
    top: 0.07rem;
    border-radius: 0.25rem;
  `,
  labelAfter: css<CheckboxWrapper>`
    width: 0.375rem;
    height: 0.625rem;
    left: -0.8125rem;
    top: 0.18rem;
    border-width: 0 0.125rem 0.125rem 0;
  `,
  indeterminate: css<CheckboxWrapper>`
    width: 0.5rem;
    height: 0.0625rem;
    top: 0.5rem;
    left: -0.875rem;
    border-width: 0.125rem 0 0 0;
  `,
}

const mdStyles = {
  input: css<CheckboxWrapper>`
    width: ${checkboxSizes.md};
    height: ${checkboxSizes.md};
  `,
  label: css<CheckboxWrapper>`
    padding-left: ${({ pure }) => (pure ? 0 : '0.75rem')};
  `,
  labelBefore: css<CheckboxWrapper>`
    width: ${checkboxSizes.md};
    height: ${checkboxSizes.md};
    left: -1.125rem;
    top: 0;
    border-radius: 0.375rem;
  `,
  labelAfter: css<CheckboxWrapper>`
    width: 0.375rem;
    height: 0.75rem;
    left: -0.75rem;
    top: 0.125rem;
    border-width: 0 0.125rem 0.125rem 0;
  `,
  indeterminate: css<CheckboxWrapper>`
    width: 0.5rem;
    height: 0.0625rem;
    top: 0.625rem;
    left: -0.75rem;
    border-width: 0.125rem 0 0 0;
  `,
}

const checkboxStyles = ({ size = 'md' }: Pick<CheckboxWrapper, 'size'>) =>
  ({
    sm: smStyles,
    md: mdStyles,
  }[size])

export const CheckboxWrapper = styled(Flex)<CheckboxWrapper>`
  width: fit-content;

  input[type='checkbox'] {
    opacity: 0;
    margin: 0;
    ${({ size = 'md' }) => checkboxStyles({ size }).input};
  }

  input[type='checkbox']:hover + label::before,
  input[type='checkbox']:focus + label::before {
    ${hoverStyles}
  }

  label {
    position: relative;
    display: inline-block;
    cursor: ${cursorDisabled};
    color: ${labelColor};
    transition: color ${({ theme }) => theme.transition};
    ${({ size = 'md' }) => checkboxStyles({ size }).label};
  }

  label::before,
  label::after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  label::before {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${checkboxBorder};
    background: ${checkboxBackground};
    transition: all ${({ theme }) => theme.transition};
    ${({ size = 'md' }) => checkboxStyles({ size }).labelBefore};
  }

  label::after {
    display: ${checkboxMarker};
    border-style: solid;
    border-color: ${({ theme }) => theme.components.checkbox.marker.borderColor};
    transform: rotate(45deg);
    ${({ size = 'md' }) => checkboxStyles({ size }).labelAfter};
  }

  input[type='checkbox']:indeterminate + label::after {
    display: ${checkboxMarker};
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.primary.contrast};
    transform: rotate(0deg);
    ${({ size = 'md' }) => checkboxStyles({ size }).indeterminate};
  }
`
