import dynamic from 'next/dynamic'
import { PropsWithChildren } from 'react'

import { FieldAmountProps } from './amount/field-amount'
import { FieldDateProps } from './date/root/field-date'
import { FieldDateRangeProps } from './date/range/field-date-range'
import { FieldCheckboxProps } from './checkbox/root/field-checkbox'
import { FieldCheckboxGroupProps } from './checkbox/group/field-checkbox-group'
import { FieldFileUploadProps } from './file-upload/root/field-file-upload'
import { FieldMaskProps } from './mask/field-mask'
import { FieldOTP, FieldOtpProps } from './otp/field-otp'
import { FieldPasswordProps } from './password/field-password'
import { FieldPhoneProps } from './phone/field-phone'
import { FieldRadioProps } from './radio/root/field-radio'
import { FieldRadioGroupProps } from './radio/group/field-radio-group'
import { FieldRadioTabGroupProps } from './radio/tab-group/field-radio-tab-group'
import { FieldSelectProps } from './select/field-select'
import { FieldStripeProps } from './stripe/field-stripe'
import { FieldBaseProps } from './base/types'
import { FieldSliderProps } from './slider/root/field-slider'
import { FieldSliderPercentGroupProps } from './slider/percent-group/field-slider-percent-group'
import { FieldFileUploadInstantProps } from './file-upload/instant/field-file-upload-instant'

const FieldAmount = dynamic<FieldAmountProps>(() =>
  import('./amount/field-amount').then((module) => module.FieldAmount),
)

const FieldDate = dynamic<FieldDateProps>(() => import('./date/root/field-date').then((module) => module.FieldDate))

const FieldDateRange = dynamic<FieldDateRangeProps>(() =>
  import('./date/range/field-date-range').then((module) => module.FieldDateRange),
)

const FieldCheckbox = dynamic<FieldCheckboxProps>(() =>
  import('./checkbox/root/field-checkbox').then((module) => module.FieldCheckbox),
)

const FieldCheckboxGroup = dynamic<FieldCheckboxGroupProps>(() =>
  import('./checkbox/group/field-checkbox-group').then((module) => module.FieldCheckboxGroup),
)

const FieldFileUpload = dynamic<FieldFileUploadProps>(() =>
  import('./file-upload/root/field-file-upload').then((module) => module.FieldFileUpload),
)

const FieldFileUploadInstant = dynamic<FieldFileUploadInstantProps>(() =>
  import('./file-upload/instant/field-file-upload-instant').then((module) => module.FieldFileUploadInstant),
)

const FieldMask = dynamic<FieldMaskProps>(() => import('./mask/field-mask').then((module) => module.FieldMask))

const FieldOtp = dynamic<PropsWithChildren<FieldOtpProps>>(() =>
  import('./otp/field-otp').then((module) => module.FieldOtp),
) as FieldOTP

const FieldPassword = dynamic<FieldPasswordProps>(() =>
  import('./password/field-password').then((module) => module.FieldPassword),
)

const FieldPhone = dynamic<FieldPhoneProps>(() => import('./phone/field-phone').then((module) => module.FieldPhone))

const FieldRadio = dynamic<FieldRadioProps>(() =>
  import('./radio/root/field-radio').then((module) => module.FieldRadio),
)

const FieldRadioGroup = dynamic<FieldRadioGroupProps>(() =>
  import('./radio/group/field-radio-group').then((module) => module.FieldRadioGroup),
)

const FieldRadioTabGroup = dynamic<FieldRadioTabGroupProps>(() =>
  import('./radio/tab-group/field-radio-tab-group').then((module) => module.FieldRadioTabGroup),
)

const Field = dynamic<FieldBaseProps>(() => import('./root/field').then((module) => module.Field))

const FieldSelect = dynamic<FieldSelectProps>(() =>
  import('./select/field-select').then((module) => module.FieldSelect),
) as <Value = string>(params: FieldSelectProps<Value>) => JSX.Element

const FieldSlider = dynamic<FieldSliderProps>(() =>
  import('./slider/root/field-slider').then((module) => module.FieldSlider),
)

const FieldSliderPercentGroup = dynamic<FieldSliderPercentGroupProps>(() =>
  import('./slider/percent-group/field-slider-percent-group').then((module) => module.FieldSliderPercentGroup),
)

const FieldStripe = dynamic<FieldStripeProps>(() =>
  import('./stripe/field-stripe').then((module) => module.FieldStripe),
)

export type { FieldRadioTabGroupProps } from './radio/tab-group/field-radio-tab-group'

export * from './select'

export {
  FieldAmount,
  FieldDate,
  FieldDateRange,
  FieldCheckbox,
  FieldCheckboxGroup,
  FieldFileUpload,
  FieldFileUploadInstant,
  FieldMask,
  FieldOtp,
  FieldPassword,
  FieldPhone,
  FieldRadio,
  FieldRadioGroup,
  FieldRadioTabGroup,
  Field,
  FieldSelect,
  FieldSlider,
  FieldSliderPercentGroup,
  FieldStripe,
}
