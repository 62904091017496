import { CachePolicies, Res } from 'use-http'
import { Customer } from '@frontend/shared/types'
import { useApiFetch } from '@frontend/domains/shared/components'
import { useToast } from '@frontend/shared/ui'

import { ENDPOINT_URL } from '../constants'

interface ChangePassword {
  otp: string
  password: string
  passwordNew: string
}

export const useServiceChangePassword = () => {
  const { showToastError } = useToast()
  const { loading, error, data, post, response } = useApiFetch<Customer>({
    cachePolicy: CachePolicies.NETWORK_ONLY,
  })

  const changePassword = async (params: ChangePassword) => {
    const onError = (response: Res<Customer>) => {
      response.status === 400 && showToastError('Invalid credentials', `Old password is not valid. Try it again.`)
    }
    const body = { ...params }
    await post(ENDPOINT_URL.changePassword, body, onError)

    return response.ok
  }

  return { loading, error, data, changePassword }
}
