import React from 'react'
import { SvgProps } from '@frontend/shared/types'

export const LinkedinSolid = ({ color }: SvgProps) => (
  <svg fill='none' height='45' viewBox='0 0 45 45' width='45' xmlns='http://www.w3.org/2000/svg'>
    <title>Earthbanc - linkedin</title>
    <circle cx='22.8272' cy='22.545' fill='#007FB1' r='21.8839' />
    <path
      d={`M18.1992 31.6632H14.0403V18.2701H18.1992V31.6632ZM16.1176 16.4431C14.7877 16.4431 13.709 15.3416 13.709 14.0116C13.709 13.3728 13.9627 12.7602 14.4144 12.3085C14.8661 11.8568 15.4788 11.603 16.1176 11.603C16.7563 11.603 17.369 11.8568 17.8207 12.3085C18.2724 12.7602 18.5261 13.3728 18.5261 14.0116C18.5261 15.3416 17.447 16.4431 16.1176 16.4431ZM33.7647 31.6632H29.6148V25.1435C29.6148 23.5897 29.5834 21.5971 27.4525 21.5971C25.2902 21.5971 24.9588 23.2852 24.9588 25.0316V31.6632H20.8044V18.2701H24.7931V20.097H24.8514C25.4066 19.0447 26.7629 17.9342 28.7864 17.9342C32.9954 17.9342 33.7692 20.706 33.7692 24.3062V31.6632H33.7647Z`}
      fill={color ?? 'white'}
    />
  </svg>
)
