/* eslint-disable react/jsx-props-no-spreading */
import { useDropzone, DropzoneProps, FileRejection } from 'react-dropzone'
import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@frontend/shared/theme'

import { Flex } from '../../../../layout/flex/flex'
import { IconFeatured } from '../../../../text/icon/featured/icon-featured'
import { Text } from '../../../../text/typography'
import { Grid } from '../../../../layout/grid/grid'
import { FileUploadWrapper } from '../base/input-file-upload.styles'

import { MultipleFiles, QueueFile } from './components/multiple-files'

type CustomDropzoneProps = Pick<DropzoneProps, 'disabled' | 'accept' | 'maxFiles' | 'maxSize' | 'validator'>

export interface InputFileUploadInstantProps extends CustomDropzoneProps {
  name: string
  onChangeFiles(files: File[]): void
  touched: boolean
  uploader: (file: File) => Promise<boolean>
  allowUploadAfterComplete?: boolean
  description?: string
}

export const InputFileUploadInstant = ({
  name,
  disabled,
  accept,
  maxFiles,
  maxSize,
  description,
  uploader,
  touched,
  onChangeFiles,
  allowUploadAfterComplete = false,
  validator,
}: InputFileUploadInstantProps) => {
  const theme = useTheme()
  const [queue, setQueue] = useState<QueueFile[]>([])

  const onDropAccepted = (files: File[]) => {
    let filesCount = queue.length

    if (allowUploadAfterComplete && filesCount === maxFiles) {
      setQueue([])
      filesCount = 0
      onChangeFiles([])
    }

    files.forEach((file) => {
      const fileExists = queue.find((queueFile) => queueFile.file.name === file.name)

      if ((maxFiles && maxFiles <= filesCount) || fileExists) {
        return
      }

      setQueue((prevFiles) => [...prevFiles, { file, status: 'waiting', errors: [] }])
      filesCount += 1
    })
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) =>
    rejectedFiles.forEach((rejectedFile) => {
      const fileNotExists = !queue.find((queueFile) => queueFile.file.name === rejectedFile.file.name)

      fileNotExists && setQueue((prevFiles) => [...prevFiles, { ...rejectedFile, status: 'error' }])
    })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    disabled,
    accept,
    maxSize,
    multiple: true,
    validator,
  })

  const removeFile = (name: string) => setQueue((prevFiles) => prevFiles.filter(({ file }) => file.name !== name))

  const upload = useCallback(
    async (queueFile?: QueueFile) => {
      if (!queueFile) {
        return
      }

      const fileUploaded = await uploader(queueFile.file)

      setQueue((prevFiles) =>
        prevFiles.map((prevFile) =>
          prevFile.file.name === queueFile.file.name
            ? { ...prevFile, status: fileUploaded ? 'uploaded' : 'error' }
            : prevFile,
        ),
      )
    },
    [uploader],
  )

  useEffect(() => {
    const files = queue.map(({ file }) => file)
    const queueFileToUpload = queue.find(({ status }) => status === 'waiting')

    upload(queueFileToUpload)
    onChangeFiles(files)
  }, [onChangeFiles, queue, upload])

  const error = !!queue.filter(({ status, errors }) => errors.length > 0 || status === 'error').length
  const filesToUploadCount = queue.filter(({ status }) => status === 'waiting').length
  const filesUploadedCount = queue.filter(({ status }) => status === 'uploaded').length
  const filesFailedCount = queue.filter(({ status }) => status === 'error').length

  return (
    <Grid gap='sm'>
      <FileUploadWrapper
        {...getRootProps()}
        data-test-id='dropzone'
        disabled={disabled}
        dragActive={isDragActive}
        error={error}
        touched={touched}
      >
        <IconFeatured color='gray' kind='FiUpload' />
        {isDragActive ? (
          <Flex mt={2}>
            <Text color={theme.colors.primary.blue.main} size='sm'>
              Drop files here
            </Text>
          </Flex>
        ) : (
          <Flex mt={2}>
            <Text color={theme.colors.primary.blue.main} size='sm'>
              Click to upload&nbsp;
            </Text>
            <Text color={theme.colors.gray['500']} size='sm' weight={400}>
              or drag and drop
            </Text>
          </Flex>
        )}
        {description && (
          <Flex mt={2}>
            <Text color={theme.colors.gray['500']} data-test-id='description' size='sm' textAlign='center' weight={400}>
              {description}
            </Text>
          </Flex>
        )}
        <input {...getInputProps()} id={name} />
      </FileUploadWrapper>

      <Flex justifyContent='space-between'>
        <Flex>
          <Text>{filesToUploadCount}&nbsp;</Text>
          <Text weight={400}> to upload</Text>
        </Flex>
        <Flex>
          <Flex>
            <Text>{filesUploadedCount}&nbsp;</Text>
            <Text weight={400}> completed</Text>
          </Flex>
          {filesFailedCount > 0 ? (
            <Flex>
              <Flex px={1}>/</Flex>
              <Text>{filesFailedCount}&nbsp;</Text>
              <Text weight={400}> failed</Text>
            </Flex>
          ) : null}
        </Flex>
      </Flex>

      <MultipleFiles queue={queue} onRemoveFile={removeFile} />
    </Grid>
  )
}
