import { HTMLAttributes, PropsWithChildren } from 'react'
import {
  layout,
  space,
  flexbox,
  position,
  zIndex,
  gridColumn,
  gridRow,
  background,
  overflow,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  PositionProps,
  ZIndexProps,
  GridColumnProps,
  OverflowProps,
  GridRowProps,
  BackgroundProps,
  display,
  DisplayProps,
} from 'styled-system'
import { styled } from '@frontend/shared/theme'

type CustomFlexProps = Pick<HTMLAttributes<HTMLDivElement>, 'onClick' | 'onKeyDown' | 'role' | 'id' | 'tabIndex'>

export type FlexProps = PropsWithChildren<
  CustomFlexProps &
    DisplayProps &
    LayoutProps &
    SpaceProps &
    FlexboxProps &
    PositionProps &
    ZIndexProps &
    GridColumnProps &
    GridRowProps &
    BackgroundProps &
    OverflowProps
>

export const Flex = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['width', 'height', 'overflow', 'display', 'color', 'order'].includes(prop.toString()) && defaultValidatorFn(prop),
})<FlexProps>`
  display: flex;
  ${display}
  ${position}
  ${zIndex}
  ${space}
  ${layout}
  ${flexbox}
  ${gridColumn}
  ${gridRow}
  ${background}
  ${overflow}
`
