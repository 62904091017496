import React, { ChangeEvent } from 'react'
import { useTheme } from '@frontend/shared/theme'
import { useBoolean } from '@frontend/shared/hooks'

import { InputRadio } from '../../root/input-radio'
import { Flex } from '../../../../../layout/flex/flex'
import { IconFeatured, IconKind, Text } from '../../../../../text'

import { IconWrapper, InputTabRadioWrapper } from './input-tab-radio.styles'

export interface InputTabRadioProps {
  checked: string
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  title: string
  value: string
  description?: string
  disabled?: boolean
  icon?: IconKind
}

export const InputTabRadio = ({
  icon = 'FiLayers',
  title,
  description,
  name,
  onChange,
  value,
  checked,
  disabled,
}: InputTabRadioProps) => {
  const theme = useTheme()
  const { value: hover, setTrue: setHoverTrue, setFalse: setHoverFalse } = useBoolean(false)

  return (
    <InputTabRadioWrapper
      checked={checked === value}
      data-test-id={`field-${name}-${value}`}
      disabled={disabled}
      onClick={() => !disabled && onChange({ target: { name, value } } as ChangeEvent<HTMLInputElement>)}
      onMouseEnter={setHoverTrue}
      onMouseLeave={setHoverFalse}
    >
      <IconWrapper>
        <IconFeatured kind={icon} />
      </IconWrapper>

      <Flex flexDirection='column'>
        <Text size='sm'>{title}</Text>
        {description && (
          <Text color={theme.colors.gray['500']} size='sm'>
            {description}
          </Text>
        )}
      </Flex>

      <Flex>
        <InputRadio
          checked={checked}
          disabled={disabled}
          // TODO-SPURNY (Ales Spurny, 19.11.2021, Add markType as check)
          hover={hover}
          markType='circle'
          name={name}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
        />
      </Flex>
    </InputTabRadioWrapper>
  )
}
