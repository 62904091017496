import { styled, StyledProps } from '@frontend/shared/theme'
import { Logger } from '@frontend/shared/logger'

import { Flex } from '../../../layout/flex/flex'

export type AlertColors = 'success' | 'error' | 'warning' | 'primary' | 'gray'

export interface WrapperProps {
  color: AlertColors
}

const borderColor = ({ color, theme }: StyledProps<WrapperProps>) => {
  switch (color) {
    case 'gray':
      // TODO-SPURNY (Ales Spurny, 07.01.2022, Fix color after figma update)
      return theme.colors.primary.blue
    case 'primary':
      return theme.colors.gray['25']
    case 'success':
      return theme.colors.success['300']
    case 'error':
      return theme.colors.error['300']
    case 'warning':
      return theme.colors.warning['300']
    default:
      Logger.error(`Implement new border color for alert component: ${color}`)

      return theme.colors.primary.blue
  }
}

const backgroundColor = ({ color, theme }: StyledProps<WrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['25']
    case 'primary':
      return theme.colors.primary.blue['25']
    case 'success':
      return theme.colors.success['25']
    case 'error':
      return theme.colors.error['25']
    case 'warning':
      return theme.colors.warning['25']
    default:
      Logger.error(`Implement new background color for alert component: ${color}`)

      return theme.colors.gray['25']
  }
}

export const titleColor = ({ color, theme }: StyledProps<WrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['700']
    case 'primary':
      return theme.colors.primary.darkBlue.mainHover
    case 'success':
      return theme.colors.success['700']
    case 'error':
      return theme.colors.error['700']
    case 'warning':
      return theme.colors.warning['700']
    default:
      Logger.error(`Implement new text color for alert component: ${color}`)

      return theme.colors.gray['700']
  }
}

export const descriptionColor = ({ color, theme }: StyledProps<WrapperProps>) => {
  switch (color) {
    case 'gray':
      return theme.colors.gray['600']
    case 'primary':
      return theme.colors.primary.blue.mainHover
    case 'success':
      return theme.colors.success['600']
    case 'error':
      return theme.colors.error['600']
    case 'warning':
      return theme.colors.warning['600']
    default:
      Logger.error(`Implement new description color for alert component: ${color}`)

      return theme.colors.gray['600']
  }
}

export const alertIcon = (color: AlertColors) => {
  switch (color) {
    case 'gray':
    case 'primary':
    case 'error':
      return 'FiInfo'
    case 'success':
      return 'FiCheckCircle'
    case 'warning':
      return 'FiAlertTriangle'
    default:
      Logger.error(`Implement new alert icon for alert component: ${color}`)

      return 'FiInfo'
  }
}

export const AlertWrapper = styled(Flex)<WrapperProps>`
  background: ${backgroundColor};
  border-width: 0.0625rem;
  border-style: solid;
  border-color: ${borderColor};
  border-radius: 0.5rem;
  padding: 1rem;
`
