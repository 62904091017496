import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'
import { ResponseStatus } from '@frontend/shared/constants'
import { CachePolicies } from 'use-http'
import { useApiFetch } from '@frontend/domains/shared/components'

import { ENDPOINT_URL } from '../constants'

enum RedeemErrorCode {
  AlreadyRedeemed = 'ALREADY_REDEEMED',
  NotFound = 'NOT_FOUND',
}

interface CertificateRedeem {
  errorCode: RedeemErrorCode
  status: ResponseStatus
}

const getRedeemErrorMessage = (code: RedeemErrorCode) => {
  switch (code) {
    case RedeemErrorCode.NotFound:
      return 'The code you have entered is not valid. Please check your input and try again.'
    case RedeemErrorCode.AlreadyRedeemed:
      return 'The code you have entered was already redeemed. Please check your input and try again or contact us.'
    default:
      Logger.error(`There is new redeem error code - ${code}. Please do implementation.`)

      return ''
  }
}

export const useServiceCertificationRedeem = () => {
  const { showToastError } = useToast()
  const { post, data, error, loading } = useApiFetch<CertificateRedeem>({ cachePolicy: CachePolicies.NO_CACHE })

  const certificateRedeem = async (code: string) => {
    const body = { code }
    const result = await post(ENDPOINT_URL.certificateRedeem, body)

    if (result?.status === ResponseStatus.Ok) {
      return true
    }

    if (result?.status === ResponseStatus.Error) {
      const message = getRedeemErrorMessage(result.errorCode)

      showToastError('Redeem validation', message)
    }

    return false
  }

  return { certificateRedeem, data, error, loading }
}
