import { ImageFormat } from '@frontend/shared/types'

export const CURRENCY = 'eur'

export const ENDPOINT_URL = {
  offsetReservations: '/customers/offset/reservations',
  paymentIntents: '/customers/offset/purchases',
  projects: '/projects',
  cmsFile: (id: number, format: ImageFormat = 'MEDIUM') => `/cms/file/${id.toString()}?format=${format}`,
}

export const STORAGE_PARTNER_ID_KEY = 'partnerId'

export const STRIPE_MIN_AMOUNT_LIMIT = 1
export const STRIPE_MAX_AMOUNT_LIMIT = 10000
