import React, { ChangeEvent, InputHTMLAttributes, FocusEvent, KeyboardEvent, forwardRef, useRef, useState } from 'react'
import { useTheme } from '@frontend/shared/theme'
import { Logger } from '@frontend/shared/logger'

import { Icon } from '../../../text'
import { useCombinedRefs } from '../../../hooks/use-combined-refs'
import { PrefixWrapper } from '../../field/base/components/prefix-wrapper'
import { IconLeftWrapper, IconRightWrapper } from '../../field/base/components/icon-wrapper'

import { Content, ErrorWrapper, InputAffix, InputStyled } from './input.styles'

export type InputProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'disabled' | 'autoComplete' | 'maxLength' | 'inputMode' | 'autoFocus' | 'readOnly'
> & {
  name: string
  value: string
  error?: boolean
  focused?: boolean
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  prefix?: InputAffix
  suffix?: InputAffix
  touched?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      value,
      placeholder,
      onChange,
      onBlur,
      onFocus,
      touched,
      error,
      prefix,
      suffix,
      readOnly,
      disabled,
      type,
      autoComplete,
      autoFocus,
      inputMode,
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme()

    const [focused, setFocused] = useState(props.focused || false)
    const innerRef = useRef<HTMLInputElement>(null)
    const combinedRef = useCombinedRefs(ref, innerRef)

    const id = name

    const inputFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      onFocus?.(event)
    }

    const inputBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      onBlur?.(event)
    }

    const getPrefix = () => {
      if (prefix) {
        return prefix?.type === 'LeadingText' ? (
          <PrefixWrapper>{prefix.component}</PrefixWrapper>
        ) : (
          <IconLeftWrapper>{prefix.component}</IconLeftWrapper>
        )
      }

      return null
    }

    const getSuffix = () => {
      if (suffix) {
        if (suffix?.type === 'LeadingText') {
          Logger.error('Field suffix Leading text is not implemented yet')

          return null
        }

        return <IconRightWrapper>{suffix.component}</IconRightWrapper>
      }

      return null
    }

    return (
      <Content focused={focused}>
        {getPrefix()}
        <InputStyled
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          customPrefix={prefix}
          disabled={disabled}
          error={error}
          id={id}
          inputMode={inputMode}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={combinedRef}
          touched={touched}
          type={type}
          value={value}
          onBlur={inputBlurHandler}
          onChange={onChange}
          onFocus={inputFocusHandler}
          onKeyDown={onKeyDown}
        />
        {getSuffix()}
        {error && touched && !suffix?.component && (
          <ErrorWrapper alignItems='center'>
            <Icon color={theme.colors.error['500']} kind='FiAlertCircle' />
          </ErrorWrapper>
        )}
      </Content>
    )
  },
)
