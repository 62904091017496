import { colors } from './colors'

export const components = {
  body: {
    backgroundColor: colors.secondary.bgGray.main,
    textColor: colors.gray['900'],
  },
  h1: {
    textColor: colors.primary.darkBlue.main,
  },
  badge: {
    gray: {
      wrapper: {
        backgroundColor: colors.gray['100'],
      },
      label: {
        textColor: colors.gray['700'],
      },
    },
  },
  button: {
    linkColor: {
      textColor: colors.primary.blue.main,
    },
    primary: {
      backgroundColor: colors.primary.mainGradient.main,
      textColor: colors.primary.contrast,
      ':hover': {
        backgroundColor: colors.primary.mainGradient.mainHover,
      },
    },
    tertiaryGray: {
      backgroundColor: colors.primary.contrast,
      textColor: colors.gray['500'],
      ':hover': {
        backgroundColor: colors.gray['50'],
      },
    },
    secondaryGray: {
      backgroundColor: colors.primary.contrast,
      borderColor: colors.primary.blue.main,
      textColor: colors.primary.blue.main,
    },
    loader: {
      primary: {
        backgroundColor: colors.primary.contrast,
      },
      secondaryColor: {
        backgroundColor: colors.primary.contrast,
      },
      others: {
        backgroundColor: colors.primary.blue.main,
      },
      ':disabled': {
        backgroundColor: colors.gray['700'],
      },
    },
  },
  card: {
    description: {
      textColor: colors.gray['600'],
    },
    place: {
      textColor: colors.gray['600'],
    },
    price: {
      textColor: colors.gray['600'],
    },
    wrapper: {
      backgroundColor: colors.primary.contrast,
      borderColor: colors.gray['200'],
    },
    ticker: {
      textColor: colors.gray['500'],
    },
    title: {
      textColor: colors.gray['700'],
    },
  },
  carousel: {
    arrow: {
      backgroundColor: colors.gray['200'],
      textColor: colors.gray['600'],
    },
    dot: {
      wrapper: {
        backgroundColor: colors.gray['200'],
      },
      ':before': {
        backgroundColor: colors.gray['500'],
        ':selected': {
          backgroundColor: colors.primary.contrast,
        },
      },
    },
    wrapper: {
      backgroundColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.7) -4.76%, rgba(0, 0, 0, 0) 20.92%)',
    },
  },
  fieldWrapper: {
    label: {
      textColor: colors.gray['700'],
    },
    message: {
      error: {
        textColor: colors.error['500'],
      },
      hint: {
        textColor: colors.gray['500'],
      },
    },
  },
  checkbox: {
    backgroundColor: colors.gray['50'],
    borderColor: colors.gray['300'],
    textColor: colors.gray['700'],
    marker: {
      borderColor: colors.primary.contrast,
    },
    ':checked': {
      backgroundColor: colors.primary.blue.main,
      borderColor: colors.primary.blue.main,
    },
  },
  iconFeatured: {
    lightCircleOutline: {
      error: {
        backgroundColor: colors.error['600'],
      },
      warning: {
        backgroundColor: colors.warning['600'],
      },
      success: {
        backgroundColor: colors.success['600'],
      },
    },
  },
  input: {
    backgroundColor: colors.primary.contrast,
    textColor: colors.gray['900'],
    borderColor: colors.gray['300'],
    ':error': {
      borderColor: colors.error['500'],
    },
    ':focused': {
      borderColor: colors.primary.blue.main,
    },
    ':placeholder': {
      textColor: colors.gray['500'],
    },
  },
  inputRadio: {
    backgroundColor: colors.primary.contrast,
    borderColor: colors.gray['300'],
    ':hover': {
      boxShadowColor: 'rgba(20, 155, 165, 0.25)',
    },
    ':checked': {
      backgroundColor: colors.primary.blue.mainHover,
      borderColor: colors.primary.blue.mainHover,
    },
  },
  inputTabRadio: {
    wrapper: {
      backgroundColor: colors.primary.contrast,
      borderColor: colors.gray['200'],
      ':checked': {
        borderColor: colors.primary.blue.main,
      },
    },
  },
  loader: {
    backgroundColor: colors.primary.blue['50'],
    textColor: colors.primary.blue.mainHover,
  },
  markdown: {
    textColor: colors.gray['700'],
  },
  section: {
    backgroundColor: colors.primary.contrast,
    borderColor: colors.gray['200'],
  },
  select: {
    option: {
      backgroundColor: colors.primary.contrast,
      textColor: colors.gray['900'],
      mark: {
        backgroundColor: colors.primary.blue.mainHover,
      },
      ':selected': {
        backgroundColor: colors.gray['100'],
        textColor: colors.gray['900'],
      },
    },
    dropdownIndicator: {
      textColor: colors.gray['500'],
    },
  },
  slider: {
    thumb: {
      backgroundColor: colors.primary.contrast,
    },
    track: {
      backgroundColor: colors.gray['200'],
      ':checked': {
        backgroundColor: colors.primary.blue.mainHover,
      },
    },
  },
  toast: {
    progress: {
      error: {
        backgroundColor: colors.error['600'],
      },
      warning: {
        backgroundColor: colors.warning['600'],
      },
      success: {
        backgroundColor: colors.success['600'],
      },
    },
    wrapper: {
      backgroundColor: colors.primary.contrast,
      textColor: colors.gray['500'],
    },
  },
}
