import { Grid, Skeleton, Section, Flex } from '@frontend/shared/ui'
import React from 'react'

export const DetailSkeleton = () => (
  <Grid>
    <Skeleton height='3rem' />

    <Grid gridTemplateColumns={['1fr', null, null, '3fr 24rem']}>
      <Section gridAutoRows='max-content' noPadding>
        <Flex height={['10rem', '14rem', '16rem', '20.75rem']} position='relative'>
          <Skeleton height='100%' style={{ lineHeight: '1.4' }} width='100%' />
        </Flex>

        <Grid gap='sm' pb={5} px={5}>
          <Skeleton height='2rem' />

          <Skeleton height='10rem' />
        </Grid>
      </Section>

      <Grid
        gridAutoRows='max-content'
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '100%']}
        order={[1, null, null, 2]}
      >
        <Section>
          <Skeleton height='20rem' />
        </Section>

        <Section>
          <Skeleton height='20rem' />
        </Section>
      </Grid>
    </Grid>
  </Grid>
)
