import { Grid } from '@frontend/shared/ui'
import { styled, css } from '@frontend/shared/theme'

interface PaymentTypeWrapperProps {
  active: boolean
}

export const PaymentTypeWrapper = styled(Grid)<PaymentTypeWrapperProps>`
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: all ${({ theme }) => theme.transition};

  ${({ active }) =>
    active
      ? css`
          position: static;
        `
      : css`
          position: absolute;
          left: -9999px;
        `}
`
