import React from 'react'
import { Badge, Flex, Grid, Icon, Section, Text, TextNonBreakable } from '@frontend/shared/ui'
import { Project } from '@frontend/shared/types'
import { formatNumber } from '@frontend/shared/utils'

import { StickyPanel } from '../../ui/screen/sticky-panel/sticky-panel'
import { useTheme } from '../../ui/theme/theme-iframe/styled-components'
import { getRating } from '../../utils'
import { Routes } from '../../types'

import { SectionFaq } from './components/section-faq'
import { SectionForm, SectionFormProps } from './components/section-form/section-form'
import { FormValues } from './components/section-form/types'

interface SidebarTreeProps extends Pick<SectionFormProps, 'customer'> {
  onSubmitForm(values: FormValues): Promise<boolean>
  place: string
  project: Project
  routes: Routes
}

export const SidebarTree = ({ project, place, customer, onSubmitForm, routes }: SidebarTreeProps) => {
  const theme = useTheme()

  const { name, tags, sellingUnit, ticker, ratings, prices, plantingImpact } = project
  const { treeOffsetOverLifetimeInTonnes, jobsCreated, ecosystemServices } = plantingImpact ?? {}

  const rating = getRating(ratings)

  return (
    <StickyPanel>
      <Section gap='md'>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex flex='auto'>
            <Text data-test-id='name'>{name}</Text>
          </Flex>

          <Flex alignItems='center' flex='auto' justifyContent='flex-end'>
            <Flex mr={1}>
              <Icon color={theme.components.detail.place.textColor} kind='FiMapPin' />{' '}
            </Flex>
            <Flex flex={0}>
              <Text color={theme.components.detail.place.textColor} data-test-id='place' size='md'>
                {place}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Grid gap='sm'>
          {rating && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='earthbanc-rating-title'>Earthbanc Rating:</Text>
              <Flex data-test-id='earthbanc-rating-value' justifyContent='flex-end'>
                {rating.value}
              </Flex>
            </Grid>
          )}

          {tags && tags.length > 0 && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='type-title'>Type:</Text>

              <Flex data-test-id='type-value' flexWrap='wrap' justifyContent='flex-end' m='-0.5rem'>
                {tags.map((tag) => (
                  <Flex flex='0 auto' key={tag} m='0.5rem'>
                    <Badge color='gray' label={tag} />
                  </Flex>
                ))}
              </Flex>
            </Grid>
          )}
        </Grid>
      </Section>

      <SectionFaq routes={routes} sellingUnit={sellingUnit} />

      <Section gap='md'>
        <Text>
          The price of planting and maintaining of one tree is <Text weight={700}>{formatNumber(prices.eur)} EUR</Text>.
        </Text>
        {treeOffsetOverLifetimeInTonnes && (
          <Text>
            Each tree offsets approximately{' '}
            <TextNonBreakable weight={700}>
              {formatNumber(treeOffsetOverLifetimeInTonnes)} {treeOffsetOverLifetimeInTonnes > 1 ? 'tonnes' : 'tonne'}
            </TextNonBreakable>{' '}
            of CO2e over lifetime.
          </Text>
        )}
        {jobsCreated && (
          <Text>
            Project creates and maintains <Text weight={700}>{formatNumber(parseFloat(jobsCreated))} jobs</Text>.
          </Text>
        )}
        {ecosystemServices && (
          <Text>
            Project brings more than <Text weight={700}>{formatNumber(parseFloat(ecosystemServices))} EUR</Text> worth
            of ecosystem services.
          </Text>
        )}
      </Section>

      <SectionForm
        customer={customer}
        productPrice={prices.eur}
        sellingUnit={sellingUnit}
        submitForm={onSubmitForm}
        ticker={ticker}
      />
    </StickyPanel>
  )
}
