import { getCookieDomain } from '@frontend/shared/utils'

import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

import { SuccessfullyAuthenticatedResponse } from './types'

interface AuthenticateOtpRequest {
  execution: string
  otp: string
  sessionCode: string
  tabId: string
}

interface AuthenticateOTPError {
  execution: string
  sessionCode: string
  tabId: string
}

/**
 * Authenticates a user with OTP code.
 * User has already registered OTP method and try to sign in via OTP
 */
export const useServiceAuthenticateOtp = () => {
  const { post, error, loading } = useAuthFetch<SuccessfullyAuthenticatedResponse>()

  const authenticateOTP = async (
    params: AuthenticateOtpRequest,
  ): Promise<SuccessfullyAuthenticatedResponse | AuthenticateOTPError> => {
    const body = { cookieDomain: getCookieDomain(), ...params }

    return post(ENDPOINT_URL.authenticateOTP, body)
  }

  return { authenticateOTP, error, loading }
}
