/* eslint-disable no-console */
import { schemaTypeSafeParseAsync } from '@frontend/shared/utils'

import { themeIframe, ThemeIframe } from '../theme-iframe/theme'

import { ThemePublic, themePublicValidationSchema } from './theme-public.validation-schema'

const getBadge = ({ backgroundColor, textColor }: ThemePublic['components']['badge']) => ({
  gray: {
    wrapper: { backgroundColor },
    label: { textColor },
  },
})

const getButtons = (button: ThemePublic['components']['button'], anchor: ThemePublic['components']['anchor']) => ({
  primary: button.primary,
  tertiaryGray: button.secondary,
  linkColor: anchor,
})

export const parsePublicTheme = async (publicTheme: ThemePublic) => {
  try {
    return await schemaTypeSafeParseAsync<ThemePublic>(themePublicValidationSchema, publicTheme, {
      stripUnknown: true,
    })
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert('Invalid public theme. Check developers console for more information')
    console.error('Invalid public theme configuration. Please, use valid theme.')
    console.error(error)
    console.info('!!! Contact provider for more information if you have trouble to set proper theme !!!')
    console.info('!!! Support only for (hex, rgb, rgba, hsl, hsla, named) HTML colors !!!')

    return null
  }
}

export const mapPublicTheme = async (publicTheme: ThemePublic): Promise<ThemeIframe> => {
  const { mergeDeepWith } = await import('ramda')
  const { body, page } = publicTheme

  const badge = getBadge(publicTheme.components.badge)
  const button = getButtons(publicTheme.components.button, publicTheme.components.anchor)
  const internalComponents = { body, ...page, ...publicTheme.components, badge, button }

  const comparator = (oldValue: string | undefined, newValue: string | undefined) => newValue || oldValue
  const components = mergeDeepWith(comparator, themeIframe.components, internalComponents)

  return { ...themeIframe, components }
}
