import { FC, CSSProperties } from 'react'
import { css, styled } from '@frontend/shared/theme'

export type DividerProps = {
  orientation?: 'vertical' | 'horizontal'
  type?: 'solid' | 'dashed' | 'dotted'
} & Pick<CSSProperties, 'color' | 'opacity' | 'margin' | 'width' | 'height'>

const verticalStyles = css<DividerProps>`
  height: ${({ height }) => height || '100%'};
  width: 0;
  margin: ${({ margin }) => margin || 'auto 0'};

  border-right-style: ${({ type = 'solid' }) => type};
  border-right-color: ${({ theme, color }) => color ?? theme.colors.gray['200']};
  border-right-width: ${({ width }) => width || '0.0625rem'};
  border-left: none;
`

const horizontalStyles = css<DividerProps>`
  width: ${({ width = '100%' }) => width};
  height: 0;
  margin: ${({ margin }) => margin || '0 auto'};

  border-top-style: ${({ type = 'solid' }) => type};
  border-top-color: ${({ theme, color }) => color ?? theme.colors.gray['200']};
  border-top-width: ${({ height }) => height || '0.0625rem'};
  border-bottom: none;
`

const orientationStyles = {
  vertical: verticalStyles,
  horizontal: horizontalStyles,
}

export const Divider: FC<DividerProps> = styled.hr<DividerProps>`
  ${({ orientation = 'horizontal' }) => orientationStyles[orientation]};

  opacity: ${({ opacity }) => opacity};
`
