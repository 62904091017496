import { styled } from '@frontend/shared/theme'
import { Flex } from '@frontend/shared/ui'

export const HEADER_HEIGHT = '4.5rem'

export const HeaderWrapper = styled(Flex).attrs(() => ({
  alignItems: 'center',
  width: '100%',
  height: HEADER_HEIGHT,
}))`
  background-color: ${({ theme }) => theme.colors.primary.darkBlue.main};
`
