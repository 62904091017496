import { SubscriptionType } from '@frontend/shared/types'

export enum PaymentType {
  Sepa = 'sepa',
  CreditCard = 'creditCard',
}

export interface FormValues {
  email: string
  gift: boolean
  name: string
  paymentType: PaymentType
  subscription: SubscriptionType
  cardNumber?: boolean
  iban?: boolean
}
