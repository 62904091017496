import { CachePolicies } from 'use-http'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'
import { getLocalStorage } from '@frontend/shared/utils'
import { SellingUnit } from '@frontend/shared/types'
import { useApiFetch } from '@frontend/domains/shared/components'

import { ENDPOINT_URL, STORAGE_PARTNER_ID_KEY } from '../../../../constants'

interface CreatePaymentIntent {
  currency: 'eur'
  email: string
  gift: boolean
  name: string
  projectTicker: string
  quantity: number
  sellingUnit: SellingUnit
  subscription: boolean
  paymentMethod?: string
  vintage?: string
}

interface PaymentIntent {
  clientSecret: string
}

export const useServiceCreatePaymentIntent = () => {
  const { showToastGenericError } = useToast()
  const { post, error, loading, response } = useApiFetch<PaymentIntent>({
    cachePolicy: CachePolicies.NETWORK_ONLY,
  })

  const createPaymentIntent = async (params: CreatePaymentIntent): Promise<PaymentIntent | undefined> => {
    const { get } = getLocalStorage<string>(STORAGE_PARTNER_ID_KEY)
    const partnerId = get()

    const body = { ...params, partnerId }
    const result = await post(ENDPOINT_URL.paymentIntents, body)

    if (400 <= response.status && response.status < 500) {
      Logger.error('An error occurred when create payment intent.', result)
      showToastGenericError()

      return undefined
    }

    return result
  }

  return { createPaymentIntent, loading, error }
}
