import React from 'react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import dayjs from 'dayjs'
import { Logger } from '@frontend/shared/logger'
import { DATE_FORMAT } from '@frontend/shared/constants'

import { Icon } from '../../../../text/icon'
import { DatePickerProps, DatepickerStyle } from '../types'
import { Flex } from '../../../../layout/flex'

import { CalendarMonths } from './calendar-months'
import { CalendarYears } from './calendar-years'
import { CustomHeaderButton, CustomHeaderArrow } from './custom-header.styles'

interface CustomHeaderProps extends Pick<DatePickerProps, 'selectsRange' | 'translations' | 'rangeYears'> {
  datepickerStyle: DatepickerStyle[]
  setDatepickerStyle: React.Dispatch<React.SetStateAction<DatepickerStyle[]>>
}

export const CustomHeader =
  ({ setDatepickerStyle, datepickerStyle, selectsRange, translations, rangeYears }: CustomHeaderProps) =>
  ({
    monthDate,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    customHeaderCount,
  }: ReactDatePickerCustomHeaderProps) => {
    const changeDatepickerStyle = (datepickerStyle: DatepickerStyle) =>
      setDatepickerStyle((prevStyle) => {
        const copy = [...prevStyle]
        copy[customHeaderCount] = datepickerStyle

        return copy
      })

    const monthChangeHandler = (month: number) => {
      changeMonth(month)
      changeDatepickerStyle('days')
    }

    const yearChangeHandler = (year: number) => {
      changeYear(year)
      changeDatepickerStyle('days')
    }

    const days = (
      <Flex>
        {customHeaderCount === 0 && (
          <CustomHeaderArrow disabled={prevMonthButtonDisabled} onClick={decreaseMonth}>
            <Icon fontSize='1.25rem' kind='FiChevronLeft' />
          </CustomHeaderArrow>
        )}
        <CustomHeaderButton onClick={() => changeDatepickerStyle('months')}>
          {dayjs(monthDate).format(DATE_FORMAT.fullMonthName)}
        </CustomHeaderButton>
        <CustomHeaderButton onClick={() => changeDatepickerStyle('years')}>
          {monthDate.getFullYear()}
        </CustomHeaderButton>
        {(customHeaderCount === 1 || !selectsRange) && (
          <CustomHeaderArrow disabled={nextMonthButtonDisabled} justifyContent='flex-end' onClick={increaseMonth}>
            <Icon fontSize='1.25rem' kind='FiChevronRight' />
          </CustomHeaderArrow>
        )}
      </Flex>
    )

    switch (datepickerStyle[customHeaderCount]) {
      case 'days':
        return days
      case 'months':
        return (
          <CalendarMonths
            actualMonth={monthDate.getMonth()}
            translations={translations}
            onChange={monthChangeHandler}
          />
        )
      case 'years':
        return (
          <CalendarYears
            actualYear={monthDate.getFullYear()}
            rangeYears={rangeYears}
            translations={translations}
            onChange={yearChangeHandler}
          />
        )
      default:
        Logger.error(`Implement new datepicker type - ${datepickerStyle[customHeaderCount]}`)

        return days
    }
  }
