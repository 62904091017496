import 'styled-components'

import { colors } from './colors'
import {
  blurs,
  breakpoints,
  fontFamily,
  fontSizes,
  fontWeights,
  gridGap,
  shadows,
  space,
  transition,
} from './constants'
import { components } from './components'

export interface Theme {
  blurs: typeof blurs
  breakpoints: typeof breakpoints
  colors: typeof colors
  components: typeof components
  fontFamily: typeof fontFamily
  fontSizes: typeof fontSizes
  fontWeights: typeof fontWeights
  gridGap: typeof gridGap
  shadows: typeof shadows
  space: typeof space
  transition: typeof transition
}

export const theme: Theme = {
  blurs,
  breakpoints,
  colors,
  components,
  fontFamily,
  fontSizes,
  fontWeights,
  gridGap,
  shadows,
  space,
  transition,
}
