export enum LoginAction {
  Authenticate = 'AUTHENTICATE',
  AuthenticateOTP = 'AUTHENTICATE_OTP',
  IssueToken = 'ISSUE_TOKEN',
  None = 'NONE',
  RegisterOTP = 'REGISTER_OTP',
  UpdatePassword = 'UPDATE_PASSWORD',
}

export interface SuccessfullyAuthenticatedResponse {
  action: LoginAction
  code: string
  sessionState: string
}

export interface OtpQrCodeResponse {
  action: LoginAction
  otpQrCodeBase64: string
  otpSecret: string
  sessionCode: string
}

export interface AuthenticateResponse {
  action: LoginAction
  errorCode: string
  execution: string
  sessionCode: string
  tabId: string
}

export interface AuthenticateOTPError {
  execution: string
  sessionCode: string
  tabId: string
}
