import { Router } from 'next/router'
import { SubscriptionType } from '@frontend/shared/types'

import { CarbonOffsetProjectCheckoutQueries } from '../types'

export const useQueryParserProjectCheckout = (query: Router['query']): CarbonOffsetProjectCheckoutQueries => {
  const quantity = (query.quantity && Number(query.quantity)) || 1
  const subscription = (query.subscription && Number(query.subscription)) || SubscriptionType.Onetime
  const name = typeof query?.name === 'string' ? query.name : ''
  const email = typeof query?.email === 'string' ? query.email : ''

  const queryParams = {
    gift: query.gift === 'true',
    quantity,
    subscription: subscription === SubscriptionType.Monthly ? SubscriptionType.Monthly : SubscriptionType.Onetime,
    ticker: String(query.ticker),
    name,
    email,
  }

  query.vintage && Object.assign(queryParams, { vintage: String(query.vintage) })

  return queryParams
}
