import React, { forwardRef, useState, FocusEvent } from 'react'
import { Props } from 'react-input-mask'
import dynamic from 'next/dynamic'
import { Logger } from '@frontend/shared/logger'

import { Content, InputAffix, InputStyled, InputStyledProps } from '../root/input.styles'
import { IconRightWrapper } from '../../field/base/components/icon-wrapper'

export interface InputMaskProps
  extends Pick<Props, 'mask'>,
    Pick<
      InputStyledProps,
      'name' | 'value' | 'onChange' | 'onBlur' | 'onFocus' | 'placeholder' | 'error' | 'disabled' | 'touched'
    > {
  focused?: boolean
  maskPlaceholder?: string
  suffix?: InputAffix
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const DynamicInputMask = dynamic(() => import('react-input-mask'), { ssr: false })

export const InputMask = forwardRef<HTMLInputElement, InputMaskProps>(
  (
    {
      value,
      mask,
      error,
      onBlur,
      onFocus,
      name,
      touched,
      placeholder,
      maskPlaceholder,
      disabled,
      onChange,
      suffix,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(props.focused || false)

    const inputFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      onFocus?.(event)
    }

    const inputBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      onBlur?.(event)
    }

    const getSuffix = () => {
      if (suffix) {
        if (suffix?.type === 'LeadingText') {
          Logger.error('Field suffix Leading text is not implemented yet')

          return null
        }

        return <IconRightWrapper>{suffix.component}</IconRightWrapper>
      }

      return null
    }

    return (
      <Content error={error} focused={focused} touched={touched}>
        <DynamicInputMask
          alwaysShowMask
          disabled={disabled}
          id={name}
          mask={focused ? mask : ''}
          maskPlaceholder={focused ? maskPlaceholder : undefined}
          name={name}
          placeholder={focused ? undefined : placeholder}
          type='text'
          value={value}
          onBlur={inputBlurHandler}
          onChange={onChange}
          onFocus={inputFocusHandler}
        >
          <InputStyled error={error} ref={ref} touched={touched} />
        </DynamicInputMask>
        {getSuffix()}
      </Content>
    )
  },
)
