import { useAuthFetch } from '../helper'
import { ENDPOINT_URL } from '../../constants'

/**
 * Enables two-factor authentication with OTP for the currently authenticated user.
 */
export const useServiceEnableOtpAuthentication = () => {
  const { post, error, loading, response } = useAuthFetch<unknown>()

  const enableOTPAuthentication = async (): Promise<boolean> => {
    await post(ENDPOINT_URL.enableOTPAuthentication)

    return response.ok
  }

  return { enableOTPAuthentication, error, loading }
}
