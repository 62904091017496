import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { CreatePaymentMethodData, PaymentMethod } from '@stripe/stripe-js'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'

import { FormValues, PaymentType } from '../form/types'

type CreatePaymentMethod = Pick<FormValues, 'name' | 'email' | 'paymentType'>

type CardOptions = Pick<CreatePaymentMethod, 'name' | 'email'>

export const useServiceCreatePaymentMethod = () => {
  const { showToastWarning, showToastGenericError } = useToast()
  const stripe = useStripe()
  const elements = useElements()

  const cardOptions = ({ name, email }: CardOptions): CreatePaymentMethodData | undefined => {
    if (!(stripe && elements)) {
      return undefined
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      Logger.error('Card element was not found')
      showToastGenericError()

      return undefined
    }

    return { type: 'card', card: cardElement, billing_details: { name, email } }
  }

  const ibanOptions = ({ name, email }: CardOptions): CreatePaymentMethodData | undefined => {
    if (!(stripe && elements)) {
      return undefined
    }

    const ibanElement = elements.getElement(IbanElement)

    if (!ibanElement) {
      Logger.error('Iban element was not found')
      showToastGenericError()

      return undefined
    }

    return { type: 'sepa_debit', sepa_debit: ibanElement, billing_details: { name, email } }
  }

  const createPaymentMethod = async ({
    email,
    name,
    paymentType,
  }: CreatePaymentMethod): Promise<PaymentMethod | undefined> => {
    if (!(stripe && elements)) {
      return undefined
    }

    const options = paymentType === PaymentType.Sepa ? ibanOptions({ name, email }) : cardOptions({ name, email })

    if (options) {
      const result = await stripe.createPaymentMethod(options)

      if (result?.error) {
        Logger.error(result.error)
        showToastWarning('There was a problem with your payment', result?.error?.message ?? '')

        return undefined
      }

      return result.paymentMethod
    }

    return undefined
  }

  return { createPaymentMethod }
}
