import { ITooltip as TooltipPropsAlias } from 'react-tooltip'
import React from 'react'

import { TooltipWrapper } from './tooltip.styles'

export interface TooltipProps extends Omit<TooltipPropsAlias, 'variant'> {
  variant?: 'dark' | 'light'
}

export const Tooltip = ({ variant = 'light', ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TooltipWrapper {...props} variant={variant} />
)
