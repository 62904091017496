import { createGlobalStyle } from './styled-components'

export const GlobalStyle = createGlobalStyle`
  html, body{
    height: 100%;
  }

  html {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    line-height: 1.4;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.components.body.backgroundColor};
    color: ${({ theme }) => theme.components.body.textColor};
  }

  * {
    box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }
`
