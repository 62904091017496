import { addMethod, string, StringSchema } from 'yup'

addMethod<StringSchema>(string, 'upperLimit', function upperLimit(max: number, message?: string) {
  return this.test('upper-limit', message ?? `Maximum is ${max}`, (value) => {
    if (!value) {
      return true
    }

    return parseFloat(value) <= max
  })
})
