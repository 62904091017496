export const COUNTRIES = [
  {
    country: 'Afghanistan',
    noun: 'Afghan(s)',
    adj: 'Afghan',
    alphaTwo: 'AF',
    alphaThree: 'AFG',
    countryCode: 4,
  },
  {
    country: 'Aland Islands',
    noun: 'Aland Islander(s)',
    adj: 'Aland Islander',
    alphaTwo: 'AX',
    alphaThree: 'ALA',
    countryCode: 248,
  },
  {
    country: 'Albania',
    noun: 'Albanian(s)',
    adj: 'Albanian',
    alphaTwo: 'AL',
    alphaThree: 'ALB',
    countryCode: 8,
  },
  {
    country: 'Algeria',
    noun: 'Algerian(s)',
    adj: 'Algerian',
    alphaTwo: 'DZ',
    alphaThree: 'DZA',
    countryCode: 12,
  },
  {
    country: 'American Samoa',
    noun: 'American Samoan(s) (US nationals)',
    adj: 'American Samoan',
    alphaTwo: 'AS',
    alphaThree: 'ASM',
    countryCode: 16,
  },
  {
    country: 'Andorra',
    noun: 'Andorran(s)',
    adj: 'Andorran',
    alphaTwo: 'AD',
    alphaThree: 'AND',
    countryCode: 20,
  },
  {
    country: 'Angola',
    noun: 'Angolan(s)',
    adj: 'Angolan',
    alphaTwo: 'AO',
    alphaThree: 'AGO',
    countryCode: 24,
  },
  {
    country: 'Anguilla',
    noun: 'Anguillan(s)',
    adj: 'Anguillan',
    alphaTwo: 'AI',
    alphaThree: 'AIA',
    countryCode: 660,
  },
  {
    country: 'Antarctica',
    noun: 'Antarctic(s)',
    adj: 'Antarctic',
    alphaTwo: 'AQ',
    alphaThree: 'ATA',
    countryCode: 10,
  },
  {
    country: 'Antigua and Barbuda',
    noun: 'Antiguan(s), Barbudan(s)',
    adj: 'Antiguan, Barbudan',
    alphaTwo: 'AG',
    alphaThree: 'ATG',
    countryCode: 28,
  },
  {
    country: 'Argentina',
    noun: 'Argentine(s)',
    adj: 'Argentine',
    alphaTwo: 'AR',
    alphaThree: 'ARG',
    countryCode: 32,
  },
  {
    country: 'Armenia',
    noun: 'Armenian(s)',
    adj: 'Armenian',
    alphaTwo: 'AM',
    alphaThree: 'ARM',
    countryCode: 51,
  },
  {
    country: 'Aruba',
    noun: 'Aruban(s)',
    adj: 'Aruban; Dutch',
    alphaTwo: 'AW',
    alphaThree: 'ABW',
    countryCode: 533,
  },
  {
    country: 'Australia',
    noun: 'Australian(s)',
    adj: 'Australian',
    alphaTwo: 'AU',
    alphaThree: 'AUS',
    countryCode: 36,
  },
  {
    country: 'Austria',
    noun: 'Austrian(s)',
    adj: 'Austrian',
    alphaTwo: 'AT',
    alphaThree: 'AUT',
    countryCode: 40,
  },
  {
    country: 'Azerbaijan',
    noun: 'Azerbaijani(s)',
    adj: 'Azerbaijani',
    alphaTwo: 'AZ',
    alphaThree: 'AZE',
    countryCode: 31,
  },
  {
    country: 'Bahamas',
    noun: 'Bahamian(s)',
    adj: 'Bahamian',
    alphaTwo: 'BS',
    alphaThree: 'BHS',
    countryCode: 44,
  },
  {
    country: 'Bahrain',
    noun: 'Bahraini(s)',
    adj: 'Bahraini',
    alphaTwo: 'BH',
    alphaThree: 'BHR',
    countryCode: 48,
  },
  {
    country: 'Bangladesh',
    noun: 'Bangladeshi(s)',
    adj: 'Bangladeshi',
    alphaTwo: 'BD',
    alphaThree: 'BGD',
    countryCode: 50,
  },
  {
    country: 'Barbados',
    noun: 'Barbadian(s)',
    adj: 'Barbadian',
    alphaTwo: 'BB',
    alphaThree: 'BRB',
    countryCode: 52,
  },
  {
    country: 'Belarus',
    noun: 'Belarusian(s)',
    adj: 'Belarusian',
    alphaTwo: 'BY',
    alphaThree: 'BLR',
    countryCode: 112,
  },
  {
    country: 'Belgium',
    noun: 'Belgian(s)',
    adj: 'Belgian',
    alphaTwo: 'BE',
    alphaThree: 'BEL',
    countryCode: 56,
  },
  {
    country: 'Belize',
    noun: 'Belizean(s)',
    adj: 'Belizean',
    alphaTwo: 'BZ',
    alphaThree: 'BLZ',
    countryCode: 84,
  },
  {
    country: 'Benin',
    noun: 'Beninese (singular and plural)',
    adj: 'Beninese',
    alphaTwo: 'BJ',
    alphaThree: 'BEN',
    countryCode: 204,
  },
  {
    country: 'Bermuda',
    noun: 'Bermudian(s)',
    adj: 'Bermudian',
    alphaTwo: 'BM',
    alphaThree: 'BMU',
    countryCode: 60,
  },
  {
    country: 'Bhutan',
    noun: 'Bhutanese (singular and plural)',
    adj: 'Bhutanese',
    alphaTwo: 'BT',
    alphaThree: 'BTN',
    countryCode: 64,
  },
  {
    country: 'Bolivia',
    noun: 'Bolivian(s)',
    adj: 'Bolivian',
    alphaTwo: 'BO',
    alphaThree: 'BOL',
    countryCode: 68,
  },
  {
    country: 'Bosnia and Herzegovina',
    noun: 'Bosnian(s), Herzegovinian(s)',
    adj: 'Bosnian, Herzegovinian',
    alphaTwo: 'BA',
    alphaThree: 'BIH',
    countryCode: 70,
  },
  {
    country: 'Botswana',
    noun: 'Motswana (singular), Batswana (plural)',
    adj: 'Motswana (singular), Batswana (plural)',
    alphaTwo: 'BW',
    alphaThree: 'BWA',
    countryCode: 72,
  },
  {
    country: 'Bouvet Island',
    noun: 'Bouvet Islander(s)',
    adj: 'Bouvet Islander',
    alphaTwo: 'BV',
    alphaThree: 'BVT',
    countryCode: 74,
  },
  {
    country: 'Brazil',
    noun: 'Brazilian(s)',
    adj: 'Brazilian',
    alphaTwo: 'BR',
    alphaThree: 'BRA',
    countryCode: 76,
  },
  {
    country: 'British Virgin Islands',
    noun: 'British Virgin Islander(s)',
    adj: 'British Virgin Islander',
    alphaTwo: 'VG',
    alphaThree: 'VGB',
    countryCode: 92,
  },
  {
    country: 'Brunei',
    noun: 'Bruneian(s)',
    adj: 'Bruneian',
    alphaTwo: 'BN',
    alphaThree: 'BRN',
    countryCode: 96,
  },
  {
    country: 'Bulgaria',
    noun: 'Bulgarian(s)',
    adj: 'Bulgarian',
    alphaTwo: 'BG',
    alphaThree: 'BGR',
    countryCode: 100,
  },
  {
    country: 'Burkina Faso',
    noun: 'Burkinabe (singular and plural)',
    adj: 'Burkinabe',
    alphaTwo: 'BF',
    alphaThree: 'BFA',
    countryCode: 854,
  },
  {
    country: 'Burma',
    noun: 'Burmese (singular and plural)',
    adj: 'Burmese',
    alphaTwo: 'MM',
    alphaThree: 'MMR',
    countryCode: 104,
  },
  {
    country: 'Burundi',
    noun: 'Burundian(s)',
    adj: 'Burundian',
    alphaTwo: 'BI',
    alphaThree: 'BDI',
    countryCode: 108,
  },
  {
    country: 'Cabo Verde',
    noun: 'Cabo Verdean(s)',
    adj: 'Cabo Verdean',
    alphaTwo: 'CV',
    alphaThree: 'CPV',
    countryCode: 132,
  },
  {
    country: 'Cambodia',
    noun: 'Cambodian(s)',
    adj: 'Cambodian',
    alphaTwo: 'KH',
    alphaThree: 'KHM',
    countryCode: 116,
  },
  {
    country: 'Cameroon',
    noun: 'Cameroonian(s)',
    adj: 'Cameroonian',
    alphaTwo: 'CM',
    alphaThree: 'CMR',
    countryCode: 120,
  },
  {
    country: 'Canada',
    noun: 'Canadian(s)',
    adj: 'Canadian',
    alphaTwo: 'CA',
    alphaThree: 'CAN',
    countryCode: 124,
  },
  {
    country: 'Cayman Islands',
    noun: 'Caymanian(s)',
    adj: 'Caymanian',
    alphaTwo: 'KY',
    alphaThree: 'CYM',
    countryCode: 136,
  },
  {
    country: 'Central African Republic',
    noun: 'Central African(s)',
    adj: 'Central African',
    alphaTwo: 'CF',
    alphaThree: 'CAF',
    countryCode: 140,
  },
  {
    country: 'Chad',
    noun: 'Chadian(s)',
    adj: 'Chadian',
    alphaTwo: 'TD',
    alphaThree: 'TCD',
    countryCode: 148,
  },
  {
    country: 'Chile',
    noun: 'Chilean(s)',
    adj: 'Chilean',
    alphaTwo: 'CL',
    alphaThree: 'CHL',
    countryCode: 152,
  },
  {
    country: 'China',
    noun: 'Chinese (singular and plural)',
    adj: 'Chinese',
    alphaTwo: 'CN',
    alphaThree: 'CHN',
    countryCode: 156,
  },
  {
    country: 'Christmas Island',
    noun: 'Christmas Islander(s)',
    adj: 'Christmas Island',
    alphaTwo: 'CX',
    alphaThree: 'CXR',
    countryCode: 162,
  },
  {
    country: 'Cocos (Keeling) Islands',
    noun: 'Cocos Islander(s)',
    adj: 'Cocos Islander',
    alphaTwo: 'CC',
    alphaThree: 'CCK',
    countryCode: 166,
  },
  {
    country: 'Colombia',
    noun: 'Colombian(s)',
    adj: 'Colombian',
    alphaTwo: 'CO',
    alphaThree: 'COL',
    countryCode: 170,
  },
  {
    country: 'Comoros',
    noun: 'Comoran(s)',
    adj: 'Comoran',
    alphaTwo: 'KM',
    alphaThree: 'COM',
    countryCode: 174,
  },
  {
    country: 'Congo, Democratic Republic of the',
    noun: 'Congolese',
    adj: 'Congolese',
    alphaTwo: 'CD',
    alphaThree: 'COD',
    countryCode: 180,
  },
  {
    country: 'Congo, Republic of the',
    noun: 'Congolese',
    adj: 'Congolese',
    alphaTwo: 'CG',
    alphaThree: 'COG',
    countryCode: 178,
  },
  {
    country: 'Cook Islands',
    noun: 'Cook Islander(s)',
    adj: 'Cook Islander',
    alphaTwo: 'CK',
    alphaThree: 'COK',
    countryCode: 184,
  },
  {
    country: 'Costa Rica',
    noun: 'Costa Rican(s)',
    adj: 'Costa Rican',
    alphaTwo: 'CR',
    alphaThree: 'CRI',
    countryCode: 188,
  },
  {
    country: 'Croatia',
    noun: 'Croat(s), Croatian(s)',
    adj: 'Croatian',
    alphaTwo: 'HR',
    alphaThree: 'HRV',
    countryCode: 191,
  },
  {
    country: 'Cuba',
    noun: 'Cuban(s)',
    adj: 'Cuban',
    alphaTwo: 'CU',
    alphaThree: 'CUB',
    countryCode: 192,
  },
  {
    country: 'Curacao',
    noun: 'Curacaoan',
    adj: 'Curacaoan; Dutch',
    alphaTwo: 'CW',
    alphaThree: 'CUW',
    countryCode: 531,
  },
  {
    country: 'Cyprus',
    noun: 'Cypriot(s)',
    adj: 'Cypriot',
    alphaTwo: 'CY',
    alphaThree: 'CYP',
    countryCode: 196,
  },
  {
    country: 'Czechia',
    noun: 'Czech(s)',
    adj: 'Czech',
    alphaTwo: 'CZ',
    alphaThree: 'CZE',
    countryCode: 203,
  },
  {
    country: 'Denmark',
    noun: 'Dane(s)',
    adj: 'Danish',
    alphaTwo: 'DK',
    alphaThree: 'DNK',
    countryCode: 208,
  },
  {
    country: 'Djibouti',
    noun: 'Djiboutian(s)',
    adj: 'Djiboutian',
    alphaTwo: 'DJ',
    alphaThree: 'DJI',
    countryCode: 262,
  },
  {
    country: 'Dominica',
    noun: 'Dominican(s)',
    adj: 'Dominican',
    alphaTwo: 'DM',
    alphaThree: 'DMA',
    countryCode: 212,
  },
  {
    country: 'Dominican Republic',
    noun: 'Dominican(s)',
    adj: 'Dominican',
    alphaTwo: 'DO',
    alphaThree: 'DOM',
    countryCode: 214,
  },
  {
    country: 'Ecuador',
    noun: 'Ecuadorian(s)',
    adj: 'Ecuadorian',
    alphaTwo: 'EC',
    alphaThree: 'ECU',
    countryCode: 218,
  },
  {
    country: 'Egypt',
    noun: 'Egyptian(s)',
    adj: 'Egyptian',
    alphaTwo: 'EG',
    alphaThree: 'EGY',
    countryCode: 818,
  },
  {
    country: 'El Salvador',
    noun: 'Salvadoran(s)',
    adj: 'Salvadoran',
    alphaTwo: 'SV',
    alphaThree: 'SLV',
    countryCode: 222,
  },
  {
    country: 'Equatorial Guinea',
    noun: 'Equatorial Guinean(s)',
    adj: 'Equatorial Guinean',
    alphaTwo: 'GQ',
    alphaThree: 'GNQ',
    countryCode: 226,
  },
  {
    country: 'Eritrea',
    noun: 'Eritrean(s)',
    adj: 'Eritrean',
    alphaTwo: 'ER',
    alphaThree: 'ERI',
    countryCode: 232,
  },
  {
    country: 'Estonia',
    noun: 'Estonian(s)',
    adj: 'Estonian',
    alphaTwo: 'EE',
    alphaThree: 'EST',
    countryCode: 233,
  },
  {
    country: 'Ethiopia',
    noun: 'Ethiopian(s)',
    adj: 'Ethiopian',
    alphaTwo: 'ET',
    alphaThree: 'ETH',
    countryCode: 231,
  },
  {
    country: 'Falkland Islands (Islas Malvinas)',
    noun: 'Falkland Islander(s)',
    adj: 'Falkland Island',
    alphaTwo: 'FK',
    alphaThree: 'FLK',
    countryCode: 238,
  },
  {
    country: 'Faroe Islands',
    noun: 'Faroese (singular and plural)',
    adj: 'Faroese',
    alphaTwo: 'FO',
    alphaThree: 'FRO',
    countryCode: 234,
  },
  {
    country: 'Fiji',
    noun: 'Fijian(s)',
    adj: 'Fijian',
    alphaTwo: 'FJ',
    alphaThree: 'FJI',
    countryCode: 242,
  },
  {
    country: 'Finland',
    noun: 'Finn(s)',
    adj: 'Finnish',
    alphaTwo: 'FI',
    alphaThree: 'FIN',
    countryCode: 246,
  },
  {
    country: 'France',
    noun: 'Frenchman(men), Frenchwoman(women)',
    adj: 'French',
    alphaTwo: 'FR',
    alphaThree: 'FRA',
    countryCode: 250,
  },
  {
    country: 'French Guiana',
    noun: 'Frenchman(men), Frenchwoman(women)',
    adj: 'of French Guiana',
    alphaTwo: 'GF',
    alphaThree: 'GUF',
    countryCode: 254,
  },
  {
    country: 'French Polynesia',
    noun: 'French Polynesian(s)',
    adj: 'of Polynesia (French)',
    alphaTwo: 'PF',
    alphaThree: 'PYF',
    countryCode: 258,
  },
  {
    country: 'French Southern Territories',
    noun: 'French Southern(s)',
    adj: 'of French Southern Territories',
    alphaTwo: 'TF',
    alphaThree: 'ATF',
    countryCode: 260,
  },
  {
    country: 'Gabon',
    noun: 'Gabonese (singular and plural)',
    adj: 'Gabonese',
    alphaTwo: 'GA',
    alphaThree: 'GAB',
    countryCode: 266,
  },
  {
    country: 'Gambia',
    noun: 'Gambian(s)',
    adj: 'Gambian',
    alphaTwo: 'GM',
    alphaThree: 'GMB',
    countryCode: 270,
  },
  {
    country: 'Georgia',
    noun: 'Georgian(s)',
    adj: 'Georgian',
    alphaTwo: 'GE',
    alphaThree: 'GEO',
    countryCode: 268,
  },
  {
    country: 'Germany',
    noun: 'German(s)',
    adj: 'German',
    alphaTwo: 'DE',
    alphaThree: 'DEU',
    countryCode: 276,
  },
  {
    country: 'Ghana',
    noun: 'Ghanaian(s)',
    adj: 'Ghanaian',
    alphaTwo: 'GH',
    alphaThree: 'GHA',
    countryCode: 288,
  },
  {
    country: 'Gibraltar',
    noun: 'Gibraltarian(s)',
    adj: 'Gibraltar',
    alphaTwo: 'GI',
    alphaThree: 'GIB',
    countryCode: 292,
  },
  {
    country: 'Greece',
    noun: 'Greek(s)',
    adj: 'Greek',
    alphaTwo: 'GR',
    alphaThree: 'GRC',
    countryCode: 300,
  },
  {
    country: 'Greenland',
    noun: 'Greenlander(s)',
    adj: 'Greenlandic',
    alphaTwo: 'GL',
    alphaThree: 'GRL',
    countryCode: 304,
  },
  {
    country: 'Grenada',
    noun: 'Grenadian(s)',
    adj: 'Grenadian',
    alphaTwo: 'GD',
    alphaThree: 'GRD',
    countryCode: 308,
  },
  {
    country: 'Guadeloupe',
    noun: 'Guadeloupen(s)',
    adj: 'of Guadeloupe',
    alphaTwo: 'GP',
    alphaThree: 'GLP',
    countryCode: 312,
  },
  {
    country: 'Guam',
    noun: 'Guamanian(s) (US citizens)',
    adj: 'Guamanian',
    alphaTwo: 'GU',
    alphaThree: 'GUM',
    countryCode: 316,
  },
  {
    country: 'Guatemala',
    noun: 'Guatemalan(s)',
    adj: 'Guatemalan',
    alphaTwo: 'GT',
    alphaThree: 'GTM',
    countryCode: 320,
  },
  {
    country: 'Guernsey',
    noun: 'Channel Islander(s)',
    adj: 'Channel Islander',
    alphaTwo: 'GG',
    alphaThree: 'GGY',
    countryCode: 831,
  },
  {
    country: 'Guinea-Bissau',
    noun: 'Bissau-Guinean(s)',
    adj: 'Bissau-Guinean',
    alphaTwo: 'GW',
    alphaThree: 'GNB',
    countryCode: 624,
  },
  {
    country: 'Guinea',
    noun: 'Guinean(s)',
    adj: 'Guinean',
    alphaTwo: 'GN',
    alphaThree: 'GIN',
    countryCode: 324,
  },
  {
    country: 'Guyana',
    noun: 'Guyanese (singular and plural)',
    adj: 'Guyanese',
    alphaTwo: 'GY',
    alphaThree: 'GUY',
    countryCode: 328,
  },
  {
    country: 'Haiti',
    noun: 'Haitian(s)',
    adj: 'Haitian',
    alphaTwo: 'HT',
    alphaThree: 'HTI',
    countryCode: 332,
  },
  {
    country: 'Holy See (Vatican City)',
    noun: 'none',
    adj: 'none',
    alphaTwo: 'VA',
    alphaThree: 'VAT',
    countryCode: 336,
  },
  {
    country: 'Honduras',
    noun: 'Honduran(s)',
    adj: 'Honduran',
    alphaTwo: 'HN',
    alphaThree: 'HND',
    countryCode: 340,
  },
  {
    country: 'Hong Kong',
    noun: 'Chinese/Hong Konger',
    adj: 'Chinese/Hong Kong',
    alphaTwo: 'HK',
    alphaThree: 'HKG',
    countryCode: 344,
  },
  {
    country: 'Hungary',
    noun: 'Hungarian(s)',
    adj: 'Hungarian',
    alphaTwo: 'HU',
    alphaThree: 'HUN',
    countryCode: 348,
  },
  {
    country: 'Iceland',
    noun: 'Icelander(s)',
    adj: 'Icelandic',
    alphaTwo: 'IS',
    alphaThree: 'ISL',
    countryCode: 352,
  },
  {
    country: 'India',
    noun: 'Indian(s)',
    adj: 'Indian',
    alphaTwo: 'IN',
    alphaThree: 'IND',
    countryCode: 356,
  },
  {
    country: 'Indonesia',
    noun: 'Indonesian(s)',
    adj: 'Indonesian',
    alphaTwo: 'ID',
    alphaThree: 'IDN',
    countryCode: 360,
  },
  {
    country: 'Iran',
    noun: 'Iranian(s)',
    adj: 'Iranian',
    alphaTwo: 'IR',
    alphaThree: 'IRN',
    countryCode: 364,
  },
  {
    country: 'Iraq',
    noun: 'Iraqi(s)',
    adj: 'Iraqi',
    alphaTwo: 'IQ',
    alphaThree: 'IRQ',
    countryCode: 368,
  },
  {
    country: 'Ireland',
    noun: 'Irishman(men), Irishwoman(women), Irish (collective plural)',
    adj: 'Irish',
    alphaTwo: 'IE',
    alphaThree: 'IRL',
    countryCode: 372,
  },
  {
    country: 'Isle of Man',
    noun: 'Manxman(men), Manxwoman(women)',
    adj: 'Manx',
    alphaTwo: 'IM',
    alphaThree: 'IMN',
    countryCode: 833,
  },
  {
    country: 'Israel',
    noun: 'Israeli(s)',
    adj: 'Israeli',
    alphaTwo: 'IL',
    alphaThree: 'ISR',
    countryCode: 376,
  },
  {
    country: 'Italy',
    noun: 'Italian(s)',
    adj: 'Italian',
    alphaTwo: 'IT',
    alphaThree: 'ITA',
    countryCode: 380,
  },
  {
    country: 'Jamaica',
    noun: 'Jamaican(s)',
    adj: 'Jamaican',
    alphaTwo: 'JM',
    alphaThree: 'JAM',
    countryCode: 388,
  },
  {
    country: 'Japan',
    noun: 'Japanese (singular and plural)',
    adj: 'Japanese',
    alphaTwo: 'JP',
    alphaThree: 'JPN',
    countryCode: 392,
  },
  {
    country: 'Jersey',
    noun: 'Channel Islander(s)',
    adj: 'Channel Islander',
    alphaTwo: 'JE',
    alphaThree: 'JEY',
    countryCode: 832,
  },
  {
    country: 'Jordan',
    noun: 'Jordanian(s)',
    adj: 'Jordanian',
    alphaTwo: 'JO',
    alphaThree: 'JOR',
    countryCode: 400,
  },
  {
    country: 'Kazakhstan',
    noun: 'Kazakhstani(s)',
    adj: 'Kazakhstani',
    alphaTwo: 'KZ',
    alphaThree: 'KAZ',
    countryCode: 398,
  },
  {
    country: 'Kenya',
    noun: 'Kenyan(s)',
    adj: 'Kenyan',
    alphaTwo: 'KE',
    alphaThree: 'KEN',
    countryCode: 404,
  },
  {
    country: 'Kiribati',
    noun: 'I-Kiribati (singular and plural)',
    adj: 'I-Kiribati',
    alphaTwo: 'KI',
    alphaThree: 'KIR',
    countryCode: 296,
  },
  {
    country: 'Korea, North',
    noun: 'Korean(s)',
    adj: 'Korean',
    alphaTwo: 'KP',
    alphaThree: 'PRK',
    countryCode: 408,
  },
  {
    country: 'Korea, South',
    noun: 'Korean(s)',
    adj: 'Korean',
    alphaTwo: 'KR',
    alphaThree: 'KOR',
    countryCode: 410,
  },
  {
    country: 'Kuwait',
    noun: 'Kuwaiti(s)',
    adj: 'Kuwaiti',
    alphaTwo: 'KW',
    alphaThree: 'KWT',
    countryCode: 414,
  },
  {
    country: 'Kyrgyzstan',
    noun: 'Kyrgyzstani(s)',
    adj: 'Kyrgyzstani',
    alphaTwo: 'KG',
    alphaThree: 'KGZ',
    countryCode: 417,
  },
  {
    country: 'Latvia',
    noun: 'Latvian(s)',
    adj: 'Latvian',
    alphaTwo: 'LV',
    alphaThree: 'LVA',
    countryCode: 428,
  },
  {
    country: 'Lebanon',
    noun: 'Lebanese (singular and plural)',
    adj: 'Lebanese',
    alphaTwo: 'LB',
    alphaThree: 'LBN',
    countryCode: 422,
  },
  {
    country: 'Lesotho',
    noun: 'Mosotho (singular), Basotho (plural)',
    adj: 'Basotho',
    alphaTwo: 'LS',
    alphaThree: 'LSO',
    countryCode: 426,
  },
  {
    country: 'Liberia',
    noun: 'Liberian(s)',
    adj: 'Liberian',
    alphaTwo: 'LR',
    alphaThree: 'LBR',
    countryCode: 430,
  },
  {
    country: 'Libya',
    noun: 'Libyan(s)',
    adj: 'Libyan',
    alphaTwo: 'LY',
    alphaThree: 'LBY',
    countryCode: 434,
  },
  {
    country: 'Liechtenstein',
    noun: 'Liechtensteiner(s)',
    adj: 'Liechtenstein',
    alphaTwo: 'LI',
    alphaThree: 'LIE',
    countryCode: 438,
  },
  {
    country: 'Lithuania',
    noun: 'Lithuanian(s)',
    adj: 'Lithuanian',
    alphaTwo: 'LT',
    alphaThree: 'LTU',
    countryCode: 440,
  },
  {
    country: 'Luxembourg',
    noun: 'Luxembourger(s)',
    adj: 'Luxembourg',
    alphaTwo: 'LU',
    alphaThree: 'LUX',
    countryCode: 442,
  },
  {
    country: 'Macau',
    noun: 'Chinese',
    adj: 'Chinese',
    alphaTwo: 'MO',
    alphaThree: 'MAC',
    countryCode: 446,
  },
  {
    country: 'Macedonia',
    noun: 'Macedonian(s)',
    adj: 'Macedonian',
    alphaTwo: 'MK',
    alphaThree: 'MKD',
    countryCode: 807,
  },
  {
    country: 'Madagascar',
    noun: 'Malagasy (singular and plural)',
    adj: 'Malagasy',
    alphaTwo: 'MG',
    alphaThree: 'MDG',
    countryCode: 450,
  },
  {
    country: 'Malawi',
    noun: 'Malawian(s)',
    adj: 'Malawian',
    alphaTwo: 'MW',
    alphaThree: 'MWI',
    countryCode: 454,
  },
  {
    country: 'Malaysia',
    noun: 'Malaysian(s)',
    adj: 'Malaysian',
    alphaTwo: 'MY',
    alphaThree: 'MYS',
    countryCode: 458,
  },
  {
    country: 'Maldives',
    noun: 'Maldivian(s)',
    adj: 'Maldivian',
    alphaTwo: 'MV',
    alphaThree: 'MDV',
    countryCode: 462,
  },
  {
    country: 'Mali',
    noun: 'Malian(s)',
    adj: 'Malian',
    alphaTwo: 'ML',
    alphaThree: 'MLI',
    countryCode: 466,
  },
  {
    country: 'Malta',
    noun: 'Maltese (singular and plural)',
    adj: 'Maltese',
    alphaTwo: 'MT',
    alphaThree: 'MLT',
    countryCode: 470,
  },
  {
    country: 'Marshall Islands',
    noun: 'Marshallese (singular and plural)',
    adj: 'Marshallese',
    alphaTwo: 'MH',
    alphaThree: 'MHL',
    countryCode: 584,
  },
  {
    country: 'Mauritania',
    noun: 'Mauritanian(s)',
    adj: 'Mauritanian',
    alphaTwo: 'MR',
    alphaThree: 'MRT',
    countryCode: 478,
  },
  {
    country: 'Mauritius',
    noun: 'Mauritian(s)',
    adj: 'Mauritian',
    alphaTwo: 'MU',
    alphaThree: 'MUS',
    countryCode: 480,
  },
  {
    country: 'Mexico',
    noun: 'Mexican(s)',
    adj: 'Mexican',
    alphaTwo: 'MX',
    alphaThree: 'MEX',
    countryCode: 484,
  },
  {
    country: 'Micronesia, Federated States of',
    noun: 'Micronesian(s)',
    adj: 'Micronesian; Chuukese, Kosraen(s), Pohnpeian(s), Yapese',
    alphaTwo: 'FM',
    alphaThree: 'FSM',
    countryCode: 583,
  },
  {
    country: 'Moldova',
    noun: 'Moldovan(s)',
    adj: 'Moldovan',
    alphaTwo: 'MD',
    alphaThree: 'MDA',
    countryCode: 498,
  },
  {
    country: 'Monaco',
    noun: 'Monegasque(s) or Monacan(s)',
    adj: 'Monegasque or Monacan',
    alphaTwo: 'MC',
    alphaThree: 'MCO',
    countryCode: 492,
  },
  {
    country: 'Mongolia',
    noun: 'Mongolian(s)',
    adj: 'Mongolian',
    alphaTwo: 'MN',
    alphaThree: 'MNG',
    countryCode: 496,
  },
  {
    country: 'Montenegro',
    noun: 'Montenegrin(s)',
    adj: 'Montenegrin',
    alphaTwo: 'ME',
    alphaThree: 'MNE',
    countryCode: 499,
  },
  {
    country: 'Montserrat',
    noun: 'Montserratian(s)',
    adj: 'Montserratian',
    alphaTwo: 'MS',
    alphaThree: 'MSR',
    countryCode: 500,
  },
  {
    country: 'Morocco',
    noun: 'Moroccan(s)',
    adj: 'Moroccan',
    alphaTwo: 'MA',
    alphaThree: 'MAR',
    countryCode: 504,
  },
  {
    country: 'Mozambique',
    noun: 'Mozambican(s)',
    adj: 'Mozambican',
    alphaTwo: 'MZ',
    alphaThree: 'MOZ',
    countryCode: 508,
  },
  {
    country: 'Namibia',
    noun: 'Namibian(s)',
    adj: 'Namibian',
    alphaTwo: 'NA',
    alphaThree: 'NAM',
    countryCode: 516,
  },
  {
    country: 'Nauru',
    noun: 'Nauruan(s)',
    adj: 'Nauruan',
    alphaTwo: 'NR',
    alphaThree: 'NRU',
    countryCode: 520,
  },
  {
    country: 'Nepal',
    noun: 'Nepali (singular and plural)',
    adj: 'Nepali',
    alphaTwo: 'NP',
    alphaThree: 'NPL',
    countryCode: 524,
  },
  {
    country: 'Netherlands',
    noun: 'Dutchman(men), Dutchwoman(women)',
    adj: 'Dutch',
    alphaTwo: 'NL',
    alphaThree: 'NLD',
    countryCode: 528,
  },
  {
    country: 'New Caledonia',
    noun: 'New Caledonian(s)',
    adj: 'New Caledonian',
    alphaTwo: 'NC',
    alphaThree: 'NCL',
    countryCode: 540,
  },
  {
    country: 'New Zealand',
    noun: 'New Zealander(s)',
    adj: 'New Zealand',
    alphaTwo: 'NZ',
    alphaThree: 'NZL',
    countryCode: 554,
  },
  {
    country: 'Nicaragua',
    noun: 'Nicaraguan(s)',
    adj: 'Nicaraguan',
    alphaTwo: 'NI',
    alphaThree: 'NIC',
    countryCode: 558,
  },
  {
    country: 'Nigeria',
    noun: 'Nigerian(s)',
    adj: 'Nigerian',
    alphaTwo: 'NG',
    alphaThree: 'NGA',
    countryCode: 566,
  },
  {
    country: 'Niger',
    noun: 'Nigerien(s)',
    adj: 'Nigerien',
    alphaTwo: 'NE',
    alphaThree: 'NER',
    countryCode: 562,
  },
  {
    country: 'Niue',
    noun: 'Niuean(s)',
    adj: 'Niuean',
    alphaTwo: 'NU',
    alphaThree: 'NIU',
    countryCode: 570,
  },
  {
    country: 'Norfolk Island',
    noun: 'Norfolk Islander(s)',
    adj: 'Norfolk Islander(s)',
    alphaTwo: 'NF',
    alphaThree: 'NFK',
    countryCode: 574,
  },
  {
    country: 'Northern Mariana Islands',
    noun: 'NA (US citizens)',
    adj: 'NA',
    alphaTwo: 'MP',
    alphaThree: 'MNP',
    countryCode: 580,
  },
  {
    country: 'Norway',
    noun: 'Norwegian(s)',
    adj: 'Norwegian',
    alphaTwo: 'NO',
    alphaThree: 'NOR',
    countryCode: 578,
  },
  {
    country: 'Oman',
    noun: 'Omani(s)',
    adj: 'Omani',
    alphaTwo: 'OM',
    alphaThree: 'OMN',
    countryCode: 512,
  },
  {
    country: 'Pakistan',
    noun: 'Pakistani(s)',
    adj: 'Pakistani',
    alphaTwo: 'PK',
    alphaThree: 'PAK',
    countryCode: 586,
  },
  {
    country: 'Palau',
    noun: 'Palauan(s)',
    adj: 'Palauan',
    alphaTwo: 'PW',
    alphaThree: 'PLW',
    countryCode: 585,
  },
  {
    country: 'Panama',
    noun: 'Panamanian(s)',
    adj: 'Panamanian',
    alphaTwo: 'PA',
    alphaThree: 'PAN',
    countryCode: 591,
  },
  {
    country: 'Papua New Guinea',
    noun: 'Papua New Guinean(s)',
    adj: 'Papua New Guinean',
    alphaTwo: 'PG',
    alphaThree: 'PNG',
    countryCode: 598,
  },
  {
    country: 'Paraguay',
    noun: 'Paraguayan(s)',
    adj: 'Paraguayan',
    alphaTwo: 'PY',
    alphaThree: 'PRY',
    countryCode: 600,
  },
  {
    country: 'Peru',
    noun: 'Peruvian(s)',
    adj: 'Peruvian',
    alphaTwo: 'PE',
    alphaThree: 'PER',
    countryCode: 604,
  },
  {
    country: 'Philippines',
    noun: 'Filipino(s)',
    adj: 'Philippine',
    alphaTwo: 'PH',
    alphaThree: 'PHL',
    countryCode: 608,
  },
  {
    country: 'Poland',
    noun: 'Pole(s)',
    adj: 'Polish',
    alphaTwo: 'PL',
    alphaThree: 'POL',
    countryCode: 616,
  },
  {
    country: 'Portugal',
    noun: 'Portuguese (singular and plural)',
    adj: 'Portuguese',
    alphaTwo: 'PT',
    alphaThree: 'PRT',
    countryCode: 620,
  },
  {
    country: 'Puerto Rico',
    noun: 'Puerto Rican(s) (US citizens)',
    adj: 'Puerto Rican',
    alphaTwo: 'PR',
    alphaThree: 'PRI',
    countryCode: 630,
  },
  {
    country: 'Qatar',
    noun: 'Qatari(s)',
    adj: 'Qatari',
    alphaTwo: 'QA',
    alphaThree: 'QAT',
    countryCode: 634,
  },
  {
    country: 'Romania',
    noun: 'Romanian(s)',
    adj: 'Romanian',
    alphaTwo: 'RO',
    alphaThree: 'ROU',
    countryCode: 642,
  },
  {
    country: 'Russia',
    noun: 'Russian(s)',
    adj: 'Russian',
    alphaTwo: 'RU',
    alphaThree: 'RUS',
    countryCode: 643,
  },
  {
    country: 'Rwanda',
    noun: 'Rwandan(s)',
    adj: 'Rwandan',
    alphaTwo: 'RW',
    alphaThree: 'RWA',
    countryCode: 646,
  },
  {
    country: 'Saint Helena, Ascension, and Tristan da Cunha',
    noun: 'Saint Helenian(s)',
    adj: 'Saint Helenian',
    alphaTwo: 'SH',
    alphaThree: 'SHN',
    countryCode: 654,
  },
  {
    country: 'Saint Kitts and Nevis',
    noun: 'Kittitian(s), Nevisian(s)',
    adj: 'Kittitian, Nevisian',
    alphaTwo: 'KN',
    alphaThree: 'KNA',
    countryCode: 659,
  },
  {
    country: 'Saint Lucia',
    noun: 'Saint Lucian(s)',
    adj: 'Saint Lucian',
    alphaTwo: 'LC',
    alphaThree: 'LCA',
    countryCode: 662,
  },
  {
    country: 'Saint Pierre and Miquelon',
    noun: 'Frenchman(men), Frenchwoman(women)',
    adj: 'French',
    alphaTwo: 'PM',
    alphaThree: 'SPM',
    countryCode: 666,
  },
  {
    country: 'Saint Vincent and the Grenadines',
    noun: 'Saint Vincentian(s) or Vincentian(s)',
    adj: 'Saint Vincentian or Vincentian',
    alphaTwo: 'VC',
    alphaThree: 'VCT',
    countryCode: 670,
  },
  {
    country: 'Samoa',
    noun: 'Samoan(s)',
    adj: 'Samoan',
    alphaTwo: 'WS',
    alphaThree: 'WSM',
    countryCode: 882,
  },
  {
    country: 'San Marino',
    noun: 'Sammarinese (singular and plural)',
    adj: 'Sammarinese',
    alphaTwo: 'SM',
    alphaThree: 'SMR',
    countryCode: 674,
  },
  {
    country: 'Sao Tome and Principe',
    noun: 'Sao Tomean(s)',
    adj: 'Sao Tomean',
    alphaTwo: 'ST',
    alphaThree: 'STP',
    countryCode: 678,
  },
  {
    country: 'Saudi Arabia',
    noun: 'Saudi(s)',
    adj: 'Saudi or Saudi Arabian',
    alphaTwo: 'SA',
    alphaThree: 'SAU',
    countryCode: 682,
  },
  {
    country: 'Senegal',
    noun: 'Senegalese (singular and plural)',
    adj: 'Senegalese',
    alphaTwo: 'SN',
    alphaThree: 'SEN',
    countryCode: 686,
  },
  {
    country: 'Serbia',
    noun: 'Serb(s)',
    adj: 'Serbian',
    alphaTwo: 'RS',
    alphaThree: 'SRB',
    countryCode: 688,
  },
  {
    country: 'Seychelles',
    noun: 'Seychellois (singular and plural)',
    adj: 'Seychellois',
    alphaTwo: 'SC',
    alphaThree: 'SYC',
    countryCode: 690,
  },
  {
    country: 'Sierra Leone',
    noun: 'Sierra Leonean(s)',
    adj: 'Sierra Leonean',
    alphaTwo: 'SL',
    alphaThree: 'SLE',
    countryCode: 694,
  },
  {
    country: 'Singapore',
    noun: 'Singaporean(s)',
    adj: 'Singapore',
    alphaTwo: 'SG',
    alphaThree: 'SGP',
    countryCode: 702,
  },
  {
    country: 'Slovakia',
    noun: 'Slovak(s)',
    adj: 'Slovak',
    alphaTwo: 'SK',
    alphaThree: 'SVK',
    countryCode: 703,
  },
  {
    country: 'Slovenia',
    noun: 'Slovene(s)',
    adj: 'Slovenian',
    alphaTwo: 'SI',
    alphaThree: 'SVN',
    countryCode: 705,
  },
  {
    country: 'Solomon Islands',
    noun: 'Solomon Islander(s)',
    adj: 'Solomon Islander',
    alphaTwo: 'SB',
    alphaThree: 'SLB',
    countryCode: 90,
  },
  {
    country: 'Somalia',
    noun: 'Somali(s)',
    adj: 'Somali',
    alphaTwo: 'SO',
    alphaThree: 'SOM',
    countryCode: 706,
  },
  {
    country: 'South Africa',
    noun: 'South African(s)',
    adj: 'South African',
    alphaTwo: 'ZA',
    alphaThree: 'ZAF',
    countryCode: 710,
  },
  {
    country: 'South Sudan',
    noun: 'South Sudanese (singular and plural)',
    adj: 'South Sudanese',
    alphaTwo: 'SS',
    alphaThree: 'SSD',
    countryCode: 728,
  },
  {
    country: 'Spain',
    noun: 'Spaniard(s)',
    adj: 'Spanish',
    alphaTwo: 'ES',
    alphaThree: 'ESP',
    countryCode: 724,
  },
  {
    country: 'Sri Lanka',
    noun: 'Sri Lankan(s)',
    adj: 'Sri Lankan',
    alphaTwo: 'LK',
    alphaThree: 'LKA',
    countryCode: 144,
  },
  {
    country: 'Sudan',
    noun: 'Sudanese (singular and plural)',
    adj: 'Sudanese',
    alphaTwo: 'SD',
    alphaThree: 'SDN',
    countryCode: 729,
  },
  {
    country: 'Suriname',
    noun: 'Surinamer(s)',
    adj: 'Surinamese',
    alphaTwo: 'SR',
    alphaThree: 'SUR',
    countryCode: 740,
  },
  {
    country: 'Swaziland',
    noun: 'Swazi(s)',
    adj: 'Swazi',
    alphaTwo: 'SZ',
    alphaThree: 'SWZ',
    countryCode: 748,
  },
  {
    country: 'Sweden',
    noun: 'Swede(s)',
    adj: 'Swedish',
    alphaTwo: 'SE',
    alphaThree: 'SWE',
    countryCode: 752,
  },
  {
    country: 'Switzerland',
    noun: 'Swiss (singular and plural)',
    adj: 'Swiss',
    alphaTwo: 'CH',
    alphaThree: 'CHE',
    countryCode: 756,
  },
  {
    country: 'Syria',
    noun: 'Syrian(s)',
    adj: 'Syrian',
    alphaTwo: 'SY',
    alphaThree: 'SYR',
    countryCode: 760,
  },
  {
    country: 'Taiwan',
    noun: 'Taiwan (singular and plural)',
    adj: 'Taiwan',
    alphaTwo: 'TW',
    alphaThree: 'TWN',
    countryCode: 158,
  },
  {
    country: 'Tajikistan',
    noun: 'Tajikistani(s)',
    adj: 'Tajikistani',
    alphaTwo: 'TJ',
    alphaThree: 'TJK',
    countryCode: 762,
  },
  {
    country: 'Tanzania',
    noun: 'Tanzanian(s)',
    adj: 'Tanzanian',
    alphaTwo: 'TZ',
    alphaThree: 'TZA',
    countryCode: 834,
  },
  {
    country: 'Thailand',
    noun: 'Thai (singular and plural)',
    adj: 'Thai',
    alphaTwo: 'TH',
    alphaThree: 'THA',
    countryCode: 764,
  },
  {
    country: 'Timor-Leste',
    noun: 'Timorese',
    adj: 'Timorese',
    alphaTwo: 'TL',
    alphaThree: 'TLS',
    countryCode: 626,
  },
  {
    country: 'Togo',
    noun: 'Togolese (singular and plural)',
    adj: 'Togolese',
    alphaTwo: 'TG',
    alphaThree: 'TGO',
    countryCode: 768,
  },
  {
    country: 'Tokelau',
    noun: 'Tokelauan(s)',
    adj: 'Tokelauan',
    alphaTwo: 'TK',
    alphaThree: 'TKL',
    countryCode: 772,
  },
  {
    country: 'Tonga',
    noun: 'Tongan(s)',
    adj: 'Tongan',
    alphaTwo: 'TO',
    alphaThree: 'TON',
    countryCode: 776,
  },
  {
    country: 'Trinidad and Tobago',
    noun: 'Trinidadian(s), Tobagonian(s)',
    adj: 'Trinidadian, Tobagonian',
    alphaTwo: 'TT',
    alphaThree: 'TTO',
    countryCode: 780,
  },
  {
    country: 'Tunisia',
    noun: 'Tunisian(s)',
    adj: 'Tunisian',
    alphaTwo: 'TN',
    alphaThree: 'TUN',
    countryCode: 788,
  },
  {
    country: 'Turkey',
    noun: 'Turk(s)',
    adj: 'Turkish',
    alphaTwo: 'TR',
    alphaThree: 'TUR',
    countryCode: 792,
  },
  {
    country: 'Turkmenistan',
    noun: 'Turkmen(s)',
    adj: 'Turkmen',
    alphaTwo: 'TM',
    alphaThree: 'TKM',
    countryCode: 795,
  },
  {
    country: 'Turks and Caicos Islands',
    noun: 'none',
    adj: 'none',
    alphaTwo: 'TC',
    alphaThree: 'TCA',
    countryCode: 796,
  },
  {
    country: 'Tuvalu',
    noun: 'Tuvaluan(s)',
    adj: 'Tuvaluan',
    alphaTwo: 'TV',
    alphaThree: 'TUV',
    countryCode: 798,
  },
  {
    country: 'Uganda',
    noun: 'Ugandan(s)',
    adj: 'Ugandan',
    alphaTwo: 'UG',
    alphaThree: 'UGA',
    countryCode: 800,
  },
  {
    country: 'Ukraine',
    noun: 'Ukrainian(s)',
    adj: 'Ukrainian',
    alphaTwo: 'UA',
    alphaThree: 'UKR',
    countryCode: 804,
  },
  {
    country: 'United Arab Emirates',
    noun: 'Emirati(s)',
    adj: 'Emirati',
    alphaTwo: 'AE',
    alphaThree: 'ARE',
    countryCode: 784,
  },
  {
    country: 'United Kingdom',
    noun: 'Briton(s), British (collective plural)',
    adj: 'British',
    alphaTwo: 'UK',
    alphaThree: 'GBR',
    countryCode: 826,
  },
  {
    country: 'United States',
    noun: 'American(s)',
    adj: 'American',
    alphaTwo: 'US',
    alphaThree: 'USA',
    countryCode: 840,
  },
  {
    country: 'Uruguay',
    noun: 'Uruguayan(s)',
    adj: 'Uruguayan',
    alphaTwo: 'UY',
    alphaThree: 'URY',
    countryCode: 858,
  },
  {
    country: 'Uzbekistan',
    noun: 'Uzbekistani',
    adj: 'Uzbekistani',
    alphaTwo: 'UZ',
    alphaThree: 'UZB',
    countryCode: 860,
  },
  {
    country: 'Vanuatu',
    noun: 'Ni-Vanuatu (singular and plural)',
    adj: 'Ni-Vanuatu',
    alphaTwo: 'VU',
    alphaThree: 'VUT',
    countryCode: 548,
  },
  {
    country: 'Venezuela',
    noun: 'Venezuelan(s)',
    adj: 'Venezuelan',
    alphaTwo: 'VE',
    alphaThree: 'VEN',
    countryCode: 862,
  },
  {
    country: 'Vietnam',
    noun: 'Vietnamese (singular and plural)',
    adj: 'Vietnamese',
    alphaTwo: 'VN',
    alphaThree: 'VNM',
    countryCode: 704,
  },
  {
    country: 'Virgin Islands',
    noun: 'Virgin Islander(s) (US citizens)',
    adj: 'Virgin Islander',
    alphaTwo: 'VI',
    alphaThree: 'VIR',
    countryCode: 850,
  },
  {
    country: 'Wallis and Futuna',
    noun: 'Wallisian(s), Futunan(s), or Wallis and Futuna Islanders',
    adj: 'Wallisian, Futunan, or Wallis and Futuna Islander',
    alphaTwo: 'WF',
    alphaThree: 'WLF',
    countryCode: 876,
  },
  {
    country: 'Western Sahara',
    noun: 'Sahrawi(s), Sahraoui(s)',
    adj: 'Sahrawi, Sahrawian, Sahraouian',
    alphaTwo: 'EH',
    alphaThree: 'ESH',
    countryCode: 732,
  },
  {
    country: 'Yemen',
    noun: 'Yemeni(s)',
    adj: 'Yemeni',
    alphaTwo: 'YE',
    alphaThree: 'YEM',
    countryCode: 887,
  },
  {
    country: 'Zambia',
    noun: 'Zambian(s)',
    adj: 'Zambian',
    alphaTwo: 'ZM',
    alphaThree: 'ZMB',
    countryCode: 894,
  },
  {
    country: 'Zimbabwe',
    noun: 'Zimbabwean(s)',
    adj: 'Zimbabwean',
    alphaTwo: 'ZW',
    alphaThree: 'ZWE',
    countryCode: 716,
  },
]

export const countryOptions = COUNTRIES.map(({ country, alphaTwo }) => ({
  label: country,
  value: alphaTwo,
}))

export const nationalityOptions = COUNTRIES.map(({ adj, alphaTwo }) => ({
  label: adj,
  value: alphaTwo,
}))
