import { Flex, Grid, Display, Text, Icon, Button } from '@frontend/shared/ui'
import React from 'react'
import { Link } from '@frontend/domains/shared/components'

import { useTheme } from '../../theme/theme-iframe/styled-components'

export interface HeaderProps {
  title: string
  onClick?: () => void
  sideLinkHref?: string
  sideLinkTitle?: string
  useButton?: boolean
}

export const Header = ({ title, sideLinkHref, sideLinkTitle, useButton = false, onClick }: HeaderProps) => {
  const theme = useTheme()
  const hasBack = sideLinkTitle

  return (
    <Grid
      alignItems='center'
      gridTemplateColumns={hasBack ? ['100%', null, null, '1fr 2fr 1fr', '2fr 5fr 2fr'] : '1fr'}
    >
      {sideLinkTitle && (
        <Flex flex={1} order={[1, null, null, 0]}>
          {useButton && (
            <Button name='back-to-projects' size='md' type='linkColor' onClick={onClick}>
              <Icon color={theme.components.header.linkBack.textColor} kind='FiArrowLeft' />

              <Text color={theme.components.header.linkBack.textColor}>{sideLinkTitle}</Text>
            </Button>
          )}

          {!useButton && sideLinkHref && (
            <Link href={sideLinkHref}>
              <Icon color={theme.components.header.linkBack.textColor} kind='FiArrowLeft' />

              <Text color={theme.components.header.linkBack.textColor}>{sideLinkTitle}</Text>
            </Link>
          )}
        </Flex>
      )}
      <Flex flex={1} justifyContent='center' order={[0, null, null, 1]}>
        <Display color={theme.components.h1.textColor} data-test-id='title' size='sm' weight={500}>
          {title}
        </Display>
      </Flex>
      {hasBack && <Flex display={['none', null, null, 'flex']} flex={1} order={[0, 2]} />}
    </Grid>
  )
}
