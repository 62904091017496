import { styled } from '@frontend/shared/theme'
import { HTMLAttributes } from 'react'

import { Grid } from '../../../../../layout/grid/grid'

export interface InputTabRadioWrapper
  extends Pick<HTMLAttributes<HTMLDivElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'> {
  checked: boolean
  disabled?: boolean
}

export const InputTabRadioWrapper = styled(Grid).attrs(() => ({
  p: 2,
  gridTemplateColumns: 'auto 1fr auto',
}))<InputTabRadioWrapper>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  background-color: ${({ theme }) => theme.components.inputTabRadio.wrapper.backgroundColor};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({ checked, theme }) =>
    checked
      ? theme.components.inputTabRadio.wrapper[':checked'].borderColor
      : theme.components.inputTabRadio.wrapper.borderColor};
  transition: ${({ theme }) => theme.transition};
  border-radius: 0.5rem;

  &:hover {
    border-color: ${({ theme }) => theme.components.inputTabRadio.wrapper[':checked'].borderColor};
  }
`

export const IconWrapper = styled.div``
