import React from 'react'
import { Theme, useTheme } from '@frontend/shared/theme'
import { TypeOptions } from 'react-toastify'

import { Icon, IconFeatured, Text } from '../../text'
import { Flex } from '../../layout'
import { IconFeaturedColor } from '../../text/icon/featured/icon-featured.styles'

export interface ToastProps {
  title: string
  message?: string
  type?: TypeOptions
}

const icon = {
  info: 'FiInfo',
  error: 'FiAlertCircle',
  warning: 'FiAlertTriangle',
  success: 'FiCheckCircle',
  default: 'FiInfo',
} as const

export const progressColor = (type: TypeOptions, theme: Theme) =>
  ({
    info: theme.colors.primary.blue.mainHover,
    error: theme.components.toast.progress.error.backgroundColor,
    warning: theme.components.toast.progress.warning.backgroundColor,
    success: theme.components.toast.progress.success.backgroundColor,
    default: theme.colors.gray['900'],
  }[type])

const toastColor = (type: TypeOptions) =>
  ({
    info: 'primary',
    error: 'error',
    warning: 'warning',
    success: 'success',
    default: 'gray',
  }[type])

export const Toast = ({ title, message, type = 'success' }: ToastProps) => {
  const theme = useTheme()

  return (
    <Flex>
      <Flex flex='0 auto' justifyContent='space-between' mr={4}>
        <IconFeatured color={toastColor(type) as IconFeaturedColor} kind={icon[type]} type='lightCircleOutline' />
      </Flex>

      <Flex flex={1} flexDirection='column'>
        <Flex>
          <Text size='sm'>{title}</Text>
        </Flex>
        <Flex>
          <Text color={theme.components.toast.wrapper.textColor} size='sm'>
            {message}
          </Text>
        </Flex>
      </Flex>

      <Flex flex='0 auto' ml={2}>
        <Icon fontSize='1.2rem' kind='FiX' />
      </Flex>
    </Flex>
  )
}
