import { useTheme } from '@frontend/shared/theme'
import React from 'react'

import { Icon, IconKind } from '../root/icon'

import { iconFeaturedTypeColor, IconFeaturedWrapper, IconFeaturedWrapperProps, iconSize } from './icon-featured.styles'

export interface IconFeaturedProps extends Partial<IconFeaturedWrapperProps> {
  kind: IconKind
}

export const IconFeatured = ({
  kind,
  color = 'primary',
  size = 'md',
  type = 'lightCircleOutline',
}: IconFeaturedProps) => {
  const theme = useTheme()

  return (
    <IconFeaturedWrapper color={color} size={size} type={type}>
      <Icon color={iconFeaturedTypeColor({ theme, color, type, size })} fontSize={iconSize(size)} kind={kind} />
    </IconFeaturedWrapper>
  )
}
