import React from 'react'

import { Grid } from '../../../../layout/grid/grid'
import { Flex } from '../../../../layout/flex/flex'
import { InputCheckbox } from '../root/input-checkbox'
import { InputCheckboxProps } from '../root/input-checkbox.styles'

interface Option {
  label: string
  value: string
  disabled?: boolean
}

type Options = Option[]

export type InputCheckboxGroupMap = { [key: string]: boolean }

export interface InputCheckboxGroupProps
  extends Pick<InputCheckboxProps, 'onChange' | 'error' | 'touched' | 'onBlur' | 'size' | 'name'> {
  checked: string[]
  options: Options
  disabledAll?: boolean
  wrap?: boolean
}

export const InputCheckboxGroup = ({
  onChange,
  checked,
  options,
  disabledAll,
  touched,
  error,
  onBlur,
  size,
  wrap = false,
  name,
}: InputCheckboxGroupProps) => {
  const items = options.map(({ label, value, disabled }, i) => {
    const itemChecked = !!checked.find((checkedValue) => checkedValue === value)

    const item = (
      <InputCheckbox
        checked={itemChecked}
        disabled={disabledAll || disabled}
        error={options.length === i + 1 ? error : undefined}
        htmlFor={value}
        key={value}
        label={label}
        name={name}
        size={size}
        touched={touched}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    )

    return wrap ? (
      <Flex mb={3} mr={3}>
        {item}
      </Flex>
    ) : (
      item
    )
  })

  if (wrap) {
    return <Flex flexWrap='wrap'>{items}</Flex>
  }

  return <Grid gap='sm'>{items}</Grid>
}
