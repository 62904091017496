import React, { KeyboardEvent, PropsWithChildren, useEffect, useState } from 'react'
import { Flex, Text, Icon, IconKind, Tooltip } from '@frontend/shared/ui'
import { KEY } from '@frontend/shared/constants'
import { useTheme } from '@frontend/shared/theme'
import dynamic from 'next/dynamic'

import { IconWrapper, MenuItemWrapper } from './menu-item.styles'
import { SubMenuAnimatedWrapperProps } from './components/sub-menu-items-wrapper'

export interface SubMenuItemType {
  active: boolean
  id: string
  label: string
  onSelect?: () => void
  tooltipText?: string
  visible?: boolean
}

export interface MenuItemType extends SubMenuItemType {
  icon: IconKind
  subMenuItems?: Array<SubMenuItemType>
}

export interface MenuItemProps extends Omit<MenuItemType, 'onSelect'> {
  onSelectItem: () => void
  onSelectSubItem: (onSelect?: () => void) => () => void
}

const SubMenuItemsWrapper = dynamic<PropsWithChildren<SubMenuAnimatedWrapperProps>>(() =>
  import('./components/sub-menu-items-wrapper').then((module) => module.SubMenuItemsWrapper),
)

export const MenuItem = ({
  icon,
  label,
  active,
  onSelectItem,
  onSelectSubItem,
  subMenuItems,
  visible,
  id: menuItemId,
}: MenuItemProps) => {
  const theme = useTheme()
  const activeSubMenuItem = subMenuItems?.some(({ active }) => active)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(activeSubMenuItem ?? false)

  useEffect(() => {
    setIsSubMenuOpen(!!activeSubMenuItem)
  }, [activeSubMenuItem, subMenuItems])

  const menuItemWrapperClickHandler = () => (subMenuItems ? setIsSubMenuOpen(!isSubMenuOpen) : onSelectItem?.())

  const menuItemWrapperKeyDownHandler = (event: KeyboardEvent<HTMLDivElement>) =>
    (event.key === KEY.spacebar || event.key === KEY.enter) && menuItemWrapperClickHandler()

  const visibleSubmenuItems = subMenuItems ? subMenuItems.filter((item) => item.visible) : []

  return (
    <>
      <MenuItemWrapper
        active={active}
        aria-expanded={subMenuItems ? isSubMenuOpen : undefined}
        aria-haspopup={subMenuItems ? 'listbox' : undefined}
        aria-label={label}
        data-test-id={`menu-item-${menuItemId}`}
        role='button'
        submenuActive={isSubMenuOpen}
        tabIndex={0}
        visible={visible}
        onClick={menuItemWrapperClickHandler}
        onKeyDown={menuItemWrapperKeyDownHandler}
      >
        <Flex alignItems='center'>
          <Flex height='1.5rem' minWidth='1.5rem' width='1.5rem'>
            <Icon aria-hidden color={theme.colors.primary.contrast} fontSize='1.5rem' kind={icon} />
          </Flex>
          <Flex pl={3}>
            <Text color={theme.colors.primary.contrast}>{label}</Text>
          </Flex>
        </Flex>
        {subMenuItems && (
          <IconWrapper>
            <Icon
              aria-hidden
              color={theme.colors.primary.contrast}
              fontSize='1.5rem'
              kind={isSubMenuOpen ? 'FiChevronUp' : 'FiChevronDown'}
            />
          </IconWrapper>
        )}
      </MenuItemWrapper>

      {subMenuItems && (
        <SubMenuItemsWrapper
          active={active}
          aria-hidden={!isSubMenuOpen}
          itemCount={visibleSubmenuItems.length}
          opened={isSubMenuOpen}
        >
          {subMenuItems.map(({ id, active, label, onSelect, visible, tooltipText }) => {
            const subMenuItemSelectHandler = onSelectSubItem(onSelect)

            const subMenuItemWrapperKeyDownHandler = (event: KeyboardEvent<HTMLDivElement>) =>
              (event.key === KEY.spacebar || event.key === KEY.enter) && subMenuItemSelectHandler()

            return (
              <MenuItemWrapper
                active={active}
                aria-selected={active}
                data-test-id={`menu-item-${id}`}
                data-tooltip-content={tooltipText}
                id={id}
                key={id}
                role='option'
                subMenu
                tabIndex={0}
                visible={visible}
                onClick={subMenuItemSelectHandler}
                onKeyDown={subMenuItemWrapperKeyDownHandler}
              >
                <Text color={theme.colors.primary.contrast}>{label}</Text>
                <Tooltip anchorId={id} variant='dark' />
              </MenuItemWrapper>
            )
          })}
        </SubMenuItemsWrapper>
      )}
    </>
  )
}
