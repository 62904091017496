import { css, styled, StyledProps, Theme } from '@frontend/shared/theme'
import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, ForwardedRef } from 'react'
import NextLink, { LinkProps } from 'next/link'

import { Flex } from '../../../layout/flex/flex'

export type ButtonTypes =
  | 'icon'
  | 'linkColor'
  | 'linkGray'
  | 'linkWhite'
  | 'outlinedBlue'
  | 'primary'
  | 'secondaryColor'
  | 'secondaryGray'
  | 'tertiaryColor'
  | 'tertiaryGray'
export type ButtonIcons = 'none' | 'leading' | 'trailing' | 'dot' | 'only'
export type ButtonSizes = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export interface StyledCustomButtonBaseProps {
  destructive: boolean
  hierarchy: ButtonTypes
  icon: ButtonIcons
  size: ButtonSizes
  loading?: boolean
}

export interface StyledButtonProps extends StyledCustomButtonBaseProps, ButtonHTMLAttributes<HTMLButtonElement> {
  clickable: boolean
  ref?: ForwardedRef<HTMLButtonElement>
}

export interface StyledAnchorProps
  extends StyledCustomButtonBaseProps,
    LinkProps,
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick' | 'onMouseEnter' | 'onTouchStart'> {
  clickable: boolean
  ref?: ForwardedRef<HTMLAnchorElement>
}

interface LoaderWrapperProps {
  hierarchy: ButtonTypes
  disabled?: boolean
  loading?: boolean
}

const padding = {
  sm: '0.5rem 0.875rem',
  md: '0.625rem 1rem',
  lg: '0.625rem 1.12rem',
  xl: '0.75rem 1.25rem',
  '2xl': '1rem 1.75rem',
}

const fontSize = {
  sm: '0.875rem',
  md: '0.875rem',
  lg: '1rem',
  xl: '1rem',
  '2xl': '1.125rem',
}

const minHeight = {
  sm: '2.25rem',
  md: '2.5rem',
  lg: '2.75rem',
  xl: '3rem',
  '2xl': '3.75rem',
}

const backgroundColor = ({ theme, hierarchy }: StyledProps<StyledCustomButtonBaseProps | LoaderWrapperProps>) => {
  switch (hierarchy) {
    case 'secondaryColor':
      return theme.colors.primary.blue.main
    case 'secondaryGray':
      return theme.components.button.secondaryGray.backgroundColor
    case 'outlinedBlue':
    case 'tertiaryGray':
      return theme.components.button.tertiaryGray.backgroundColor
    case 'tertiaryColor':
    case 'icon':
    case 'linkWhite':
    case 'linkColor':
    case 'linkGray':
      return 'transparent'
    case 'primary':
    default:
      return theme.components.button.primary.backgroundColor
  }
}

const primaryStyles = css<StyledButtonProps>`
  background: ${backgroundColor};
  border: 1px solid transparent;
  color: ${({ theme }) => theme.components.button.primary.textColor};
  padding: ${({ size }) => padding[size]};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.components.button.primary[':hover'].backgroundColor};
  }
`

const secondaryColorStyles = css<StyledButtonProps>`
  background: ${backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.primary.blue.main};
  color: ${({ theme }) => theme.colors.primary.contrast};
  padding: ${({ size }) => padding[size]};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  font-size: 0.875rem;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primary.blue.mainHover};
  }
`

const secondaryGrayStyles = css<StyledButtonProps>`
  background: ${backgroundColor};
  border: 1px solid ${({ theme }) => theme.components.button.secondaryGray.borderColor};
  padding: ${({ size }) => padding[size]};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  color: ${({ theme }) => theme.components.button.secondaryGray.textColor};
`

const tertiaryColorStyles = css<StyledButtonProps>`
  background: ${backgroundColor};
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.primary.blue.main};
  padding: ${({ size }) => padding[size]};
  border-radius: 0.5rem;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primary.blue['25']};
  }
`

const tertiaryGrayStyles = css<StyledButtonProps>`
  background: ${backgroundColor};
  border: 1px solid transparent;
  color: ${({ theme }) => theme.components.button.tertiaryGray.textColor};
  padding: ${({ size }) => padding[size]};
  border-radius: 0.5rem;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.components.button.tertiaryGray[':hover'].backgroundColor};
  }
`

const linkColorStyles = css`
  background: ${backgroundColor};
  color: ${({ theme }) => theme.components.button.linkColor.textColor};
  border: 0;
`

const linkGrayStyles = css`
  background: ${backgroundColor};
  color: ${({ theme }) => theme.colors.gray['500']};
  border: 0;
`

const linkWhiteStyles = css`
  background: ${backgroundColor};
  color: ${({ theme }) => theme.colors.gray['50']};
  border: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.gray['200']};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.gray['500']};
  }
`

const outlinedBlueStyles = css<StyledButtonProps>`
  background: ${backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.primary.blue.main};
  padding: ${({ size }) => padding[size]};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  color: ${({ theme }) => theme.colors.primary.blue.main};

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.gray['50']};
  }
`

const iconStyles = css`
  background: ${backgroundColor};
  padding: 0;
  border: 0;
  color: inherit;
  width: fit-content;
  min-height: initial;

  &:hover,
  &:focus {
    outline: transparent;
    transition: all ${({ theme }) => theme.transition};

    svg {
      color: ${({ theme }) => theme.colors.gray['500']};
      transition: all ${({ theme }) => theme.transition};
    }
  }

  &:active {
    transition: color ${({ theme }) => theme.transition};
  }

  &:disabled,
  [disabled] {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray['200']};
    cursor: default;
    transition: color ${({ theme }) => theme.transition};

    svg {
      color: ${({ theme }) => theme.colors.gray['200']};
    }
  }
`

const buttonStyles = {
  icon: iconStyles,
  linkColor: linkColorStyles,
  linkGray: linkGrayStyles,
  linkWhite: linkWhiteStyles,
  outlinedBlue: outlinedBlueStyles,
  primary: primaryStyles,
  secondaryColor: secondaryColorStyles,
  secondaryGray: secondaryGrayStyles,
  tertiaryColor: tertiaryColorStyles,
  tertiaryGray: tertiaryGrayStyles,
}

const getCursor = ({ disabled, clickable }: StyledButtonProps) => {
  switch (true) {
    case disabled:
      return 'not-allowed'
    case clickable:
      return 'pointer'
    default:
      return 'default'
  }
}

const buttonBaseStyles = css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  height: max-content;
  max-width: 100%;
  text-decoration: none;
  cursor: ${getCursor};
  font-size: ${({ size }) => fontSize[size]};
  min-height: ${({ size }) => minHeight[size]};
  transition: all ${({ theme }) => theme.transition};
  ${({ hierarchy }) => buttonStyles[hierarchy]};

  &:disabled,
  [disabled] {
    opacity: 0.4;
  }
`

export const StyledButton: FC<StyledButtonProps> = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop.toString()) && defaultValidatorFn(prop),
})<StyledButtonProps>`
  ${buttonBaseStyles};
`

export const StyledAnchor: FC<StyledAnchorProps> = styled(NextLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop.toString()) && defaultValidatorFn(prop),
})<StyledButtonProps>`
  ${buttonBaseStyles};
  min-height: auto;
`

export const getLoaderColor = (theme: Theme, type: ButtonTypes) => {
  if (type === 'primary') {
    return theme.components.button.loader.primary.backgroundColor
  }

  if (type === 'secondaryColor') {
    return theme.components.button.loader.secondaryColor.backgroundColor
  }

  return theme.components.button.loader.others.backgroundColor
}

export const getLoaderBackgroundColor = (theme: Theme, type: ButtonTypes, disabled?: boolean) => {
  if (!disabled && type === 'primary') {
    return theme.components.button.loader.primary.backgroundColor
  }

  if (!disabled && type === 'secondaryColor') {
    return theme.components.button.loader.secondaryColor.backgroundColor
  }

  return theme.components.button.loader[':disabled'].backgroundColor
}

export const getLoaderOpacity = (type: ButtonTypes, disabled?: boolean): number =>
  !disabled && (type === 'primary' || type === 'secondaryColor' || type === 'secondaryGray') ? 0.4 : 1

export const LoaderWrapper = styled(Flex)
  .attrs(() => ({
    width: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop.toString()) && defaultValidatorFn(prop),
  })<LoaderWrapperProps>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 1 !important;
  background: ${backgroundColor};
  border-radius: 0.5rem;
`
