interface InitialValues {
  expectedPrice: number
  vintage: string
  email?: string
  emailDefault?: string
  name?: string
}

export const getInitialValues = ({ vintage, emailDefault, expectedPrice, name, email }: InitialValues) => ({
  name: name ?? '',
  expectedPrice: expectedPrice.toString(),
  email: (email || emailDefault) ?? '',
  vintage,
})
