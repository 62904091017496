import React from 'react'
import { useTheme } from '@frontend/shared/theme'
import { formatDate } from '@frontend/shared/utils'

import { Text } from '../../text/typography'
import { Flex } from '../../layout/flex/flex'
import { Avatar } from '../avatar/avatar'
import { Icon } from '../../text'

import { ActivityFeedWrapper } from './activity-feed.styles'

export interface ActivityFeedProps {
  date: string
  description: string
  title: string
}

export const ActivityFeed = ({ title, date, description }: ActivityFeedProps) => {
  const theme = useTheme()

  return (
    <ActivityFeedWrapper data-test-id={`activity-feed-${date}`}>
      <Flex flex='0 auto'>
        <Avatar size='lg'>
          <Icon fontSize={28} kind='FiRefreshCcw' />
        </Avatar>
      </Flex>
      <Flex flex={1} flexDirection='column' pl={2}>
        <div>
          <Text color={theme.colors.gray['700']} data-test-id='title' size='sm'>
            {title}
          </Text>
          <Flex display='inline-block' flex={1} ml={1}>
            <Text color={theme.colors.gray['500']} data-test-id='date' size='xs' weight={400}>
              {formatDate(date)}
            </Text>
          </Flex>
        </div>
        <Flex>
          <Text color={theme.colors.gray['500']} data-test-id='description' size='md' weight={400}>
            {description}
          </Text>
        </Flex>
      </Flex>
    </ActivityFeedWrapper>
  )
}
