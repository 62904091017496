import { useField } from 'formik'
import React, { FocusEvent, FormHTMLAttributes } from 'react'
import { SingleValue } from 'react-select'

import { isOptionType, OptionType, Select, SelectProps } from '../../select/select'
import { FieldBaseProps } from '../base/types'
import { FieldWrapper } from '../base/components/field-wrapper'

export type FieldSelectProps<Value = string> = Pick<
  SelectProps<Value>,
  'error' | 'touched' | 'placeholder' | 'defaultValue' | 'styles' | 'displayValue'
> &
  Omit<FieldBaseProps, 'placeholder' | 'onChange'> & {
    options: OptionType<Value>[]
    autoComplete?: FormHTMLAttributes<HTMLInputElement>['autoComplete']
    caseSensitive?: boolean
    onChange?: (value: Value | Value[]) => void
    valueKey?: string
  }

export const getFlatOptions = <Value,>(options: OptionType<Value>[]): OptionType<Value>[] =>
  options.reduce<OptionType<Value>[]>((previousValue, currentValue) => {
    if (currentValue?.options) {
      return [...previousValue, ...currentValue.options]
    }

    return [...previousValue, currentValue]
  }, [])

export const FieldSelect = <Value,>({
  name,
  options,
  valueKey,
  label,
  placeholder,
  onChange,
  disabled,
  styles,
  onBlur,
  caseSensitive = true,
  displayValue,
}: Omit<FieldSelectProps<Value>, 'Icon'>): JSX.Element => {
  const [{ value, onBlur: onBlurFormikField }, { touched, error }, { setValue }] = useField(name)

  const changeHandler = (newValue: SingleValue<OptionType<Value>>) =>
    // TODO-MEDELSKY (Lukas Medelsky, 18.10.21, https://stackoverflow.com/questions/64088541/react-select-not-working-on-mobile-browsers)
    setTimeout(() => {
      if (Array.isArray(newValue)) {
        const values = newValue.map((singleValue) => singleValue.value)

        onChange?.(values)
        setValue(values)
      }

      if (newValue && isOptionType<Value>(newValue) && !newValue.disabled) {
        onChange?.(newValue.value)
        setValue(newValue.value)
      }
    }, 1)

  const blurHandler = (event: FocusEvent<HTMLInputElement>) =>
    setTimeout(() => {
      onBlurFormikField(event)
      onBlur?.(event)
    }, 1)

  const sameValue = (option: OptionType<Value>) => (value: Value) => {
    if (valueKey) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option.value[valueKey] === value[valueKey]
    }

    if (typeof option.value === 'string' && typeof value === 'string' && !caseSensitive) {
      return option.value.toLowerCase() === value.toLowerCase()
    }

    return option.value === value
  }

  const flatOptions = getFlatOptions(options)

  return (
    <FieldWrapper disabled={disabled} error={error} label={label} name={name} touched={touched}>
      <Select<Value>
        displayValue={displayValue}
        error={error}
        isDisabled={disabled}
        name={name}
        noOptionsMessage={() => 'No options'}
        options={options}
        placeholder={placeholder}
        styles={styles}
        touched={touched}
        value={flatOptions?.find((option) => sameValue(option)(value))}
        onBlur={blurHandler}
        onChange={changeHandler}
      />
    </FieldWrapper>
  )
}
