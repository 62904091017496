import React, { useState } from 'react'
import {
  Badge,
  Flex,
  Grid,
  Icon,
  OptionType,
  Section,
  Select,
  Text,
  TextNonBreakable,
  FieldSelectProps,
} from '@frontend/shared/ui'
import { VerificationPeriod, Project } from '@frontend/shared/types'
import { formatPrice } from '@frontend/shared/utils'
import { SingleValue } from 'react-select'

import { StickyPanel } from '../../ui/screen/sticky-panel/sticky-panel'
import { useTheme } from '../../ui/theme/theme-iframe/styled-components'
import { getRating } from '../../utils'
import { byVintageDateDesc } from '../../filter'
import { Routes } from '../../types'

import { SectionFaq } from './components/section-faq'
import { SectionForm, SectionFormProps } from './components/section-form/section-form'
import { FormValues } from './components/section-form/types'

interface SidebarTonneProps extends Pick<SectionFormProps, 'customer'> {
  onSubmitForm(values: FormValues): Promise<boolean>
  place: string
  project: Project
  routes: Routes
  verificationPeriod: VerificationPeriod
}

const getCreditClass = (verificationPeriod: VerificationPeriod) => {
  if (verificationPeriod?.creditClassName && verificationPeriod?.creditClassVersion) {
    return verificationPeriod.creditClassVersionFirst
      ? `${verificationPeriod.creditClassVersion} ${verificationPeriod.creditClassName}`
      : `${verificationPeriod.creditClassName} ${verificationPeriod.creditClassVersion}`
  }

  if (verificationPeriod?.creditClassName) {
    return verificationPeriod.creditClassName
  }

  if (verificationPeriod?.creditClassVersion) {
    return verificationPeriod.creditClassVersion
  }

  return undefined
}

const getMethodology = (verificationPeriod: VerificationPeriod) => {
  if (verificationPeriod?.methodologyName && verificationPeriod?.methodologyVersion) {
    return verificationPeriod.methodologyVersionFirst
      ? `${verificationPeriod.methodologyVersion} ${verificationPeriod.methodologyName}`
      : `${verificationPeriod.methodologyName} ${verificationPeriod.methodologyVersion}`
  }

  if (verificationPeriod?.methodologyName) {
    return verificationPeriod.methodologyName
  }

  if (verificationPeriod?.methodologyVersion) {
    return verificationPeriod.methodologyVersion
  }

  return undefined
}

const getDisplayVintageValue: FieldSelectProps['displayValue'] = ({ value }) => {
  const parsedValue = value.match(/(\d+) -/)

  return parsedValue ? parsedValue[1] : value
}

export const SidebarTonne = ({
  project,
  place,
  customer,
  onSubmitForm,
  verificationPeriod,
  routes,
}: SidebarTonneProps) => {
  const theme = useTheme()

  const sortedVintagesDesc = verificationPeriod.vintages.sort(byVintageDateDesc)
  const [selectedVintage, setSelectedVintage] = useState(sortedVintagesDesc[0])

  const { name, tags, sellingUnit, ticker, ratings } = project

  const rating = getRating(ratings)

  const vintageOptions = sortedVintagesDesc.map(({ vintage, priceEur }) => ({
    label: `${vintage} - ${formatPrice(priceEur)}`,
    value: vintage,
  }))

  const vintageDetails = verificationPeriod.vintages.find(({ vintage }) => vintage === selectedVintage.vintage)

  const changeVintageOption = (valueObject: SingleValue<OptionType<string>>) => {
    if (valueObject) {
      const vintage = verificationPeriod.vintages.find(({ vintage }) => valueObject.value === vintage)
      vintage && setSelectedVintage(vintage)
    }
  }

  return vintageDetails ? (
    <StickyPanel>
      <Section gap='md'>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex flex='auto'>
            <Text data-test-id='name'>{name}</Text>
          </Flex>

          <Flex alignItems='center' flex='auto' justifyContent='flex-end'>
            <Flex mr={1}>
              <Icon color={theme.components.detail.place.textColor} kind='FiMapPin' />{' '}
            </Flex>
            <Flex flex={0}>
              <Text color={theme.components.detail.place.textColor} data-test-id='place' size='md'>
                {place}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Grid gap='sm'>
          {rating && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='standard-title'>Standard:</Text>
              <Flex justifyContent='flex-end'>
                <TextNonBreakable data-test-id='standard-value'>{rating.type}</TextNonBreakable>
              </Flex>
            </Grid>
          )}

          {rating && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='earthbanc-rating-title'>Earthbanc Rating:</Text>
              <Flex data-test-id='earthbanc-rating-value' justifyContent='flex-end'>
                {rating.value}
              </Flex>
            </Grid>
          )}

          {tags && tags.length > 0 && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='type-title'>Type:</Text>

              <Flex data-test-id='type-value' flexWrap='wrap' justifyContent='flex-end' m='-0.5rem'>
                {tags.map((tag) => (
                  <Flex flex='0 auto' key={tag} m='0.5rem'>
                    <Badge color='gray' label={tag} />
                  </Flex>
                ))}
              </Flex>
            </Grid>
          )}
        </Grid>
      </Section>

      <SectionFaq routes={routes} sellingUnit={sellingUnit} />

      <Section gap='md'>
        <Grid alignItems='center' gap='sm' gridTemplateColumns='auto 9.5rem'>
          <Text data-test-id='vintage-title'>Vintage:</Text>
          <Select<string>
            displayValue={getDisplayVintageValue}
            name='vintage'
            options={vintageOptions}
            value={vintageOptions?.find(({ value }) => value === selectedVintage.vintage)}
            onChange={changeVintageOption}
          />
        </Grid>

        <Grid gap='sm' gridTemplateColumns='auto 1fr' justifyContent='space-between'>
          <Text data-test-id='price-title'>Price:</Text>
          <Flex justifyContent='flex-end'>
            <TextNonBreakable data-test-id='price-value'>{formatPrice(vintageDetails.priceEur)}</TextNonBreakable>
          </Flex>
        </Grid>

        <Grid gap='sm'>
          <Grid gap='sm' gridTemplateColumns='auto 1fr'>
            <Text data-test-id='vintage-id-title'>Vintage ID:</Text>
            <Flex justifyContent='flex-end'>
              <Text data-test-id='vintage-id-value' textAlign='right'>
                {vintageDetails.externalId}
              </Text>
            </Flex>
          </Grid>

          {(verificationPeriod?.creditClassName || verificationPeriod?.creditClassVersion) && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='credit-class-title'>Credit class:</Text>
              <Flex justifyContent='flex-end'>
                <Text data-test-id='credit-class-value' textAlign='right'>
                  {getCreditClass(verificationPeriod)}
                </Text>
              </Flex>
            </Grid>
          )}

          {(verificationPeriod?.methodologyName || verificationPeriod?.methodologyVersion) && (
            <Grid gap='sm' gridTemplateColumns='auto 1fr'>
              <Text data-test-id='methodology-title'>Methodology:</Text>
              <Flex justifyContent='flex-end'>
                {' '}
                <Text data-test-id='methodology-value' textAlign='right'>
                  {getMethodology(verificationPeriod)}
                </Text>
              </Flex>
            </Grid>
          )}
        </Grid>
      </Section>

      <SectionForm
        customer={customer}
        productPrice={vintageDetails.priceEur}
        sellingUnit={sellingUnit}
        submitForm={onSubmitForm}
        ticker={ticker}
        vintage={selectedVintage}
      />
    </StickyPanel>
  ) : null
}
