import { getCookieDomain } from '@frontend/shared/utils'
import { RefObject } from 'react'
import { FormikProps } from 'formik'
import { useApiFetch } from '@frontend/domains/shared/components'
import { CachePolicies, Res } from 'use-http'

import { ENDPOINT_URL } from '../../constants'

import { AuthenticateResponse, OtpQrCodeResponse, SuccessfullyAuthenticatedResponse } from './types'

enum ErrorCode {
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
}

interface AuthenticateRequest<FormValues> {
  execution: string
  formikRef: RefObject<FormikProps<FormValues>>
  password: string
  sessionCode: string
  tabId: string
  username: string
}

/**
 * Authenticates a user with username and password.
 */
export const useServiceAuthenticate = () => {
  const { post, error, loading, response } = useApiFetch<AuthenticateResponse>({
    cachePolicy: CachePolicies.NO_CACHE,
  })

  const authenticate = async <FormValues>({
    formikRef,
    ...params
  }: AuthenticateRequest<FormValues>): Promise<
    AuthenticateResponse | SuccessfullyAuthenticatedResponse | OtpQrCodeResponse | undefined
  > => {
    const onError = (response: Res<AuthenticateResponse>) => {
      if (response.status === 400) {
        if (response?.data?.errorCode === ErrorCode.EmailNotVerified) {
          formikRef?.current?.setFieldError('email', 'We have sent account verification link to your email address.')
        } else {
          formikRef?.current?.setFieldError('email', 'Invalid user credentials.')
        }
      }
    }
    const body = { cookieDomain: getCookieDomain(), ...params }
    const result = await post(ENDPOINT_URL.authenticate, body, onError)

    return response.ok ? result : undefined
  }

  return { authenticate, error, loading }
}
