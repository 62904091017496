import { Display } from '@frontend/shared/ui'
import { useTheme } from '@frontend/shared/theme'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type CardTitleProps = Pick<HTMLAttributes<HTMLDivElement>, 'onDoubleClick'>

export const CardTitle = ({ children, onDoubleClick }: PropsWithChildren<CardTitleProps>) => {
  const theme = useTheme()

  return (
    <Display
      color={theme.colors.gray['700']}
      fontSize={[theme.fontSizes.text.xl, theme.fontSizes.display.xs]}
      size='xs'
      weight={500}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </Display>
  )
}
