import { styled } from '@frontend/shared/theme'
import { Flex } from '@frontend/shared/ui'

export const LayoutWrapper = styled(Flex).attrs(() => ({
  position: 'absolute',
  flexDirection: 'column',
  justifyContent: ['flex-start', 'center'],
  alignItems: 'center',
  p: [1, '0rem'],
}))`
  width: 100%;
  min-height: 100%;
  background-image: url('/images/background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
`

export const StyledLayout = styled(Flex).attrs(() => ({
  position: 'relative',
  as: 'main',
  flex: 0,
  flexDirection: 'column',
  width: ['100%', '28rem'],
  p: '2.5rem',
}))`
  z-index: 10;
  background: ${({ theme }) => theme.colors.primary.contrast};
  border-width: 0.0625rem;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray['200']};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  border-radius: 0.5rem;
`

export const LogoWrapper = styled(Flex).attrs(() => ({
  flex: 0,
}))``

export const FooterContainer = styled(Flex).attrs(({ theme }) => ({
  flex: 0,
  position: ['static', 'fixed'],
  flexDirection: 'column',
  width: ['100%', ...theme.breakpoints],
}))`
  bottom: 0;
`
